import * as React from 'react'
import { LoadingOutlined } from '@ant-design/icons'
import { Button as AntButton, Spin } from 'antd'
import { Auth } from 'aws-amplify'
import { Buffer } from 'buffer'
import { Button, CountdownText, DictionaryValue, Grid } from '../../components'
import { useRokt } from '../../context/roktContext'
import './ConfirmSignUp.scss'

type Props = {
  error?: Error
  username: string | null
  email: string | null
  confirmationCode: string | null
}

type State = {
  disableTimeout: boolean
  confirmed: boolean
  isVerifying: boolean
  error?: Error | string
  resend?: boolean
}

const antIcon = (
  <LoadingOutlined
    style={{ fontSize: 24 }}
    spin
  />
)

const ConfirmSignUp = (props: Props) => {
  const initialState: State = {
    disableTimeout: false,
    confirmed: false,
    isVerifying: false,
    resend: false,
  }

  const [state, setState] = React.useState(initialState)

  const rokt = useRokt()

  const handleEmailDecode = () => {
    const { username, email } = props
    if (username !== null && email !== null) {
      const buff = Buffer.from(email, 'base64')
      const emailDecoded = buff.toString('ascii')
      return emailDecoded
    }
    return null
  }

  const handleVerification = async () => {
    const { username, confirmationCode } = props
    const { confirmed } = state

    if (confirmationCode && username && !confirmed) {
      try {
        setState(prevState => {
          return { ...prevState, isVerifying: true, error: undefined }
        })
        const response = await Auth.confirmSignUp(username, confirmationCode)
        if (response === 'SUCCESS') {
          setState(prevState => {
            return { ...prevState, confirmed: true, isVerifying: false }
          })
          const emailDecoded = handleEmailDecode()
          handlePublishEmailToRokt(emailDecoded)
        } else {
          setState(prevState => {
            return { ...prevState, error: response }
          })
        }
      } catch (err: any) {
        setState(prevState => {
          return { ...prevState, error: err.message, isVerifying: false }
        })
      }
    } else {
      setState(prevState => {
        return { ...prevState, error: 'Invalid Confimation Link!', resend: true }
      })
    }
  }

  const sendVerificationEmail = async (username: string) => {
    try {
      await Auth.resendSignUp(username)
      setState(prevState => {
        return { ...prevState, disableTimeout: true }
      })

      setTimeout(() => {
        setState(prevState => {
          return { ...prevState, disableTimeout: false }
        })
      }, 60000)
    } catch (e: any) {
      alert(e.message)
    }
  }

  const handleCallback = () => {
    const { username } = props
    if (username !== null) {
      sendVerificationEmail(username)
    }
    throw new Error('Invalid Username')
  }

  const handlePublishEmailToRokt = (emailDecoded: string | null) => {
    try {
      if (!emailDecoded) {
        return
      }
      rokt.setAttributes({ email: emailDecoded })
      rokt.triggerPageChange('confirmSignUp.page')
    } catch (err) {
      console.error('Error while publishing email to rokt', err)
    }
  }

  const emailDecoded = handleEmailDecode()

  const t = new Date()
  t.setMinutes(t.getMinutes() + 1)

  React.useEffect(() => {
    return () => {
      rokt.closeAll()
    }
  }, [])

  return (
    <div className='confirm-sign-up'>
      <Grid>
        <div className='row'>
          <div
            className={`
                        col-xs-12 col-sm-8 col-sm-offset-2 col-md-6 col-md-offset-3 col-lg-4 col-lg-offset-4
                    `}
          >
            {!state.confirmed ? (
              <>
                <h1 className='confirm-sign-up__page-heading'>
                  <DictionaryValue token='confirm-sign-up.title' />
                </h1>
                {emailDecoded !== null && !state.resend ? (
                  <>
                    <div className='confirm-sign-up__page-intro'>
                      <DictionaryValue token='confirm-sign-up.verify-intro' />
                    </div>
                    <div className='confirm-sign-up__user-data'>
                      <span className='confirm-sign-up__data-label'>Your Email:</span> {emailDecoded}
                    </div>
                    <Button
                      buttonType='primary'
                      buttonStyle='square'
                      type='button'
                      disabled={state.isVerifying}
                      callback={handleVerification}
                      className='confirm-sign-up__button confirm-sign-up__button--send'
                    >
                      {state.isVerifying ? (
                        <>
                          Verifying ... <Spin indicator={antIcon} />
                        </>
                      ) : (
                        <>Verify Now</>
                      )}
                    </Button>
                    <div className='confirm-sign-up__page-intro confirm-sign-up__resend'>
                      <DictionaryValue token='confirm-sign-up.cannot-verify' />
                      <AntButton
                        type='link'
                        onClick={handleCallback}
                        disabled={state.disableTimeout}
                      >
                        <DictionaryValue token='confirm-sign-up.resend' />
                        {state.disableTimeout ? (
                          <>
                            <span> (</span>
                            <CountdownText to={Math.floor(t.getTime() / 1000)} />
                            <span>)</span>
                          </>
                        ) : (
                          ''
                        )}
                      </AntButton>
                    </div>
                  </>
                ) : (
                  <>
                    <div className='confirm-sign-up__page-intro'>
                      <DictionaryValue token='confirm-sign-up.intro' />
                    </div>
                    <Button
                      buttonType='primary'
                      buttonStyle='square'
                      type='button'
                      disabled={state.disableTimeout}
                      callback={handleCallback}
                      className='confirm-sign-up__button confirm-sign-up__button--send'
                    >
                      Resend Verification Email
                      {state.disableTimeout ? (
                        <>
                          <span> (</span>
                          <CountdownText to={Math.floor(t.getTime() / 1000)} />
                          <span>)</span>
                        </>
                      ) : (
                        ''
                      )}
                    </Button>
                  </>
                )}
                {state.error && (
                  <div className='confirm-sign-up__error'>
                    <>{state.error}</>
                  </div>
                )}
              </>
            ) : (
              <>
                <h1 className='confirm-sign-up__page-heading'>
                  <DictionaryValue token='confirm-sign-up.success.title' />
                </h1>
                <div className='confirm-sign-up__page-intro'>
                  <DictionaryValue token='confirm-sign-up.success.intro' />
                </div>
                <Button
                  buttonType='primary'
                  buttonStyle='square'
                  type='button'
                  redirectUrl='/account'
                  className='confirm-sign-up__button confirm-sign-up__button--login'
                >
                  Login Now
                </Button>
              </>
            )}
          </div>
        </div>
      </Grid>
    </div>
  )
}

export { ConfirmSignUp }
