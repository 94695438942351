// SVG ICON : CalendarIco
import React from 'react'

type Props = {
  color?: string
  className?: string
}

class ArrowRight extends React.Component<Props> {
  render() {
    const { color, className } = this.props

    return (
      <svg
        width='8'
        height='14'
        viewBox='0 0 8 14'
        fill='none'
        xmlns='http://www.w3.org/2000/svg'
        className={className}
      >
        <path
          d='M0.939453 0.654419L6.8928 6.60777L0.939453 12.5611'
          stroke={color || '#ffffff'}
          strokeWidth='1.13397'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
      </svg>
    )
  }
}

export default ArrowRight
