import Sticky from 'react-stickynode'
import Brand from '../../models/brand'
import NamedItem from '../../models/namedItem'
import NavigationLetter from './navigation-letter'
import './navigation.scss'

export type FieldProps = {
  groups: NamedItem<Brand[]>[]
}

export type MethodProps = {
  navigateTo: (letter: string) => void
}

export type Props = FieldProps & MethodProps

const Navigation = (props: Props) => {
  const { groups, navigateTo } = props

  const getAlphabet = () => {
    const alphabet = ['#']

    for (let i = 65; i <= 90; i += 1) {
      alphabet.push(String.fromCharCode(i))
    }

    return alphabet
  }

  const alphabets = getAlphabet()

  return (
    <Sticky
      top='.layout__header'
      innerZ={800}
    >
      <div className='brand-navigation'>
        <div className='brand-navigation__grid'>
          <div className='row brand-navigation__row'>
            <div className='col-xs-12 brand-navigation__cell'>
              {alphabets.map(letter => {
                const group = groups.find(group => group.name === letter)
                const isMissing = group === undefined || group.item.length === 0
                return (
                  <NavigationLetter
                    key={letter}
                    letter={letter}
                    navigateTo={navigateTo}
                    isMissing={isMissing}
                  />
                )
              })}
              <li className='brand-navigation__spacer' />
            </div>
          </div>
        </div>
      </div>
    </Sticky>
  )
}

export default Navigation
