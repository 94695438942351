import * as React from 'react'
import { Icon, IconType } from '../icon'
import './IconButton.scss'

type Props = {
    icon: IconType
    className?: string
    onClick?: () => void
    type?: 'button' | 'submit' | 'reset' | undefined
}

class IconButton extends React.Component<Props> {
    static defaultProps = {
        className: '',
        type: 'button',
    }

    render() {
        const { className, icon, onClick, type } = this.props

        return (
            <button
                className={`icon-button ${className}`}
                onClick={onClick}
                type={type}
            >
                <Icon icon={icon} wrappingElement='span' />
            </button>
        )
    }
}

export {
    IconButton
}
