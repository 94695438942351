import * as React from 'react'
import Deal from '../../models/deal'
import Card from './Card'
import { ApplicationState } from '../../reducers'
import { connect } from 'react-redux'
import { loadDeals, handleFavourite } from '../../data/actions'
import { getBrandUrl } from '../brand/utilities'
import { loadBrands } from '../brand/actions'
import { brandSelector, currentEventSelector } from '../../data/selectors'
import { DealType } from '../../models/dealType'
import { getResizedImageFromCDN, brandLogoSize } from '../../utilities'
import EventType from '../../models/eventType'
import { FavouriteDeal } from '../account/types'
import bind from 'bind-decorator'
import { Modal } from 'antd'
import { history } from '../../history'
import { withViewport, ViewportProps } from '../../properties/withViewport'
import moment from 'moment'

type OwnProps = {
  slug?: string
  showHover?: boolean
  className?: string
  type?: DealType
  location?: 'home' | 'default'
  isSponsored?: boolean
}

type FieldProps = {
  slug?: string
  showHover?: boolean
  event?: Event
  deal?: Deal
  brandName?: string
  brandImageUrl?: string
  className?: string
  type?: DealType
  eventType?: EventType
  location?: 'home' | 'default'
  isFavourite?: boolean
  favDealId?: string
  isLoggedIn?: boolean
  username?: string
  isExclusive?: boolean
  isGreyedOut?: boolean
  dealStartTime?: number
  dealEndTime?: number
  isSponsored?: boolean
}

type MethodProps = {
  loadDeals: (slugs: string[]) => void
  handleFavourite: (dealId: string, type: string, brandSlug: string, favDealId?: string, active?: boolean) => void
  loadBrands: () => void
}

type Props = FieldProps & MethodProps & ViewportProps

class LoadCard extends React.Component<Props> {
  load() {
    const { deal, slug, loadDeals, loadBrands } = this.props

    if (slug === undefined) {
      return
    }

    if (deal === undefined) {
      loadDeals([slug])
    }
    loadBrands()
  }

  @bind
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  toggleFavourite(e: React.MouseEvent) {
    const { deal, type, isLoggedIn, isFavourite, favDealId, handleFavourite } = this.props
    if (!isLoggedIn) {
      Modal.confirm({
        title: 'Want to mark this deal faviourite?',
        centered: true,
        content: 'You need to login to be able to mark favourite deals.',
        okText: 'Login Now',
        onOk() {
          history.replace('/account')
        },
      })
    } else if (deal && type && deal.brandSlug) {
      handleFavourite(deal.id, type, deal.brandSlug, favDealId, isFavourite)
    }
  }

  componentDidMount() {
    this.load()
  }

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  componentDidUpdate(prevProps: Props) {
    this.load()
  }

  render() {
    const {
      deal,
      showHover,
      className,
      brandName,
      brandImageUrl,
      type,
      eventType,
      location,
      isFavourite,
      favDealId,
      isMobile,
      isExclusive,
      isGreyedOut,
      isLoggedIn,
      dealStartTime,
      dealEndTime,
      isSponsored,
    } = this.props

    if (deal === undefined) {
      return null
    }

    let brandProps
    if (deal.brandSlug) {
      brandProps = {
        brandName,
        brandImageUrl,
        brandUrl: getBrandUrl(deal.brandSlug),
        brandSlug: deal.brandSlug,
      }
    }

    return (
      <Card
        id={deal.id}
        title={isMobile && deal.titleMobile ? deal.titleMobile : deal.title}
        offer={isMobile && deal.offerMobile ? deal.offerMobile : deal.offer}
        optional1={isMobile && deal.detailsMobile ? deal.detailsMobile : deal.details}
        optional2={isMobile && deal.redemptionMobile ? deal.redemptionMobile : deal.redemptionInfo}
        imageUrl={deal.image}
        slug={deal.slug}
        type={type}
        eventType={eventType}
        showHover={showHover}
        className={className}
        location={location}
        isFavourite={isFavourite}
        favDealId={favDealId}
        dealURL={deal.url}
        toggleFavourite={this.toggleFavourite}
        isExclusive={isExclusive}
        isGreyedOut={isGreyedOut}
        isLoggedIn={isLoggedIn}
        dealStartTime={dealStartTime}
        dealEndTime={dealEndTime}
        isSponsored={isSponsored}
        {...brandProps}
      />
    )
  }
}

const mapStateToProps = (state: ApplicationState, ownProps: OwnProps): FieldProps => {
  if (ownProps.slug === undefined) {
    return {}
  }
  const event = currentEventSelector(state)
  let deal: Deal | undefined = state.data.deals[ownProps.slug]

  let location = ownProps.location
  if (location === undefined) {
    location = 'default'
  }

  let brandName
  let brandImageUrl
  if (deal !== undefined && deal.brandSlug !== undefined) {
    const brand = brandSelector(state, deal.brandSlug)
    brandName = brand === undefined ? undefined : brand.name
    brandImageUrl =
      brand === undefined ? undefined : getResizedImageFromCDN(brand.logo, brand.logoIsBitmap, brandLogoSize.dealCard)
  }

  let isFavourite = false
  let favDealId: any = undefined
  if (state.account.favDeals && deal) {
    const favDeal = state.account.favDeals.find((item: FavouriteDeal) => item.dealId === deal?.id)

    if (favDeal) {
      if (favDeal.active) {
        isFavourite = true
      }
      favDealId = favDeal.id
    }
  }

  let isExclusive = false
  let isGreyedOut = false

  if (deal !== undefined) {
    isExclusive = deal.isExclusive !== undefined && deal.isExclusive
    isGreyedOut = deal.isGreyedOut !== undefined && deal.isGreyedOut
  }

  let dealStartTime = event && parseInt(moment(moment.unix(event.start).format('YYYY-MM-DD')).format('X'))
  let dealEndTime = event && parseInt(event.end.toString())

  if (deal && deal.dealStartTime) {
    dealStartTime = deal.dealStartTime
  }

  if (deal && deal.dealEndTime) {
    dealEndTime = deal.dealEndTime
  }

  if (
    !(
      dealStartTime &&
      dealStartTime <= Math.floor(Date.now() / 1000) &&
      dealEndTime &&
      dealEndTime > Math.floor(Date.now() / 1000)
    ) &&
    !state.account.isAdmin &&
    !isExclusive
  ) {
    deal = undefined
  }

  return {
    deal,
    location,
    brandName,
    brandImageUrl,
    isFavourite,
    favDealId,
    isExclusive,
    isGreyedOut,
    dealStartTime,
    dealEndTime,
    slug: ownProps.slug,
    type: ownProps.type,
    eventType: event && event.type ? event.type : undefined,
    isLoggedIn: state.account.loggedIn,
    username: state.account.username,
  }
}

const actions: MethodProps = {
  loadDeals,
  loadBrands,
  handleFavourite,
}

const CardWithData = connect(mapStateToProps, actions)(withViewport(LoadCard))
export default CardWithData
