import { CognitoUser, CognitoUserAttribute } from 'amazon-cognito-identity-js'
import { API, Auth } from 'aws-amplify'
import * as jose from 'jose'

import { handleError } from '.'

const ADMIN_USER_GROUP = 'click-frenzy-admin'
export const MEMBERSHIP_GROUPS = ['frenz-with-benefits', 'special-frenz', 'best-frenz', 'best-frenz-forever']

const LOCAL_STORAGE_IS_ADMIN = 'click-frenzy-is-admin'

// eslint-disable-next-line @typescript-eslint/no-inferrable-types
export function getCurrentUser(bypassCache: boolean = false) {
  return Auth.currentAuthenticatedUser({ bypassCache })
}

export const getCurrentUserJwtTokenInfo = async () => {
  const token = (await Auth.currentSession()).getAccessToken().getJwtToken()
  return jose.decodeJwt(token)
}

export const userHasAdminGroup = (user?: CognitoUser, groups?: string | string[]) => {
  let isAdmin = false

  if (groups) {
    if (Array.isArray(groups)) {
      isAdmin = groups.includes(ADMIN_USER_GROUP)
    } else {
      isAdmin = groups === ADMIN_USER_GROUP
    }
  } else if (user) {
    const session = user.getSignInUserSession()
    if (session) {
      const groups = session.getAccessToken().payload['cognito:groups'] || []
      isAdmin = groups.includes(ADMIN_USER_GROUP)
    }
  }

  return isAdmin
}

export const getMembershipGroup = (user?: CognitoUser, groups?: string | string[]) => {
  let group

  if (groups) {
    if (Array.isArray(groups)) {
      group = groups.find(group => MEMBERSHIP_GROUPS.includes(group))
    } else {
      group = groups
    }
  } else if (user) {
    const session = user.getSignInUserSession()
    if (session) {
      const groups: string[] = session.getAccessToken().payload['cognito:groups'] || []
      group = groups.find(group => MEMBERSHIP_GROUPS.includes(group))
    }
  }

  return group
}

export const addUserToFwBGroup = (username: string): Promise<boolean> => {
  return new Promise((resolve, reject) => {
    API.post('clickfrenzyFeUsers', `/join/${username}`, {}).then(res => {
      if (res.success) {
        resolve(true)
      } else {
        handleError(res.error)
        reject(false)
      }
    })
  })
}

export const setIsUserAdminInsecure = () => {
  try {
    window.localStorage.setItem(LOCAL_STORAGE_IS_ADMIN, 'true')
  } catch (error) {
    console.log(error)
  }
}

export const removeIsUserAdminInsecure = () => {
  try {
    window.localStorage.removeItem(LOCAL_STORAGE_IS_ADMIN)
  } catch (error) {
    console.log(error)
  }
}

export const isUserAdminInsecure = () => {
  try {
    return window.localStorage.getItem(LOCAL_STORAGE_IS_ADMIN) === 'true'
  } catch (error) {
    console.log(error)
  }
  return false
}

export const isUserAdmin = async () => {
  try {
    const user = await getCurrentUser()
    return userHasAdminGroup(user)
  } catch (_) {
    return Promise.resolve(false)
  }
}

export const getAttributeByName = (name: string, attributes: CognitoUserAttribute[]) => {
  const attribute = attributes.find(attribute => attribute.getName() === name)

  return attribute ? attribute.getValue() : ''
}
