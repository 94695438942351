import * as React from 'react'
import classnames from 'classnames'
import bind from 'bind-decorator'
import { Link } from 'react-router-dom'
import { Grid } from '../../../components/grid'
import { Hamburger, Icon, logo } from '../../../components'
import { DictionaryValue } from '../../../components/dictionary-value'
import './NavBar.scss'
import { enableScroll, disableScroll } from '../../../utilities'
import goWildAvatar from '../../../static/images/avatar.png'
import mayhemAvatar from '../../../static/images/mayhem-avatar.png'
import juloveTravelAvatar from '../../../static/images/julove-travel-avatar.png'
import fashionAvatar from '../../../static/images/fashion-avatar.png'
import EventType from '../../../models/eventType'
import { MenuItem } from '../../../models/menu'
import { TopBanner } from '../../../models/topBanner'
import SecondNavWithData from './SecondNavWithData'
import CategoryNavWithData from './CategoryNavWithData'
import { SearchBarWithData } from '../../search/components/SearchBar'
import { SearchRootWithData } from '../../search/components/SearchRoot'
import { isUserOnSignupPage } from '../../account/utilities'
import MobileNavWithData from './MobileNavWithData'
import SponsorBubble from '../../../components/sponsor-bubble'

export interface NavBarColors {
  backgroundColor: string
  countdownBackgroundColor: string
  countdownTextColor: string
}

export interface FieldProps {
  isEventRunning: boolean
  isSubmenuOpen: boolean
  isCategoryMenuOpen: boolean
  isMobileNavMenuOpen: boolean
  isLoggedIn: boolean
  eventType?: EventType | null
  customColors?: NavBarColors
  customMenuItems?: MenuItem[]
  eventLogo?: string | null
  topBanner: TopBanner | null
  eventStartDate?: number | null
  eventName?: string | null
  resetPageHeight?: () => void
}

export interface MethodProps {
  openSubmenu: () => void
  closeSubmenu: () => void
  openCategoryMenu: () => void
  closeCategoryMenu: () => void
  openMobileNavMenu: () => void
  closeMobileNavMenu: () => void
}

type Props = FieldProps & MethodProps

type State = {
  mainCategoryIndexSelected: number | null
}

let avatar: any = undefined

class NavBar extends React.Component<Props, State> {
  private mainNavBarRef = React.createRef<HTMLDivElement>()
  readonly state: State = {
    mainCategoryIndexSelected: null,
  }

  componentDidMount() {
    document.body.style.overscrollBehavior = 'none'
    let lastScrollTop: number
    window.addEventListener('scroll', () => {
      const scrollTop: number = window.pageYOffset || document.documentElement.scrollTop
      if (document.documentElement.offsetWidth < 768 && this.props.isEventRunning && this.mainNavBarRef.current) {
        if (scrollTop > lastScrollTop && scrollTop > 200) {
          this.mainNavBarRef.current.style.display = 'none'
        } else {
          this.mainNavBarRef.current.style.display = 'block'
        }
      }
      lastScrollTop = scrollTop
    })
  }

  componentDidUpdate() {
    if (this.props.resetPageHeight) {
      this.props.resetPageHeight()
    }
  }

  @bind
  mainCategoryHandler(index: number) {
    this.setState({
      mainCategoryIndexSelected: index,
    })
  }

  @bind
  toggleMenu() {
    const { openSubmenu, closeSubmenu, isSubmenuOpen } = this.props

    if (isSubmenuOpen) {
      closeSubmenu()
      enableScroll()
    } else {
      openSubmenu()
      disableScroll()
    }
  }

  @bind
  toggleCategoryMenu() {
    const { openCategoryMenu, closeCategoryMenu, isCategoryMenuOpen } = this.props

    if (isCategoryMenuOpen) {
      closeCategoryMenu()
      enableScroll()
    } else {
      this.setState({
        mainCategoryIndexSelected: null,
      })
      openCategoryMenu()
      disableScroll()
    }
  }

  @bind
  toggleMobileNavMenu() {
    const { openMobileNavMenu, closeMobileNavMenu, isMobileNavMenuOpen } = this.props

    if (isMobileNavMenuOpen) {
      closeMobileNavMenu()
      enableScroll()
    } else {
      openMobileNavMenu()
      disableScroll()
    }
  }

  render() {
    const {
      isEventRunning,
      isCategoryMenuOpen,
      isLoggedIn,
      eventType,
      eventLogo,
      topBanner,
      isMobileNavMenuOpen,
      resetPageHeight,
    } = this.props

    avatar = goWildAvatar

    if (eventType !== undefined && eventType !== null) {
      if (eventType.toString() === 'mayhem') {
        avatar = mayhemAvatar
      }
      if (eventType.toString() === 'julove' || eventType.toString() === 'travel') {
        avatar = juloveTravelAvatar
      }
      if (eventType.toString() === 'fashion') {
        avatar = fashionAvatar
      }
    }

    const isSearchPath = window.location.pathname === '/search'

    const isCfReceiptPath = window.location.pathname === '/clickfrenzyreceipt'

    const isSignUpPage = isUserOnSignupPage()

    return (
      <>
        {/* {!isEventRunning && eventStartDate && (
          <NavCountdownTimer
            to={eventStartDate}
            eventType={eventType}
            description={eventName ? eventName.toLocaleUpperCase() + ' STARTS IN' : ''}
          />
        )} */}
        <nav
          className='nav-bar'
          style={{ display: isMobileNavMenuOpen ? 'none' : 'block' }}
        >
          <div
            ref={this.mainNavBarRef}
            className={`nav-bar__body nav-bar__body--${eventType}`}
          >
            <Grid>
              <div className='row'>
                <div className='col-xs-12 nav-bar__wrapper'>
                  <Hamburger
                    className='nav-bar__hamburger nav-bar__hamburger--mobile'
                    isActive={isMobileNavMenuOpen}
                    animationType={'arrowalt'}
                    onClick={this.toggleMobileNavMenu}
                  />
                  <div className='nav-bar__left'>
                    <a
                      href='/'
                      className='nav-bar__home-link'
                    >
                      {eventLogo ? (
                        <img
                          data-blink-src={eventLogo}
                          data-blink-quality='better'
                          data-blink-resize='200x'
                          className='nav-bar__event-logo'
                          alt='nav-bar-event-logo'
                        />
                      ) : (
                        <Icon
                          icon={logo}
                          className='nav-bar__logo'
                        />
                      )}
                    </a>
                    {topBanner && (
                      <SponsorBubble
                        topBanner={topBanner}
                        textColor={topBanner.bannerTextColor ? topBanner.bannerTextColor.toString() : '#000'}
                        backgroundColor={topBanner.backgroundColor ? topBanner.backgroundColor.toString() : '#fff'}
                      />
                    )}
                  </div>
                  {isEventRunning && !isCfReceiptPath && !isSignUpPage && (
                    <div className='nav-bar__center'>
                      {isSearchPath ? (
                        <SearchRootWithData>
                          <SearchBarWithData eventType={this.props.eventType} />
                        </SearchRootWithData>
                      ) : (
                        <Link to='/search'>
                          <SearchRootWithData>
                            <SearchBarWithData eventType={this.props.eventType} />
                          </SearchRootWithData>
                        </Link>
                      )}
                    </div>
                  )}
                  <div className='nav-bar__right'>
                    {isLoggedIn && isEventRunning && (
                      <Link
                        to='/favourite'
                        className='nav-bar__link nav-bar__link--is-icon nav-bar__link-favourite'
                      >
                        <svg
                          width={20}
                          height={18}
                          viewBox='0 0 20 18'
                          fill='none'
                          xmlns='http://www.w3.org/2000/svg'
                        >
                          <path
                            d='M2.70109 2.81694C1.01695 4.50107 1.01695 7.23159 2.70109 8.91573L10.063 16.2777L17.4249 8.91573C19.109 7.23159 19.109 4.50107 17.4249 2.81693C15.7407 1.1328 13.0102 1.1328 11.3261 2.81694L10.063 4.08009L8.79989 2.81694C7.11575 1.1328 4.38523 1.1328 2.70109 2.81694Z'
                            stroke='#FFFFFF'
                            strokeWidth={2}
                            strokeLinecap='round'
                            strokeLinejoin='round'
                            style={
                              eventType?.toString() === EventType[EventType.goWild]
                                ? { stroke: 'black' }
                                : eventType?.toString() === EventType[EventType.fashion]
                                ? { stroke: '#0070E0', fill: '#0070E0' }
                                : { stroke: 'white' }
                            }
                          />
                        </svg>
                      </Link>
                    )}
                    {isLoggedIn ? (
                      <Link
                        to='/account'
                        className='nav-bar__link nav-bar__link--avatar'
                      >
                        <img
                          data-blink-src={avatar}
                          alt='Account'
                          className='nav-bar__avatar'
                        />
                      </Link>
                    ) : (
                      <>
                        <Link
                          to='/account?join'
                          className={`
                                                        nav-bar__link-join
                                                        nav-bar__link${
                                                          eventType ? eventType && ` nav-bar__link--${eventType}` : ''
                                                        }`}
                        >
                          <DictionaryValue token='header.nav.links.join' />
                        </Link>
                        <Link
                          to='/account?login'
                          className={`
                                                        nav-bar__link-login
                                                        nav-bar__link${
                                                          eventType ? eventType && ` nav-bar__link--${eventType}` : ''
                                                        }`}
                        >
                          <div className={`nav-bar__login-button nav-${eventType}`}>
                            <div className={`nav-bar__login-button-icon nav-${eventType}`}>
                              <div className='nav-bar__login-button-icon-top' />
                              <div className='nav-bar__login-button-icon-bottom' />
                            </div>
                            <div className={`nav-bar__login-button-text nav-${eventType}`}>
                              <DictionaryValue token='header.nav.links.login' />
                            </div>
                          </div>
                        </Link>
                      </>
                    )}
                  </div>
                </div>
              </div>
            </Grid>
          </div>
          {!isSignUpPage && !isCfReceiptPath && (
            <>
              <SecondNavWithData
                categoryMenuHandler={this.toggleCategoryMenu}
                eventType={eventType}
              />
              {/* {!isEventRunning && <NavBanner />} */}
            </>
          )}
          <CategoryNavWithData
            isActive={isCategoryMenuOpen}
            mainCategoryIndex={this.state.mainCategoryIndexSelected}
            mainCategoryHandler={this.mainCategoryHandler}
          />
        </nav>
        {isMobileNavMenuOpen && (
          <nav className='mobile-nav'>
            <MobileNavWithData resetPageHeight={resetPageHeight} />
          </nav>
        )}
        <div
          className={classnames('nav-bar-overlay', {
            'nav-bar-overlay--is-active': isCategoryMenuOpen || isMobileNavMenuOpen,
          })}
          onClick={this.toggleCategoryMenu}
        />
      </>
    )
  }
}

export default NavBar
