import * as React from 'react'
import { Grid } from '../components/grid'
import { Layout } from './layout'
import { DictionaryValue } from '../components/dictionary-value'
import { Button } from '../components/button'
import './NotFound.scss'
import { connect } from 'react-redux'
import EventType from '../models/eventType'

type Props = {
    eventType: EventType | undefined | null
}

class NotFound extends React.Component<Props> {
    render() {
        const { eventType } = this.props
        return (
            <Layout>
                <Grid>
                    <div className='row'>
                        <div
                            className={`
                            col-xs-8
                            col-xs-offset-2
                            col-sm-6
                            col-sm-offset-3
                            col-md-12
                            col-md-offset-0
                            col-lg-12
                            col-lg-offset-0
                            not-found__container
                            `}
                        >
                            <p className='not-found__container--main-heading'>
                                <DictionaryValue token='404page.title' />
                            </p>
                        </div>
                        <div
                            className={`
                                col-xs-8
                                col-xs-offset-2
                                col-sm-6
                                col-sm-offset-3
                                col-md-6
                                col-md-offset-3
                                col-lg-4
                                col-lg-offset-4
                                not-found__container
                            `}
                        >
                            <p><DictionaryValue token='404page.description' /></p>
                            <div
                                className={`
                                col-xs-12
                                col-sm-8
                                col-sm-offset-2
                                not-found__cta-button-signup
                                `}
                            >
                                <Button
                                    className={eventType ? `not-found__cta-button--${eventType}` : ''}
                                    buttonType='primary'
                                    buttonStyle='square'
                                    size='large'
                                    redirectUrl='/account?join'
                                >
                                    SIGN UP NOW
                                </Button>
                            </div>
                            <div
                                className={`
                                col-xs-12
                                col-sm-8
                                col-sm-offset-2
                                not-found__cta-button-home
                                `}
                            >
                                <Button
                                    className={eventType ? `not-found__cta-button--${eventType}` : ''}
                                    buttonType='primary'
                                    buttonStyle='square'
                                    size='large'
                                    redirectUrl='/'
                                >
                                    RETURN TO HOME
                                </Button>
                            </div>
                        </div>
                    </div>
                </Grid>
            </Layout>
        )
    }
}

const mapStateToProps = (state: any) => {
    const eventType = state.data.events.length > 0 ? state.data.events[0].type : null
    return { eventType }
}

export default connect(mapStateToProps)(NotFound)
