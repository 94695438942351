import * as React from 'react'
import bind from 'bind-decorator'
import { history } from '../../../../../../src/history'
import { CfButton } from '../../../../../components/cf-package'
import './style/Card.scss'

type Props = {
  className?: string
  id?: string
}

class SignUpCard extends React.Component<Props> {
  @bind
  handleSignUpLink() {
    history.push('/account?join')
  }

  render() {
    const { className = '', id } = this.props

    return (
      <section
        className={`${className} cf-sign-up-card cf-gray-card event-card`}
        id={id}
      >
        <div className='inner cf-flex flex-col'>
          <div className='ct'>
            <h3 className='ttl head-md'>Upcoming events</h3>
            <div className='desc'>
              Save the date on our upcoming Click Frenzy events. Don’t miss out, become a member and get notified early.
            </div>
          </div>
          <div className='cta-blk'>
            <CfButton
              onClick={this.handleSignUpLink}
              buttonType='blue'
              size='large'
            >
              Sign Up
            </CfButton>
          </div>
        </div>
      </section>
    )
  }
}

export default SignUpCard
