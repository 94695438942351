import { connect } from 'react-redux'
import { currentEventSelector } from '../../../data/selectors'
import { ApplicationState } from '../../../reducers'
import Popup, { FieldProps } from './Popup'

const mapStateToProps = (state: ApplicationState): FieldProps => {
  const event = currentEventSelector(state)
  return {
    loading: state.loading.count !== 0,
    isLoggedIn: state.account.loggedIn,
    group: state.account.group,
    username: state.account.username,
    mobile: state.account.mobile,
    address: state.account.address,
    eventType: event && event.type ? event.type : undefined,
  }
}

export default connect(mapStateToProps)(Popup)
