// SVG ICON : ClockIco
import React from 'react'

type Props = {
  color?: string
}

class ClockIco extends React.Component<Props> {
  render() {
    const { color } = this.props

    return (
      <svg
        width='33'
        height='32'
        viewBox='0 0 33 32'
        fill='none'
        xmlns='http://www.w3.org/2000/svg'
      >
        <path
          d='M10.7552 8.33317C14.8053 8.33317 18.0885 6.84079 18.0885 4.99984C18.0885 3.15889 14.8053 1.6665 10.7552 1.6665C6.70512 1.6665 3.42188 3.15889 3.42188 4.99984C3.42188 6.84079 6.70512 8.33317 10.7552 8.33317Z'
          stroke={color || '#E9028D'}
          strokeMiterlimit='10'
          strokeLinecap='square'
        />
        <path
          d='M18.0885 5V9.66667C18.0885 11.5073 14.8052 13 10.7552 13C6.70521 13 3.42188 11.5073 3.42188 9.66667V5'
          stroke={color || '#E9028D'}
          strokeMiterlimit='10'
        />
        <path
          d='M15.4219 16.9045C14.1539 17.3805 12.5279 17.6665 10.7552 17.6665C6.70521 17.6665 3.42188 16.1738 3.42188 14.3332V9.6665'
          stroke={color || '#E9028D'}
          strokeMiterlimit='10'
        />
        <path
          d='M15.4212 21.571C14.1539 22.047 12.5279 22.333 10.7552 22.333C6.70521 22.333 3.42188 20.8403 3.42188 18.9997V14.333'
          stroke={color || '#E9028D'}
          strokeMiterlimit='10'
        />
        <path
          d='M15.4219 26.238C14.1539 26.714 12.5279 27 10.7552 27C6.70521 27 3.42188 25.5073 3.42188 23.6667V19'
          stroke={color || '#E9028D'}
          strokeMiterlimit='10'
        />
        <path
          d='M22.7552 16.9997C26.8053 16.9997 30.0885 15.5073 30.0885 13.6663C30.0885 11.8254 26.8053 10.333 22.7552 10.333C18.7051 10.333 15.4219 11.8254 15.4219 13.6663C15.4219 15.5073 18.7051 16.9997 22.7552 16.9997Z'
          stroke={color || '#E9028D'}
          strokeMiterlimit='10'
          strokeLinecap='square'
        />
        <path
          d='M30.0885 13.6665V18.3332C30.0885 20.1738 26.8052 21.6665 22.7552 21.6665C18.7052 21.6665 15.4219 20.1738 15.4219 18.3332V13.6665'
          stroke={color || '#E9028D'}
          strokeMiterlimit='10'
        />
        <path
          d='M30.0885 18.333V22.9997C30.0885 24.8403 26.8052 26.333 22.7552 26.333C18.7052 26.333 15.4219 24.8403 15.4219 22.9997V18.333'
          stroke={color || '#E9028D'}
          strokeMiterlimit='10'
        />
        <path
          d='M30.0885 23V27.6667C30.0885 29.5073 26.8052 31 22.7552 31C18.7052 31 15.4219 29.5073 15.4219 27.6667V23'
          stroke={color || '#E9028D'}
          strokeMiterlimit='10'
        />
      </svg>
    )
  }
}

export default ClockIco
