import React from 'react'
import './SearchRefineBy.scss'
import { CheckboxList, CheckboxItem } from '../../../components'
import { DictionaryValue } from '../../../components/dictionary-value'
import bind from 'bind-decorator'
import { RESET_ITEM } from '../constants'

type Props = {
    items: CheckboxItem[]
    refine: (value: string[]) => any
    loadBrands: () => void
    requestClose?: () => void
    className?: string
}

class SearchRefineBy extends React.Component<Props> {
    componentDidMount() {
        this.props.loadBrands()
    }

    @bind
    handleItemClick(item: CheckboxItem) {
        const { refine } = this.props

        if (item.id === RESET_ITEM) {
            refine([])
            return
        }

        refine(item.value)
    }

    @bind
    handleAction() {
        const { requestClose } = this.props

        if (requestClose) {
            requestClose()
        }
    }

    render() {
        const { items, className = '' } = this.props

        return (
            <div className={`search-refine-by ${className}`}>
                <CheckboxList
                    className='filter-by-brand'
                    handleAction={this.handleAction}
                    onItemClick={this.handleItemClick}
                    buttonText={<DictionaryValue token='search.filter.modal.done' />}
                    items={items}
                />
            </div>
        )
    }
}

export { SearchRefineBy }
