import { connect } from 'react-redux'
import { default as EventsCountdown, Props } from './EventsCountdown'
import { ApplicationState } from '../../reducers'
import { currentAndFutureEventsSelector } from '../../data/selectors'

const mapStateToProps = (state: ApplicationState): Props => {
    return {
        events: currentAndFutureEventsSelector(state),
    }
}

export default connect(mapStateToProps)(EventsCountdown)
