import React, { PropsWithChildren } from 'react'
import './ColumnHeader.scss'

class ColumnHeader extends React.Component<PropsWithChildren> {
    render() {
        return <h2 className='column-header'>{this.props.children}</h2>
    }
}

export { ColumnHeader }
