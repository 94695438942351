import * as React from 'react'
import { Layout } from '../layout'
import { AccountPageWithData } from './AccountPageWithData'
import { CFHeader, CFFooterWithData } from '../../components/cf-package'
import FooterLink from '../../models/footerLink'

type Props = {
  isEventRunning: boolean
  links?: FooterLink[]
}

class AccountArea extends React.Component<Props> {
  render() {
    const { isEventRunning, links } = this.props
    return isEventRunning ? (
      <Layout>
        <AccountPageWithData />
      </Layout>
    ) : (
      <Layout
        className='cf-layout'
        hideFooter
        hideHeader
        showFooterPartners={false}
      >
        <CFHeader links={links} />
        <section className='cf-section'>
          <AccountPageWithData />
        </section>
        <CFFooterWithData />
      </Layout>
    )
  }
}

export { AccountArea }
