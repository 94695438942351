import * as React from 'react'
import classnames from 'classnames'
import bind from 'bind-decorator'
import { Icon, chevron, DropdownMenu } from '..'
import './Dropdown.scss'
import { disableScroll, enableScroll } from '../../utilities'
import { withViewport, ViewportProps } from '../../properties/withViewport'

type Props = {
    label: string | JSX.Element
    labelMobile?: string | JSX.Element
    dropdownMenuTitle: string | JSX.Element
    className?: string
    unmountDropdownMenuOnClose?: boolean
    hideCloseButtonForDropdownMenu?: boolean
    children: ((toggleDropdown: () => void, dropdownStatus: boolean) => JSX.Element) | JSX.Element
} & ViewportProps

type State = {
    open: boolean
}

const ESC_KEY = 27

class Dropdown extends React.Component<Props, State> {
    public static defaultProps = {
        unmountDropdownMenuOnClose: false,
        hideCloseButtonForDropdownMenu: false,
    }

    public state = {
        open: false,
    }

    @bind
    toggleDropdown() {
        const { isMobile } = this.props
        const willBeOpen = !this.state.open

        if (willBeOpen && isMobile) {
            disableScroll()
        } else {
            enableScroll()
        }

        this.setState({
            open: willBeOpen,
        })
    }

    @bind
    listenForEscapeKeyPress(event: KeyboardEvent) {
        const { open } = this.state

        if (event.keyCode === ESC_KEY && open) {
            event.stopPropagation()
            this.toggleDropdown()
        }
    }

    componentDidMount() {
        window.document.addEventListener('keydown', this.listenForEscapeKeyPress)
    }

    componentWillUnmount() {
        window.document.removeEventListener('keydown', this.listenForEscapeKeyPress)
    }

    render() {
        const {
            label,
            labelMobile,
            dropdownMenuTitle,
            className,
            unmountDropdownMenuOnClose,
            children,
            isMobile,
            hideCloseButtonForDropdownMenu,
        } = this.props
        const { open } = this.state
        const additionalClasses = classnames(className, {
            'dropdown--open': open,
        })

        return (
            <div className={`dropdown ${additionalClasses}`}>
                <button className='dropdown__label' onClick={this.toggleDropdown}>
                    <span className='dropdown__label-mobile'>{labelMobile || label}</span>
                    <span className='dropdown__label-desktop'>{label}</span>
                    <Icon className='dropdown__label-icon' icon={chevron} />
                </button>
                <DropdownMenu
                    open={open}
                    requestClose={this.toggleDropdown}
                    title={dropdownMenuTitle}
                    unmountOnExit={unmountDropdownMenuOnClose}
                    isMobile={isMobile}
                    hideCloseButton={hideCloseButtonForDropdownMenu}
                >
                    {typeof children === 'function' ? children(this.toggleDropdown, this.state.open) : children}
                </DropdownMenu>
            </div>
        )
    }
}

const DropdownWithViewport = withViewport(Dropdown)

export { Dropdown, DropdownWithViewport }
