import { GraphQLResult } from '@aws-amplify/api'
import { API, graphqlOperation } from 'aws-amplify'
import * as queries from '../graphql/queries'

export function getQueryByKey(key: string) {
  const resources: any = queries
  return resources[key]
}

export function getNormalisedResultFromData(
  data: any,
  queryKey: string,
  selector?: string | null,
  alias?: string | null,
  onFilter?: (item: any) => any
) {
  const result = data[alias || queryKey]
  let items

  // List query
  if (result.items) {
    items = result.items
      .filter((item: any) => {
        if (onFilter) {
          return onFilter(item)
        }
        return item !== null
      })
      .map((item: any) => {
        if (!selector) {
          return item
        }

        const { [selector]: prop } = item
        if (!prop) {
          throw new Error(`Could not find property ${selector} in ${queryKey}`)
        }

        return prop
      })
  } else {
    // Get query
    items = result
  }

  return items
}

export async function renderAllData(
  allData: any[],
  nextToken: string | undefined,
  queryKey: string,
  filter?: any,
  alias?: string | null,
  authMode?: boolean
) {
  const operation = getQueryByKey(queryKey)
  const params = {
    filter,
    nextToken,
    limit: 100000,
  }

  let result

  if (authMode) {
    result = (await API.graphql({ query: operation, authMode: 'AWS_IAM' })) as GraphQLResult
  } else {
    result = (await API.graphql(graphqlOperation(operation, params))) as GraphQLResult
  }

  if (!result || result.data === undefined) {
    throw new Error('Could not load data')
  }

  const data = (result.data as any)[alias || queryKey]
  const formattedData = getNormalisedResultFromData(result.data, queryKey, undefined, alias)
  let allDataMore = allData.concat(formattedData)

  // eslint-disable-next-line no-prototype-builtins
  if (data.hasOwnProperty('nextToken') && data.nextToken !== null) {
    allDataMore = (await renderAllData(allDataMore, data.nextToken, queryKey, filter, alias)) as any[]
  }

  return allDataMore
}
