// SVG ICON : CalendarIco
import React from 'react'

type Props = {
  className?: string
}

class BigArrow2 extends React.Component<Props> {
  render() {
    const { className } = this.props

    return (
      <svg
        width='141'
        height='230'
        viewBox='0 0 141 230'
        fill='none'
        xmlns='http://www.w3.org/2000/svg'
        className={className}
      >
        <path
          opacity='0.1'
          d='M0 0V183.913L43.8825 144.655L80.6676 230L119.651 214.697L80.4892 131.562L141 124.529'
          fill='white'
        />
      </svg>
    )
  }
}

export default BigArrow2
