/* tslint:disable */
/* eslint-disable */
// this is an auto generated file. This will be overwritten

import * as APITypes from "../API";
type GeneratedMutation<InputType, OutputType> = string & {
  __generatedMutationInput: InputType;
  __generatedMutationOutput: OutputType;
};

export const createUserUploadedFiles = /* GraphQL */ `mutation CreateUserUploadedFiles(
  $input: CreateUserUploadedFilesInput!
  $condition: ModelUserUploadedFilesConditionInput
) {
  createUserUploadedFiles(input: $input, condition: $condition) {
    id
    userId
    eventId
    brand
    spend
    paymentMethod
    save
    name
    url
    uploadedAt
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreateUserUploadedFilesMutationVariables,
  APITypes.CreateUserUploadedFilesMutation
>;
export const updateUserUploadedFiles = /* GraphQL */ `mutation UpdateUserUploadedFiles(
  $input: UpdateUserUploadedFilesInput!
  $condition: ModelUserUploadedFilesConditionInput
) {
  updateUserUploadedFiles(input: $input, condition: $condition) {
    id
    userId
    eventId
    brand
    spend
    paymentMethod
    save
    name
    url
    uploadedAt
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdateUserUploadedFilesMutationVariables,
  APITypes.UpdateUserUploadedFilesMutation
>;
export const deleteUserUploadedFiles = /* GraphQL */ `mutation DeleteUserUploadedFiles(
  $input: DeleteUserUploadedFilesInput!
  $condition: ModelUserUploadedFilesConditionInput
) {
  deleteUserUploadedFiles(input: $input, condition: $condition) {
    id
    userId
    eventId
    brand
    spend
    paymentMethod
    save
    name
    url
    uploadedAt
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.DeleteUserUploadedFilesMutationVariables,
  APITypes.DeleteUserUploadedFilesMutation
>;
export const createUserFrenzWithBenefits = /* GraphQL */ `mutation CreateUserFrenzWithBenefits(
  $input: CreateUserFrenzWithBenefitsInput!
  $condition: ModelUserFrenzWithBenefitsConditionInput
) {
  createUserFrenzWithBenefits(input: $input, condition: $condition) {
    id
    userId
    eventId
    brand
    spend
    paymentMethod
    save
    name
    url
    uploadedAt
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreateUserFrenzWithBenefitsMutationVariables,
  APITypes.CreateUserFrenzWithBenefitsMutation
>;
export const updateUserFrenzWithBenefits = /* GraphQL */ `mutation UpdateUserFrenzWithBenefits(
  $input: UpdateUserFrenzWithBenefitsInput!
  $condition: ModelUserFrenzWithBenefitsConditionInput
) {
  updateUserFrenzWithBenefits(input: $input, condition: $condition) {
    id
    userId
    eventId
    brand
    spend
    paymentMethod
    save
    name
    url
    uploadedAt
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdateUserFrenzWithBenefitsMutationVariables,
  APITypes.UpdateUserFrenzWithBenefitsMutation
>;
export const deleteUserFrenzWithBenefits = /* GraphQL */ `mutation DeleteUserFrenzWithBenefits(
  $input: DeleteUserFrenzWithBenefitsInput!
  $condition: ModelUserFrenzWithBenefitsConditionInput
) {
  deleteUserFrenzWithBenefits(input: $input, condition: $condition) {
    id
    userId
    eventId
    brand
    spend
    paymentMethod
    save
    name
    url
    uploadedAt
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.DeleteUserFrenzWithBenefitsMutationVariables,
  APITypes.DeleteUserFrenzWithBenefitsMutation
>;
export const createUserFavouriteDeal = /* GraphQL */ `mutation CreateUserFavouriteDeal(
  $input: CreateUserFavouriteDealInput!
  $condition: ModelUserFavouriteDealConditionInput
) {
  createUserFavouriteDeal(input: $input, condition: $condition) {
    id
    type
    userId
    dealId
    brandSlug
    active
    addedAt
    deactivatedAt
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreateUserFavouriteDealMutationVariables,
  APITypes.CreateUserFavouriteDealMutation
>;
export const updateUserFavouriteDeal = /* GraphQL */ `mutation UpdateUserFavouriteDeal(
  $input: UpdateUserFavouriteDealInput!
  $condition: ModelUserFavouriteDealConditionInput
) {
  updateUserFavouriteDeal(input: $input, condition: $condition) {
    id
    type
    userId
    dealId
    brandSlug
    active
    addedAt
    deactivatedAt
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdateUserFavouriteDealMutationVariables,
  APITypes.UpdateUserFavouriteDealMutation
>;
export const deleteUserFavouriteDeal = /* GraphQL */ `mutation DeleteUserFavouriteDeal(
  $input: DeleteUserFavouriteDealInput!
  $condition: ModelUserFavouriteDealConditionInput
) {
  deleteUserFavouriteDeal(input: $input, condition: $condition) {
    id
    type
    userId
    dealId
    brandSlug
    active
    addedAt
    deactivatedAt
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.DeleteUserFavouriteDealMutationVariables,
  APITypes.DeleteUserFavouriteDealMutation
>;
export const createUserPopupAnswers = /* GraphQL */ `mutation CreateUserPopupAnswers(
  $input: CreateUserPopupAnswersInput!
  $condition: ModelUserPopupAnswersConditionInput
) {
  createUserPopupAnswers(input: $input, condition: $condition) {
    id
    popupId
    username
    mobile
    email
    product
    question
    answer
    answeredAt
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreateUserPopupAnswersMutationVariables,
  APITypes.CreateUserPopupAnswersMutation
>;
export const updateUserPopupAnswers = /* GraphQL */ `mutation UpdateUserPopupAnswers(
  $input: UpdateUserPopupAnswersInput!
  $condition: ModelUserPopupAnswersConditionInput
) {
  updateUserPopupAnswers(input: $input, condition: $condition) {
    id
    popupId
    username
    mobile
    email
    product
    question
    answer
    answeredAt
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdateUserPopupAnswersMutationVariables,
  APITypes.UpdateUserPopupAnswersMutation
>;
export const deleteUserPopupAnswers = /* GraphQL */ `mutation DeleteUserPopupAnswers(
  $input: DeleteUserPopupAnswersInput!
  $condition: ModelUserPopupAnswersConditionInput
) {
  deleteUserPopupAnswers(input: $input, condition: $condition) {
    id
    popupId
    username
    mobile
    email
    product
    question
    answer
    answeredAt
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.DeleteUserPopupAnswersMutationVariables,
  APITypes.DeleteUserPopupAnswersMutation
>;
export const createPopup = /* GraphQL */ `mutation CreatePopup(
  $input: CreatePopupInput!
  $condition: ModelPopupConditionInput
) {
  createPopup(input: $input, condition: $condition) {
    id
    eventId
    title
    product
    price
    rrp
    question
    img
    start
    end
    finish
    usergroups
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreatePopupMutationVariables,
  APITypes.CreatePopupMutation
>;
export const updatePopup = /* GraphQL */ `mutation UpdatePopup(
  $input: UpdatePopupInput!
  $condition: ModelPopupConditionInput
) {
  updatePopup(input: $input, condition: $condition) {
    id
    eventId
    title
    product
    price
    rrp
    question
    img
    start
    end
    finish
    usergroups
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdatePopupMutationVariables,
  APITypes.UpdatePopupMutation
>;
export const deletePopup = /* GraphQL */ `mutation DeletePopup(
  $input: DeletePopupInput!
  $condition: ModelPopupConditionInput
) {
  deletePopup(input: $input, condition: $condition) {
    id
    eventId
    title
    product
    price
    rrp
    question
    img
    start
    end
    finish
    usergroups
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.DeletePopupMutationVariables,
  APITypes.DeletePopupMutation
>;
