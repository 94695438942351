import { Fragment } from 'react'
import { TopBanner } from '../../models/topBanner'
import './index.scss'

type Props = {
  topBanner: TopBanner | null
  textColor?: string | null
  backgroundColor?: string | null
  partnerLogo?: any | undefined
}

const SponsorBubble = ({
  topBanner,
  textColor,
  backgroundColor,
  // partnerLogo
}: Props) => {
  const topBannerTextColor = textColor
    ? textColor
    : topBanner && topBanner.bannerTextColor
      ? topBanner.bannerTextColor
      : process.env.REACT_APP_REGION === 'NZ'
        ? '#FFFFFF'
        : '#FFFFFF'
  const topBannerBackgroundColor = backgroundColor
    ? backgroundColor
    : topBanner && topBanner.backgroundColor
      ? topBanner.backgroundColor
      : process.env.REACT_APP_REGION === 'NZ'
        ? '#FFFFFF'
        : '#FFFFFF'
  const topBannerText = topBanner && topBanner.text ? topBanner.text : ''

  return (
    <Fragment>
      {topBanner &&
        topBanner.logos.map((item, index) => (
          <div
            className='sponsor-bubble__partner-wrapper'
            key={index}
          >
            <a
              href={item?.externalUrl || item.url}
              target='_blank'
              rel='noreferrer'
            >
              <div
                className='sponsor-bubble__partner-container'
                style={{
                  backgroundColor: topBannerBackgroundColor.toString(),
                }}
              >
                <div className='sponsor-bubble__partner-content'>
                  <div
                    className='sponsor-bubble__partner-text'
                    style={{ color: topBannerTextColor.toString() }}
                  >
                    {topBanner && index === 0 ? topBannerText : ''}
                  </div>
                  {/* {
                    // This is the temporary code for Fashion Frenzy
                    partnerLogo && partnerLogo !== undefined ? (
                      <img
                        className='sponsor-bubble__partner-logo'
                        src={partnerLogo}
                        alt='sponsor-bubble-logo'
                      />
                    ) : (
                      <img
                        className='sponsor-bubble__partner-logo'
                        data-blink-src={item.logo}
                        data-blink-quality='better'
                        data-blink-resize='200x'
                        alt='sponsor-bubble-logo'
                      />
                    )
                  } */}

                  <img
                    className='sponsor-bubble__partner-logo'
                    data-blink-src={item.logo}
                    data-blink-quality='better'
                    data-blink-resize='200x'
                    alt='sponsor-bubble-logo'
                  />
                </div>
              </div>
            </a>
          </div>
        ))}
    </Fragment>
  )
}

export default SponsorBubble
