import React, { PropsWithChildren } from 'react'
import bind from 'bind-decorator'
import { Button } from './Button'
import { facebook, google, Icon } from '../icon'
import './SocialButton.scss'

type Props = {
    onClick?: () => void
    brand: 'Facebook' | 'Google'
}

class SocialButton extends React.Component<PropsWithChildren<Props>> {
    @bind
    getIcon() {
        const { brand } = this.props

        switch (brand) {
            case 'Facebook':
                return facebook
            case 'Google':
                return google
        }
    }

    render() {
        const { onClick, children, brand } = this.props
        const icon = this.getIcon()

        return (
            <Button
                className={`social-button social-button--${brand.toLowerCase()}`}
                callback={onClick}
                buttonType='primary'
                buttonStyle='square'
                size='large'
                display='block'
                disableBoxShadow={true}
            >
                {icon && <Icon className='social-button__icon' icon={icon} />}
                {children}
            </Button>
        )
    }
}

export { SocialButton }
