import * as React from 'react'
import './style/MenuButtonSm.scss'

type Props = {
  className?: string
  id?: string
  isOpenMenu: boolean
  toggleMenu: () => void
}

class MenuButtonSm extends React.Component<Props> {
  render() {
    const { className = '', id, isOpenMenu, toggleMenu } = this.props

    return (
      <div
        className={`${className} cf-menu-btn-sm`}
        id={id}
      >
        <div
          onClick={toggleMenu}
          className={`call-menu ${isOpenMenu ? 'is-open' : ''}`}
          role='button'
        >
          <div
            className={`hamburger ${isOpenMenu ? 'is-active' : ''}`}
            id='hamburger-1'
          >
            <span className='line'></span>
            <span className='line'></span>
          </div>
        </div>
      </div>
    )
  }
}

export default MenuButtonSm
