import { ApplicationState } from '../../reducers'
import { connect } from 'react-redux'
import CategoryDealListFooter from './CategoryDealListFooter'
import { loadMoreCategoryDeals } from '../category/actions'
import { brandsQueryToArray, getCategoryDealsPageSize } from '../category/utilities'
import { categoryDealsSelector, filterCategoryDealsSelector } from '../../data/selectors'

type OwnProps = {
    slug: string
}

type FieldProps = {
    currentDeals: number
    totalDeals: number
}

type MethodProps = {
    loadNextPage: () => void
}

const mapStateToProps = (state: ApplicationState, ownProps: OwnProps): FieldProps => {
    const pageSize = getCategoryDealsPageSize()
    const selectedBrands = brandsQueryToArray(state.router.location.search)
    const currentPage = state.category.silverDealsCurrentPage
    const { goldDeals, silverDeals, selfServeDeals } = categoryDealsSelector(state, ownProps)
    const filteredGoldDeals = filterCategoryDealsSelector(state, goldDeals, ownProps.slug, selectedBrands, 'gold')
    const filteredSilverDeals = filterCategoryDealsSelector(state, silverDeals, ownProps.slug, selectedBrands, 'silver')
    const filteredSelfServeDeals =
      filterCategoryDealsSelector(state, selfServeDeals, ownProps.slug, selectedBrands, 'selfServe')

    return {
        currentDeals: filteredGoldDeals.length + currentPage * pageSize,
        totalDeals: filteredGoldDeals.length + filteredSilverDeals.length + filteredSelfServeDeals.length,
    }
}

const actions: MethodProps = {
    loadNextPage: loadMoreCategoryDeals,
}

export default connect(mapStateToProps, actions)(CategoryDealListFooter)
