import * as React from 'react'
import { default as BannerModel } from '../../models/banner'
import { Grid } from '../normal-grid'
import { Carousel } from '../carousel'
import Banner from './Banner'
import EventType from '../../models/eventType'

export type FieldProps = {
  banners: BannerModel[]
  eventType?: EventType | null
  userId?: string | undefined
}

type Props = FieldProps

class BannerCarousel extends React.Component<Props> {
  render() {
    const { banners, eventType, userId } = this.props

    if (banners.length < 1) {
      return null
    }

    return (
      <div style={{ background: '#f5f5f5', paddingBottom: '30px' }}>
        <Grid>
          <div className='row'>
            <div className='col-xs-12'>
              <Carousel eventType={eventType}>
                {banners.map(banner => (
                  <Banner
                    key={banner.alt}
                    banner={banner}
                    userId={userId}
                  />
                ))}
              </Carousel>
            </div>
          </div>
        </Grid>
      </div>
    )
  }
}

export default BannerCarousel
