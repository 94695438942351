import * as React from 'react'
import { Builder } from '@builder.io/react'
import { NavBarWithData } from '../../global/nav'
import { bind } from 'bind-decorator'
import { NavBarColors } from '../../global/nav/NavBar'
import { MenuItem } from '../../../models/menu'

type Props = {
  show: boolean
  customColors?: NavBarColors
  customMenuItems?: MenuItem[]
}

type State = {
  topHeaderHeight: number
  navBarHeight: number
}

class HeaderBuilderComponent extends React.Component<Props, State> {
  private topHeader = React.createRef<HTMLDivElement>()
  private navBar = React.createRef<HTMLDivElement>()
  readonly state: State = {
    topHeaderHeight: 0,
    navBarHeight: 0,
  }

  componentDidMount() {
    this.calculateHeight()
    window.addEventListener('resize', this.calculateHeight)
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.calculateHeight)
  }

  @bind
  calculateHeight() {
    if (this.topHeader.current === null || this.navBar.current === null) {
      return
    }

    const topHeaderHeight = this.topHeader.current.offsetHeight
    const navBarHeight = this.navBar.current.offsetHeight

    if (topHeaderHeight !== this.state.topHeaderHeight || navBarHeight !== this.state.navBarHeight) {
      this.setState({
        topHeaderHeight,
        navBarHeight,
      })
    }
  }

  render() {
    const { customColors, customMenuItems } = this.props
    return (
      <>
        {this.props.show && (
          <header className='layout__header'>
            {/* <div ref={this.topHeader}>
                            <TopHeaderWithData customColors={customColors} calculateHeight={this.calculateHeight} />
                        </div> */}
            <div ref={this.navBar}>
              <NavBarWithData
                resetPageHeight={this.calculateHeight}
                customColors={customColors}
                customMenuItems={customMenuItems}
              />
            </div>
          </header>
        )}
      </>
    )
  }
}

Builder.registerComponent(HeaderBuilderComponent, {
  name: 'Header Component',
  inputs: [
    {
      name: 'show',
      type: 'boolean',
      defaultValue: true,
    },
  ],
})

Builder.registerComponent(HeaderBuilderComponent, {
  name: 'Custom Header Component',
  inputs: [
    {
      name: 'show',
      type: 'boolean',
      defaultValue: true,
    },
    {
      name: 'customMenuItems',
      type: 'list',
      friendlyName: 'Menu items',
      required: true,
      defaultValue: [
        {
          name: 'Custom menu item',
          path: '/',
        },
      ],
      subFields: [
        {
          name: 'name',
          type: 'string',
          required: true,
        },
        {
          name: 'path',
          type: 'string',
          required: true,
        },
      ],
    },
    {
      name: 'customColors',
      type: 'object',
      friendlyName: 'Colors',
      required: true,
      defaultValue: {
        backgroundColor: '#5C2D91',
        countdownBackgroundColor: '#932091',
        countdownTextColor: '#ffff',
      },
      subFields: [
        {
          name: 'backgroundColor',
          type: 'color',
          required: true,
        },
        {
          name: 'countdownBackgroundColor',
          type: 'color',
          required: true,
        },
        {
          name: 'countdownTextColor',
          type: 'color',
          required: true,
        },
      ],
    },
  ],
})
