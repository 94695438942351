// SVG ICON : CalendarIco
import React from 'react'

type Props = {
  className?: string
}

class BigArrow extends React.Component<Props> {
  render() {
    const { className } = this.props

    return (
      <svg
        width='146'
        height='240'
        viewBox='0 0 146 240'
        fill='none'
        xmlns='http://www.w3.org/2000/svg'
        className={className}
      >
        <path
          opacity='0.1'
          d='M0 0V191.909L45.4386 150.945L83.5282 240L123.894 224.032L83.3434 137.282L146 129.943'
          fill='white'
        />
      </svg>
    )
  }
}

export default BigArrow
