import * as React from 'react'
import { ConfirmSignIn, RequireNewPassword, VerifyContact, Authenticator } from 'aws-amplify-react'
import { SignUpWithData } from './SignUpWithData'
import { LoginWithData } from './LoginWithData'
import { ConfirmSignUpWithData } from './ConfirmSignUpWithData'
import { Welcome } from './Welcome'
import { ForgotPasswordWithData } from './ForgotPasswordWithData'
import { ProfilePageWithData } from './ProfilePageWithData'
import { connect } from 'react-redux'
import { ApplicationState } from '../../reducers'

// keep track of the password outside of the Authenticator component
// this is so that we can sign a user in after they confirm their account
let password = ''
const setPassword = (incoming: string) => (password = incoming)
const getPassword = () => password

interface Props {
  isLoggedIn: boolean
}

const AccountPage = ({ isLoggedIn }: Props) => (
  <>
    {isLoggedIn && <ProfilePageWithData />}
    {!isLoggedIn && (
      <Authenticator hideDefault={true}>
        <LoginWithData key='SignIn' />
        <ConfirmSignIn key='ConfirmSignIn' />
        <VerifyContact key='VerifyContact' />
        <SignUpWithData
          key='SignUp'
          usernameAttributes='email'
          setPassword={setPassword}
        />
        <ConfirmSignUpWithData
          key='ConfirmSignUp'
          usernameAttributes='email'
          getPassword={getPassword}
        />
        <ForgotPasswordWithData
          usernameAttributes='email'
          key='ForgotPassword'
        />
        <RequireNewPassword key='RequireNewPassword' />
        <Welcome key='welcome' />
      </Authenticator>
    )}
  </>
)

const mapStateToProps = (state: ApplicationState): Props => ({
  isLoggedIn: state.account.loggedIn,
})

const AccountPageWithData = connect(mapStateToProps)(AccountPage)

export { AccountPageWithData }
