import * as React from 'react'
import { Field } from 'react-final-form'
import { SelectField } from './SelectField'
import { required } from '../validations'

export type Props = {
    name: string
    placeholder: string
    male: string
    female: string
    unspecified: string
}

class GenderField extends React.Component<Props> {
    render() {
        const { name, placeholder, male, female, unspecified } = this.props

        return (
            <Field<string>
                name={name}
                component={SelectField}
                validate={required(name)}
            >
                <option value=''>{placeholder}</option>
                <option value='male'>{male}</option>
                <option value='female'>{female}</option>
                <option value='unspecified'>{unspecified}</option>
            </Field>
        )
    }
}

export { GenderField }
