import * as React from 'react'

import './Grid.scss'

type Props = {
    className?: string
    id?: string
    children?: React.ReactNode[] | React.ReactNode
}

class Grid extends React.Component<Props> {
    render() {
        const { className = '', children, id } = this.props

        return (
            <div className={`${className} container-new grid`} id={id}>
                {children}
            </div>
        )
    }
}

export default Grid
