import { addFillGapSelector, disablePageScroll, enablePageScroll } from 'scroll-lock'

function disableScroll(scrollableElement?: HTMLElement) {
    addFillGapSelector('.layout__header')
    disablePageScroll(scrollableElement)
}

function enableScroll(scrollableElement?: HTMLElement) {
    enablePageScroll(scrollableElement)
}

export {
    disableScroll,
    enableScroll,
}
