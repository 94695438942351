import * as React from 'react'
import { Grid as GridWrapper } from '../../components/grid'
import './Grid.scss'

class Grid extends React.Component {
    render() {
        return (
            <>
                <GridWrapper>
                    <div className='row'>
                        <div className='col-xs-12'>
                            <h2>Grids</h2>
                        </div>
                    </div>
                </GridWrapper>
                <GridWrapper className='grid-example'>
                    <div className='row'>
                        <div className='col-xs-12'>
                            <div className='grid-example__cell'>
                                <p>12 columns</p>
                            </div>
                        </div>
                    </div>
                </GridWrapper>
                <GridWrapper className='grid-example'>
                    <div className='row'>
                        <div className='col-xs-6'>
                            <div className='grid-example__cell'>
                                <p>6 columns</p>
                            </div>
                        </div>
                        <div className='col-xs-6'>
                            <div className='grid-example__cell'>
                                <p>6 columns</p>
                            </div>
                        </div>
                    </div>
                </GridWrapper>
                <GridWrapper className='grid-example'>
                    <div className='row'>
                        <div className='col-xs-4'>
                            <div className='grid-example__cell'>
                                <p>4 columns</p>
                            </div>
                        </div>
                        <div className='col-xs-4'>
                            <div className='grid-example__cell'>
                                <p>4 columns</p>
                            </div>
                        </div>
                        <div className='col-xs-4'>
                            <div className='grid-example__cell'>
                                <p>4 columns</p>
                            </div>
                        </div>
                    </div>
                </GridWrapper>
                <GridWrapper className='grid-example'>
                    <div className='row'>
                        <div className='col-xs-4'>
                            <div className='grid-example__cell'>
                                <p>4 columns</p>
                            </div>
                        </div>
                        <div className='col-xs-8'>
                            <div className='grid-example__cell'>
                                <p>8 columns</p>
                            </div>
                        </div>
                    </div>
                </GridWrapper>
            </>
        )
    }
}

export default Grid
