import * as React from 'react'
import { Heading } from '../../../../../components/cf-package'
import './style/style.scss'
import MainEvent from './MainEvent'
import SignUpCard from './SignUpCard'
import SecondEvent from './SecondEvent'
import Event from '../../../../../models/event'

export type Props = {
  className?: string
  id?: string
  events: Event[]
}

class ComingEvent extends React.Component<Props> {
  render() {
    const { className = '', id, events } = this.props

    if (events.length === 0) {
      return null
    }

    return (
      <section
        className={`${className} cf-upcoming cf-flex flex-col`}
        id={id}
      >
        <div className='head-blk cf-flex flex-col justify-center align-center'>
          <Heading className='text-primary ttl-blk head-lg'>
            <span>Heads up for the next Click Frenzy netbuster!</span>
          </Heading>
          <div className='sub-heading'>
            Our online shopping events are legendary. Millions of Australians have scored bargains and you can get among
            them. It&apos;s free so sign up and save the date so you don&apos;t miss out!
          </div>
        </div>

        <MainEvent data={events[0]} />
        <div className='three-blk cf-flex flex-row'>
          <SignUpCard />
          {events.length > 1 && <SecondEvent data={events[1]} />}
          {events.length > 2 && <SecondEvent data={events[2]} />}
        </div>
      </section>
    )
  }
}

export default ComingEvent
