import * as React from 'react'
import Slider, { Settings } from 'react-slick'
import './Carousel.scss'
import NextArrow from './NextArrow'
import PrevArrow from './PrevArrow'
import EventType from '../../models/eventType'

interface IProps {
  icon?: string
  rand?: number
  isSearch?: boolean
  eventType?: EventType | null
}
class Carousel extends React.Component<React.PropsWithChildren<IProps>> {
  shouldComponentUpdate() {
    return false
  }
  render() {
    const { children, eventType } = this.props
    const settings: Settings = {
      arrows: children && Array.isArray(children) ? children.length > 1 : false,
      autoplay: true,
      autoplaySpeed: 5000,
      dots: children && Array.isArray(children) ? children.length > 1 : false,
      infinite: children && Array.isArray(children) ? children.length > 1 : false,
      speed: 1000,
      slidesToScroll: 1,
      slidesToShow: 1,
      initialSlide: this.props.rand ? this.props.rand : 0,
      className: 'carousel',
      dotsClass: `carousel__dots carousel__dots__${eventType}`,
      nextArrow: (
        <NextArrow
          icon={this.props.icon}
          isSearch={this.props.isSearch}
        />
      ),
      prevArrow: (
        <PrevArrow
          icon={this.props.icon}
          isSearch={this.props.isSearch}
        />
      ),
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      customPaging: i => {
        return <div className='carousel__dot' />
      },
    }

    return <Slider {...settings}>{children}</Slider>
  }
}

export default Carousel
