import { connect } from 'react-redux'
import { ApplicationState } from '../../../reducers'
import FeaturedBrands, { FieldProps } from './FeaturedBrands'
import { modalCategorySelector } from '../../category/reducer'
import { currentEventSelector } from '../../../data/selectors'
import { loadBrands } from '../actions'
import * as React from 'react'

export type FeaturedBrandsLocation =
  | 'home'
  | 'home.topRetailers'
  | 'category'
  | 'search'
  | 'nonEvent.topRetailers'
  | 'category.featuredBrands'

type OwnProps = {
  location: FeaturedBrandsLocation
}

type MethodProps = {
  loadBrands: () => void
}

type Props = FieldProps & MethodProps

class FeaturedBrandsList extends React.Component<Props> {
  componentDidMount() {
    const { loadBrands } = this.props
    loadBrands()
  }

  render() {
    const { brands, presentation, internalLink, location } = this.props

    if (brands === undefined || brands.length === 0) {
      return null
    }

    return (
      <FeaturedBrands
        brands={brands}
        presentation={presentation}
        internalLink={internalLink}
        location={location}
      />
    )
  }
}

const mapStateToProps = (state: ApplicationState, ownProps: OwnProps): FieldProps => {
  const { location } = ownProps

  const currentEvent = currentEventSelector(state)

  if (currentEvent === undefined) {
    return {
      brands: [],
    }
  }

  let brands
  let presentation: 'default' | 'modal' | undefined
  let internalLink = true

  switch (location) {
    case 'category': {
      const category = modalCategorySelector(state)

      if (category === undefined || category.featuredBrands === undefined) {
        return {
          brands: [],
        }
      }
      brands = category.featuredBrands.brands
      presentation = 'modal'
      break
    }

    case 'search':
      if (currentEvent.searchFeaturedBrands === undefined) {
        return {
          brands: [],
        }
      }
      brands = currentEvent.searchFeaturedBrands.brands
      break

    case 'home':
      if (currentEvent.homepageFeaturedBrands === undefined) {
        return {
          brands: [],
        }
      }
      brands = currentEvent.homepageFeaturedBrands.brands
      break

    case 'home.topRetailers':
      if (currentEvent.homepageTopRetailers === undefined) {
        return {
          brands: [],
        }
      }
      brands = currentEvent.homepageTopRetailers.brands
      break

    case 'category.featuredBrands':
      if (currentEvent.categoryFeaturedBrands === undefined) {
        return {
          brands: [],
        }
      }
      brands = currentEvent.categoryFeaturedBrands.brands
      break

    case 'nonEvent.topRetailers': {
      const nonEventConfig = state.data.nonEventConfig
      if (nonEventConfig && nonEventConfig.topRetailers && nonEventConfig.topRetailers.brands) {
        brands = nonEventConfig.topRetailers.brands
        internalLink = false
      } else {
        return {
          brands: [],
        }
      }
      break
    }

    default:
      return {
        brands: [],
      }
  }

  return {
    presentation,
    brands,
    internalLink,
    location,
  }
}

const actions: MethodProps = {
  loadBrands,
}

const FeaturedBrandsWithData = connect(mapStateToProps, actions)(FeaturedBrandsList)
export default FeaturedBrandsWithData
