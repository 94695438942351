import * as React from 'react'
import { ApplicationState } from '../../../reducers'
import { connect } from 'react-redux'
import Event from '../../../models/event'
import {
  currentEventSelector,
  getTimeoutToNextRun,
  isEventRunning as checkIsEventRunning,
} from '../../../data/selectors'
import { eventRunning, eventStarted, loadEvents, noEventRunning, updateNetworkTime } from './actions'
import EventSite from './EventSite'
import StandardSite from './StandardSite'
import bind from 'bind-decorator'
import { history } from '../../../history'
import getCurrentTime from '../../../utilities/fetchCurrentTime'
import FooterLink from '../../../models/footerLink'

type FieldProps = {
  currentEvent: Event | undefined
  isEventRunning: boolean
  isLoggedIn: boolean
  networkTime: number
  links?: FooterLink[]
}

type MethodProps = {
  loadEvents: (isByTimer: boolean, isLoggedIn: boolean) => void
  eventStarted: (currentEvent: Event) => void
  eventRunning: () => void
  noEventRunning: () => void
  updateNetworkTime: (time: number) => void
}

type Props = FieldProps & MethodProps

type State = {
  eventTimer?: number
  refreshEventTimer?: number
  refreshEventInterval?: number
  networkTimerInterval?: number
}

const MAX_TIMEOUT = 2147483647

class Status extends React.Component<Props, State> {
  componentDidMount() {
    const { loadEvents, isLoggedIn, updateNetworkTime } = this.props
    // Events
    loadEvents(false, isLoggedIn)

    this.getEventsWithTimeout()
    this.setState({ eventTimer: window.setInterval(this.checkEvent, 1000) })
    this.checkEvent()

    const time = async () => {
      const networkTime = await getCurrentTime()
      updateNetworkTime(parseInt(networkTime.format('X')))
    }
    time()
    this.setState({ networkTimerInterval: window.setInterval(updateNetworkTime, 1000) })
  }

  componentWillUnmount() {
    // Events
    window.clearInterval(this.state.eventTimer)
    if (this.state && this.state.refreshEventTimer) {
      window.clearInterval(this.state.refreshEventTimer)
    }

    // Network time interval
    window.clearInterval(this.state.networkTimerInterval)
  }

  @bind
  async checkEvent() {
    const { currentEvent, isEventRunning, noEventRunning, eventStarted } = this.props

    if (currentEvent === undefined) {
      return
    }
    // const isEventStillRunning = await checkIsEventRunningUsingNetworkTime(currentEvent, this.props.networkTime)
    const isEventStillRunning = await checkIsEventRunning(currentEvent)

    if (isEventStillRunning === isEventRunning) {
      return
    }

    if (isEventStillRunning) {
      eventStarted(currentEvent)
    } else {
      noEventRunning()
      history.push('/')
    }
  }

  @bind
  async getEventsWithTimeout() {
    const { currentEvent, isLoggedIn } = this.props
    // const isEventStillRunning = await checkIsEventRunningUsingNetworkTime(currentEvent, this.props.networkTime)
    const isEventStillRunning = await checkIsEventRunning(currentEvent)
    if (!isEventStillRunning) {
      this.props.loadEvents(true, isLoggedIn)
    }

    /**
     * Browsers including Internet Explorer, Chrome, Safari, and Firefox store the delay
     * as a 32-bit signed integer internally.This causes an integer overflow when using delays
     * larger than 2,147,483,647 ms (about 24.8 days), resulting in the timeout being executed immediately.
     *
     * https://developer.mozilla.org/en-US/docs/Web/API/WindowOrWorkerGlobalScope/setTimeout
     */

    // const eventTimeout =
    //   getTimeoutToNextRunUsingNetworkTime(currentEvent, this.props.networkTime) * 1000 > MAX_TIMEOUT
    //     ? MAX_TIMEOUT
    //     : getTimeoutToNextRunUsingNetworkTime(currentEvent, this.props.networkTime) * 1000

    const eventTimeout =
      getTimeoutToNextRun(currentEvent) * 1000 > MAX_TIMEOUT ? MAX_TIMEOUT : getTimeoutToNextRun(currentEvent) * 1000

    if (eventTimeout > 0) {
      const newEventTimer = window.setTimeout(this.getEventsWithTimeout, eventTimeout)
      this.setState({ refreshEventTimer: newEventTimer })
    }
  }

  render() {
    const { currentEvent, isEventRunning, links } = this.props

    if (currentEvent && isEventRunning) {
      return <EventSite isEventRunning={isEventRunning} />
    }

    return (
      <StandardSite
        isEventRunning={isEventRunning}
        links={links}
      />
    )
  }
}

const mapStateToProps = (state: ApplicationState): FieldProps => {
  return {
    currentEvent: currentEventSelector(state),
    isEventRunning: state.status.isEventRunning,
    isLoggedIn: state.account.loggedIn,
    networkTime: state.status.networkTime,
    links: state.data.footerLinks.sort((a, b) => a.sortOrder - b.sortOrder),
  }
}

const actions: MethodProps = {
  loadEvents,
  eventStarted,
  eventRunning,
  noEventRunning,
  updateNetworkTime,
}

export default connect(mapStateToProps, actions)(Status)
