import * as React from 'react'
import { CustomArrowProps } from 'react-slick'
import { Icon, chevron, sideArrow } from '..'
import classNames from 'classnames'

type OwnProps = {
    direction: 'prev' | 'next'
    icon?: string
    isSearch?: boolean
}

type Props = OwnProps & CustomArrowProps

class Arrow extends React.Component<Props> {
    render() {
        const { className, direction, onClick, style, icon, isSearch } = this.props
        const classes = classNames(
            icon === 'alternative' ?
            'carousel__arrow-alt' :
            isSearch ? 'carousel__arrow-search' : 'carousel__arrow',
            icon === 'alternative' ?
            `carousel__arrow-alt--direction-${direction}` :
            isSearch ? `carousel__arrow-search--direction-${direction}-search` :
            `carousel__arrow--direction-${direction}`,
            className
        )

        return (
            <Icon
                onClick={onClick}
                icon={icon === 'alternative' ? sideArrow : chevron}
                style={{ ...style }}
                className={classes}
            />
        )
    }
}

export default Arrow
