// SVG ICON : CalendarIco
import React from 'react'

type Props = {
  color?: string
  className?: string
}

class AttendArrowSm extends React.Component<Props> {
  render() {
    const { color, className } = this.props

    return (
      <svg
        width='56'
        height='85'
        viewBox='0 0 56 85'
        fill='none'
        xmlns='http://www.w3.org/2000/svg'
        className={className}
      >
        <path
          d='M53.7807 83.145C53.7807 83.145 7.16327 67.0825 7.16328 3.84018M1.75588 23.4684C1.37507 9.47358 6.10626 2.13553 6.10626 2.13553C14.4927 13.6183 21.0991 17.44 21.0991 17.44'
          stroke={color || '#F7F410'}
          strokeWidth='3'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
      </svg>
    )
  }
}

export default AttendArrowSm
