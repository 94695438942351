import * as React from 'react'
import { RouteComponentProps } from 'react-router'
import CmsPage from '../../../models/cmsPage'
import Category from '../../../models/category'
import { categorySelector, pageSelector } from '../../../data/selectors'
import { ApplicationState } from '../../../reducers'
import { route } from './actions'
import { connect } from 'react-redux'
import { isLoading } from '../loading/reducers'
import { CategoryPageWithData } from '../../category'
import { PageWithData } from '../../page'
import NotFound from '../../NotFound'

type RouterProps = RouteComponentProps<{
  slug: string
}>

type FieldProps = {
  slug: string
  category: Category | undefined
  page: CmsPage | undefined
  loading: boolean
}

type MethodProps = {
  route: (slug: string) => void
}

type Props = FieldProps & MethodProps

class Router extends React.Component<Props> {
  componentDidMount() {
    const { slug, route } = this.props
    route(slug)
  }

  componentDidUpdate(prevProps: Props) {
    const { slug, route } = this.props

    if (slug !== prevProps.slug) {
      route(slug)
    }
  }

  render() {
    const { loading, category, page } = this.props

    if (loading && !category && !page) {
      return null
    }

    if (category) {
      return <CategoryPageWithData />
    }

    if (page) {
      return <PageWithData />
    }

    return <NotFound />
  }
}

const mapStateToProps = (state: ApplicationState, ownProps: RouterProps): FieldProps => ({
  slug: ownProps.match.params.slug,
  category: categorySelector(state, ownProps.match.params.slug),
  page: pageSelector(state, ownProps.match.params.slug),
  loading: isLoading(state),
})

const actions: MethodProps = {
  route,
}

const RouterWithData = connect(mapStateToProps, actions)(Router)

export { RouterWithData }
