import * as React from 'react'
import './BrandDetailAdditional.scss'
import Brand from '../../models/brand'
import Option from '../../models/option'
import BrandDetailAdditionalSummary from './BrandDetailAdditionalSummary'
import { DictionaryValue } from '../../components/dictionary-value'
import { Grid } from '../../components/normal-grid'
import { getResizedImageFromCDN, brandLogoSize } from '../../utilities'
import EventType from '../../models/eventType'

export type Props = {
    brand: Brand | undefined
    payments: Option[] | undefined
    deliveries: Option[] | undefined
    rewardsCashbacks: Option[] | undefined
    eventType?: EventType | null
}

class BrandAdditional extends React.Component<Props> {
    render() {
        const { brand, eventType } = this.props
        if (brand === undefined) {
            return null
        }
        const titlePayment = <DictionaryValue token='brand.detail.title.payment' />
        const titleRefundReturnsPolicy = <DictionaryValue token='brand.detail.title.refund.returns.policy' />
        const titleTermsConditions = <DictionaryValue token='brand.detail.title.terms.conditions' />

        return (
            <div className='brand-detail-additional'>
                <Grid className='brand-detail-additional__additional-grid'>
                    <BrandDetailAdditionalSummary
                        className='brand-detail-additional__payment'
                        eventType={eventType}
                        title={titlePayment}
                        content={brand.tandcClickmeterLinkUrl && brand.tandcClickmeterLinkUrl !== 'null' ?
                            `<a href="${brand.tandcClickmeterLinkUrl}">${brand.paymentUrl}</a>`
                            : brand.paymentUrl}
                        brandName={brand.name}
                        brandLogo={
                            getResizedImageFromCDN(
                                brand.logo,
                                brand.logoIsBitmap,
                                brandLogoSize.detailModal
                            )
                        }
                    />
                    <BrandDetailAdditionalSummary
                        className='brand-detail-additional__termsconditions'
                        eventType={eventType}
                        title={titleTermsConditions}
                        content={brand.tandcClickmeterLinkUrl && brand.tandcClickmeterLinkUrl !== 'null' ?
                            `<a href="${brand.tandcClickmeterLinkUrl}">${brand.termsConditions}</a>`
                            : brand.termsConditions}
                        brandName={brand.name}
                        brandLogo={
                            getResizedImageFromCDN(
                                brand.logo,
                                brand.logoIsBitmap,
                                brandLogoSize.detailModal
                            )
                        }
                    />
                    <BrandDetailAdditionalSummary
                        className='brand-detail-additional__refundreturnpolicy'
                        eventType={eventType}
                        title={titleRefundReturnsPolicy}
                        content={brand.randrClickmeterLinkUrl && brand.randrClickmeterLinkUrl !== 'null' ?
                            `<a href="${brand.randrClickmeterLinkUrl}">${brand.refundReturnsPolicy}</a>`
                            : brand.refundReturnsPolicy}
                        brandName={brand.name}
                        brandLogo={
                            getResizedImageFromCDN(
                                brand.logo,
                                brand.logoIsBitmap,
                                brandLogoSize.detailModal
                            )
                        }
                    />
                </Grid>
            </div>
        )
    }
}

export default BrandAdditional
