import { connect } from 'react-redux'
import { ApplicationState } from '../../reducers'
import MembershipBenefits, { Props } from './MembershipBenefits'
import { currentEventSelector } from '../../data/selectors'

const mapStateToProps = (state: ApplicationState): Props => {
    const event = currentEventSelector(state)
    if ((event === undefined || !state.status.isEventRunning) && state.data.nonEventConfig) {
        return {
            nonEventConfigData: state.data.nonEventConfig,
        }
    }
    return {}

}
const MembershipBenefitsWithData = connect(mapStateToProps)(MembershipBenefits)
export default MembershipBenefitsWithData
