import * as React from 'react'
import List from './List'
import { ApplicationState } from '../../../reducers'
import { connect } from 'react-redux'
import Brand from '../../../models/brand'
import { loadBrands, navigateTo } from '../actions'
import NamedItem from '../../../models/namedItem'
import { groupBrandsSelector } from '../../../data/selectors'
import { dataLayerPushPageType } from '../../../services/gtm'

type FieldProps = {
    groups: NamedItem<Brand[]>[]
}

type MethodProps = {
    loadBrands: () => void
    navigateTo: (letter: string) => void
}

type Props = FieldProps & MethodProps

class BrandList extends React.Component<Props> {
    componentDidMount() {
        const { loadBrands } = this.props
        loadBrands()
        dataLayerPushPageType('Brand Listing')
    }

    render() {
        const { groups, navigateTo } = this.props

        if (groups === undefined || groups.length === 0) {
            return null
        }

        return (
            <List
                groups={groups}
                navigateTo={navigateTo}
            />
        )
    }
}

const mapStateToProps = (state: ApplicationState): FieldProps => {
    return {
        groups: groupBrandsSelector(state),
    }
}

const actions: MethodProps = {
    loadBrands,
    navigateTo,
}

const ListWithData = connect(
    mapStateToProps,
    actions
)(BrandList)
export default ListWithData
