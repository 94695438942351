import * as React from 'react'
import { DictionaryValue, Grid } from '../../../components'
import { FeaturedBrandsWithData } from '.'
import { FeaturedBrandsLocation } from './FeaturedBrandsWithData'
import './FeaturedBrandsPanel.scss'
import { BackToTop } from '../../../components/back-to-top'

type Props = {
    title: string
    location: FeaturedBrandsLocation
    footer?: string
}

class FeaturedBrandsPanel extends React.Component<Props> {
    render() {
        const { title, location, footer } = this.props

        return (
            <div className='featured-brands-panel'>
                <Grid>
                    <div className='row'>
                        <div className='col-xs-12 col-md-12'>
                            <div className='featured-brands-panel__wrapper'>
                                <h2 className='featured-brands-panel__heading'>
                                    <DictionaryValue token={title} />
                                </h2>
                                <FeaturedBrandsWithData location={location} />
                                {footer && (
                                    <div className='featured-brands-panel__footer'>
                                        <DictionaryValue token={footer} />
                                    </div>
                                )}
                            </div>
                        </div>
                    </div>
                    <BackToTop />
                </Grid>
            </div>
        )
    }
}

export default FeaturedBrandsPanel
