import * as React from 'react'
import { DictionaryValue, PasswordField, CodeField } from '../../components'
import bind from 'bind-decorator'
import { AccountForm } from './AccountForm'

type Props = {
    error?: Error
    handleSubmit: (event: React.FormEvent<HTMLFormElement>) => void
    resendCode: () => void
}

class ForgotPasswordChange extends React.Component<Props> {
    @bind
    resendCode(e: React.MouseEvent) {
        e.preventDefault()
        this.props.resendCode()
    }

    render() {
        const { handleSubmit, error } = this.props

        return (
            <AccountForm
                handleSubmit={handleSubmit}
                error={error}
                heading='forgot-password-change.heading'
                intro='forgot-password-change.intro'
                ctaLabel='forgot-password-change.submit'
                footer={
                    (
                        <a onClick={this.resendCode}>
                            <DictionaryValue token='forgot-password-change.resend' />
                        </a>
                    )
                }
                fieldList={[
                    <CodeField key='code' name='code' placeholder='forgot-password-change.field.code' />,
                    (
                        <PasswordField
                            key='password'
                            name='password'
                            placeholder='forgot-password-change.field.new-password'
                            validateFormat={true}
                        />
                    ),
                ]}
            />
        )
    }
}

export { ForgotPasswordChange }
