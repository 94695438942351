import React from 'react'
import { connectStateResults, StateResultsProvided } from 'react-instantsearch-core'
import { SearchHit } from '../types'
import { DictionaryValue } from '../../../components/dictionary-value'
import { hasResultsSelector } from '../utilities'
import './SearchResultsProgress.scss'
import Progress from '../../../components/progress-bar/Progress'

export type SearchResultsProgressProps = StateResultsProvided<SearchHit>

class SearchResultsProgressView extends React.Component<SearchResultsProgressProps> {
    // eslint-disable-next-line @typescript-eslint/no-inferrable-types
    hasResults: boolean = false

    constructor(props: SearchResultsProgressProps) {
        super(props)

        this.hasResults = hasResultsSelector(props.searchResults)
    }

    componentDidUpdate() {
        this.hasResults = hasResultsSelector(this.props.searchResults)
    }

    render() {
        const { searchResults } = this.props
        // calculate deal number
        // - hitsPerPage: page size
        // - nbHits: total hits
        // - nbPages: total pages
        // - page: current page (starting from 0)
        let currentHits = 0
        let totalHits = 0
        if (searchResults && searchResults.nbHits > 0) {
            totalHits = searchResults.nbHits
            currentHits = (searchResults.page + 1) * searchResults.hitsPerPage
            if (currentHits > totalHits) {
                currentHits = totalHits
            }
        }

        return (
            <div className='search-results-progress'>
                <div className='search-results-progress__text'>
                    <DictionaryValue token='search.results.progress.viewed' /> {currentHits}{' '}
                    <DictionaryValue token='search.results.progress.of' />{' '}
                    {totalHits}{' '}
                    <DictionaryValue token='search.results.progress.deals' />
                </div>
                <Progress current={currentHits} total={totalHits} />
            </div>
        )
    }
}

const SearchResultsProgress = connectStateResults<SearchResultsProgressProps>(SearchResultsProgressView)
export { SearchResultsProgress, SearchResultsProgressView }
