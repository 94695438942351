import { Auth } from '@aws-amplify/auth'
import { SignIn } from 'aws-amplify-react'
import bind from 'bind-decorator'
import { AnyObject, Form, FormRenderProps } from 'react-final-form'
import { connect } from 'react-redux'
import { history } from '../../history'
import { ApplicationState } from '../../reducers'
import { dataLayerPushEvent } from '../../services/gtm'
import { Login } from './Login'

type Props = {
  isLogin: boolean
}

type State = {
  error?: Error
}

class AwsLoginWithData extends SignIn<Props, State> {
  readonly state: State = {}

  componentDidUpdate() {
    if (this.props.isLogin) {
      this.changeState('signIn')
      history.replace('/account')
    }
  }

  @bind
  async onSubmit(values: AnyObject) {
    try {
      dataLayerPushEvent('accountRequest', '', '')
      this.setState({ error: undefined })
      await Auth.signIn(values.email, values.password)

      // If you login during the early bird period you will not see
      // the event site. This fix causes the browser to reload and
      // redirect to the home page.
      window.location.href = window.location.origin
    } catch (error: any) {
      this.setState({
        error: {
          ...error,
          message: error.message ? error.message : 'Incorrect username or password.',
        },
      })
      if (error && error.name && error.name === 'UserNotConfirmedException') {
        this.setState({
          error: {
            ...error,
            message: `${error.message}
                            A new verification link has been sent to your registered email address,
                                please verify to finish signing up.`,
          },
        })
        await Auth.resendSignUp(values.email)
      }
    }
  }

  @bind
  showSignUp() {
    this.changeState('signUp')
  }

  @bind
  showForgotPassword() {
    this.changeState('forgotPassword')
  }

  @bind
  renderForm({ handleSubmit }: FormRenderProps) {
    const { error } = this.state

    return (
      <Login
        handleSubmit={handleSubmit}
        showSignUp={this.showSignUp}
        showForgotPassword={this.showForgotPassword}
        error={error}
      />
    )
  }

  showComponent() {
    return (
      <Form
        onSubmit={this.onSubmit}
        render={this.renderForm}
      />
    )
  }
}

const mapStateToProps = (state: ApplicationState): Props => {
  const params = new URLSearchParams(state.router.location.search)

  return {
    isLogin: params.get('login') !== null,
  }
}

const LoginWithData = connect(mapStateToProps)(AwsLoginWithData)

export { LoginWithData }
