import * as React from 'react'
import BrandWall from '../../../components/brandwall'
import { CFFooterWithData, CFHeader, Container } from '../../../components/cf-package'
import BrandWallBrand from '../../../models/brandWallBrand'
import { LandingPageDeals } from '../../../models/cfdeal'
import Event from '../../../models/event'
import EventType from '../../../models/eventType'
import FooterLink from '../../../models/footerLink'
import NonEventConfig from '../../../models/nonEventConfig'
import { Layout } from '../../layout'
import { BecomeMember, CFBoard, DealBrowse, DealCarousel, FeatureOn, Numbers } from './components'
import BrandwallSignup from './components/ComingEvent/BrandwallSignup'
import ComingEventWithData from './components/ComingEvent/ComingEventWithData'
import './style.scss'

export type FieldProps = {
  eventType: EventType | undefined
  nonEventConfig: NonEventConfig
  deals?: LandingPageDeals
  links?: FooterLink[]
  brandWallData?: BrandWallBrand[]
  isBrandWallEnabled?: boolean
  isAlwaysOnEnabled?: boolean
  upcomingEvent?: Event
}

type Props = FieldProps

class NonEventHome extends React.Component<Props> {
  render() {
    const {
      deals,
      links,
      // nonEventConfig,
      brandWallData,
      isBrandWallEnabled = false,
      isAlwaysOnEnabled = false,
      upcomingEvent,
    } = this.props

    return (
      <Layout
        className='cf-layout'
        hideFooter
        hideHeader
        showFooterPartners={false}
      >
        <CFHeader links={links} />
        {isBrandWallEnabled && (
          <section className='cf-section bg-light-violet brand-wall-section'>
            <Container>
              {upcomingEvent && <BrandwallSignup data={upcomingEvent} />}
              <BrandWall
                brandWallTitle='home.top-retailers'
                data={brandWallData}
              />
            </Container>
          </section>
        )}
        {deals && isAlwaysOnEnabled && (
          <section className='cf-section bg-light-violet deals-section'>
            <Container>
              <div className='cf-flex flex-col'>
                {deals.featureDeals && <DealCarousel data={deals?.featureDeals} />}
                {deals.deals && deals.deals.length > 0 && <DealBrowse data={deals.deals} />}
              </div>
            </Container>
          </section>
        )}
        <section className='cf-section bg-white'>
          <Container>
            <ComingEventWithData />
          </Container>
        </section>
        <section className='bg-primary'>
          <Numbers />
        </section>
        <section className='bg-light-violet'>
          <BecomeMember />
        </section>
        <section className='bg-blue cf-board-container'>
          <CFBoard />
        </section>
        <FeatureOn />
        <CFFooterWithData />
      </Layout>
    )
  }
}

export default NonEventHome
