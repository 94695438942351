import * as React from 'react'
import { connect } from 'react-redux'
import SubNav from './SubNav'
import { MenuItem } from '../../../models/menu'
import { ApplicationState } from '../../../reducers'
import { loadMenuData } from './actions'
import { currentEventSelector } from '../../../data/selectors'
import EventType from '../../../models/eventType'
import { NavBarColors } from './NavBar'

type FieldProps = {
    isEventRunning: boolean
    isSubmenuOpen: boolean
    items: MenuItem[]
    eventType?: EventType | null
    customColors?: NavBarColors
    customMenuItems?: MenuItem[]
}

type MethodProps = {
    loadMenu: () => void
}

type Props = FieldProps & MethodProps

class SubNavLoad extends React.Component<Props> {
    componentDidMount() {
        const { loadMenu } = this.props
        loadMenu()
    }

    componentDidUpdate(prevProps: Props) {
        const { isEventRunning, loadMenu } = this.props

        if (isEventRunning !== prevProps.isEventRunning) {
            loadMenu()
        }
    }

    render() {
        const { isSubmenuOpen, items, eventType, customColors, customMenuItems } = this.props

        if (items.length === 0) {
            return null
        }

        return (
            <SubNav
                isSubmenuOpen={isSubmenuOpen}
                items={customMenuItems || items}
                eventType={eventType}
                customColors={customColors}
            />
        )
    }
}

const mapStateToProps = (
    state: ApplicationState,
    currentProps: Pick<FieldProps, 'customColors' | 'customMenuItems'>
): FieldProps => {
    const event = currentEventSelector(state)

    return {
        isEventRunning: state.status.isEventRunning,
        isSubmenuOpen: state.nav.isSubmenuOpen,
        items: state.data.menu.items,
        eventType: event === undefined || event.type === undefined ? null : event.type,
        ...currentProps,
    }
}

const actions: MethodProps = {
    loadMenu: loadMenuData,
}

const SubNavWithData = connect(mapStateToProps, actions)(SubNavLoad)
export default SubNavWithData
