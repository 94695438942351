import React from 'react'
import { Hit, SearchResults, SearchState } from 'react-instantsearch-core'
import { SearchHit } from './types'
import { Badge, CheckboxItem, DictionaryValue, Logo } from '../../components'
import { RESET_ITEM } from './constants'
import { brandSelectorByName } from '../../data/selectors'
import { ApplicationState } from '../../reducers'
import { getResizedImageFromCDN, brandLogoSize } from '../../utilities'
import qs from 'qs'

const hasResultsSelector = (searchResults: SearchResults<SearchHit>): boolean => {
    return !!(searchResults && searchResults.query && searchResults.nbHits > 0)
}

const generateResetItem = (dictionaryValue: string, checked: boolean) => {
    return {
        checked,
        name: (
            <span className='filter-by-brand__checkbox-name filter-by-brand__checkbox-name--no-logo'>
                <DictionaryValue token={dictionaryValue} />
            </span>
        ),
        id: RESET_ITEM,
    }
}

const sortRefineItemsAlphabetically = (a: CheckboxItem, b: CheckboxItem) => {
    if (!a.originalName || !b.originalName) {
        return 0
    }

    if (a.originalName.toLowerCase() > b.originalName.toLowerCase()) {
        return 1
    }

    if (a.originalName.toLowerCase() < b.originalName.toLowerCase()) {
        return -1
    }

    return 0
}

const sortRefineItemsCheckedToTop = (a: CheckboxItem, b: CheckboxItem) => {
    if (a.checked === b.checked) {
        return 0
    }

    return a.checked ? -1 : 1
}

const attachBrandLogoToRefineItem = (
    state: ApplicationState,
    item: Hit<{ count: number; isRefined: boolean; label: string; value: string[] }>,
    index: number
) => {
    const brand = brandSelectorByName(state, item.label)
    const name = (
        <>
            <span className='filter-by-brand__checkbox-name'>
                {brand && (
                    <Logo
                        className='filter-by-brand__checkbox-name-image'
                        src={getResizedImageFromCDN(brand.logo, brand.logoIsBitmap, brandLogoSize.filter)}
                        alt={brand.name}
                        size='small'
                    />
                )}
                <span className='filter-by-brand__checkbox-name-text'>{brand ? brand.name : item.label}</span>
            </span>
            <Badge>{item.count}</Badge>
        </>
    )

    return {
        name,
        originalName: item.label,
        checked: item.isRefined,
        id: `${item.value[0]}-${index}`,
        value: item.value,
    }
}

const createQuerystringFromSearchState = (state: SearchState) => `?${qs.stringify(state)}`

const searchStateToUrl = (pathname: string, searchState: SearchState) =>
    searchState ? `${pathname}${createQuerystringFromSearchState(searchState)}` : ''

const querystringToSearchState = (search: string) => qs.parse(search.slice(1))

export {
    hasResultsSelector,
    generateResetItem,
    sortRefineItemsAlphabetically,
    sortRefineItemsCheckedToTop,
    attachBrandLogoToRefineItem,
    createQuerystringFromSearchState,
    searchStateToUrl,
    querystringToSearchState
}
