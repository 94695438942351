import * as React from 'react'
import { FieldRenderProps } from 'react-final-form'
import { Input } from '../Input'
import { getErrorMessage } from '../utilities'

type FormProps = FieldRenderProps<string, HTMLElement>

type ComponentProps = {
    tooltip?: string
    autoComplete?: 'off'
    maskField?: boolean
    handleMaskField?: (e: React.MouseEvent) => void
}

type Props = FormProps & ComponentProps

class InputField extends React.Component<Props> {
    render() {
        const { input, meta, tooltip, maskField, handleMaskField, ...rest } = this.props
        const errorMessage = getErrorMessage(meta)

        return (
            <Input
                {...input}
                {...rest}
                errorMessage={errorMessage}
                tooltip={tooltip}
                maskField={maskField}
                handleMaskField={handleMaskField}
            />
        )
    }
}

export { InputField }
