import * as React from 'react'
import makeCountdown from '../../properties/makeCountdown'
import { DictionaryValue } from '../../components/dictionary-value'
import './CountdownTimer.scss'

type Props = {
    days: number
    hours: number
    minutes: number
    seconds: number
}

class CountdownTimer extends React.Component<Props> {
    render() {
        const { days, hours, minutes, seconds } = this.props
        const format = new Intl.NumberFormat('en-AU', { style: 'decimal', minimumIntegerDigits: 2 }).format

        return (
            <div className='cf-countdown-timer'>
                <span className='cf-countdown-timer__time'>
                    <span className='cf-countdown-timer__number'>
                        {format(days)}
                    </span> <DictionaryValue token='countdown.days' />
                </span>
                <span className='cf-countdown-timer__time'>
                    <span className='cf-countdown-timer__number'>
                        {format(hours % 24)}
                    </span> <DictionaryValue token='countdown.hours' />
                </span>
                <span className='cf-countdown-timer__time'>
                    <span className='cf-countdown-timer__number'>
                        {format(minutes)}
                    </span> <DictionaryValue token='countdown.minutes' />
                </span>
                <span className='cf-countdown-timer__time'>
                    <span className='cf-countdown-timer__number'>
                        {format(seconds)}
                    </span> <DictionaryValue token='countdown.seconds' />
                </span>
            </div>
        )
    }
}

export default makeCountdown(CountdownTimer)
