import { Action } from 'redux'
import { isType } from 'typescript-fsa'
import { toggleCategoryMenu, selectModalCategory, loadMoreCategoryDeals, resetCategoryPage } from './actions'
import { LOCATION_CHANGE } from 'connected-react-router'
import { ApplicationState } from '../../reducers'
import Category from '../../models/category'
import { currentEventFromEventsSelector } from '../../data/selectors'

export type State = {
  categoryMenuOpen: boolean
  silverDealsCurrentPage: number
  categoryMenuCategory?: string
}

const initialState: State = {
  categoryMenuOpen: false,
  silverDealsCurrentPage: 1,
}

export const CategoryReducer = (state: State = initialState, action: Action): State => {
  if (isType(action, toggleCategoryMenu)) {
    return {
      ...state,
      categoryMenuOpen: !state.categoryMenuOpen,
    }
  }

  if (isType(action, resetCategoryPage)) {
    return {
      ...state,
      silverDealsCurrentPage: 1,
    }
  }

  if (isType(action, loadMoreCategoryDeals)) {
    return {
      ...state,
      silverDealsCurrentPage: state.silverDealsCurrentPage + 1,
    }
  }

  if (action.type === LOCATION_CHANGE) {
    return {
      ...state,
      categoryMenuOpen: false,
      silverDealsCurrentPage: 1,
    }
  }

  if (isType(action, selectModalCategory)) {
    return {
      ...state,
      categoryMenuCategory: action.payload,
    }
  }

  return state
}

export const modalCategorySelector = (state: ApplicationState): Category | undefined => {
  if (state.category.categoryMenuCategory === undefined) {
    return
  }
  // const currentEvent = currentEventFromEventsSelectorUsingNetworkTime(state.data.events, state.status.networkTime)
  const currentEvent = currentEventFromEventsSelector(state.data.events)
  let currentCategory
  if (currentEvent !== undefined && currentEvent.categories !== undefined) {
    currentCategory = currentEvent.categories.find(category => category.slug === state.category.categoryMenuCategory)
  }

  return currentCategory
}
