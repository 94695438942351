import * as React from 'react'
import { Subtract } from 'utility-types'
import { ModalProps } from './types'

const withModal = <P extends ModalProps>(Component: React.ComponentType<P>) => {
    type ParentProps = Subtract<P, ModalProps>
    type State = {
        isModalOpen: boolean
    }

    return class WithModal extends React.Component<ParentProps, State> {
        readonly state: State = {
            isModalOpen: false,
        }

        constructor(props: ParentProps) {
            super(props)

            this.toggleModal = this.toggleModal.bind(this)
        }

        toggleModal() {
            this.setState(currentState => ({
                isModalOpen: !currentState.isModalOpen,
            }))
        }

        render() {
            const { isModalOpen } = this.state

            return (
                <Component
                    {...this.props as P}
                    toggleModal={this.toggleModal}
                    isModalOpen={isModalOpen}
                />
            )
        }
    }
}

export default withModal
