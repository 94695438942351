import { FieldMetaState } from 'react-final-form'

const getErrorMessage = (meta: FieldMetaState<any>) => {
    return meta.error && meta.touched
        ? meta.error
        : undefined
}

const phoneToAws = (input: string | undefined) => {
    if (input === undefined) {
        return ''
    }

    if (process.env.REACT_APP_REGION === 'NZ') {
        return input.replace(/^02/, '+642')
    }

    return input.replace(/^04/, '+614')
}
let awsToPhone = (input: string | undefined) => input && input.replace(/^\+614/, '04')

if (process.env.REACT_APP_REGION === 'NZ') {
    awsToPhone = (input: string | undefined) => input && input.replace(/^\+642/, '02')
}

export {
    getErrorMessage,
    phoneToAws,
    awsToPhone,
}
