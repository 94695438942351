import { createInMemoryCache } from '@algolia/cache-in-memory'
import { algoliasearch } from 'algoliasearch'
import bind from 'bind-decorator'
import * as React from 'react'
import { SearchState } from 'react-instantsearch-core'
import { Configure, Index, InstantSearch } from 'react-instantsearch-dom'
import { connect } from 'react-redux'
import { history } from '../../../history'
import { ApplicationState } from '../../../reducers'
import { SEARCH_INDEX, SEARCH_UPDATE_URL_ON_SEARCH_DELAY } from '../constants'
import { createQuerystringFromSearchState, querystringToSearchState, searchStateToUrl } from '../utilities'
import { StructuredResults } from './StructuredResults'

const algoliaClient = algoliasearch(
  process.env.REACT_APP_ALGOLIA_APP_ID as string,
  process.env.REACT_APP_ALGOLIA_API_KEY as string,
  {
    responsesCache: createInMemoryCache(),
    requestsCache: createInMemoryCache({ serializable: false }),
  }
)

type Props = {
  pathname: string
  search: string
  children?: any
}

type State = {
  searchState: SearchState
}

const searchClient = {
  ...algoliaClient,
  search(requests: any) {
    if (requests.every(({ params }: any) => !params.query || params.query.length < 3)) {
      return Promise.resolve({
        results: requests.map(() => ({
          hits: [],
          nbHits: 0,
          nbPages: 0,
          page: 0,
          processingTimeMS: 0,
        })),
      })
    }
    return algoliaClient.search(requests)
  },
}

class SearchRoot extends React.Component<Props, State> {
  private debouncedSetState: number | undefined

  constructor(props: Props) {
    super(props)

    this.state = {
      searchState: querystringToSearchState(props.search),
    }
  }

  componentDidMount() {
    // eslint-disable-next-line react/no-direct-mutation-state
    this.state.searchState.page = 1
  }

  @bind
  onSearchStateChange(searchState: SearchState) {
    const { pathname } = this.props

    clearTimeout(this.debouncedSetState)

    this.debouncedSetState = window.setTimeout(
      () => history.push(searchStateToUrl(pathname, searchState), searchState),
      SEARCH_UPDATE_URL_ON_SEARCH_DELAY
    )

    this.setState({ searchState })
  }

  componentWillReceiveProps(nextProps: Props) {
    const { pathname, search } = this.props

    const currentUrl = `${pathname}${search}`
    const nextUrl = `${nextProps.pathname}${nextProps.search}`

    if (nextUrl !== currentUrl) {
      this.setState({ searchState: querystringToSearchState(nextProps.search) })
    }
  }

  render() {
    const { searchState } = this.state

    return (
      <InstantSearch
        searchClient={searchClient}
        indexName={SEARCH_INDEX}
        createURL={createQuerystringFromSearchState}
        onSearchStateChange={this.onSearchStateChange}
        searchState={searchState}
      >
        <Index
          indexName={`deals-by-custom-search-${
            process.env.REACT_APP_ALGOLIA_INDEX_SUFFIX ? process.env.REACT_APP_ALGOLIA_INDEX_SUFFIX : 'qa'
          }`}
        >
          <Configure
            hitsPerPage={process.env.REACT_APP_ALGOLIA_HITS_PER_PAGE}
            getRankingInfo={true}
          />
          <StructuredResults />
        </Index>
        <Configure hitsPerPage={process.env.REACT_APP_ALGOLIA_HITS_PER_PAGE} />
        {this.props.children}
      </InstantSearch>
    )
  }
}

const mapStateToProps = (state: ApplicationState) => {
  return {
    pathname: state.router.location.pathname,
    search: state.router.location.search,
  }
}

const SearchRootWithData = connect(mapStateToProps)(SearchRoot)

export { SearchRoot, SearchRootWithData }
