import React from 'react'
import { connectStateResults, StateResultsProvided } from 'react-instantsearch-core'
import classnames from 'classnames'
import bind from 'bind-decorator'
import { SearchHit } from '../types'
import { DictionaryValue, RefinementGroup } from '../../../components'
import { hasResultsSelector } from '../utilities'
import './SearchHeader.scss'
import { SearchSortWithData } from './SearchSort'
import { SearchFilterWithData } from './SearchFilter'
import { SearchRefineModalWithData } from './SearchRefineModal'

export type SearchHeaderProps = StateResultsProvided<SearchHit>

class SearchHeader extends React.Component<SearchHeaderProps> {
    // eslint-disable-next-line @typescript-eslint/no-inferrable-types
    hasResults: boolean = false

    constructor(props: SearchHeaderProps) {
        super(props)

        this.hasResults = hasResultsSelector(props.searchResults)
    }

    componentDidUpdate() {
        this.hasResults = hasResultsSelector(this.props.searchResults)
    }

    @bind
    getStatus() {
        const { error, searchResults } = this.props

        if (!this.hasResults && (searchResults && searchResults.query)) {
            return <DictionaryValue token='search.status.no-results' />
        }

        if (this.hasResults) {
            return (
                <>
                    <DictionaryValue token='search.status.showing' /> {searchResults.nbHits}{' '}
                    <DictionaryValue token='search.status.deals-for' />{' '}
                    <span className='search-header__status-highlight'>{searchResults.query}</span>
                </>
            )
        }

        if (error) {
            return <DictionaryValue token='search.status.error' />
        }
    }

    render() {
        const status = this.getStatus()
        const hasContent = !!status
        const showFilters = hasContent && this.hasResults

        const classes = classnames(
            'row',
            'middle-xs',
            'search-header',
            { 'search-header--has-content': hasContent }
        )

        return (
            <div className={classes}>
                {hasContent && (
                    <div className='col-xs-9 col-md-6'>
                        <div className='search-header__status'>{status}</div>
                    </div>
                )}
                <div
                    className={
                        classnames(
                            'col-xs-3 col-md-6 search-header__refine-wrapper',
                            { 'search-header__refine-wrapper--visible': showFilters }
                        )
                    }
                >
                    <div className='search-header__refine search-header__refine--mobile'>
                        <SearchRefineModalWithData />
                    </div>
                    <RefinementGroup className='search-header__refine search-header__refine--desktop'>
                        <SearchFilterWithData />
                        <SearchSortWithData isVisible={showFilters} />
                    </RefinementGroup>
                </div>
            </div>
        )
    }
}

const SearchHeaderWithData = connectStateResults<SearchHeaderProps>(SearchHeader)

export { SearchHeaderWithData, SearchHeader }
