import { connect } from 'react-redux'
import { ApplicationState } from '../../../reducers'
import { isLoggedInSelector } from '../../../features/account/reducer'
import { currentEventSelector } from '../../../data/selectors'
import CFSignIn, { FieldProps } from './CFSignIn'

const mapStateToProps = (state: ApplicationState): FieldProps => {
  const event = currentEventSelector(state)

  return {
    isLoggedIn: isLoggedInSelector(state),
    eventType: event === undefined || event.type === undefined ? null : event.type,
  }
}

const CFSignInWithData = connect(mapStateToProps)(CFSignIn)
export default CFSignInWithData
