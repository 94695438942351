import * as React from 'react'
import ReactModal from 'react-modal'
import bind from 'bind-decorator'
import { disableScroll, enableScroll } from '../../utilities/lockScroll'
import './Modal.scss'
import { ModalBody, ModalHeader } from './index'
import { ModalPadding } from './types'
import ModalCloseButton from './ModalCloseButton'

if (process.env.NODE_ENV !== 'test') {
    ReactModal.setAppElement('#root')
}

type Props = {
    isOpen: boolean
    onRequestClose: () => void
    header?: React.ReactElement | string
    body?: React.ReactElement | string
    closeButton?: CloseButton
    size?: ModalSize
    padding?: ModalPadding
    className?: string
    contentRef?: (e: HTMLDivElement) => void
}

type ModalSize = 'default'| 'responsive' | 'fullscreen'
export type CloseButton = 'header' | 'inline' | 'none'

class Modal extends React.Component<React.PropsWithChildren<Props>> {
    public static closeTimeoutMS = 400
    public static defaultProps = {
        showCloseButton: true,
        size: 'default',
        padding: 'default',
    }

    private contentRef: HTMLDivElement | undefined
    private closeTimeout: number | undefined

    @bind
    setContentRef(ref: HTMLDivElement) {
        this.contentRef = ref

        if (this.props.contentRef) {
            this.props.contentRef(ref)
        }
    }

    @bind
    onAfterOpen() {
        if (this.contentRef) {
            disableScroll(this.contentRef)
        }
    }

    @bind
    onRequestClose() {
        const { onRequestClose } = this.props

        if (onRequestClose) {
            onRequestClose()
        }

        if (this.contentRef) {
            this.closeTimeout = window.setTimeout(
                () => enableScroll(this.contentRef),
                Modal.closeTimeoutMS
            )
        }
    }

    componentWillUnmount() {
        enableScroll(this.contentRef)
        window.clearTimeout(this.closeTimeout)
    }

    @bind
    renderHeader() {
        const { header, padding, closeButton } = this.props

        if (!header && closeButton !== 'header') {
            return null
        }

        return (
            <ModalHeader modalPadding={padding}>
                { closeButton === 'header'
                    ? <ModalCloseButton type={closeButton} onClick={this.onRequestClose} />
                    : null
                }
                {header}
            </ModalHeader>
        )
    }

    @bind
    renderBody() {
        const { body, padding } = this.props

        if (!body) {
            return null
        }

        return (
            <ModalBody modalPadding={padding}>
                {body}
            </ModalBody>
        )
    }

    render() {
        const { className = '', children, isOpen, closeButton = 'inline', size, padding } = this.props

        const modalProps: ReactModal.Props = {
            isOpen,
            portalClassName: `modal modal--size-${size} ${className}`,
            overlayClassName: 'modal__overlay',
            className: `modal__content modal__content--padding-${padding}`,
            closeTimeoutMS: Modal.closeTimeoutMS,
            contentRef: this.setContentRef,
            onAfterOpen: () => this.onAfterOpen(),
            onRequestClose: () => this.onRequestClose(),
        }

        return (
            <ReactModal {...modalProps}>
                {this.renderHeader()}
                {this.renderBody()}
                {children}
                {closeButton === 'inline' && (
                    <ModalCloseButton type={closeButton} onClick={this.onRequestClose} />
                )}
            </ReactModal>
        )
    }
}

export default Modal
