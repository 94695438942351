import * as React from 'react'
import { Link } from '../../link'
import FooterLink from '../../../models/footerLink'
import './style.scss'

type Props = {
  className?: string
  id?: string
  links?: FooterLink[]
}

class CFMenu extends React.Component<Props> {
  render() {
    const { className = '', id, links } = this.props
    let calendarLink: FooterLink | undefined = undefined
    let advertLink: FooterLink | undefined = undefined

    if (links) {
      calendarLink = links.find(itm => itm.path === '/calendar') || {
        name: 'Event Calendar',
        path: '/calendar',
        sortOrder: 1,
      }
      advertLink = links.find(itm => itm.path === '/advertise') || {
        name: 'Advertise with us',
        path: '/advertise',
        sortOrder: 1,
      }
    }

    return (
      <nav
        id={id}
        className={`cf-menu ${className}`}
      >
        <ul className='cf-flex flex-row align-center'>
          {calendarLink && (
            <li>
              <Link to={calendarLink.path}>{calendarLink.name}</Link>
            </li>
          )}
          {/* {advertLink && (
            <li>
              <Link to={advertLink?.path}>How it works</Link>
            </li>
          )} */}
          {advertLink && (
            <li>
              <Link to={advertLink?.path}>Advertise with us</Link>
            </li>
          )}
        </ul>
      </nav>
    )
  }
}

export default CFMenu
