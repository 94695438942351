import React from 'react'
import { DropdownWithViewport } from '../../../components/dropdown-menu'
import { SearchRefineByWithData } from './SearchRefineByWithData'
import { connectCurrentRefinements, CurrentRefinementsProvided } from 'react-instantsearch-core'
import { DictionaryValue } from '../../../components/dictionary-value'

type OwnProps = {
    className?: string
}

type Props = OwnProps & CurrentRefinementsProvided

const SearchFilter: React.FunctionComponent<Props> = ({
    className = '',
    items,
}) => {
    const label = items.length === 0 ? 'search.filter.all' : 'search.filter.selected'

    return (
        <DropdownWithViewport
            className={className}
            label={<><DictionaryValue token='search.filter.label' />: <DictionaryValue token={label} /></>}
            dropdownMenuTitle={<DictionaryValue token='search.filter.modal.title'/>}
            unmountDropdownMenuOnClose={false}
            hideCloseButtonForDropdownMenu={true}
        >
            {toggleDropdown => <SearchRefineByWithData attribute='brand' requestClose={toggleDropdown} limit={1000} />}
        </DropdownWithViewport>
    )
}

const SearchFilterWithData = connectCurrentRefinements(SearchFilter)

export { SearchFilter, SearchFilterWithData }
