/* eslint-disable @typescript-eslint/no-empty-function */
import React, { createContext, useContext, useMemo } from 'react'

export const RoktContext = createContext({
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    setAttributes: (attributes: any) => { },
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    triggerPageChange: (pageIdentifier: string) => { },
    closeAll: () => { },
})
export const RoktContextConsumer = RoktContext.Consumer

export function useRokt() {
    return useContext(RoktContext)
}

type Props = {
    children: any;
    tagId: string;
    sandbox: boolean,
    windowRef?: any,
}

export const RoktContextProvider: React.FC<Props> = ({ children, tagId, sandbox = true, windowRef = window,
}) => {
    const roktLoaded = useMemo(
        () => {
            return new Promise((resolve) => {
                windowRef._ROKT_ = 'rokt'
                windowRef.rokt = {
                    id: tagId,
                    lc: [
                        (rokt: any) =>
                            rokt.init({
                                sandbox,
                                skipInitialSelection: true,
                            }),
                        (rokt: any) => resolve(rokt),
                    ],
                    it: new Date(),
                }
                const target =
                    windowRef.document.head ||
                    windowRef.document.body
                const script =
                    windowRef.document.createElement('script')
                script.type = 'text/javascript'
                script.src = 'https://apps.rokt.com/wsdk/integrations/snippet.js'
                script.crossOrigin = 'anonymous'
                script.async = true
                target.appendChild(script)
            })
        },
        [tagId, sandbox]
    )

    function setAttributes(attributes: any) {
        roktLoaded.then((rokt: any) => {
            rokt.setAttributes(attributes)
        })
    }

    function triggerPageChange(pageIdentifier: string) {
        roktLoaded.then((rokt: any) => {
            rokt.triggerPageChange({
                pageIdentifier,
            })
        })
    }

    function closeAll() {
        roktLoaded.then((rokt: any) => {
            rokt.closeAll()
        })
    }

    const roktWrapper = {
        setAttributes,
        triggerPageChange,
        closeAll,
    }

    return (
        <RoktContext.Provider value={roktWrapper}>
            {children}
        </RoktContext.Provider>
    )
}
