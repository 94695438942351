import * as React from 'react'
import { Field } from 'react-final-form'
import { CheckboxField } from './CheckboxField'

type Props = {
    name: string
}

class EmailsField extends React.Component<Props> {
    render() {
        const { name } = this.props

        return (
            <Field<string>
                name={name}
                component={CheckboxField}
                type='checkbox'
            />
        )
    }
}

export { EmailsField }
