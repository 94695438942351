import * as React from 'react'
import './Advertise.scss'
import { pointer, Icon } from '../../../components/icon'
import { Link } from '../../../components/link'
import EventType from '../../../models/eventType'

export type Props = {
    advertiseUrl?: string
    advertiseText?: string
    type?: EventType
}

class Advertise extends React.Component<Props> {
    render() {
        const { advertiseUrl, advertiseText, type } = this.props

        if (!advertiseUrl || !advertiseText) {
            return null
        }

        return (
            <div className={`advertise-here ${type && `advertise-here--${type}`}`}>
                <Link className='advertise-here__link' to={advertiseUrl} target='_blank'>
                    <Icon icon={pointer} className='advertise-here__icon' />
                    <span className='advertise-here__text'>{advertiseText}</span>
                </Link>
            </div>
        )
    }
}

export default Advertise
