import bind from 'bind-decorator'
import * as React from 'react'
import { history } from '../../../../src/history'
import { Link } from '../../link'
import { CfButton } from '../Button'
import './style/CFMenuSm.scss'

type Props = {
  className?: string
  id?: string
  toggleMenu: () => void
}

class CFMenuSm extends React.Component<Props> {
  @bind
  handleSignUpLink() {
    history.push('/account?join')
    this.props.toggleMenu()
  }

  @bind
  handleSignInLink() {
    history.push('/account?login')
    this.props.toggleMenu()
  }

  render() {
    const { className = '', id } = this.props

    return (
      <div
        id={id}
        className={`cf-menu-sm ${className}`}
      >
        <div
          id='menu-warp'
          className='cf-menu-sm-content'
        >
          <div className='nav-sm'>
            <ul className='cf-flex flex-col'>
              <li>
                <Link to='/calendar'>Event calendar</Link>
              </li>
              {/* <li>
                <Link to='/advertise'>How it works</Link>
              </li> */}
              <li>
                <Link to='/advertise'>Advertise with us</Link>
              </li>
            </ul>
          </div>
        </div>

        <div className='cf-sign-sm'>
          <div className='cf-flex flex-col align-center justify-center'>
            <CfButton
              onClick={this.handleSignUpLink}
              buttonType='blue'
              size='large'
            >
              Become a member
            </CfButton>
            <div
              style={{ color: 'white', fontSize: '22px' }}
              onClick={this.handleSignInLink}
            >
              Sign in
            </div>
            {/* <Link to='/account?login'>Sign in</Link> */}
          </div>
        </div>
      </div>
    )
  }
}

export default CFMenuSm
