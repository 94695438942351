import * as React from 'react'
import { DictionaryValue } from '../../components/dictionary-value'
import { Button } from '../../components/button'
import Progress from '../../components/progress-bar/Progress'
import classnames from 'classnames'
import './CategoryDealListFooter.scss'

type Props = {
    currentDeals: number
    totalDeals: number
    loadNextPage: () => void
}

class CategoryDealListFooter extends React.Component<Props> {
    render() {
        const { currentDeals, totalDeals, loadNextPage } = this.props
        let hasMore = false
        let current = currentDeals
        if (currentDeals >= totalDeals) {
            current = totalDeals
        } else {
            hasMore = true
        }

        const loadMoreClasses = classnames(
            'category-deal-list-footer__load-more',
            { 'category-deal-list-footer__load-more--visible': hasMore }
        )
        return (
            <div className='category-deal-list-footer'>
                <div className='category-deal-list-footer__progress'>
                    <div className='category-deal-list-footer__text'>
                        <DictionaryValue token='category.silver.deals.progress.viewed' /> {current}{' '}
                        <DictionaryValue token='category.silver.deals.progress.of' />{' '}
                        {totalDeals}{' '}
                        <DictionaryValue token='category.silver.deals.progress.deals' />
                    </div>
                    <Progress current={current} total={totalDeals} />
                </div>
                <Button
                    buttonType='primary'
                    display='block'
                    size='large'
                    buttonStyle='square'
                    className={loadMoreClasses}
                    callback={loadNextPage}
                >
                    <DictionaryValue token='category.silver.deals.load-more' />
                </Button>
            </div>
        )
    }
}

export default CategoryDealListFooter
