import { ApplicationState } from '../../reducers'
import List, { Props } from './List'
import { connect } from 'react-redux'
import { brandDetailedSelector } from '../../data/selectors'
import { convertSlugsToDealPlacements } from '../deal/utilities'

type OwnProps = {
    slug: string
}

const mapStateToProps = (state: ApplicationState, ownProps: OwnProps): Props => {
    const brand = brandDetailedSelector(state, ownProps.slug)
    const dealSlugs = brand === undefined || brand.deals === undefined ? [] : brand.deals

    // Filter out pre sale deals
    const preSaleCollectionId = '91d84ec1-7f6d-4e91-9356-847fdd707641'
    const nonPreSaleDeals = dealSlugs.filter(key => state.data.deals[key] &&
        state.data.deals[key].collectionId && state.data.deals[key].collectionId !== preSaleCollectionId)

    const deals = convertSlugsToDealPlacements(nonPreSaleDeals)

    return {
        deals,
        together: true,
    }
}

export default connect(mapStateToProps)(List)
