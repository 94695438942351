import * as React from 'react'
import bind from 'bind-decorator'
import { CfDealItem } from '../../../../../models/cfdeal'
import { ArrowLeft, ArrowRight, CFCarousel, Heading, DealTerm } from '../../../../../components/cf-package'
import { Modal } from '../../../../../components/modal'
import DealCardSlide from './DealCardSlide'
import { shuffleArray } from '../../../../../utilities/deal.helper'
import './style.scss'

type Props = {
  className?: string
  id?: string
  data: CfDealItem[]
}

type State = {
  isModalOpen: boolean
  openedDeal?: CfDealItem
  deals?: CfDealItem[]
}

class DealCarousel extends React.Component<Props> {
  readonly state: State = {
    isModalOpen: false,
    openedDeal: undefined,
  }

  @bind
  openModal(data: CfDealItem) {
    this.setState({
      isModalOpen: true,
      openedDeal: data,
    })
  }

  @bind
  handleModalCancel() {
    this.setState({
      isModalOpen: false,
    })
  }

  componentDidMount(): void {
    if (this.props.data.length > 0) {
      this.setState({
        deals: shuffleArray(this.props.data),
      })
    }
  }
  // componentDidUpdate(prevProps: Readonly<Props>): void {
  //   console.log('reload', prevProps.data)
  //   console.log('reload2', this.props.data)

  //   if (prevProps.data) {
  //     console.log('reload')
  //   }
  // }

  render() {
    const { className = '', id, data } = this.props
    const { isModalOpen, openedDeal, deals } = this.state

    return (
      <section
        className={`${className} cf-deal-carousel`}
        id={id}
      >
        {data.length > 0 && (
          <>
            <div className='head-blk'>
              <Heading className='text-primary ttl-blk head-sm'>
                <span>Featured deals</span>
              </Heading>
            </div>
            <div className='cf-box'>
              {deals && (
                <div className='slider-desktop'>
                  <CFCarousel
                    arrows={data.length === 1 ? false : true}
                    buttonPrev={
                      <span>
                        <ArrowLeft />
                      </span>
                    }
                    buttonNext={
                      <span>
                        <ArrowRight />
                      </span>
                    }
                  >
                    {deals.map((item: CfDealItem) => {
                      const { id } = item
                      return (
                        <DealCardSlide
                          key={id}
                          data={item}
                          openTerm={() => this.openModal(item)}
                        />
                      )
                    })}
                  </CFCarousel>
                </div>
              )}
              {deals && (
                <div className='slider-mobile'>
                  <div className='cf-flex flex-row'>
                    {deals.map((item: CfDealItem) => {
                      const { id } = item
                      return (
                        <DealCardSlide
                          key={id}
                          data={item}
                          openTerm={() => this.openModal(item)}
                        />
                      )
                    })}
                  </div>
                </div>
              )}
            </div>
          </>
        )}

        <Modal
          className='cf-modal-box width-md'
          isOpen={isModalOpen}
          onRequestClose={this.handleModalCancel}
          padding='large'
          size='responsive'
        >
          {openedDeal && <DealTerm data={openedDeal} />}
        </Modal>
      </section>
    )
  }
}

export default DealCarousel
