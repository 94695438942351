import React from 'react'
import SVG from 'react-inlinesvg'
import classNames from 'classnames'
import './Icon.scss'
import favourite from '../../static/icons/favourite.svg'
import star from '../../static/icons/star.svg'
import starFilled from '../../static/icons/starFilled.svg'
import menu from '../../static/icons/menu.svg'
import search from '../../static/icons/search.svg'
import chevron from '../../static/icons/chevron.svg'
import cross from '../../static/icons/cross.svg'
import logo from '../../static/icons/logo.svg'
import facebook from '../../static/icons/facebook.svg'
import google from '../../static/icons/google.svg'
import instagram from '../../static/icons/instagram.svg'
import twitter from '../../static/icons/twitter.svg'
import arrowTopLeft from '../../static/icons/arrow-top-left.svg'
import checked from '../../static/icons/checked.svg'
import unchecked from '../../static/icons/unchecked.svg'
import checkedSquare from '../../static/icons/checked-square.svg'
import uncheckedSquare from '../../static/icons/unchecked-square.svg'
import info from '../../static/icons/info.svg'
import thumbUp from '../../static/icons/thumb-up.svg'
import sideArrow from '../../static/icons/side-arrow.svg'
import downArrow from '../../static/icons/arrow-down.svg'
import calendar from '../../static/icons/calendar.svg'
import signup from '../../static/icons/signup.svg'
import pointer from '../../static/icons/pointer.svg'
import bellFilled from '../../static/icons/bell-filled.svg'
import bellOutline from '../../static/icons/bell-outline.svg'
import heart from '../../static/icons/heart.svg'
import heartFilled from '../../static/icons/heartFilled.svg'

export type IconType =
  | typeof logo
  | typeof menu
  | typeof search
  | typeof favourite
  | typeof star
  | typeof starFilled
  | typeof chevron
  | typeof cross
  | typeof facebook
  | typeof google
  | typeof instagram
  | typeof twitter
  | typeof arrowTopLeft
  | typeof checked
  | typeof unchecked
  | typeof checkedSquare
  | typeof uncheckedSquare
  | typeof info
  | typeof thumbUp
  | typeof sideArrow
  | typeof downArrow
  | typeof calendar
  | typeof signup
  | typeof pointer
  | typeof bellFilled
  | typeof bellOutline
  | typeof heart
  | typeof heartFilled

type Props = React.AllHTMLAttributes<HTMLDivElement> & {
  icon: IconType
  classPrefix?: string
  classModifier?: string
  className?: string
  wrappingElement?: 'div' | 'span'
  circleBackground?: boolean
}
class Icon extends React.Component<Props> {
  static defaultProps = {
    wrappingElement: 'div',
  }

  render() {
    const { icon, classPrefix, classModifier, className, wrappingElement, circleBackground, ...props } = this.props

    const wrapperClass = classNames(
      className,
      { [`${classPrefix}icon-wrapper`]: classPrefix },
      { 'icon--circle-background': circleBackground }
    )
    const svgClass = classNames(
      { [`${classPrefix}icon`]: classPrefix },
      { [`${classPrefix}icon--${classModifier}`]: classPrefix && classModifier }
    )

    return React.createElement(
      wrappingElement as string,
      {
        ...props,
        className: wrapperClass,
      },
      <SVG
        className={svgClass}
        cacheRequests={true}
        src={icon}
      />
    )
  }
}

interface SvgIconProps {
  icon: IconType
}

const SvgIcon = ({ icon }: SvgIconProps) => (
  <SVG
    cacheRequests={true}
    src={icon}
  />
)

export {
  Icon,
  SvgIcon,
  logo,
  menu,
  search,
  favourite,
  star,
  starFilled,
  chevron,
  cross,
  facebook,
  google,
  instagram,
  twitter,
  arrowTopLeft,
  checked,
  unchecked,
  checkedSquare,
  uncheckedSquare,
  info,
  thumbUp,
  sideArrow,
  downArrow,
  calendar,
  signup,
  pointer,
  bellFilled,
  bellOutline,
  heart,
  heartFilled,
}
