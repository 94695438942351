import * as React from 'react'
import Deal from '../../../models/deal'
import BrandDetailPage from './detailPage'
import { withRouter, RouteComponentProps } from 'react-router'
import { ApplicationState } from '../../../reducers'
import { connect } from 'react-redux'
import { default as BrandModel } from '../../../models/brand'
import { loadBrand as loadBrandData } from '../actions'
import NotFound from '../../NotFound'
import { dataLayerPushPageType } from '../../../services/gtm'
import { brandDetailedSelector, dealsForBrandSelector, currentEventSelector } from '../../../data/selectors'
import EventType from '../../../models/eventType'

type PathProps = {
    slug: string
}

type RouterProps = RouteComponentProps<PathProps>

type FieldProps = {
    slug: string
    brand?: BrandModel
    brandDeals: Deal[]
    eventType?: EventType | null
}

type MethodProps = {
    loadBrand: (slug: string) => void
}

type Props = FieldProps & MethodProps

class BrandDetailIndex extends React.Component<Props> {
    componentDidMount() {
        const { slug, loadBrand } = this.props

        loadBrand(slug)
        dataLayerPushPageType('Brand Detail')
    }

    componentDidUpdate(prevProps: Props) {
        const { slug, loadBrand } = this.props

        if (slug !== prevProps.slug) {
            loadBrand(slug)
        }
    }

    render() {
        const { brand, eventType } = this.props

        if (brand === undefined) {
            return <NotFound />
        }

        return (
            <BrandDetailPage
                brand={brand}
                eventType={eventType}
            />
        )
    }
}

const mapStateToProps = (state: ApplicationState, routerProps: RouterProps): FieldProps => {
    const slug = routerProps.match.params.slug
    const brand = brandDetailedSelector(state, slug)
    const event = currentEventSelector(state)

    if (brand === undefined || brand.deals === undefined) {
        return {
            slug,
            brand,
            brandDeals: [],
            eventType: event === undefined || event.type === undefined ? null : event.type,
        }
    }

    const brandDeals = dealsForBrandSelector(state, brand)

    return {
        slug,
        brand,
        brandDeals,
        eventType: event === undefined || event.type === undefined ? null : event.type,
    }
}

const actions: MethodProps = {
    loadBrand: loadBrandData,
}

export default withRouter(connect(
    mapStateToProps,
    actions
)(BrandDetailIndex))
