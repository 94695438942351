import { ApplicationState } from '../../../reducers'
import { AddressField, AddressProps } from './AddressField'
import { dictionaryValueSelector } from '../../../data/selectors'
import { connect } from 'react-redux'

type OwnProps = {
    name: string
    placeholder: string
}

const mapStateToProps = (ApplicationState: ApplicationState, ownProps: OwnProps): AddressProps => {
    const placeholder = dictionaryValueSelector(ownProps.placeholder, ApplicationState)
    const state = dictionaryValueSelector(`${ownProps.placeholder}state`, ApplicationState)

    return {
        ...ownProps,
        placeholder,
        state,
    }
}

const AddressFieldWithData = connect(mapStateToProps)(AddressField)
export { AddressFieldWithData }
