import * as React from 'react'
import Brand from '../../../models/brand'
import classNames from 'classnames'
import { Link } from '../../../components/link'
import { getBrandUrl } from '../utilities'
import { getResizedImageFromCDN, brandLogoSize } from '../../../utilities'
import './FeaturedBrands.scss'
import Slider, { Settings } from 'react-slick'
import NextArrow from '../../../components/carousel/NextArrow'
import PrevArrow from '../../../components/carousel/PrevArrow'

export type FeaturedBrandsPresentation = 'default' | 'modal'

export type FeaturedBrandsLocation =
  | 'home'
  | 'home.topRetailers'
  | 'category'
  | 'search'
  | 'nonEvent.topRetailers'
  | 'category.featuredBrands'

export type FieldProps = {
  brands: Brand[]
  presentation?: FeaturedBrandsPresentation
  internalLink?: boolean
  location?: FeaturedBrandsLocation
}

type Props = FieldProps

class FeaturedBrands extends React.Component<Props> {
  editBrandUrl = (url?: string) => {
    if (url && !url.match(/^[a-zA-Z]+:\/\//)) {
      return `//${url}`
    }
    return url || ''
  }

  render() {
    const { brands, presentation = 'default', internalLink = true, location } = this.props

    const classes = classNames(
      'featured-brands',
      `featured-brands--presentation-${presentation}`,
      `${location && location === 'nonEvent.topRetailers' && 'featured-brands__non-event'}`
    )

    const slidesToShow = Math.min(brands.length, 3)

    const settings: Settings = {
      slidesToScroll: 1,
      arrows: true,
      autoplay: true,
      autoplaySpeed: 5000,
      infinite: true,
      speed: 1000,
      initialSlide: 0,
      className: 'carousel',
      nextArrow: <NextArrow />,
      prevArrow: <PrevArrow />,
      responsive: [
        {
          breakpoint: 999999,
          settings: 'unslick',
        },
        {
          breakpoint: 767,
          settings: {
            slidesToShow,
          },
        },
      ],
    }

    return (
      <div>
        <ul className={classes}>
          {brands
            .sort((a, b) => Number(a.sortOrder) - Number(b.sortOrder))
            .map(brand => (
              <li
                className='featured-brands__brand'
                key={brand.slug}
              >
                <Link
                  to={internalLink ? getBrandUrl(brand.slug) : this.editBrandUrl(brand.websiteUrl)}
                  target={internalLink ? '_self' : '_blank'}
                  className='featured-brands__link'
                >
                  <img
                    src='data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAIAAACQd1PeAAAABnRSTlMA/
                                    wD/AP83WBt9AAAADElEQVQI12P4//8/AAX+Av7czFnnAAAAAElFTkSuQmCC'
                    data-blink-src={getResizedImageFromCDN(
                      brand.squarelogo && brand.squarelogo !== '' ? brand.squarelogo : brand.logo,
                      brand.logoIsBitmap,
                      brandLogoSize.featured
                    )}
                    alt={brand.name}
                    className='featured-brands__logo'
                  />
                </Link>
              </li>
            ))}
        </ul>
        {location && location !== 'nonEvent.topRetailers' && (
          <div className='fetured-brands__carosel carousel'>
            <Slider {...settings}>
              {brands
                .sort((a, b) => Number(a.sortOrder) - Number(b.sortOrder))
                .map((brand, index) => (
                  <div
                    className='slider-item'
                    key={index}
                  >
                    <Link
                      to={internalLink ? getBrandUrl(brand.slug) : this.editBrandUrl(brand.websiteUrl)}
                      target={internalLink ? '_self' : '_blank'}
                      className='fetured-brands__carosel__link'
                    >
                      <img
                        src='data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAIAAACQd1PeAAAABnRSTlMA/
                                            wD/AP83WBt9AAAADElEQVQI12P4//8/AAX+Av7czFnnAAAAAElFTkSuQmCC'
                        data-blink-src={getResizedImageFromCDN(
                          brand.squarelogo && brand.squarelogo !== '' ? brand.squarelogo : brand.logo,
                          brand.logoIsBitmap,
                          brandLogoSize.featured
                        )}
                        alt={brand.name}
                        className='fetured-brands__carosel__logo'
                      />
                    </Link>
                  </div>
                ))}
            </Slider>
          </div>
        )}
      </div>
    )
  }
}

export default FeaturedBrands
