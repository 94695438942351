import * as React from 'react'
import { GroupOf } from '../../models/groupof'
import Deal from '../../models/deal'
import CategoryPage from './CategoryPage'
import { withRouter, RouteComponentProps } from 'react-router'
import { ApplicationState } from '../../reducers'
import { connect } from 'react-redux'
import { default as CategoryModel } from '../../models/category'
import { loadCategoryData } from './actions'
import NotFound from '../NotFound'
import { dataLayerPushPageType } from '../../services/gtm'
import { categorySelector, dealsSelector, currentEventSelector } from '../../data/selectors'
import { loadBrands } from '../brand/actions'
import { isSubcategory } from './utilities'
// import { setEmarsysWebExtend } from '../../utilities'
import EventType from '../../models/eventType'

type PathProps = {
  slug: string
  parentSlug?: string
}

type RouterProps = RouteComponentProps<PathProps>

type FieldProps = {
  slug: string
  parentSlug?: string | null | undefined
  subCats?: CategoryModel[]
  allDeals: GroupOf<Deal>
  category?: CategoryModel
  parentCategory?: CategoryModel
  isMainCategory: boolean
  loading: boolean
  eventType?: EventType
}

type MethodProps = {
  loadCategory: (slug: string) => void
  loadBrands: () => void
}

type Props = FieldProps & MethodProps

class Category extends React.Component<Props> {
  componentDidMount() {
    const { slug, loadCategory, loadBrands, subCats } = this.props

    loadCategory(slug)

    if (subCats) {
      subCats.forEach(subCat => {
        loadCategory(subCat.slug)
      })
    }

    loadBrands()
    dataLayerPushPageType('Category')
  }

  componentDidUpdate(prevProps: Props) {
    const { slug, loadCategory, subCats } = this.props

    // if (prevProps.loading && !this.props.loading) {
    //   setEmarsysWebExtend(undefined, 'category', slug)
    // }

    if (slug !== prevProps.slug) {
      loadCategory(slug)
    }

    if (subCats) {
      subCats.forEach(subCat => {
        loadCategory(subCat.slug)
      })
    }
  }

  render() {
    const { category, parentCategory, isMainCategory, subCats, eventType } = this.props

    if (category === undefined) {
      return <NotFound />
    }

    return (
      <CategoryPage
        category={category}
        parentCategory={parentCategory}
        isMainCategory={isMainCategory}
        subCats={subCats}
        eventType={eventType}
      />
    )
  }
}

const mapStateToProps = (state: ApplicationState, routerProps: RouterProps): FieldProps => {
  const slug = routerProps.match.params.slug.toLowerCase()
  const parentFromRoute = routerProps.match.params.parentSlug
  const category = categorySelector(state, slug)
  const allDeals = dealsSelector(state)
  let parentSlug: any = null
  let parentCat: any = undefined
  let subCats: any = undefined

  const isMainCategory = !isSubcategory(state, slug)

  const currentEvent = currentEventSelector(state)

  if (currentEvent !== undefined && currentEvent.categories !== undefined) {
    if (isMainCategory) {
      parentCat = currentEvent.categories.find(item => item.slug.toLowerCase() === slug.toLowerCase())
    } else {
      parentCat = currentEvent.categories.find(
        item => item.slug.toLowerCase() === (parentFromRoute || '').toLowerCase()
      )
    }
  }

  if (parentCat !== undefined && parentCat.subCategories !== undefined) {
    subCats = parentCat.subCategories
  }

  if (parentFromRoute === undefined) {
    // Main category page
    parentSlug = parentFromRoute
  } else {
    // SubCategory page
    if (
      subCats &&
      subCats.find((item: { slug: string }) => item.slug.toLowerCase() === slug.toLowerCase()) !== undefined
    ) {
      parentSlug = parentFromRoute.toLowerCase()
      subCats = undefined
    }
  }

  if (category === undefined) {
    return {
      slug,
      parentSlug,
      subCats,
      category,
      allDeals,
      isMainCategory,
      loading: state.loading.count !== 0,
      eventType: currentEvent ? currentEvent.type : undefined,
    }
  }

  return {
    slug,
    parentSlug,
    subCats,
    category,
    allDeals,
    isMainCategory,
    parentCategory: parentCat && parentCat.slug !== category.slug ? parentCat : undefined,
    loading: state.loading.count !== 0,
    eventType: currentEvent ? currentEvent.type : undefined,
  }
}

const actions: MethodProps = {
  loadBrands,
  loadCategory: loadCategoryData,
}

export default withRouter(connect(mapStateToProps, actions)(Category))
