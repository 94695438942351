import actionCreatorFactory from 'typescript-fsa'
import { SearchTerm } from '../types'
import { Dispatch } from 'redux'
import {
    getRecentSearchTerms as getRecentSearchTermsFromLocalStorage,
    setRecentSearchTerms as saveRecentSearchTermsToLocalStorage,
} from '../../../utilities/localStorage'
import { SEARCH_TERM_LIMIT } from '../constants'

const actionCreator = actionCreatorFactory('SEARCH')
const recentSearchTermsLoaded = actionCreator<SearchTerm[]>('RECENT_SEARCH_TERMS_LOADED')
const recentSearchTermsUpdated = actionCreator<SearchTerm[]>('RECENT_SEARCH_TERMS_UPDATED')

function saveRecentSearchTerm(term: SearchTerm) {
    return (dispatch: Dispatch) => {
        let terms = getRecentSearchTermsFromLocalStorage()

        terms.unshift(term)
        terms = terms.splice(0, SEARCH_TERM_LIMIT)

        saveRecentSearchTermsToLocalStorage(terms)
        dispatch(recentSearchTermsUpdated(terms))
    }
}

function loadRecentSearchTerms() {
    return (dispatch: Dispatch) => {
        const terms = getRecentSearchTermsFromLocalStorage()

        dispatch(recentSearchTermsLoaded(terms))
    }
}

function clearRecentSearchTerms() {
    return (dispatch: Dispatch) => {
        const terms: SearchTerm[] = []

        saveRecentSearchTermsToLocalStorage(terms)
        dispatch(recentSearchTermsUpdated(terms))
    }
}

export {
    loadRecentSearchTerms,
    recentSearchTermsLoaded,
    recentSearchTermsUpdated,
    saveRecentSearchTerm,
    clearRecentSearchTerms,
}
