import * as React from 'react'
import Grid from './Grid'
import Spacing from './Spacing'
import Theme from './Theme'
import Typography from './Typography'

class Styleguide extends React.Component {
    render() {
        return (
            <>
                <Grid />
                <Spacing />
                <Theme />
                <Typography />
            </>
        )
    }
}

export default Styleguide
