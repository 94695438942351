import { CloseOutlined, UploadOutlined } from '@ant-design/icons'
import { GraphQLResult } from '@aws-amplify/api'
import { FileInfo } from '@uploadcare/react-widget'
import { Alert, Button, Checkbox, Col, Form, Input, InputNumber, Row, Select, Table, Tag } from 'antd'
import type { FormInstance } from 'antd/es/form'
import { API, Auth, graphqlOperation } from 'aws-amplify'
import bind from 'bind-decorator'
import moment from 'moment'
import * as React from 'react'
import { Block, DictionaryValue, Grid, ImageUpload, phoneToAws } from '../../../components'
import { DATE_FORMAT_TABLE, PAGE_SIZE } from '../../../constants'
import { createUserUploadedFiles, deleteUserUploadedFiles } from '../../../graphql/mutations'
import { UserUploadedFilesByUser } from '../../../graphql/queries'
import Brand from '../../../models/brand'
import { handleError } from '../../../utilities'
import { updateBrazeUser } from '../../../utilities/braze'
import { UserActionInput } from '../actions'
import { PaymentMethods, UserInfo } from '../types'
import './index.scss'
import { File } from './UploadPageWithData'

const { Option } = Select

type CreateUserUploadedFiles = {
  id: string
  name: string
  brand: string
  spend: number
  save: number
  paymentMethod: string
  uploadedAt: string
}

type FilesTable = {
  id: string
  brand: string
  spend: number
  paymentMethod: string
  save: number
  fileName: string
  uploadedAt: string
}

type MethodProps = {
  refresh: (payload: UserActionInput) => void
}

type Props = {
  brands: Brand[] | null
  userInfo: UserInfo
  eventId?: string
  networkTime: number
} & MethodProps

type State = {
  error?: Error
  showWidget: boolean
  isSaving: boolean
  fileInfo: any
  success: boolean
  data: FilesTable[]
  loading: boolean
}

class UploadPage extends React.Component<Props, State> {
  readonly state: State = {
    showWidget: false,
    isSaving: false,
    fileInfo: undefined,
    success: false,
    data: [],
    loading: true,
  }

  formRef = React.createRef<FormInstance>()

  @bind
  handleClick() {
    this.setState({ showWidget: !this.state.showWidget })
  }

  @bind
  onFinish(fileInfo: FileInfo) {
    this.setState({ fileInfo })
  }

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  handleSubmit = (e: any) => {
    this.setState({ isSaving: true })

    const { userInfo, eventId, refresh, networkTime } = this.props
    const { fileInfo } = this.state
    this.formRef.current
      ?.validateFields()
      .then(async () => {
        const values = this.formRef.current?.getFieldsValue()
        const url = values.files

        if (url && fileInfo) {
          try {
            const address = {
              street_address: escape(values.addressone),
              address_2: `${values.addresstwo ? escape(values.addresstwo) : ''}`,
              locality: escape(values.suburb),
              region: values.state,
              postal_code: values.postcode,
              country: 'Australia',
            }

            const mobile = values.mobile

            const user = await Auth.currentAuthenticatedUser()

            Auth.updateUserAttributes(user, {
              address: JSON.stringify(address),
              phone_number: phoneToAws(mobile),
            }).then(response => {
              if (response === 'SUCCESS') {
                refresh({ address, mobile } as UserActionInput)
                updateBrazeUser(
                  userInfo.email,
                  userInfo.firstName,
                  userInfo.lastName,
                  phoneToAws(mobile),
                  userInfo.gender,
                  Number(address.postal_code),
                  address.country,
                  userInfo.marketingOptIn
                )
              }
            })

            const response = (await API.graphql(
              graphqlOperation(createUserUploadedFiles, {
                input: {
                  url,
                  eventId,
                  id: fileInfo.uuid,
                  userId: userInfo.username,
                  brand: values.brand,
                  spend: values.spend,
                  save: values.save,
                  paymentMethod: values.paymentMethod,
                  name: fileInfo.name,
                  uploadedAt: moment.unix(networkTime),
                },
              })
            )) as GraphQLResult

            if (response.data && response.data !== null) {
              const data = this.state.data
              const createUserUploadedFiles = response.data as CreateUserUploadedFiles
              data.push({
                id: createUserUploadedFiles.id,
                fileName: createUserUploadedFiles.name,
                brand: createUserUploadedFiles.brand,
                spend: createUserUploadedFiles.spend,
                save: createUserUploadedFiles.save,
                paymentMethod: createUserUploadedFiles.paymentMethod,
                uploadedAt: moment(createUserUploadedFiles.uploadedAt).format(DATE_FORMAT_TABLE),
              })
              this.setState({ data, success: true })
              setTimeout(() => {
                this.setState({ success: false })
              }, 3000)
            } else {
              handleError(response.errors)
            }
          } catch (ex) {
            handleError(ex)
          } finally {
            this.formRef.current?.setFieldsValue({
              files: null,
            })
          }
          this.setState({ isSaving: false, showWidget: false })
        }
      })
      .catch((err: any) => {
        console.log('Error:', err)
        this.setState({ isSaving: false })
      })
  }

  handleDelete = (id: string) => (e: React.MouseEvent) => {
    e.preventDefault()
    this.setState({ loading: true })

    const response = API.graphql(
      graphqlOperation(deleteUserUploadedFiles, {
        input: { id },
      })
    ) as GraphQLResult

    if (response.data && response.data !== null) {
      const data = this.state.data.filter(file => {
        return file.id !== id
      })
      this.setState({ data, loading: false })
    } else {
      handleError(response.errors)
    }
  }

  validateMobile = (rule: any, value: any, callback: any) => {
    if (value && !/^04\d{8}$/.test(value)) {
      callback('Please enter a valid mobile phone number!')
    } else {
      callback()
    }
  }

  async componentDidMount() {
    const { userInfo } = this.props

    // Show Files Table
    const data: FilesTable[] = []

    const params = {
      userId: userInfo.username,
    }

    const result = (await API.graphql(graphqlOperation(UserUploadedFilesByUser, params))) as GraphQLResult

    if (result.data === undefined) {
      throw new Error(`Result loading exception: ${UserUploadedFilesByUser}`)
    }

    const allFiles: File[] = (result.data as any).UserUploadedFilesByUser.items

    if (allFiles.length > 0) {
      allFiles.forEach((file: File) => {
        data.push({
          id: file.id,
          fileName: file.name,
          brand: file.brand,
          spend: file.spend,
          save: file.save,
          paymentMethod: file.paymentMethod,
          uploadedAt: moment(file.uploadedAt).format(DATE_FORMAT_TABLE),
        })
      })
    }

    this.setState({ data, loading: false })
  }

  render() {
    const { brands, userInfo } = this.props

    const { showWidget, isSaving, success, data, loading } = this.state

    const address = userInfo.address

    const UploadHelp = (
      <Block className='cfa-image-upload__help'>
        <p>File requirements:</p>
        <ul>
          <li>Upload your receipt as a word, pdf or image file (JPG or PNG)</li>
          <li>Accepted formats: DOC, DOCX, PDF, XLS, XLSX, PNG, JPG, JPEG, TXT, RTF, WEBP, HEIF, HEIC</li>
          <li>Maximum 100MB file size</li>
        </ul>
      </Block>
    )

    const allowFileTypes = [
      'doc',
      'docx',
      'pdf',
      'xls',
      'xlsx',
      'png',
      'jpg',
      'jpeg',
      'txt',
      'rtf',
      'webp',
      'heif',
      'heic',
    ]

    const allowFileSize = {
      max: 100, // Size unit is MB
    }

    const formatter = new Intl.NumberFormat('en-AU', {
      style: 'currency',
      currency: 'AUD',
    })

    const columns = [
      {
        title: 'File Name',
        dataIndex: 'fileName',
        key: 'fileName',
        sorter: (a: any, b: any) => +(a.fileName > b.fileName) - +(a.fileName < b.fileName),
      },
      {
        title: 'Brand',
        key: 'brand',
        render: (record: FilesTable) => (
          <span>
            {record.brand
              ? record.brand.startsWith('[') && record.brand.endsWith(']')
                ? record.brand
                    .replace(/[\[\]']+/g, '') /* eslint-disable-line */
                    .split(',')
                    .map(String)
                    .map(brand => {
                      return (
                        <Tag
                          color='purple'
                          key={brand.trim()}
                        >
                          {brand.trim()}
                        </Tag>
                      )
                    })
                : record.brand
              : ''}
          </span>
        ),
      },
      {
        title: 'Spend',
        key: 'spend',
        render: (record: FilesTable) => record.spend && formatter.format(record.spend),
      },
      {
        title: 'Payment Method',
        dataIndex: 'paymentMethod',
        key: 'paymentMethod',
      },
      {
        title: 'Save',
        key: 'save',
        render: (record: FilesTable) => record.save && formatter.format(record.save),
      },
      {
        title: 'Uploaded Time',
        dataIndex: 'uploadedAt',
        key: 'uploadedAt',
        sorter: (a: any, b: any) => +(a.uploadedAt > b.uploadedAt) - +(a.uploadedAt < b.uploadedAt),
      },
      {
        title: '',
        key: 'action',
        render: (record: FilesTable) => <a onClick={this.handleDelete(record.id)}>Delete</a>,
      },
    ]

    return (
      <Grid className='cf-file-upload'>
        <h1>
          <DictionaryValue token='fileupload.title' />
        </h1>
        {success && (
          <Alert
            message='Your file has been uploaded successfully!'
            type='success'
            showIcon={true}
            closable={true}
          />
        )}
        <div className='cf-file-upload__widget'>
          <div className='cf-file-upload__widget__upload-button'>
            <Button
              type='primary'
              onClick={this.handleClick}
              size='large'
              icon={showWidget ? <CloseOutlined /> : <UploadOutlined />}
            >
              {showWidget ? 'Cancel' : 'Upload Files'}
            </Button>
          </div>
          {showWidget && (
            <Form
              layout='vertical'
              onFinish={this.handleSubmit}
              ref={this.formRef}
            >
              {!brands || brands.length === 0 ? (
                <Form.Item
                  label='What brand(s) did you purchase from?'
                  name='brand'
                  rules={[
                    {
                      required: true,
                      message: 'At least 1 brand is required',
                    },
                  ]}
                >
                  <Input />
                </Form.Item>
              ) : (
                <Form.Item
                  label='What brand(s) did you purchase from?'
                  name='brand'
                  rules={[
                    {
                      required: true,
                      message: 'At least 1 brand is required',
                      type: 'array',
                    },
                  ]}
                >
                  <Select
                    mode='multiple'
                    placeholder='Please select from the following brands'
                  >
                    {brands.map((brand: Brand) => (
                      <Option
                        value={brand.name}
                        key={brand.slug}
                      >
                        {brand.name}
                      </Option>
                    ))}
                  </Select>
                </Form.Item>
              )}
              <Form.Item
                label='How much did you spend?'
                name='spend'
                rules={[{ required: true }]}
              >
                <InputNumber<number>
                  min={0}
                  // tslint:disable: jsx-no-lambda
                  formatter={value => `$ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                  parser={value => (value ? Number.parseFloat(value.replace(/\$\s?|(,*)/g, '')) : 0)}
                />
              </Form.Item>
              <Form.Item
                label='What payment method did you use? (eg. Visa, Amex, Mastercard, AfterPay etc.)'
                name='paymentMethod'
                rules={[{ required: true }]}
              >
                <Select placeholder='Please select from the following payment methods'>
                  {Object.keys(PaymentMethods).map(key => (
                    <Option
                      value={key}
                      key={key}
                    >
                      {(PaymentMethods as any)[key]}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
              <Form.Item
                label='How much did you save?'
                name='save'
                rules={[{ required: true }]}
              >
                <InputNumber<number>
                  min={0}
                  formatter={value => `$ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                  parser={value => (value ? Number.parseFloat(value.replace(/\$\s?|(,*)/g, '')) : 0)}
                />
              </Form.Item>
              <Form.Item
                label='Mobile'
                name='mobile'
                rules={[{ required: true }, { validator: this.validateMobile }]}
              >
                <Input style={{ width: '100%' }} />
              </Form.Item>
              <Row
                gutter={8}
                className='cf-file-upload__widget__address'
              >
                <h2>Shipping Address</h2>
                <Col span={24}>
                  <Form.Item
                    label='Street Address'
                    name='addressone'
                    rules={[{ required: true }]}
                    initialValue={address && unescape(address.street_address || '')}
                  >
                    <Input />
                  </Form.Item>
                </Col>
                <Col span={24}>
                  <Form.Item
                    label='Address Line 2'
                    name='addresstwo'
                    rules={[{ required: false }]}
                    initialValue={address && address.address_2 && unescape(address.address_2 || '')}
                  >
                    <Input />
                  </Form.Item>
                </Col>
                <Col span={24}>
                  <Form.Item
                    label='Suburb'
                    name='suburb'
                    rules={[{ required: true }]}
                    initialValue={address && unescape(address.locality || '')}
                  >
                    <Input />
                  </Form.Item>
                </Col>
                <Col span={12}>
                  <Form.Item
                    label='State'
                    name='state'
                    rules={[{ required: true }]}
                    initialValue={address && address.region}
                  >
                    <Select placeholder='Select State'>
                      <Option value='ACT'>ACT</Option>
                      <Option value='NSW'>NSW</Option>
                      <Option value='NT'>NT</Option>
                      <Option value='QLD'>QLD</Option>
                      <Option value='SA'>SA</Option>
                      <Option value='TAS'>TAS</Option>
                      <Option value='VIC'>VIC</Option>
                      <Option value='WA'>WA</Option>
                    </Select>
                  </Form.Item>
                </Col>
                <Col span={12}>
                  <Form.Item
                    label='Postcode'
                    name='postcode'
                    rules={[
                      {
                        required: true,
                        message: 'Invalid Postcode',
                        pattern: new RegExp(/^\d{4}$/),
                      },
                    ]}
                    initialValue={address && address.postal_code}
                  >
                    <Input />
                  </Form.Item>
                </Col>
                <Col span={24}>
                  <Form.Item
                    name='addressconfirm'
                    rules={[
                      {
                        required: true,
                        message: 'Please confirm that your shipping address is correct',
                      },
                    ]}
                    valuePropName='checked'
                  >
                    <Checkbox>Tick this box to confirm that your shipping address is correct</Checkbox>
                  </Form.Item>
                </Col>
              </Row>
              <Form.Item
                label='Insert your upload image(s) below.
                                To be eligible for this promotion we will need proof that your purchase was
                                completed during the promotional period.'
                help={UploadHelp}
                name='files'
                rules={[{ required: true }]}
              >
                <ImageUpload
                  resizeWidth={750}
                  resizeHeight={750}
                  description='Please read the file requirements below'
                  allowFileTypes={allowFileTypes}
                  allowFileSize={allowFileSize}
                  onFinish={this.onFinish}
                  valueField='uuid'
                />
              </Form.Item>
              <Form.Item>
                <Button
                  type='primary'
                  block={true}
                  htmlType='submit'
                  size='large'
                  loading={isSaving}
                >
                  Submit
                </Button>
              </Form.Item>
            </Form>
          )}
        </div>
        <div className='cf-file-upload__fileslist'>
          <Table
            columns={columns}
            rowKey='id'
            dataSource={data}
            loading={loading}
            pagination={
              data && data.length < PAGE_SIZE
                ? false
                : {
                    defaultPageSize: PAGE_SIZE,
                  }
            }
          />
        </div>
      </Grid>
    )
  }
}

export default UploadPage
