import * as React from 'react'
import bind from 'bind-decorator'
import { history } from '../../../../../../src/history'
import { CfButton, Container } from '../../../../../components/cf-package'
import { ClockIco, CoinIco, TagIco } from '../../../../../components/cf-package/Svg'
import './style.scss'

type Props = {
  className?: string
  id?: string
}

class BecomeMember extends React.Component<Props> {
  @bind
  handleSignUpLink() {
    history.push('/account?join')
  }

  render() {
    const { className = '', id } = this.props

    return (
      <Container>
        <section
          className={`${className} cf-become-member-blk`}
          id={id}
        >
          <div className='cf-flex flex-row align-center justify-between'>
            <div className='content'>
              <h3 className='ttl head-lg'>
                Become a member.
                <br />
                It’s free and easy!
              </h3>
              <div className='desc'>Ready to make your money go further? Members get all the perks, join today!</div>
              <div className='cta-blk'>
                <CfButton
                  onClick={this.handleSignUpLink}
                  buttonType='pink'
                  size='large'
                >
                  Join and get notified early
                </CfButton>
              </div>
            </div>
            <div className='three-list'>
              <div className='cf-flex flex-row'>
                <div className='benefit-item first-item'>
                  <ClockIco />
                  <div className='title font-sg-bold'>Early access</div>
                  <div className='desc'>Access to Click Frenzy deals before the rest of Australia!</div>
                </div>
                <div className='benefit-item first-item'>
                  <TagIco />
                  <div className='title font-sg-bold'>99% off deals</div>
                  <div className='desc'>
                    Yes you read that right. $19 Apple Macbooks, $5 Nintendo Switch Consoles. It’s a frenzy.
                  </div>
                </div>
                <div className='benefit-item first-item'>
                  <CoinIco />
                  <div className='title font-sg-bold'>Cash prizes</div>
                  <div className='desc'>The first 100 members who spend $200 or more unlock $0 cashback!</div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </Container>
    )
  }
}

export default BecomeMember
