import * as React from 'react'
import './ListRows.scss'
import { Grid } from '../../components/grid'
import CardWithData from '../deal/CardWithData'
import DealPlacement from '../../models/dealPlacement'

export type Props = {
  deals: DealPlacement[]
}

class ListRows extends React.Component<Props> {
  render() {
    const { deals } = this.props

    return (
      <Grid>
        <div className='row'>
          {deals.map(({ slug, type }) => (
            <div
              className='col-xs-6 col-sm-6 col-md-4 col-lg-3 list-deal'
              key={slug}
            >
              <CardWithData
                slug={slug}
                type={type}
              />
            </div>
          ))}
        </div>
      </Grid>
    )
  }
}

export default ListRows
