import * as React from 'react'
import { Layout } from '../../layout'
import { UploadPageWithData } from './UploadPageWithData'

class UploadArea extends React.Component {
    render() {
        return (
            <Layout>
                <UploadPageWithData />
            </Layout>
        )
    }
}

export { UploadArea }
