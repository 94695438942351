import { connect } from 'react-redux'
import { default as Countdown, Props } from './Countdown'
import { ApplicationState } from '../../reducers'
import { currentEventSelector } from '../../data/selectors'

const mapStateToProps = (state: ApplicationState): Props => {
    const event = currentEventSelector(state)

    if (event === undefined) {
        return {
            to: 0,
        }
    }

    if (state.status.isEventRunning) {
        return {
            to: event.end,
            logo: event.logo,
            background: event.background,
            type: event.type,
            eventable: event.eventable,
        }
    }

    return {
        to: event.start,
        logo: event.logo,
        background: event.background,
        type: event.type,
        eventable: event.eventable,
        isLoggedIn: state.account.loggedIn,
    }
}

const CountdownWithData = connect(mapStateToProps)(Countdown)
export default CountdownWithData
