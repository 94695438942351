import * as React from 'react'
import './style.scss'

type Props = {
  className?: string
  id?: string
  children?: React.ReactNode[] | React.ReactNode
}

class Heading extends React.Component<Props> {
  render() {
    const { className = '', children, id } = this.props

    return (
      <div
        className={`${className} cf-heading`}
        id={id}
      >
        {children}
      </div>
    )
  }
}

export default Heading
