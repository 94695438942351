import * as React from 'react'
import loadingLogo from '../../../static/images/loading_cf_logo.png'
import './loading.scss'

export type Props = {
  loading: boolean
}

class Loading extends React.Component<Props> {
  render() {
    const { loading } = this.props

    if (!loading) {
      return null
    }

    return (
      <div className='loading-overlay'>
        <img
          className='loading-overlay__logo'
          src={loadingLogo}
        />
        <h1 className='loading-overlay__text'></h1>
      </div>
    )
  }
}

export default Loading
