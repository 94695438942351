import React from 'react'
import { default as CategoryModel } from '../../../models/category'
import { Grid } from '../../../components/grid'
import { Link } from 'react-router-dom'
import { Button } from '../../../components'
import { getCategoryUrl, getSubcategoryUrl } from '../utilities'
import styles from './index.module.scss'

type Props = {
    parentSlug?: string
    categories: CategoryModel[]
}

class SubCategoriesSlider extends React.Component<Props> {
    render () {

        const { parentSlug, categories } = this.props

        return (
            <Grid>
                <div className={styles.row}>
                    <div className={styles.cell}>
                        <ul className={styles.categories}>
                            {categories.map((category) => {
                                return (
                                    <li className={styles.item} key={category.slug}>
                                        <Link
                                            to={
                                                parentSlug
                                                ? getSubcategoryUrl(parentSlug, category.slug)
                                                : getCategoryUrl(category.slug)
                                            }
                                            className={styles.link}
                                        >
                                            <Button
                                                className={styles.button}
                                                buttonType='primary'
                                                buttonStyle='square'
                                            >
                                                {category.name}
                                            </Button>
                                        </Link>
                                    </li>
                                )
                            })}
                        </ul>
                    </div>
                </div>
            </Grid>
        )
    }
}

export default SubCategoriesSlider
