import * as React from 'react'
import NonEventHome, { FieldProps } from './NonEventHome'
import { connect } from 'react-redux'
import { ApplicationState } from '../../../reducers'
import { loadHomepageData } from './actions'
import { loadCfDeals } from '../../../data/actions'
import { cfDealsSelector, currentEventSelector } from '../../../data/selectors'
import { dataLayerPushPageType } from '../../../services/gtm'
import FooterLink from '../../../models/footerLink'

type OwnProps = {
  customLinks?: FooterLink[]
}

type MethodProps = {
  loadHomepageData: () => void
  loadCfDeals: () => void
}

type Props = FieldProps & MethodProps & OwnProps

class NonEventHomeWithData extends React.Component<Props> {
  componentDidMount() {
    const { loadCfDeals, loadHomepageData } = this.props
    dataLayerPushPageType('Home')

    loadHomepageData()
    loadCfDeals()
    window.dispatchEvent(new Event('resize'))
  }

  render() {
    const {
      eventType,
      nonEventConfig,
      deals,
      links,
      brandWallData,
      isBrandWallEnabled,
      isAlwaysOnEnabled,
      upcomingEvent,
    } = this.props
    return (
      <NonEventHome
        eventType={eventType}
        nonEventConfig={nonEventConfig}
        deals={deals}
        links={links}
        brandWallData={brandWallData}
        isBrandWallEnabled={isBrandWallEnabled}
        isAlwaysOnEnabled={isAlwaysOnEnabled}
        upcomingEvent={upcomingEvent}
      />
    )
  }
}

const mapStateToProps = (state: ApplicationState): FieldProps => {
  const event = currentEventSelector(state)
  const cfDeals = cfDealsSelector(state)
  const links = state.data.footerLinks.sort((a, b) => a.sortOrder - b.sortOrder)

  let eventType
  if (event !== undefined) {
    eventType = event.type
  }
  return {
    eventType,
    nonEventConfig: state.data.nonEventConfig,
    deals: cfDeals,
    links,
    brandWallData: state.data.nonEventConfig.topRetailers?.brands,
    isBrandWallEnabled: state.data.nonEventConfig.isBrandWallEnabled,
    isAlwaysOnEnabled: state.data.nonEventConfig.isAlwaysOnEnabled,
    upcomingEvent: event,
  }
}

const actions: MethodProps = {
  loadHomepageData,
  loadCfDeals,
}

export default connect(mapStateToProps, actions)(NonEventHomeWithData)
