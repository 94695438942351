import * as React from 'react'
import { DictionaryValue } from '../../components'
import { AddressFormat, BlankAddress } from './types'

type Props = {
    label: string
    value: string | JSX.Element | AddressFormat
    address?: boolean
}

class ProfilePageRow extends React.Component<Props> {
    render() {
        const { label, value, address } = this.props

        const fullAddress = address && value !== '' ? (value as AddressFormat) : BlankAddress

        return (
            <div className='profile-page__row'>
                <div className='profile-page__label'>
                    <DictionaryValue token={label} />
                </div>
                {address ? (
                    <div className='profile-page__value'>
                        {/* {unescape(fullAddress.street_address)}<br />
                        {fullAddress.address_2 &&
                            <span dangerouslySetInnerHTML={{ __html: `${unescape(fullAddress.address_2)}<br/>` }}/>
                        }
                        {unescape(fullAddress.locality)}<br /> */}
                        {fullAddress.region} {fullAddress.postal_code},&nbsp;
                        {fullAddress.country}
                    </div>
                ) : (
                    <div className='profile-page__value'>
                        {value.toString()}
                        </div>
                )}
            </div>
        )
    }
}

export { ProfilePageRow }
