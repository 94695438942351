import * as React from 'react'
import bind from 'bind-decorator'
import { ClearInput } from '../../../components/form'
import { Icon, search, cross } from '../../../components'
import './SearchBar.scss'
import { Button } from '../../../components/button'
import { connectSearchBox, SearchBoxProvided } from 'react-instantsearch-core'
import EventType from '../../../models/eventType'

type FieldProps = {
  eventType?: EventType | null
}

type Props = FieldProps & SearchBoxProvided
class SearchBar extends React.Component<Props> {
  inputRef: HTMLInputElement | null = null

  @bind
  handleSubmitSearch(event: React.FormEvent<HTMLFormElement>) {
    event.preventDefault()

    if (this.inputRef) {
      this.inputRef.blur()
    }
  }

  @bind
  handleSearchInputChange(event: React.ChangeEvent<HTMLInputElement>) {
    this.props.refine(event.target.value)
  }

  @bind
  handleClearClick() {
    this.props.refine('')

    if (this.inputRef) {
      this.inputRef.focus()
    }
  }

  @bind
  setInputRef(ref: HTMLInputElement) {
    this.inputRef = ref
  }

  componentDidMount() {
    const isSearchPath = window.location.pathname === '/search'
    if (this.inputRef && isSearchPath) {
      this.inputRef.focus()
    }
  }

  render() {
    const { currentRefinement, eventType } = this.props

    return (
      <form
        className='search-bar'
        onSubmit={this.handleSubmitSearch}
        action='.'
      >
        <div className={`search-bar__input-wrapper search-bar__input-wrapper--${eventType}`}>
          <ClearInput
            type='search'
            placeholder='search.input.placeholder'
            onChange={this.handleSearchInputChange}
            value={currentRefinement}
            className={`search-bar__input search-bar__input--${eventType}`}
            inputRef={this.setInputRef}
            autoComplete='off'
          />
          {currentRefinement && (
            <Button
              buttonType='tertiary'
              className='search-bar__clear'
              callback={this.handleClearClick}
              size='large'
              type='button'
            >
              <Icon
                icon={cross}
                className='search-bar__icon search-bar__icon__cross'
              />
            </Button>
          )}
          <Icon
            icon={search}
            className={'search-bar__icon search-bar__icon-' + eventType}
          />
        </div>
      </form>
    )
  }
}

const SearchBarWithData = connectSearchBox(SearchBar)

export { SearchBar, SearchBarWithData }
