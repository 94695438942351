import * as React from 'react'
import { Container } from '../../components/cf-package'
import { DictionaryValue } from '../dictionary-value'
import './style.scss'
import BrandWallBrand from '../../models/brandWallBrand'
import { getResizedImageFromCDN, brandLogoSize } from '../../utilities'
import { Link } from '../link'

type Props = {
  className?: string
  id?: string
  brandWallTitle?: string
  data?: BrandWallBrand[]
}

class BrandWall extends React.Component<Props> {
  editBrandUrl = (url?: string) => {
    if (url && !url.match(/^[a-zA-Z]+:\/\//)) {
      return `//${url}`
    }
    return url || ''
  }

  render() {
    const { className = '', id, brandWallTitle, data } = this.props

    return (
      <div className='bg-light-voilet'>
        <Container>
          <section
            className={`${className} brandwall-blk`}
            id={id}
          >
            <h3 className='ttl head-lg'>
              <DictionaryValue token={brandWallTitle ? brandWallTitle : ''} />
            </h3>
            {/* <div className='logos-list cf-flex flex-row'>
              <div className='logo-item'>
                <img
                  src='data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAIAAACQd1PeAAAABnRSTlMA/
                    wD/AP83WBt9AAAADElEQVQI12P4//8/AAX+Av7czFnnAAAAAElFTkSuQmCC'
                  data-blink-src={
                    data
                      ? getResizedImageFromCDN(
                          data[0].squarelogo && data[0].squarelogo !== '' ? data[0].squarelogo : data[0].logo,
                          data[0].logoIsBitmap,
                          brandLogoSize.featured
                        )
                      : ''
                  }
                  alt='logo'
                />
              </div>
              <div className='logo-item'>
                <img
                  src='./static/images/logos/logo_2.png'
                  alt='logo'
                />
              </div>
              <div className='logo-item'>
                <img
                  src='./static/images/logos/logo_3.png'
                  alt='logo'
                />
              </div>
              <div className='logo-item'>
                <img
                  src='./static/images/logos/logo_4.png'
                  alt='logo'
                />
              </div>
              <div className='logo-item'>
                <img
                  src='./static/images/logos/logo_5.png'
                  alt='logo'
                />
              </div>
              <div className='logo-item'>
                <img
                  src='./static/images/logos/logo_6.png'
                  alt='logo'
                />
              </div>
            </div>
            <div className='logos-list cf-flex flex-row'>
              <div className='logo-item'>
                <img
                  src='data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAIAAACQd1PeAAAABnRSTlMA/
                    wD/AP83WBt9AAAADElEQVQI12P4//8/AAX+Av7czFnnAAAAAElFTkSuQmCC'
                  data-blink-src={
                    data
                      ? getResizedImageFromCDN(
                          data[0].squarelogo && data[0].squarelogo !== '' ? data[0].squarelogo : data[0].logo,
                          data[0].logoIsBitmap,
                          brandLogoSize.featured
                        )
                      : ''
                  }
                  alt='logo'
                />
              </div>
              <div className='logo-item'>
                <img
                  src='./static/images/logos/logo_2.png'
                  alt='logo'
                />
              </div>
              <div className='logo-item'>
                <img
                  src='./static/images/logos/logo_3.png'
                  alt='logo'
                />
              </div>
              <div className='logo-item'>
                <img
                  src='./static/images/logos/logo_4.png'
                  alt='logo'
                />
              </div>
            </div> */}
            <div>
              <ul className='brandwall-brands brandwall-brands--presentation-default brandwall-brands__non-event'>
                {data &&
                  data.length > 0 &&
                  data
                    .sort((a, b) => Number(a.sortOrder) - Number(b.sortOrder))
                    .map(brand => (
                      <li
                        className='brandwall-brands__brand'
                        key={brand.slug}
                      >
                        <Link
                          to={this.editBrandUrl(brand.websiteUrl)}
                          target={'_blank'}
                          className='brandwall-brands__link'
                        >
                          <img
                            src='data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAIAAACQd1PeAAAABnRSTlMA/
                                    wD/AP83WBt9AAAADElEQVQI12P4//8/AAX+Av7czFnnAAAAAElFTkSuQmCC'
                            data-blink-src={getResizedImageFromCDN(
                              brand.squarelogo && brand.squarelogo !== '' ? brand.squarelogo : brand.logo,
                              brand.logoIsBitmap,
                              brandLogoSize.featured
                            )}
                            alt={brand.name}
                            className='brandwall-brands__logo'
                          />
                        </Link>
                      </li>
                    ))}
              </ul>
            </div>
          </section>
        </Container>
      </div>
    )
  }
}

export default BrandWall
