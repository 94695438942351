import * as React from 'react'
import Brand from '../../models/brand'
import { getResizedImageFromCDN, brandLogoSize } from '../../utilities'
import './BrandDetailBasic.scss'

export type Props = {
    brand: Brand | undefined
}

class BrandDetailBasic extends React.Component<Props> {
    render() {
        const { brand } = this.props

        if (brand === undefined) {
            return null
        }

        return (
            <div className='brand-detail-basic'>
                <div className='brand-detail-basic__primary'>
                    <div className='brand-detail-basic__logo-wrapper'>
                        <img
                            src='data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAIAAACQd1PeAAAABnRSTlMA/
                            wD/AP83WBt9AAAADElEQVQI12P4//8/AAX+Av7czFnnAAAAAElFTkSuQmCC'
                            data-blink-src={
                                getResizedImageFromCDN(
                                    brand.squarelogo ? brand.squarelogo : brand.logo,
                                    brand.logoIsBitmap,
                                    brandLogoSize.detail
                                )
                            }
                            className='brand-detail-basic__logo-image'
                            alt={brand.name}
                        />
                    </div>
                    <div className='brand-detail-basic__name-wrapper'>
                        <h1 className='brand-detail-basic__name'>{brand.name}</h1>
                    </div>
                </div>
                <div className='brand-detail-basic__secondary'>
                    <p className='brand-detail-basic__description'>{brand.description}</p>
                </div>
            </div>
        )
    }
}

export default BrandDetailBasic
