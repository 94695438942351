export type SignUpFormSubmission = {
    email: string
    emails?: boolean
    firstName: string
    gender: string
    lastName: string
    mobile?: string
    password: string
    addressone: string
    addresstwo?: string
    suburb: string
    state: string
    postcode: string
    terms: boolean
    termsncondition?: boolean
}

export type FavouriteDeal = {
    id: string
    type: string
    userId: string
    dealId: string
    brandSlug: string
    active: boolean
    addedAt: string
    deactivatedAt: string
}

export type AddressFormat = {
    street_address: string
    address_2?: string
    locality: string
    region: string
    postal_code: string
    country: string
}

export const BlankAddress = {
    street_address: '',
    address_2: undefined,
    locality: '',
    region: '',
    postal_code: '',
    country: '',
}

export type UserInfo = {
    username: string
    email: string
    firstName: string
    lastName: string
    mobile?: string
    gender: string
    address?: AddressFormat
    marketingOptIn: boolean
}

export enum PaymentMethods {
    visa = 'Visa',
    mastercard = 'MasterCard',
    amex = 'AMEX',
    paypal = 'Paypal',
    afterpay = 'Afterpay',
    zip = 'Zip',
    humm = 'Humm',
    klarna = 'Klarna',
    applePay = 'Apple Pay',
    giftCard = 'Gift Card',
    bankTransfer = 'Bank Transfer',
    other = 'Other',
}
