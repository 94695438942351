import * as React from 'react'
import UploadPage from './UploadPage'
import UploadFilesAdmin from './UploadFilesAdmin'
import { ApplicationState } from '../../../reducers'
import { connect } from 'react-redux'
import Brand from '../../../models/brand'
import { loadBrands } from '../../brand/actions'
import { UserInfo } from '../types'
import { isLoggedInSelector } from '../reducer'
import { updateAccount, UserActionInput } from '../actions'
import { currentEventSelector } from '../../../data/selectors'

export type File = {
  id: string
  userId: string
  brand: string
  spend: number
  paymentMethod: string
  save: number
  name: string
  url: string
  uploadedAt: number
}

type OwnProps = {
  isLoggedIn: boolean
  brands: Brand[] | null
  isAdmin?: boolean
  userInfo?: UserInfo
  eventId?: string
  networkTime: number
}

type MethodProps = {
  loadBrands: () => void
  updateAccount: (payload: UserActionInput) => void
}

type Props = OwnProps & MethodProps

class UploadPageLoad extends React.Component<Props> {
  componentDidMount() {
    const { loadBrands } = this.props
    loadBrands()
  }

  render() {
    const { isLoggedIn, isAdmin, brands, userInfo, eventId, updateAccount, networkTime } = this.props

    return (
      <>
        {isLoggedIn && userInfo ? (
          isAdmin ? (
            <UploadFilesAdmin />
          ) : (
            <UploadPage
              brands={brands}
              userInfo={userInfo}
              eventId={eventId}
              refresh={updateAccount}
              networkTime={networkTime}
            />
          )
        ) : (
          ''
        )}
      </>
    )
  }
}

const mapStateToProps = (state: ApplicationState): OwnProps => {
  const event = currentEventSelector(state)
  return {
    isLoggedIn: isLoggedInSelector(state),
    brands: state.data.brands,
    isAdmin: state.account.isAdmin,
    networkTime: state.status.networkTime,
    userInfo: isLoggedInSelector(state)
      ? ({
          username: state.account.username,
          email: state.account.email,
          firstName: state.account.firstName,
          lastName: state.account.lastName,
          mobile: state.account.mobile,
          gender: state.account.gender,
          address: state.account.address,
          marketingOptIn: state.account.marketing,
        } as UserInfo)
      : undefined,
    eventId: event && state.status.isEventRunning ? event.slug : undefined,
  }
}

const actions: MethodProps = {
  loadBrands,
  updateAccount,
}

const UploadPageWithData = connect(mapStateToProps, actions)(UploadPageLoad)

export { UploadPageWithData }
