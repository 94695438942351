import * as React from 'react'
import { AuthPiece } from 'aws-amplify-react'
import { Grid, DictionaryValue, Icon, thumbUp } from '../../components'
import './Welcome.scss'

// eslint-disable-next-line @typescript-eslint/ban-types
class Welcome extends AuthPiece<{}, {}> {
  protected readonly _validAuthStates = ['welcome']

  showComponent() {
    return (
      <Grid>
        <div className='row welcome'>
          <div className='col-xs-12 col-md-8 col-md-offset-2'>
            <h1 className='welcome__title'>
              <DictionaryValue token='welcome.title' />
            </h1>
            <Icon
              className='welcome__image'
              icon={thumbUp}
              circleBackground={true}
            />
            <p className='welcome__description'>
              <DictionaryValue token='welcome.description.intro' />
            </p>
            <p className='welcome__description'>
              <DictionaryValue token='welcome.description.info' />
            </p>
          </div>
        </div>
      </Grid>
    )
  }
}

export { Welcome }
