import React from 'react'
import { Grid } from '../../../components/grid'
import { Link } from 'react-router-dom'
import { MenuItem } from '../../../models/menu'
import SecondNavItem from './SecondNavItem'
import { SearchBarWithData } from '../../search/components/SearchBar'
import { SearchRootWithData } from '../../search/components/SearchRoot'
import AdvertiseWithData from '../top-header/AdvertiseWithData'
import EventType from '../../../models/eventType'
import './SecondNav.scss'

export interface FieldProps {
  items: MenuItem[]
  categoryMenuHandler: () => void
  isCategoryMenuOpen: boolean
  isEventRunning: boolean
  totalCategories: number
  eventType?: EventType | null
}

type Props = FieldProps

class SecondNav extends React.Component<Props> {
  render() {
    const { items, categoryMenuHandler, isCategoryMenuOpen, isEventRunning, totalCategories, eventType } = this.props

    const isSearchPath = window.location.pathname === '/search'

    return (
      <nav className={`second-nav second-nav__${eventType}`}>
        <div className='second-nav__body'>
          <Grid>
            {isEventRunning && (
              <>
                <div className='second-nav second-nav__container'>
                  {items.map((item, index) => {
                    if (item.name === 'Shop by Category' && totalCategories) {
                      if (item.path === '') {
                        return (
                          <SecondNavItem
                            name={item.name}
                            path={item.path}
                            displayIcon={true}
                            key={index}
                            categoryMenuHandler={categoryMenuHandler}
                            isCategoryMenuOpen={isCategoryMenuOpen}
                            eventType={eventType}
                          />
                        )
                      }
                      return (
                        <SecondNavItem
                          name={item.name}
                          path={item.path}
                          displayIcon={true}
                          key={index}
                          categoryMenuHandler={categoryMenuHandler}
                          isCategoryMenuOpen={isCategoryMenuOpen}
                          eventType={eventType}
                        />
                      )
                    }
                    if (item.name === 'Shop by Brand') {
                      return (
                        <SecondNavItem
                          name={item.name}
                          path={item.path}
                          displayIcon={true}
                          key={index}
                          eventType={eventType}
                        />
                      )
                    }
                    // if (item.name === 'Exclusive Deals') {
                    //   return (
                    //     <SecondNavItem
                    //       name={item.name}
                    //       path={item.path}
                    //       displayIcon={true}
                    //       key={index}
                    //       eventType={eventType}
                    //     />
                    //   )
                    // }

                    // if (item.name === 'Best of Frenzy') {
                    //   return (
                    //     <SecondNavItem
                    //       name={item.name}
                    //       path={item.path}
                    //       displayIcon={true}
                    //       key={index}
                    //       eventType={eventType}
                    //     />
                    //   )
                    // }
                    if (item.name === 'FAQs') {
                      return (
                        <SecondNavItem
                          name={item.name}
                          path={item.path}
                          key={index}
                          eventType={eventType}
                        />
                      )
                    }
                  })}
                </div>
                <div className='mobile-search-bar'>
                  {isSearchPath ? (
                    <SearchRootWithData>
                      <SearchBarWithData eventType={eventType} />
                    </SearchRootWithData>
                  ) : (
                    <Link to='/search'>
                      <SearchRootWithData>
                        <SearchBarWithData eventType={eventType} />
                      </SearchRootWithData>
                    </Link>
                  )}
                </div>
              </>
            )}
            {!isEventRunning && <AdvertiseWithData />}
          </Grid>
        </div>
      </nav>
    )
  }
}

export default SecondNav
