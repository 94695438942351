import React, { useState } from 'react'
import { RouteComponentProps, withRouter } from 'react-router'
import { Page } from './Page'
import { pageSelector } from '../../data/selectors'
import { ApplicationState } from '../../reducers'
import { connect } from 'react-redux'
import CmsPage from '../../models/cmsPage'
import { loadPageData } from './actions'
import NotFound from '../NotFound'
import builder from '@builder.io/react'
import { Spin } from 'antd'

process.env.REACT_APP_BUILDER_IO_KEY && builder.init(process.env.REACT_APP_BUILDER_IO_KEY)

type RouterProps = RouteComponentProps<{
    slug: string
}>

type FieldProps = {
    slug: string,
    page: CmsPage | undefined
}

type MethodProps = {
    loadPageData: (slug: string) => void
}

type Props = FieldProps & MethodProps

const LoadPage = (props: Props) => {
    const { slug, page, loadPageData } = props
    const [loadingBuilderComponent, setLoadingBuilderComponent] = useState(false)

    React.useEffect(() => {
        slug && loadPageData(slug)
    }, [slug])

    React.useEffect(() => {
        if (page && page.builderJson) {
            // lazy load builder components
            setLoadingBuilderComponent(true)
            import('./builder').finally(() => {
                setLoadingBuilderComponent(false)
            })
        }
    }, [page])

    if (loadingBuilderComponent) {
        return <Spin size='large' />
    }

    if (!page || (page && !(page.builderJson || page.content))) {
        return <NotFound />
    }

    return <Page page={page} />
}

const mapStateToProps = (state: ApplicationState, ownProps: RouterProps): FieldProps => ({
    slug: ownProps.match.params.slug,
    page: pageSelector(state, ownProps.match.params.slug),
})

const actions: MethodProps = {
    loadPageData,
}

const PageWithData = withRouter(connect(mapStateToProps, actions)(LoadPage))

export { PageWithData }
