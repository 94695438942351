import React, { PropsWithChildren } from 'react'
import './Section.scss'

class Section extends React.Component<PropsWithChildren> {
    render() {
        const { children } = this.props

        return (
            <div className='section'>
                {children}
            </div>
        )
    }
}

export { Section }
