import * as React from 'react'
import './CategoryPage.scss'
import Category, { default as CategoryModel } from '../../models/category'
import CategoryDealList from '../deal-list/CategoryDealList'
import MainCategoryDealList from '../deal-list/MainCategoryDealList'
import CategoryDealListFooterWithData from '../deal-list/CategoryDealListFooterWithData'
import MainCategoryDealListFooterWithData from '../deal-list/MainCategoryDealListFooterWithData'
import { Layout } from '../layout'
import { HeaderWithData } from '../../features/home/event-site/header'
import { BannerCarouselWithData } from '../../components/banner'
import { Grid, SectionHeader } from '../../components'
import { Refinement } from './Refinement'
import { CategoryBrands } from './CategoryBrands'
import { Breadcrumb } from '../../components/breadcrumb/Breadcrumb'
import { getCategoryUrl } from './utilities'
import EventType from '../../models/eventType'
import { BackToTop } from '../../components/back-to-top'
import SubCategoriesSlider from './SubCategoriesSlider'

type Props = {
    category: Category
    parentCategory?: CategoryModel
    isMainCategory: boolean
    subCats?: CategoryModel[]
    eventType?: EventType
}

type State = {
    subcategories: Category[]
}

class CategoryPage extends React.Component<Props, State> {
    render() {
        const {
            category: { slug, name },
            parentCategory,
            isMainCategory,
            subCats,
        } = this.props
        const bannerType = isMainCategory ? 'CATEGORY_HEADER' : 'SUB_CATEGORY_HEADER'
        const headerBannerType = isMainCategory ? 'CATEGORY_LEADERBOARD' : 'SUB_CATEGORY_LEADERBOARD'

        let slugs = [slug]
        if (isMainCategory && subCats) {
            slugs = [...slugs].concat([...subCats.map(subCat => subCat.slug)])
        }

        let breadcrumbItems: { title: string; to?: string }[] = [{ title: name }]

        if (parentCategory) {
            breadcrumbItems = [
                {
                    title: parentCategory.name,
                    to: getCategoryUrl(parentCategory.slug),
                },
                ...breadcrumbItems,
            ]
        } else {
            breadcrumbItems = [
                ...breadcrumbItems,
                {
                    title: 'All',
                },
            ]
        }

        return (
            <Layout>
                <HeaderWithData type={headerBannerType} category={slug} />
                {/* <CategoryListWithData /> */}
                <BannerCarouselWithData type={bannerType} category={slug} />
                <div className='category_page__breadcrumb-section'>
                    <Grid>
                        <div className='row'>
                            <div className='col-xs-12'>
                                <Breadcrumb items={breadcrumbItems} />
                            </div>
                        </div>
                    </Grid>
                </div>
                <SectionHeader title={name} rightSide={<Refinement slug='' subCatSlugs={slugs} />} />
                {isMainCategory && subCats && subCats !== null && (
                    <SubCategoriesSlider parentSlug={slug} categories={subCats} />
                )}
                <CategoryDealList slug={slug} />
                {isMainCategory &&
                    subCats &&
                    subCats !== null &&
                    subCats.map((subCat: CategoryModel, index) => (
                        <>
                            <SectionHeader
                                title={subCat.name}
                                className={isMainCategory ? 'section-header--category' : ''}
                                key={`subcatheader-${index}`}
                            />
                            <CategoryDealList slug={subCat.slug} showGold={true} key={`subcatdeallist-${index}`} />
                        </>
                    ))}
                {isMainCategory && (
                    <>
                        <SectionHeader title='More Deals' className='section-header--more' />
                        <MainCategoryDealList slugs={slugs} showSandSS={true} />
                        <MainCategoryDealListFooterWithData slugs={slugs} />
                        <CategoryBrands slug={slug} />
                    </>
                )}
                {!isMainCategory && <CategoryDealListFooterWithData slug={slug} />}
                <BackToTop />
            </Layout>
        )
    }
}

export default CategoryPage
