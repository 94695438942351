import { connect } from 'react-redux'
import { ApplicationState } from '../../../reducers'
import { currentEventSelector } from '../../../data/selectors'
import Advertise, { Props } from './Advertise'

const mapStateToProps = (state: ApplicationState): Props => {
    const event = currentEventSelector(state)

    if ((event === undefined || !state.status.isEventRunning) && state.data.nonEventConfig) {
        return {
            advertiseUrl: state.data.nonEventConfig.advertiseUrl,
            advertiseText: state.data.nonEventConfig.advertiseText,
            type: event ? event.type : undefined,
        }
    }
    return {}
}

const AdvertiseWithData = connect(mapStateToProps)(Advertise)
export default AdvertiseWithData
