const algoliaIndexSuffix = process.env.REACT_APP_ALGOLIA_INDEX_SUFFIX as string

const SEARCH_TERM_LIMIT = 20
const SEARCH_INDEX = `deals-${algoliaIndexSuffix}`
const SEARCH_INDEX_BY_BRAND_ASC = `deals-by-brand-asc-${algoliaIndexSuffix}`
const SEARCH_INDEX_BY_BRAND_DESC = `deals-by-brand-desc-${algoliaIndexSuffix}`
const SEARCH_UPDATE_URL_ON_SEARCH_DELAY = 500
const RESET_ITEM = 'RESET_ITEM'

export {
    SEARCH_TERM_LIMIT,          // TODO: not being used
    SEARCH_INDEX,
    SEARCH_INDEX_BY_BRAND_ASC,
    SEARCH_INDEX_BY_BRAND_DESC,
    SEARCH_UPDATE_URL_ON_SEARCH_DELAY,
    RESET_ITEM
}
