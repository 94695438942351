import * as React from 'react'
import bind from 'bind-decorator'
import CSSTransition from 'react-transition-group/CSSTransition'
import { Button } from '../button'
import { DictionaryValue } from '../dictionary-value'
import './BackToTop.scss'

type State = {
  shown: boolean
  atBottom: boolean
  timeout?: number | undefined
}

// eslint-disable-next-line @typescript-eslint/ban-types
class BackToTop extends React.Component<{}, State> {
  readonly state: State = { shown: false, atBottom: false }
  private positionRef = React.createRef<HTMLDivElement>()

  componentDidMount() {
    window.addEventListener('scroll', this.listenToScroll)
    this.listenToScroll()
  }

  componentWillUnmount() {
    window.removeEventListener('scroll', this.listenToScroll)
    clearTimeout(this.state.timeout)
  }

  onClick(e: React.MouseEvent) {
    e.preventDefault()
    window.scrollTo({
      top: 0,
      behavior: 'smooth'
    })
  }

  @bind
  listenToScroll() {
    if (this.positionRef.current === null) {
      return
    }

    const winScroll = document.body.scrollTop || document.documentElement.scrollTop
    clearTimeout(this.state.timeout)

    const positionRectangle = this.positionRef.current.getBoundingClientRect()
    const windowPosition = (window.innerHeight || document.documentElement.clientHeight) - 60
    const atBottom = positionRectangle.top < windowPosition

    this.setState({
      atBottom,
      shown: winScroll !== 0,
      timeout: setTimeout(this.hide, 2000) as any
    })
  }

  @bind
  hide() {
    this.setState({ timeout: undefined, shown: false })
  }

  render() {
    const { shown, atBottom } = this.state

    return (
      <>
        <div ref={this.positionRef} />
        <CSSTransition in={shown && !atBottom} timeout={300} classNames="back-to-top-">
          <Button buttonType="secondary" className="back-to-top" callback={this.onClick}>
            <DictionaryValue token="cta.back-to-top" />
          </Button>
        </CSSTransition>
      </>
    )
  }
}

export default BackToTop
