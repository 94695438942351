import React from 'react'
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore TODO: improve this
import { LocationDescriptor } from 'history'
import classnames from 'classnames'
import { Link } from '../link'
import { Icon, chevron } from '../icon/Icon'
import styles from './Breadcrumb.module.scss'
import EventType from '../../models/eventType'

interface Item {
  title: React.ReactNode
  to?: LocationDescriptor
}

interface BreadcrumbItemProps {
  item: Item
  titleClassName?: string
}
const BreadcrumbItem = ({ item, titleClassName }: BreadcrumbItemProps) => (
  <span
    className={classnames(titleClassName, styles.title)}
    itemProp='name'
  >
    {item.title}
  </span>
)

interface BreadcrumbProps {
  items?: Item[]
  className?: string
  titleClassName?: string
  itemClassName?: string
  eventType?: EventType | null
}

export const Breadcrumb = ({ items, className, titleClassName, itemClassName, eventType }: BreadcrumbProps) => (
  <>
    {items && (
      <ol
        className={classnames(className, styles.container)}
        itemScope={true}
        itemType='https://schema.org/BreadcrumbList'
      >
        {items.map((item, index) => (
          <li
            key={index}
            className={classnames(itemClassName, styles.item)}
            itemScope={true}
            itemProp='itemListElement'
            itemType='https://schema.org/ListItem'
          >
            {item.to && (
              <Link
                to={item.to}
                className={styles.link}
                itemScope={true}
                itemProp='item'
                itemType='https://schema.org/WebPage'
                itemID={item.to.toString()}
              >
                <BreadcrumbItem
                  item={item}
                  titleClassName={titleClassName}
                />
              </Link>
            )}
            {!item.to && (
              <BreadcrumbItem
                item={item}
                titleClassName={titleClassName}
              />
            )}

            <meta
              itemProp='position'
              content={(index + 1).toString()}
            />
            {index < items.length - 1 && (
              <Icon
                icon={chevron}
                className={`${styles.chevron} ${styles[`chevron-${eventType}`]}`}
              />
            )}
          </li>
        ))}
      </ol>
    )}
  </>
)
