// SVG ICON : CalendarIco
import React from 'react'

type Props = {
  color?: string
  className?: string
}

class AttendArrow extends React.Component<Props> {
  render() {
    const { color, className } = this.props

    return (
      <svg
        width='119'
        height='85'
        viewBox='0 0 119 85'
        fill='none'
        xmlns='http://www.w3.org/2000/svg'
        className={className}
      >
        <path
          d='M117.428 1.99988C117.428 1.99988 93.2072 61.9806 4.02613 77.5607M23.5 83.5C9.5 83.5 2.29336 78.571 2.29336 78.571C14 70.5 18 64 18 64'
          stroke={color || '#F7F410'}
          strokeWidth='3'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
      </svg>
    )
  }
}

export default AttendArrow
