import * as React from 'react'
import { CSSTransition } from 'react-transition-group'
import { cross, IconButton, Overlay } from '..'
import './DropdownMenu.scss'

type Props = {
    open: boolean
    title: string | JSX.Element
    className?: string
    requestClose?: () => void
    unmountOnExit?: boolean
    isMobile?: boolean
    hideCloseButton?: boolean
}

class DropdownMenu extends React.Component<React.PropsWithChildren<Props>> {
    static defaultProps = {
        open: false,
        className: '',
        unmountOnExit: true,
        hideCloseButton: false,
    }

    render() {
        const { open, title, className, requestClose, unmountOnExit, isMobile, children, hideCloseButton } = this.props

        return (
            <>
                <CSSTransition
                    in={open}
                    timeout={300}
                    classNames='dropdown-menu-'
                    unmountOnExit={unmountOnExit}
                >
                    <div className={`dropdown-menu ${className}`}>
                        <div className='dropdown-menu__header'>
                            {title}
                            {hideCloseButton || (
                                <IconButton
                                    className='dropdown-menu__close-button'
                                    icon={cross}
                                    onClick={requestClose}
                                />
                            )}
                        </div>
                        <div className='dropdown-menu__body'>
                            {children}
                        </div>
                    </div>
                </CSSTransition>
                {!isMobile && (
                    <Overlay onClick={requestClose} show={open} />
                )}
            </>
        )
    }
}

export { DropdownMenu }
