import React from 'react'
import EventType from '../../../models/eventType'
import './SecondNavIcon.scss'

type Props = {
    eventType?: EventType | null
}

class SecondNavIcon extends React.Component<Props> {
    render() {

        const { eventType } = this.props

        return (
            <svg
                width={26}
                height={26}
                viewBox='0 0 26 26'
                fill='none'
                xmlns='http://www.w3.org/2000/svg'
                className={`second-nav__item-icon second-nav__item-fill--${eventType}`}
            >
                <path
                    fillRule='evenodd'
                    clipRule='evenodd'
                    d={
                        `M25.4907 11.8686C26.1156 12.4935 26.1156 13.5065 25.4907 14.1314L14.2907
                         25.3314C13.6659 25.9562 12.6528 25.9562 12.028 25.3314L0.828004 14.1314C0.515535
                          13.8189 0.359325 13.4094 0.359375 12.9998V5.00001C0.359375 2.34905 2.50841 0.200012
                           5.15938 0.200012H13.1598C13.5691 0.200124 13.9784 0.356333 14.2907 0.668641L25.4907
                            11.8686ZM5.15938 6.60001C6.04303 6.60001 6.75938 5.88367 6.75938 5.00001C6.75938
                             4.11636 6.04303 3.40001 5.15938 3.40001C4.27572 3.40001 3.55938 4.11636 3.55938
                              5.00001C3.55938 5.88367 4.27572 6.60001 5.15938 6.60001Z`
                    }
                />
            </svg>
        )
    }
}

export default SecondNavIcon
