import * as React from 'react'
import { Grid } from '../../components/grid'

class Typography extends React.Component {
    render() {
        return (
            <Grid className='styleguide-typography'>
                <div className='row'>
                    <div className='col-xs-12'>
                        <h1 className='h1'>Heading 1</h1>
                        <p>Maven Pro, 700, 1.5/2</p>

                        <h2 className='h2'>Heading 2</h2>
                        <p>Maven Pro, 700, 1.25/1.75</p>

                        <h3 className='h3'>Heading 3</h3>
                        <p>Maven Pro, 700, 1/1.5</p>

                        <h4 className='h4'>Heading 4</h4>
                        <p>Catamaran, 400, 1/1.5</p>

                        <h5 className='h5'>Heading 5</h5>
                        <p>Catamaran, 400, 1/1.5</p>

                        <h6 className='h6'>Heading 6</h6>
                        <p>Catamaran, 400, 1/1.5</p>

                        <p className='p'>Paragraph text</p>
                        <p>Catamaran, 400, 1/1.5</p>
                    </div>
                </div>
            </Grid>
        )
    }
}

export default Typography
