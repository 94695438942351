import { OutgoingType } from './types'

const getOutgoingUrl = (type: OutgoingType, slug: string) => `/outgoing/${type}/${slug}`

const getBannerUrl = (slug: string) => getOutgoingUrl('banner', slug)
const getDealUrl = (slug: string) => getOutgoingUrl('deal', slug)

export {
    getBannerUrl,
    getDealUrl,
}
