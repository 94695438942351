const validationHeader = 'form.validation'

const composeValidators = (...validators: ((value: string) => string | undefined)[]) => (
    value: string) =>
    validators.reduce((error, validator) => error || validator(value), undefined as string | undefined)

const required = (name: string) => (value: any) => (value ? undefined : `${validationHeader}.required.${name}`)
const email = (name: string) => (value: string) =>
    /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(value) ? undefined : `${validationHeader}.email.${name}`
const password = (name: string) => (value: string) =>
    // eslint-disable-next-line no-useless-escape
    /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])(?=.{6,})/.test(value) ? undefined :
        `${validationHeader}.password.${name}`
const minLength = (name: string, minLength: number) => (value: string) =>
    value.length >= minLength ? undefined : `${validationHeader}.min-length-${minLength}.${name}`
const noNumbers = (name: string) => (value: string) => /\d/.test(value) ?
    `${validationHeader}.no-numbers.${name}` : undefined
const textField = (name: string) => (value: string) => !/^[\w'\-,.][^0-9_!¡?÷?¿/\\+=@#$%ˆ&*(){}|~<>;:[\]]*$/.test(value)
    ? `${validationHeader}.text-field.${name}` : undefined
// eslint-disable-next-line no-useless-escape
const addressField = (name: string) => (value: string) => (value && !/^[a-zA-Z\s\d\/,'-]*$/.test(value) ?
    `${validationHeader}.address-field.${name}` : undefined)
const postcode = (name: string) => (value: string) => /^\d{4}$/.test(value) ?
    undefined : `${validationHeader}.postcode.${name}`
const code = (name: string) => (value: string) => /^\d{6}$/.test(value) ?
    undefined : `${validationHeader}.code.${name}`
const mobileNumber = (name: string) => (value: string) => {
    if (value === undefined || value === '') {
        return
    }

    if (process.env.REACT_APP_REGION === 'NZ') {
        return /^02\d{7,9}$/.test(value) ? undefined : `${validationHeader}.mobile.${name}`
    }

    return /^04\d{8}$/.test(value) ? undefined : `${validationHeader}.mobile.${name}`
}

export {
    composeValidators,
    required,
    email,
    password,
    minLength,
    noNumbers,
    textField,
    addressField,
    postcode,
    mobileNumber,
    code
}
