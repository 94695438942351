import { ApplicationState } from '../../reducers'
import { connect } from 'react-redux'
import CategoryDealListFooter from './CategoryDealListFooter'
import { loadMoreCategoryDeals } from '../category/actions'
import { brandsQueryToArray, getCategoryDealsPageSize } from '../category/utilities'
import { categoryDealsSelector, filterCategoryDealsSelector } from '../../data/selectors'
import DealPlacement from '../../models/dealPlacement'

type OwnProps = {
    slugs: string[]
}

type FieldProps = {
    currentDeals: number
    totalDeals: number
}

type MethodProps = {
    loadNextPage: () => void
}

const mapStateToProps = (state: ApplicationState, ownProps: OwnProps): FieldProps => {
    const pageSize = getCategoryDealsPageSize()
    const selectedBrands = brandsQueryToArray(state.router.location.search)
    const currentPage = state.category.silverDealsCurrentPage
    let silverDeals: DealPlacement[] = []
    let selfServeDeals: DealPlacement[] = []

    ownProps.slugs.map((slug) => {
        silverDeals = silverDeals.concat(
            ...categoryDealsSelector(state, { slug }).silverDeals
        )
        selfServeDeals = selfServeDeals.concat(
            ...categoryDealsSelector(state, { slug }).selfServeDeals
        )
    })

    const filteredSilverDeals = filterCategoryDealsSelector(state, silverDeals, '', selectedBrands, 'silver', true)
    const filteredSelfServeDeals = filterCategoryDealsSelector(
        state, selfServeDeals, '', selectedBrands, 'selfServe', true)

    return {
        currentDeals: currentPage * pageSize,
        totalDeals: filteredSilverDeals.length + filteredSelfServeDeals.length,
    }
}

const actions: MethodProps = {
    loadNextPage: loadMoreCategoryDeals,
}

export default connect(mapStateToProps, actions)(CategoryDealListFooter)
