import * as React from 'react'
import { Field, FieldRenderProps } from 'react-final-form'
import { InputField } from './InputField'
import { required, composeValidators, password, minLength } from '../validations'
import bind from 'bind-decorator'

type Props = {
    name: string
    placeholder: string
    tooltip?: string
    validateFormat?: boolean
}

type State = {
    maskPassword: boolean
}

class PasswordField extends React.Component<Props, State> {
    readonly state: State = { maskPassword: true }
    @bind
    renderField(props: FieldRenderProps<string, HTMLElement>) {
        const { maskPassword } = this.state

        return (
            <InputField
                {...props}
                // tooltip={tooltip}
                maskField={maskPassword}
                handleMaskField={this.handleMaskField}
            />
        )
    }

    @bind
    handleMaskField(e: React.MouseEvent) {
        e.preventDefault()
        this.setState({ maskPassword: !this.state.maskPassword })
    }

    render() {
        const { name, placeholder, validateFormat = false } = this.props
        const { maskPassword } = this.state

        let validations = required(name)

        if (validateFormat) {
            validations = composeValidators(
                required(name),
                password(name),
                minLength(name, 6)
            )
        }

        return (
            <Field<string>
                name={name}
                type={maskPassword ? 'password' : 'text'}
                placeholder={placeholder}
                validate={validations}
            >
                {this.renderField}
            </Field>
        )
    }
}

export { PasswordField }
