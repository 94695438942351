import * as React from 'react'
import { default as BannerModel } from '../../models/banner'
import './Banner.scss'
import { getBannerUrl } from '../../features/interstitial'
import { Link } from '../link'
import classnames from 'classnames'

export type FieldProps = {
  banner: BannerModel
  className?: string
  userId?: string | undefined
}

type Props = FieldProps

class Banner extends React.Component<Props> {
  render() {
    const { banner, userId } = this.props
    const url =
      banner.slug === undefined
        ? ''
        : process.env.REACT_APP_INTERSTITIAL_PAGE === 'true'
        ? getBannerUrl(banner.slug)
        : banner.target !== undefined
        ? 'https://' + banner.target + (userId ? '/?userId=' + userId : '')
        : getBannerUrl(banner.slug)

    let bannerTypeClass
    switch (banner.type) {
      case 'CATEGORY_LEADERBOARD':
      case 'SUB_CATEGORY_LEADERBOARD':
      case 'HOME_HEADER':
        bannerTypeClass = 'banner-home-header'
        break
      case 'HOME_CAROUSEL':
        bannerTypeClass = 'banner-home-carousel banner-carousel'
        break
      case 'HOME_MIDWAY_AD':
        bannerTypeClass = 'banner-home-midway-ad'
        break
      case 'CATEGORY_HEADER':
        bannerTypeClass = 'banner-category-header banner-carousel'
        break
      default:
        break
    }
    const classes = classnames('banner', bannerTypeClass)
    return (
      <Link
        className={classes}
        to={url}
        target='_blank'
        data-banner-id={banner.id}
      >
        <picture>
          <source
            srcSet={`
                            ${banner.desktopImage}-/resize/1100x/-/quality/lighter/,
                            ${banner.desktopImage}-/resize/2200x/-/quality/lightest/ 2x
                        `}
            media='(min-width: 960px)'
          />
          <source
            srcSet={`
                            ${banner.tabletImage}-/resize/728x/,
                            ${banner.tabletImage}-/resize/1456x/-/quality/lighter/ 2x,
                        `}
            media='(min-width: 415px)'
          />
          <img
            srcSet={`
                            ${banner.mobileImage}-/resize/414x/,
                            ${banner.mobileImage}-/resize/828x/ 2x
                        `}
            alt={banner.alt}
            className='banner__image'
          />
        </picture>
      </Link>
    )
  }
}

export default Banner
