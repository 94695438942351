import * as React from 'react'
import { Auth } from '@aws-amplify/auth'
import bind from 'bind-decorator'
import classNames from 'classnames'
import { Button, DictionaryValue, Grid } from '../../components'
import { history } from '../../history'
import { UserActionInput } from './actions'
import './ProfilePage.scss'
import { ProfilePageEdit } from './ProfilePageEdit'
import { ProfilePageRow } from './ProfilePageRow'
import { UserInfo } from './types'

export type FieldProps = {
  userInfo: UserInfo
  showFwB: boolean
}

type MethodProps = {
  refresh: (payload: UserActionInput) => void
}

type Props = FieldProps & MethodProps

type State = {
  error?: Error
  edit: boolean
}

class ProfilePage extends React.Component<Props> {
  readonly state: State = {
    edit: false,
  }

  @bind
  async handleSignout() {
    try {
      this.setState({ error: undefined })
      await Auth.signOut({ global: true })
      history.push('/')
      window.location.reload()
    } catch (e: any) {
      this.setState({ error: e.message })
    }
  }

  @bind
  handleEdit() {
    this.setState({ edit: !this.state.edit })
  }

  @bind
  async onFinish(payload: UserActionInput) {
    this.props.refresh(payload)
    this.handleEdit()
  }

  render() {
    const {
      userInfo: { username, firstName, lastName, gender, email, mobile, address, marketingOptIn },
    } = this.props

    const { error, edit } = this.state

    const buttonClassnames = classNames('col-xs-12 col-sm-12 col-md-6')

    return (
      <Grid>
        {edit ? (
          <>
            <ProfilePageEdit
              username={username}
              firstName={firstName}
              lastName={lastName}
              gender={gender}
              email={email}
              mobile={mobile || ''}
              address={address}
              marketing={marketingOptIn}
              handler={this.handleEdit}
              onFinish={this.onFinish}
            />
          </>
        ) : (
          <>
            <div className='row'>
              <div className='col-xs-12'>
                <h1 className='profile-page__title'>
                  <DictionaryValue token='profile.title' />
                </h1>
              </div>
            </div>
            <div className='row'>
              <div className='col-xs-12 col-md-6'>
                <div className='profile-page__section'>
                  <ProfilePageRow
                    label='profile.first-name'
                    value={firstName}
                  />
                  <ProfilePageRow
                    label='profile.last-name'
                    value={lastName}
                  />
                  <ProfilePageRow
                    label='profile.gender'
                    value={gender}
                  />
                  <ProfilePageRow
                    label='profile.email'
                    value={email}
                  />
                </div>
              </div>
              <div className='col-xs-12 col-md-6'>
                <div className='profile-page__section profile-page__section--secondary'>
                  <ProfilePageRow
                    label='profile.password'
                    value='**************'
                  />
                  <ProfilePageRow
                    label='profile.mobile'
                    value={mobile || ''}
                  />
                  <ProfilePageRow
                    label='profile.address'
                    value={address || ''}
                    address={true}
                  />
                </div>
              </div>
              <div className='col-xs-12'>
                <ProfilePageRow
                  label='Email Opt-in'
                  value={marketingOptIn ? 'Yes' : 'No'}
                />
              </div>
            </div>
            <div className='row'>
              <div className={buttonClassnames}>
                <>
                  <Button
                    buttonType='primary'
                    buttonStyle='square'
                    size='large'
                    type='button'
                    callback={this.handleEdit}
                    className='profile-page__button profile-page__button--edit'
                  >
                    Edit
                  </Button>
                  {error}
                </>
              </div>
              {/* <div className={buttonClassnames}>
                <>
                  <Button
                    buttonType='primary'
                    buttonStyle='square'
                    size='large'
                    type='button'
                    redirectUrl='/presale'
                    className='profile-page__button profile-page__button--presale'
                  >
                    Click Frenzy Pre Sale
                  </Button>
                  {error}
                </>
              </div>
              {process.env.REACT_APP_REGION === 'AU' && (
                <div className={buttonClassnames}>
                  <>
                    <Button
                      buttonType='primary'
                      buttonStyle='square'
                      size='large'
                      type='button'
                      redirectUrl='/account/upload'
                      className='profile-page__button profile-page__button--upload'
                    >
                      <DictionaryValue token='profile.upload' />
                    </Button>
                    {error}
                  </>
                </div>
              )} */}
              <div className={buttonClassnames}>
                <>
                  <Button
                    buttonType='primary'
                    buttonStyle='square'
                    size='large'
                    type='button'
                    callback={this.handleSignout}
                    className='profile-page__button profile-page__button--signout'
                  >
                    Logout
                  </Button>
                  {error}
                </>
              </div>
            </div>
          </>
        )}
      </Grid>
    )
  }
}

export { ProfilePage }
