import * as React from 'react'
import { Grid, DictionaryValue } from '../../components'
import './InterstitialPage.scss'
import EventType from '../../models/eventType'
import Partner from '../../models/partner'
import { Col, Row } from 'antd'
import partnerLogo from '../../static/images/Laybuy_Logo.png'

type Props = {
    eventLogo: string
    eventName: string
    eventType: EventType | null
    brandLogo: string
    brandName: string
    countdownRemaining: number
    target: string
    partners: Partner[]
    redirectionNotice: string
    interstitialPageBanner?: string
}

class InterstitialPage extends React.Component<Props> {
    render() {
        const {
            eventLogo,
            eventName,
            eventType,
            brandLogo,
            brandName,
            countdownRemaining,
            target,
            partners,
            redirectionNotice,
            interstitialPageBanner,
        } = this.props

        return (
            <div
                className='interstitial-page'
                data-blink-src={interstitialPageBanner}
                data-blink-ops='resize: x2000; quality: better'
            >
                <div className='interstitial-page__wrapper'>
                    <Grid className='interstitial-page__grid'>
                        <div
                            className={`interstitial-page__logo-wrapper${eventType && ` interstitial-page__logo-wrapper--${eventType}`
                                }`}
                        >
                            <img
                                src='data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAIAAACQd1PeAAAABnRSTlMA/
                                wD/AP83WBt9AAAADElEQVQI12P4//8/AAX+Av7czFnnAAAAAElFTkSuQmCC'
                                data-blink-src={eventLogo}
                                alt={eventName}
                                className='interstitial-page__event-logo'
                            />
                        </div>
                        <img
                            src='data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAIAAACQd1PeAAAABnRSTlMA/
                            wD/AP83WBt9AAAADElEQVQI12P4//8/AAX+Av7czFnnAAAAAElFTkSuQmCC'
                            data-blink-src={brandLogo}
                            alt={brandName}
                            className='interstitial-page__brand-logo'
                        />
                        <p className='interstitial-page__redirection-notice'>{redirectionNotice}</p>
                        <p className='interstitial-page__countdown'>{countdownRemaining}</p>
                        <p className='interstitial-page__failure-text'>
                            <DictionaryValue token='interstitial.didnt-work' />
                            <a href={target} className='interstitial-page__retry-link'>
                                <DictionaryValue token='interstitial.click-here' />
                            </a>
                        </p>
                        <div className='interstitial-page__partners'>
                            {process.env.REACT_APP_REGION === 'NZ' &&
                                <img
                                    data-blink-src={partnerLogo}
                                    alt='Laybuy Logo'
                                    className='interstitial-page__laybuy-logo'
                                />
                            }
                            {(partners.length > 0 && process.env.REACT_APP_REGION !== 'NZ') && (
                                <Row gutter={8}>
                                    {partners.map(partner => (
                                        <Col
                                            className='interstitial-page__partner-col'
                                            lg={6}
                                            xs={12}
                                            key={partner.slug}
                                        >
                                            <img
                                                src='data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAIAAACQd1PeAAAABnRSTlMA/
                                                wD/AP83WBt9AAAADElEQVQI12P4//8/AAX+Av7czFnnAAAAAElFTkSuQmCC'
                                                data-blink-src={partner.banner}
                                                alt={partner.name}
                                                className='interstitial-page__partner'
                                            />
                                        </Col>
                                    ))}
                                </Row>
                            )}
                        </div>
                    </Grid>
                </div>
            </div>
        )
    }
}

export default InterstitialPage
