import * as React from 'react'
import Brand from '../../models/brand'
import { Grid } from '../../components/grid'
import Item from './item'
import './group.scss'

type Props = {
    name: string
    brands: Brand[]
}

class Group extends React.Component<Props> {
    render() {
        const { name, brands } = this.props
        return (
            <Grid className='brand-group' id={`brand-group--${name}`}>
                <div className='row brand-group__row'>
                    <div className='col-xs-12 brand-group__cell'>
                        <h2 className='brand-group__name'>{name}</h2>
                    </div>
                </div>
                <div className='row brand-group__row'>
                    {brands.map(brand => (
                        <div key={brand.slug} className='col-xs-12 col-md-6 col-lg-4 col-xl-3 brand-group__cell'>
                            <Item brand={brand} />
                        </div>
                    ))}
                </div>
            </Grid>
        )
    }
}

export default Group
