import { ApplicationState } from '../../reducers'
import { connect } from 'react-redux'
import BrandDetailAdditional, { Props } from './BrandDetailAdditional'
import { brandDetailedSelector, currentEventSelector } from '../../data/selectors'

type OwnProps = {
  slug: string
}

const mapStateToProps = (state: ApplicationState, ownProps: OwnProps): Props => {
  const brand = brandDetailedSelector(state, ownProps.slug)
  let payments: any = undefined
  let deliveries: any = undefined
  let rewardsCashbacks: any = undefined

  const event = currentEventSelector(state)

  if (brand !== undefined && brand.payments !== undefined) {
    const brandPaymentSlugs = brand.payments
    payments = state.data.payments.filter(item => {
      return brandPaymentSlugs.includes(item.slug)
    })
  }

  if (brand !== undefined && brand.deliveries !== undefined) {
    const brandDeliverySlugs = brand.deliveries
    deliveries = state.data.deliveries.filter(item => {
      return brandDeliverySlugs.includes(item.slug)
    })
  }

  if (brand !== undefined && brand.rewardsCashbacks !== undefined) {
    const brandRewardscashbackSlugs = brand.rewardsCashbacks
    rewardsCashbacks = state.data.rewardscashbacks.filter(item => {
      return brandRewardscashbackSlugs.includes(item.slug)
    })
  }

  return {
    brand,
    payments,
    deliveries,
    rewardsCashbacks,
    eventType: event === undefined || event.type === undefined ? null : event.type,
  }
}

export default connect(mapStateToProps)(BrandDetailAdditional)
