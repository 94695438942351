import React from 'react'
import { LogoWall, LogoProps } from '../../components'
import { ApplicationState } from '../../reducers'
import { connect } from 'react-redux'
import { categorySelector, brandsFromSlugsSelector, } from '../../data/selectors'
import { mapBrandToLogoProps, removeDuplicateBrands, sortBrandsAlphabetically } from '../brand/utilities'
import './CategoryBrands.scss'

type Props = {
    brands: LogoProps[]
}

class CategoryBrandsComponent extends React.Component<Props> {
    render() {
        const { brands } = this.props

        return <LogoWall className='category-brands' logos={brands} />
    }
}

type OwnProps = {
    slug: string
}

const mapStateToProps = (state: ApplicationState, ownProps: OwnProps) => {
    const category = categorySelector(state, ownProps.slug)

    if (!category || !category.brands || category.brands.length < 1) {
        return {
            brands: [],
        }
    }

    const brands = brandsFromSlugsSelector(state, category.brands)
        .reduce(removeDuplicateBrands, [])
        .sort(sortBrandsAlphabetically)
        .map(mapBrandToLogoProps)

    return {
        brands,
    }
}

const CategoryBrands = connect(mapStateToProps)(CategoryBrandsComponent)

export { CategoryBrands, CategoryBrandsComponent }
