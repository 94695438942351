import * as React from 'react'
import classnames from 'classnames'
import './ImageAndTitle.scss'

type Props = {
    imageURL: string
    title: string
    className?: string
    padTitleOnMobile?: boolean
}

class ImageAndTitle extends React.Component<Props> {
    render() {
        const { imageURL, title, className, padTitleOnMobile } = this.props
        const additionalClasses = classnames(className, {
            'image-and-title--pad-title-on-mobile': padTitleOnMobile,
        })

        return (
            <div className={`image-and-title ${additionalClasses}`}>
                <div className='image-and-title__image-wrapper'>
                    <img
                        className='image-and-title__image'
                        alt={title}
                        src='data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAIAAACQd1PeAAAABnRSTlMA/
                        wD/AP83WBt9AAAADElEQVQI12P4//8/AAX+Av7czFnnAAAAAElFTkSuQmCC'
                        data-blink-src={imageURL}
                    />
                </div>
                <h1 className='image-and-title__title'>{title}</h1>
            </div>
        )
    }
}

export default ImageAndTitle
