import React from 'react'
import { DictionaryValue, SocialButton } from '../../components'
import { Auth } from 'aws-amplify'
import './FederatedLogin.scss'

class FederatedLogin extends React.Component {
    handleSocialClick(provider: string) {
        return async () => {
            await Auth.federatedSignIn({
                customProvider: provider,
            })
        }
    }

    render() {
        return (
            <div className='federated-login '>
                <SocialButton brand='Facebook' onClick={this.handleSocialClick('Facebook')}>
                    <DictionaryValue token='login.social.facebook' />
                </SocialButton>
                <SocialButton brand='Google' onClick={this.handleSocialClick('Google')}>
                    <DictionaryValue token='login.social.google' />
                </SocialButton>
                <div className='federated-login__or'>
                    <DictionaryValue token='login.social.or' />
                </div>
            </div>
        )
    }
}

export { FederatedLogin }
