import React from 'react'
import { Modal } from 'antd'

type HandleErrorOptions = {
    showModal?: boolean
    logToConsole?: boolean
    title?: string
    content?: string
}

const defaultOptions = {
    showModal: true,
    logToConsole: true,
    title: 'Error',
    content: (
        <>
            Please contact a Click Frenzy team member at{' '}
            <a target='_blank' href='mailto:production@clickfrenzy.com.au' rel='noreferrer'>
                production@clickfrenzy.com.au
            </a>{' '}
            for further assistance.
        </>
    ),
}

function handleError(exception: any, options?: HandleErrorOptions) {
    const { showModal, logToConsole, title, content } = {
        ...defaultOptions,
        ...options,
    }

    if (showModal) {
        Modal.error({
            title,
            content: <ErrorMessage content={content} exception={exception} />,
        })
    }

    if (logToConsole) {
        console.log(exception)
    }
}

type ErrorMessageProps = {
    content: string | JSX.Element
    exception?: any
}

const ErrorMessage: React.FC<ErrorMessageProps> = ({ content, exception }) => (
    <span className='cf-error-message'>
        {content}
        {exception && (
            <>
                <br />
                <br />
                <code>{JSON.stringify(exception)}</code>
            </>
        )}
    </span>
)

function getErrorMessage(error: any) {
    if (error.message) {
        return error.message
    }

    return JSON.stringify(error)
}

export { handleError, getErrorMessage }
