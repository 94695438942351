import { connect } from 'react-redux'
import { partnersForCurrentEventSelector, partnersForNonEventSelector } from '../../data/selectors'
import { isUserOnSignupPage } from '../../features/account/utilities'
import { FooterVariation } from '../../features/global/footer/Footer'
import Partner from '../../models/partner'
import { ApplicationState } from '../../reducers'
import { DictionaryValue } from '../dictionary-value'
import { Grid } from '../grid'
import './Partners.scss'

type PartnersVariation = FooterVariation | 'non-event'

type OwnProps = {
  variation: PartnersVariation
}

type Props = OwnProps & {
  partners?: Partner[]
  isEventRunning?: boolean
  isSignupPage?: boolean
}

const Partners = ({
  partners = [],
  variation,
  // isEventRunning = false,
  isSignupPage,
}: Props) => {
  if (partners.length === 0) {
    return null
  }

  // const carouselItemsPerRow = Math.min(partners.length, 3)

  const { sponsorBannerUrl, sponsorBannerDesktop, sponsorBannerMobile, name } = partners[0]

  if (!sponsorBannerDesktop || !sponsorBannerMobile || !sponsorBannerUrl) {
    return null
  }

  return (
    <>
      {!isSignupPage && (
        <Grid className='partners'>
          <div className='row'>
            <div className='col-xs-12 col-md-12'>
              <div className={`partners partners--variation-${variation}`}>
                <h2 className='partners__heading'>
                  <DictionaryValue token='partners.title' />
                </h2>
              </div>
            </div>
            <div className='partners--sponsor-banner'>
              <a
                href={sponsorBannerUrl}
                target='_blank'
                rel='noreferrer'
              >
                <picture>
                  <source
                    media='(min-width: 768px)'
                    srcSet={sponsorBannerDesktop}
                  />
                  <img
                    src='data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAIAAACQd1PeAAAABnRSTlMA/wD/AP83WBt9AAAADElEQVQI12P4//8/AAX+Av7czFnnAAAAAElFTkSuQmCC'
                    data-blink-src={sponsorBannerMobile}
                    alt={name || ''}
                    className='partners--sponsor-banner-image'
                  />
                </picture>
              </a>
            </div>
          </div>
        </Grid>
      )}
    </>
  )
}

const mapStateToProps = (state: ApplicationState, ownProps: OwnProps): Props => {
  const isEventRunning = state.status.isEventRunning
  const isSignupPage = isUserOnSignupPage()
  const partners = isEventRunning ? partnersForCurrentEventSelector(state) : partnersForNonEventSelector(state)

  return {
    isEventRunning,
    isSignupPage,
    partners,
    variation: ownProps.variation,
  }
}

export default connect(mapStateToProps, null)(Partners)
