import React, { Component, ReactNode } from 'react'
import classNames from 'classnames'
import './Block.scss'

type Props = {
    top?: boolean
    left?: boolean,
    bottom?: boolean,
    right?: boolean,
    size?: '2xs' | 'xs' | 'default' | 'md' | 'lg' | 'xl' | '2xl' | '3xl' | '4xl' | '5xl' | '6xl' | '7xl' | '8xl'
    className?: string
    children: ReactNode | ReactNode[]
}

class Block extends Component<Props> {
    static defaultProps = {
        size: 'lg',
        top: true,
        bottom: true,
        left: false,
        right: false,
    }

    render() {
        const { children, size, top, bottom, left, right, className } = this.props
        const classes = classNames(
            'cfa-block',
            className,
            { [`cfa-block--${size}-top`]: top },
            { [`cfa-block--${size}-bottom`]: bottom },
            { [`cfa-block--${size}-left`]: left },
            { [`cfa-block--${size}-right`]: right }
        )

        return (<div className={classes}>{children}</div>)
    }
}

export default Block
