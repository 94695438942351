import * as React from 'react'
import { Layout } from '../layout'
import { ApplicationState } from '../../reducers'
import { connect } from 'react-redux'
import { FavouritePage } from './FavouritePage'
import { FavouriteDeal } from '../account/types'
import DealPlacement from '../../models/dealPlacement'
import { loadDeals } from '../../data/actions'

type FieldProps = {
    favIds: string[]
    deals: DealPlacement[]
    isLoggedIn: boolean
    username?: string
}

type MethodProps = {
    loadDeals: (slugs: string[]) => void
}

type Props = FieldProps & MethodProps

class FavouritePageLoad extends React.Component<Props> {
    componentDidMount() {
        const { loadDeals, favIds } = this.props
        loadDeals(favIds)
    }

    render() {
        return (
            <Layout>
                <FavouritePage
                    deals={this.props.deals}
                />
            </Layout>
        )
    }
}

const mapStateToProps = (state: ApplicationState): FieldProps => {
    const favDeals = state.account.favDeals

    const deals: DealPlacement[] = []
    const allDeals = state.data.deals
    const favIds: string[] = []
    const sortingArr = ['gold', 'silver', 'selfServe', undefined]
    if (favDeals) {
        favDeals.sort((a, b) => {
            if (a.type === b.type) {
                return +(a.brandSlug > b.brandSlug) - +(a.brandSlug < b.brandSlug)
            }
            return sortingArr.indexOf(a.type) - sortingArr.indexOf(b.type)
        }).forEach((favDeal: FavouriteDeal) => {
            favIds.push(favDeal.dealId)
            if ((allDeals[favDeal.dealId] !== undefined) && favDeal.active) {
                deals.push({
                    slug: favDeal.dealId,
                    position: 0,
                    type: favDeal.type,
                } as DealPlacement)
            }
        })
    }

    return {
        favIds,
        deals,
        isLoggedIn: state.account.loggedIn,
        username: state.account.username,
    }
}

const actions: MethodProps = {
    loadDeals,
}

const FavouritePageWithData = connect(mapStateToProps, actions)(FavouritePageLoad)

export { FavouritePageWithData }
