/* tslint:disable */
/* eslint-disable */
// this is an auto generated file. This will be overwritten

import * as APITypes from "../API";
type GeneratedSubscription<InputType, OutputType> = string & {
  __generatedSubscriptionInput: InputType;
  __generatedSubscriptionOutput: OutputType;
};

export const onCreateUserUploadedFiles = /* GraphQL */ `subscription OnCreateUserUploadedFiles(
  $filter: ModelSubscriptionUserUploadedFilesFilterInput
  $userId: String
) {
  onCreateUserUploadedFiles(filter: $filter, userId: $userId) {
    id
    userId
    eventId
    brand
    spend
    paymentMethod
    save
    name
    url
    uploadedAt
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnCreateUserUploadedFilesSubscriptionVariables,
  APITypes.OnCreateUserUploadedFilesSubscription
>;
export const onUpdateUserUploadedFiles = /* GraphQL */ `subscription OnUpdateUserUploadedFiles(
  $filter: ModelSubscriptionUserUploadedFilesFilterInput
  $userId: String
) {
  onUpdateUserUploadedFiles(filter: $filter, userId: $userId) {
    id
    userId
    eventId
    brand
    spend
    paymentMethod
    save
    name
    url
    uploadedAt
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnUpdateUserUploadedFilesSubscriptionVariables,
  APITypes.OnUpdateUserUploadedFilesSubscription
>;
export const onDeleteUserUploadedFiles = /* GraphQL */ `subscription OnDeleteUserUploadedFiles(
  $filter: ModelSubscriptionUserUploadedFilesFilterInput
  $userId: String
) {
  onDeleteUserUploadedFiles(filter: $filter, userId: $userId) {
    id
    userId
    eventId
    brand
    spend
    paymentMethod
    save
    name
    url
    uploadedAt
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnDeleteUserUploadedFilesSubscriptionVariables,
  APITypes.OnDeleteUserUploadedFilesSubscription
>;
export const onCreateUserFrenzWithBenefits = /* GraphQL */ `subscription OnCreateUserFrenzWithBenefits(
  $filter: ModelSubscriptionUserFrenzWithBenefitsFilterInput
  $userId: String
) {
  onCreateUserFrenzWithBenefits(filter: $filter, userId: $userId) {
    id
    userId
    eventId
    brand
    spend
    paymentMethod
    save
    name
    url
    uploadedAt
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnCreateUserFrenzWithBenefitsSubscriptionVariables,
  APITypes.OnCreateUserFrenzWithBenefitsSubscription
>;
export const onUpdateUserFrenzWithBenefits = /* GraphQL */ `subscription OnUpdateUserFrenzWithBenefits(
  $filter: ModelSubscriptionUserFrenzWithBenefitsFilterInput
  $userId: String
) {
  onUpdateUserFrenzWithBenefits(filter: $filter, userId: $userId) {
    id
    userId
    eventId
    brand
    spend
    paymentMethod
    save
    name
    url
    uploadedAt
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnUpdateUserFrenzWithBenefitsSubscriptionVariables,
  APITypes.OnUpdateUserFrenzWithBenefitsSubscription
>;
export const onDeleteUserFrenzWithBenefits = /* GraphQL */ `subscription OnDeleteUserFrenzWithBenefits(
  $filter: ModelSubscriptionUserFrenzWithBenefitsFilterInput
  $userId: String
) {
  onDeleteUserFrenzWithBenefits(filter: $filter, userId: $userId) {
    id
    userId
    eventId
    brand
    spend
    paymentMethod
    save
    name
    url
    uploadedAt
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnDeleteUserFrenzWithBenefitsSubscriptionVariables,
  APITypes.OnDeleteUserFrenzWithBenefitsSubscription
>;
export const onCreateUserFavouriteDeal = /* GraphQL */ `subscription OnCreateUserFavouriteDeal(
  $filter: ModelSubscriptionUserFavouriteDealFilterInput
  $userId: String
) {
  onCreateUserFavouriteDeal(filter: $filter, userId: $userId) {
    id
    type
    userId
    dealId
    brandSlug
    active
    addedAt
    deactivatedAt
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnCreateUserFavouriteDealSubscriptionVariables,
  APITypes.OnCreateUserFavouriteDealSubscription
>;
export const onUpdateUserFavouriteDeal = /* GraphQL */ `subscription OnUpdateUserFavouriteDeal(
  $filter: ModelSubscriptionUserFavouriteDealFilterInput
  $userId: String
) {
  onUpdateUserFavouriteDeal(filter: $filter, userId: $userId) {
    id
    type
    userId
    dealId
    brandSlug
    active
    addedAt
    deactivatedAt
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnUpdateUserFavouriteDealSubscriptionVariables,
  APITypes.OnUpdateUserFavouriteDealSubscription
>;
export const onDeleteUserFavouriteDeal = /* GraphQL */ `subscription OnDeleteUserFavouriteDeal(
  $filter: ModelSubscriptionUserFavouriteDealFilterInput
  $userId: String
) {
  onDeleteUserFavouriteDeal(filter: $filter, userId: $userId) {
    id
    type
    userId
    dealId
    brandSlug
    active
    addedAt
    deactivatedAt
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnDeleteUserFavouriteDealSubscriptionVariables,
  APITypes.OnDeleteUserFavouriteDealSubscription
>;
export const onCreateUserPopupAnswers = /* GraphQL */ `subscription OnCreateUserPopupAnswers(
  $filter: ModelSubscriptionUserPopupAnswersFilterInput
  $username: String
) {
  onCreateUserPopupAnswers(filter: $filter, username: $username) {
    id
    popupId
    username
    mobile
    email
    product
    question
    answer
    answeredAt
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnCreateUserPopupAnswersSubscriptionVariables,
  APITypes.OnCreateUserPopupAnswersSubscription
>;
export const onUpdateUserPopupAnswers = /* GraphQL */ `subscription OnUpdateUserPopupAnswers(
  $filter: ModelSubscriptionUserPopupAnswersFilterInput
  $username: String
) {
  onUpdateUserPopupAnswers(filter: $filter, username: $username) {
    id
    popupId
    username
    mobile
    email
    product
    question
    answer
    answeredAt
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnUpdateUserPopupAnswersSubscriptionVariables,
  APITypes.OnUpdateUserPopupAnswersSubscription
>;
export const onDeleteUserPopupAnswers = /* GraphQL */ `subscription OnDeleteUserPopupAnswers(
  $filter: ModelSubscriptionUserPopupAnswersFilterInput
  $username: String
) {
  onDeleteUserPopupAnswers(filter: $filter, username: $username) {
    id
    popupId
    username
    mobile
    email
    product
    question
    answer
    answeredAt
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnDeleteUserPopupAnswersSubscriptionVariables,
  APITypes.OnDeleteUserPopupAnswersSubscription
>;
export const onCreatePopup = /* GraphQL */ `subscription OnCreatePopup($filter: ModelSubscriptionPopupFilterInput) {
  onCreatePopup(filter: $filter) {
    id
    eventId
    title
    product
    price
    rrp
    question
    img
    start
    end
    finish
    usergroups
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnCreatePopupSubscriptionVariables,
  APITypes.OnCreatePopupSubscription
>;
export const onUpdatePopup = /* GraphQL */ `subscription OnUpdatePopup($filter: ModelSubscriptionPopupFilterInput) {
  onUpdatePopup(filter: $filter) {
    id
    eventId
    title
    product
    price
    rrp
    question
    img
    start
    end
    finish
    usergroups
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnUpdatePopupSubscriptionVariables,
  APITypes.OnUpdatePopupSubscription
>;
export const onDeletePopup = /* GraphQL */ `subscription OnDeletePopup($filter: ModelSubscriptionPopupFilterInput) {
  onDeletePopup(filter: $filter) {
    id
    eventId
    title
    product
    price
    rrp
    question
    img
    start
    end
    finish
    usergroups
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnDeletePopupSubscriptionVariables,
  APITypes.OnDeletePopupSubscription
>;
