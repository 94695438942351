import * as React from 'react'
import { Link } from '../../components/link'
import { Icon, chevron } from '../../components/icon/Icon'

type Props = {
    url: string
    handleLinkClick: (e: React.MouseEvent) => void
}

class CategoryModalCategoryItem extends React.Component<React.PropsWithChildren<Props>> {
    render() {
        const { children, url, handleLinkClick } = this.props

        return (
            <li className='category-modal__category'>
                <Link
                    to={url}
                    className='category-modal__category-link'
                    onClick={handleLinkClick}
                >
                    <span className='category-modal__category-name'>{children}</span>
                    <Icon icon={chevron} className='category-modal__category-chevron' />
                </Link>
            </li>
        )
    }
}

export default CategoryModalCategoryItem
