import * as React from 'react'
import { Field } from 'react-final-form'
import { InputField } from './InputField'
import { required, composeValidators, textField } from '../validations'

type Props = {
    name: string
    placeholder: string
}

class NameField extends React.Component<Props> {
    render() {
        const { name, placeholder } = this.props

        return (
            <Field<string>
                name={name}
                component={InputField}
                type='text'
                placeholder={placeholder}
                validate={composeValidators(
                    required(name),
                    textField(name)
                )}
            />
        )
    }
}

export { NameField }
