import actionCreatorFactory from 'typescript-fsa'
import { ApplicationState } from '../../../reducers'
import { loadMenu, loadEventExtraData as getEventExtraData } from '../../../services/Api'
import { currentEventSelector } from '../../../data/selectors'
import { menuLoaded } from '../../../data/actions'
import Menu from '../../../models/menu'

const actionCreator = actionCreatorFactory('NAV')

const openSubmenu = actionCreator('OPEN_SUBMENU')
const closeSubmenu = actionCreator('CLOSE_SUBMENU')
const openCategoryMenu = actionCreator('OPEN_CATEGORY_MENU')
const closeCategoryMenu = actionCreator('CLOSE_CATEGORY_MENU')
const openMobileNavMenu = actionCreator('OPEN_MOBIEL_NAV_MENU')
const closeMobileNavMenu = actionCreator('CLOSE_MOBILE_NAV_MENU')

const loadMenuData = () => {
  return async (dispatch: any, getState: () => ApplicationState) => {
    let menu: Menu
    let slug = 'menu'
    const currentEvent = currentEventSelector(getState())
    if (currentEvent !== undefined) {
      await getEventExtraData(currentEvent.slug).then(async eventExtra => {
        if (eventExtra.menu !== undefined) {
          // load menu of current event
          slug = eventExtra.menu
        }
        return slug
      })
    }
    try {
      if (slug === 'menu') {
        menu = await loadMenu(slug, false)
      } else {
        menu = await loadMenu(slug, true)
      }
    } catch (e) {
      return
    }
    dispatch(menuLoaded(menu))
  }
}

export {
  openSubmenu,
  closeSubmenu,
  openCategoryMenu,
  closeCategoryMenu,
  openMobileNavMenu,
  closeMobileNavMenu,
  loadMenuData,
}
