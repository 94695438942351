import * as React from 'react'
import { Col, Row, Button } from 'antd'
import classNames from 'classnames'
import './PopupCard.scss'
import { Link } from 'react-router-dom'
import EventType from '../../models/eventType'
import { PopupCountdownTimer } from '../popups-countdown'
import bind from 'bind-decorator'
import { Form, AnyObject, Field } from 'react-final-form'
import { DictionaryValue } from '../dictionary-value'
import { MobileField, InputField, composeValidators, required } from '..'
import { EmailField } from '../form'

type Props = {
  eventType: EventType
  popupTitle: string
  productName: string
  productImage: string
  productPrice?: string
  productRRP?: string
  popupQuestion: string
  handleSubmit: (answer: string, mobile: string, email: string) => void
  answerSubmitted: boolean
  popupTime?: number
  mobile?: string
}

type State = {
  showForm: boolean
}

class PopupCardPresentation extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props)

    this.state = {
      showForm: false,
    }
  }

  @bind
  handleAnswer(e: React.MouseEvent) {
    e.preventDefault()
    this.setState({ showForm: true })
  }

  @bind
  onSubmit(values: AnyObject) {
    const { answer, mobile, email } = values
    this.props.handleSubmit(answer, mobile, email)
  }

  render() {
    const {
      eventType,
      popupTime,
      popupTitle,
      productName,
      productImage,
      productPrice,
      popupQuestion,
      answerSubmitted,
      mobile,
    } = this.props

    const { showForm } = this.state

    const classes = classNames('popup-card', `popup-card--${eventType}`)

    return (
      <div className={classes}>
        <Row
          gutter={8}
          justify='space-between'
        >
          <Col
            span={24}
            md={{ span: 12, order: 2 }}
            className='popup-card__image-col'
          >
            <div className='popup-card__image-wrapper'>
              <img
                data-blink-src={productImage}
                alt={productName}
                className='popup-card__image'
              />
            </div>
          </Col>
          <Col
            span={24}
            md={{ span: 12, order: 1 }}
          >
            <div className='popup-card__title'>
              <h1>{popupTitle}</h1>
            </div>
            <div className='popup-card__product-name'>
              <h1>{productName}</h1>
              <h1>{productPrice}</h1>
            </div>
            <div className='popup-card__question'>
              <h2>QUESTION:</h2>
              <p>{popupQuestion}</p>
            </div>
            {answerSubmitted ? (
              <div className='popup-card__confirm-message'>
                <h1>
                  <DictionaryValue token='popup.confirmtext' />
                </h1>
              </div>
            ) : (
              <>
                {popupTime && popupTime >= Math.floor(Date.now() / 1000) && (
                  <div className='popup-card__countdown'>
                    <p>Time left to answer</p>
                    <PopupCountdownTimer to={popupTime} />
                  </div>
                )}
                <div className='popup-card__button'>
                  {showForm ? (
                    <Form
                      onSubmit={this.onSubmit}
                      initialValues={{
                        mobile,
                      }}
                      render={({ submitError, handleSubmit, valid }) => (
                        <form onSubmit={handleSubmit}>
                          <div className='row'>
                            {submitError && (
                              <div className='col-xs-12'>
                                <strong>Error: </strong>
                                {submitError}
                              </div>
                            )}
                            <div className='col-xs-12 popup-card__input'>
                              <Field<string>
                                name='answer'
                                component={InputField}
                                type='text'
                                placeholder='popup.field.answer'
                                validate={composeValidators(required('answer'))}
                              />
                              <MobileField
                                name='mobile'
                                placeholder='popup.field.mobile'
                                key='mobile'
                                require={true}
                              />
                              <EmailField
                                name='email'
                                placeholder='popup.field.email'
                                key='email'
                                validateFormat={true}
                              />
                              
                              <Button
                                type='primary'
                                block={true}
                                className='popup-card__button__answer'
                                size='large'
                                htmlType='submit'
                                disabled={!valid}
                              >
                                Submit
                              </Button>
                            </div>
                          </div>
                        </form>
                      )}
                    />
                  ) : (
                    <Button
                      type='primary'
                      block={true}
                      className='popup-card__button__answer'
                      size='large'
                      onClick={this.handleAnswer}
                    >
                      Answer Now
                    </Button>
                  )}
                </div>
              </>
            )}
            <div className='popup-card__tcs'>
              <p>
                <DictionaryValue token='popup.message' />
                <br />
                See full terms and conditions <Link to='/terms-and-conditions?popup=true'>here</Link>
              </p>
            </div>
          </Col>
        </Row>
      </div>
    )
  }
}

export { PopupCardPresentation }
