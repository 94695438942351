import * as React from 'react'
import { Field } from 'react-final-form'
import { InputField } from './InputField'
import { mobileNumber, required } from '../validations'

type Props = {
    name: string
    placeholder: string
    require?: boolean
}

class MobileField extends React.Component<Props> {
    render() {
        const { name, placeholder, require } = this.props

        return (
            <Field<string>
                name={name}
                component={InputField}
                type='tel'
                placeholder={placeholder}
                validate={require ? (mobileNumber(name) && required(name)) : mobileNumber(name)}
            />
        )
    }
}

export { MobileField }
