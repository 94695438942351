import * as React from 'react'
import { Field } from 'react-final-form'
import { CheckboxField } from './CheckboxField'
import { required } from '../validations'

type Props = {
    name: string
}

const TermsNCondition = (props: Props) => {
    const { name } = props
    return (
        <Field<string>
            name={name}
            component={CheckboxField}
            validate={required(name)}
            type='checkbox'
        />
    )
}

export { TermsNCondition }
