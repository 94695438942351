import * as React from 'react'
import NewFooter from '../../../components/new-footer'
import Partners from '../../../components/partners/Partners'
import EventType from '../../../models/eventType'
import FooterLink from '../../../models/footerLink'
import SocialAccount from '../../../models/socialAccount'
import { TopBanner } from '../../../models/topBanner'

export type FooterVariation = 'default' | 'under-full-width'

export type FieldProps = {
  socialAccounts: SocialAccount[]
  links: FooterLink[]
  year: string
  variation?: FooterVariation
  eventType?: EventType
  isEventRunning: boolean
  loading: boolean
  showPartners?: boolean
  topBanner: TopBanner | null
  eventLogo: string | null
}

export type Props = FieldProps

class Footer extends React.Component<Props> {
  componentDidUpdate() {
    // oldProps: Props
    // if (oldProps.loading && !this.props.loading) {
    //     setTimeout(() => setEmarsysWebExtend(undefined, undefined, undefined, true), 500)
    // }
  }

  render() {
    const {
      socialAccounts,
      links,
      year,
      variation = 'default',
      eventType,
      showPartners,
      topBanner,
      eventLogo,
    } = this.props

    return (
      <>
        {showPartners && <Partners variation={variation} />}
        <NewFooter
          topBanner={topBanner}
          menuList={links}
          socialAccounts={socialAccounts}
          year={year}
          eventLogo={eventLogo}
          eventType={eventType}
        />
      </>
    )
  }
}

export default Footer
