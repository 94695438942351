import moment from 'moment-timezone'
import * as React from 'react'
import { DictionaryValue } from '../dictionary-value'
import { downArrow, Grid, Icon, CtaBanner } from '../../components'
import CountdownTimer from './CountdownTimer'
import './Countdown.scss'
import EventType from '../../models/eventType'
import { CalendarButton } from '../calendar-button'
import { Eventable } from '../../models/eventable'

export type Props = {
  to: number
  logo?: string
  background?: string
  type?: EventType
  eventable?: Eventable
  isLoggedIn?: boolean
}

class Countdown extends React.Component<Props> {
  render() {
    const { logo, to, background, type, isLoggedIn } = this.props
    const start = moment.unix(to)

    return (
      <div
        className={`cf-countdown-background cf-countdown-background--${type}`}
        data-blink-src={background}
        data-blink-ops='resize: x2000; quality: better'
      >
        <Grid>
          <div className='cf-countdown'>
            {logo && (
              <span className='cf-countdown__logo'>
                <img
                  src='data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAIAAACQd1PeAAAABnRSTlMA/
                                    wD/AP83WBt9AAAADElEQVQI12P4//8/AAX+Av7czFnnAAAAAElFTkSuQmCC'
                  data-blink-src={logo}
                  alt='logo'
                />
              </span>
            )}
            {!isLoggedIn && (
              <CtaBanner
                title=''
                description=''
                ctaText='become-member.btn.title'
                ctaUrl='/account?join'
                className='cta-banner--become-member'
                eventType={type}
                standardSite={true}
              />
            )}
            <p className='cf-countdown__copy'>
              <DictionaryValue token='countdown.description' />
            </p>
            {to && <CountdownTimer to={to} />}
            <p className='cf-countdown__copy'>
              <DictionaryValue token='countdown.nextevent' />
              {` ${start.format('h:mm a')}`}
              {` (${start.tz(`${Intl.DateTimeFormat().resolvedOptions().timeZone}`).zoneAbbr()}) `}
              {start.format('dddd, MMMM DD, YYYY')} <br />
              <DictionaryValue token='countdown.suffix' />
            </p>
            <CalendarButton eventType={type}>
              <DictionaryValue token='be-reminded.cta' />
            </CalendarButton>
            <div className='cf-countdown__arrow'>
              <Icon icon={downArrow} />
            </div>
          </div>
        </Grid>
      </div>
    )
  }
}

export default Countdown
