import React from 'react'
import Category from '../../models/category'
import classnames from 'classnames'
import { Link, Button } from '../../components'
import { getCategoryUrl } from './utilities'
import EventType from '../../models/eventType'
import styles from './CategoryListCategory.module.scss'

interface Props {
    category: Category
    eventType?: EventType
    selectItem: (slug: string) => void
}

const CategoryListCategory = ({ selectItem, category, eventType }: Props) => {
    const handleCategoryClick = (e: React.MouseEvent) => {
        e.preventDefault()
        selectItem(category.slug)
    }

    return (
        <li className={styles.item} key={category.slug}>
            <Link className={styles.link} to={getCategoryUrl(category.slug)} onClick={handleCategoryClick}>
                <Button
                    className={classnames(styles.button, eventType ? styles[eventType] : styles.noEvent)}
                    buttonType='primary'
                    buttonStyle='square'
                >
                    {category.name}
                </Button>
            </Link>
        </li>
    )
}

export default CategoryListCategory
