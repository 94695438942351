import * as React from 'react'
import { connect } from 'react-redux'
import InterstitialPage from './InterstitialPage'
import { withRouter, RouteComponentProps } from 'react-router'
import { OutgoingType } from './types'
import { ApplicationState } from '../../reducers'
import {
  currentEventSelector,
  dealsSelector,
  brandSelector,
  interstitialPagePartnersForCurrentEventSelector,
  bannerBySlugSelector,
  dictionaryValueSelector,
} from '../../data/selectors'
import { loadBrands } from '../brand/actions'
import Brand from '../../models/brand'
import { loadDeals, loadBanners } from '../../data/actions'
import bind from 'bind-decorator'
import { getResizedImageFromCDN, brandLogoSize } from '../../utilities'
import EventType from '../../models/eventType'
import Partner from '../../models/partner'

type PathProps = {
  type: OutgoingType
  slug: string
}

type RouterProps = RouteComponentProps<PathProps>

type FieldProps = {
  eventLogo: string
  eventName: string
  eventType: EventType | null
  brandLogo: string
  brandName: string
  dealSlug?: string
  partners: Partner[]
  target: string
  redirectionNotice: string
  loading: boolean
  email?: string
  interstitialPageBanner?: string
}

type MethodProps = {
  loadBrands: () => void
  loadDeals: (slugs: string[]) => void
  loadBanners: () => void
}

type Props = RouterProps & FieldProps & MethodProps

type State = {
  secondsRemaining: number
  interval?: number
}

class InterstitialPageWithDataLoad extends React.Component<Props, State> {
  readonly state: State = { secondsRemaining: 3 }

  componentDidMount() {
    const { loadBrands, loadDeals, loadBanners, dealSlug } = this.props

    loadBrands()
    loadBanners()

    if (dealSlug !== undefined) {
      loadDeals([dealSlug])
    }
  }

  componentDidUpdate(oldProps: Props) {
    if (this.props.target !== '' && oldProps.target === '') {
      if (this.props.target !== '') {
        this.setState({
          interval: window.setInterval(this.tick, 1000),
        })
        // Set Emarsys Web Extend View
        if (!this.props.loading) {
          // setEmarsysWebExtend(this.props.email)
          // setEmarsysWebExtend(undefined, 'view', this.props.target)
          // setTimeout(() => setEmarsysWebExtend(undefined, undefined, undefined, true), 500)
        }
      } else {
        this.setState({
          secondsRemaining: 0,
        })
      }
    }
  }

  @bind
  tick() {
    this.setState(state => {
      const secondsRemaining = Math.max(state.secondsRemaining - 1, 0)

      if (secondsRemaining <= 0) {
        window.clearInterval(state.interval)
        window.location.href = this.props.target
      }

      return {
        secondsRemaining,
      }
    })
  }

  render() {
    const { secondsRemaining } = this.state
    const {
      eventLogo,
      eventName,
      eventType,
      brandLogo,
      brandName,
      partners,
      target,
      redirectionNotice,
      interstitialPageBanner,
    } = this.props

    return (
      <InterstitialPage
        eventLogo={eventLogo}
        eventName={eventName}
        eventType={eventType}
        brandLogo={brandLogo}
        brandName={brandName}
        countdownRemaining={secondsRemaining}
        target={target}
        partners={partners}
        redirectionNotice={redirectionNotice}
        interstitialPageBanner={interstitialPageBanner}
      />
    )
  }
}

const mapStateToProps = (state: ApplicationState, routerProps: RouterProps): FieldProps => {
  const event = currentEventSelector(state)
  const params = routerProps.match.params
  const { type, slug } = params
  let brand: Brand | undefined
  let target: string | undefined

  const emptyResult = {
    eventLogo: '',
    eventName: '',
    eventType: null,
    brandLogo: '',
    brandName: '',
    partners: [],
    target: '',
    redirectionNotice: '',
  }

  if (event === undefined) {
    return {
      ...emptyResult,
      loading: state.loading.count !== 0,
      email: state.account.email,
    }
  }

  switch (type) {
    case 'deal': {
      const deal = dealsSelector(state)[slug]

      if (deal === undefined || deal.brandSlug === undefined) {
        return {
          ...emptyResult,
          loading: state.loading.count !== 0,
          email: state.account.email,
          dealSlug: slug,
        }
      }

      brand = brandSelector(state, deal.brandSlug)
      target = deal.url

      break
    }

    case 'banner': {
      const banner = bannerBySlugSelector(state, slug)

      if (banner === undefined) {
        return {
          ...emptyResult,
          loading: state.loading.count !== 0,
          email: state.account.email,
        }
      }

      target = banner.target

      if (banner.brandSlug !== undefined) {
        brand = brandSelector(state, banner.brandSlug)
      }

      break
    }
  }

  let redirectionNotice = dictionaryValueSelector('interstitial.redirection-notice', state)

  if (brand !== undefined) {
    redirectionNotice = redirectionNotice.replace('{brand}', brand.name)
  }

  const partners = interstitialPagePartnersForCurrentEventSelector(state)

  return {
    redirectionNotice,
    partners,
    eventLogo: event.logo === undefined ? '' : event.logo,
    eventName: event.name === undefined ? '' : event.name,
    eventType: event.type === undefined ? null : event.type,
    brandLogo:
      brand === undefined ? '' : getResizedImageFromCDN(brand.logo, brand.logoIsBitmap, brandLogoSize.interstitial),
    brandName: brand === undefined ? '' : brand.name,
    target:
      target === undefined
        ? ''
        : `${target}${
            state.account.loggedIn && (!brand || !brand.isAffiliateNetwork) ? `?userId=${state.account.username}` : ''
          }`,
    loading: state.loading.count !== 0,
    email: state.account.email,
    interstitialPageBanner: event.interstitialPageBanner,
  }
}

const actions: MethodProps = {
  loadBrands,
  loadDeals,
  loadBanners,
}

const InterstitialPageWithData = withRouter(connect(mapStateToProps, actions)(InterstitialPageWithDataLoad))

export default InterstitialPageWithData
