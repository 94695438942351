import { Action } from 'redux'
import { isType } from 'typescript-fsa'
import Collection from '../models/collection'
import Deal from '../models/deal'
import {
  dealsLoaded,
  collectionsLoaded,
  categoryLoaded,
  brandsLoaded,
  brandDetailsLoaded,
  paymentsLoaded,
  deliveriesLoaded,
  rewardsCashbacksLoaded,
  dictionaryLoaded,
  eventsLoaded,
  eventDetailsLoaded,
  menuLoaded,
  footerLinksLoaded,
  socialAccountsLoaded,
  bannersLoaded,
  nonEventConfigLoaded,
  pageLoaded,
  sponsoredResultsLoaded,
  cfDealsLoaded,
} from './actions'
import { GroupOf } from '../models/groupof'
import Category from '../models/category'
import Brand from '../models/brand'
import Option from '../models/option'
import Dictionary from '../models/dictionary'
import Event from '../models/event'
import Menu from '../models/menu'
import FooterLink from '../models/footerLink'
import SocialAccount from '../models/socialAccount'
import Banner from '../models/banner'
import NonEventConfig from '../models/nonEventConfig'
import ThreeColumnImageText from '../models/threeColumnImageText'
import CmsPage from '../models/cmsPage'
import { CfDeals } from '../models/cfdeal'

export type State = {
  collections: GroupOf<Collection>
  deals: GroupOf<Deal>
  categories: GroupOf<Category>
  brands: Brand[] | null
  brandsDetailed: Brand[]
  payments: Option[]
  deliveries: Option[]
  rewardscashbacks: Option[]
  dictionary: Dictionary[]
  events: Event[]
  menu: Menu
  footerLinks: FooterLink[]
  socialAccounts: SocialAccount[]
  banners: Banner[]
  nonEventConfig: NonEventConfig
  membershipBenefits: ThreeColumnImageText
  pages: GroupOf<CmsPage>
  sponsoredResults: Deal[]
  cfDeals: CfDeals
}

export const initialState: State = {
  collections: {},
  deals: {},
  categories: {},
  brands: null,
  brandsDetailed: [],
  payments: [],
  deliveries: [],
  rewardscashbacks: [],
  dictionary: [],
  events: [],
  menu: {
    name: '',
    slug: '',
    items: [],
  },
  footerLinks: [],
  socialAccounts: [],
  banners: [],
  membershipBenefits: {
    title: '',
    items: [],
  },
  nonEventConfig: {
    membershipBenefits: {
      title: '',
      items: [],
    },
  },
  pages: {},
  sponsoredResults: [],
  cfDeals: { draft: {}, public: [] },
}

export function dataReducer(state: State = initialState, action: Action): State {
  if (isType(action, dealsLoaded)) {
    return {
      ...state,
      deals: {
        ...state.deals,
        ...action.payload,
      },
    }
  }

  if (isType(action, collectionsLoaded)) {
    return {
      ...state,
      collections: {
        ...state.collections,
        ...action.payload,
      },
    }
  }

  if (isType(action, categoryLoaded)) {
    return {
      ...state,
      categories: {
        ...state.categories,
        [action.payload.slug.toLowerCase()]: action.payload,
      },
    }
  }

  if (isType(action, brandsLoaded)) {
    return {
      ...state,
      brands: action.payload,
    }
  }

  if (isType(action, brandDetailsLoaded)) {
    const newBrand = action.payload
    const updatedBrandsDetailed = state.brandsDetailed

    const brandsDetailed = updatedBrandsDetailed.some(brand => brand.slug === newBrand.slug)
      ? updatedBrandsDetailed
      : [...updatedBrandsDetailed, newBrand]

    return {
      ...state,
      brandsDetailed,
    }
  }

  if (isType(action, paymentsLoaded)) {
    return {
      ...state,
      payments: action.payload,
    }
  }

  if (isType(action, deliveriesLoaded)) {
    return {
      ...state,
      deliveries: action.payload,
    }
  }

  if (isType(action, rewardsCashbacksLoaded)) {
    return {
      ...state,
      rewardscashbacks: action.payload,
    }
  }

  if (isType(action, dictionaryLoaded)) {
    return {
      ...state,
      dictionary: action.payload,
    }
  }

  if (isType(action, eventsLoaded)) {
    return {
      ...state,
      events: action.payload,
    }
  }

  if (isType(action, eventDetailsLoaded)) {
    return {
      ...state,
      events: action.payload,
    }
  }

  if (isType(action, menuLoaded)) {
    return {
      ...state,
      menu: action.payload,
    }
  }

  if (isType(action, footerLinksLoaded)) {
    return {
      ...state,
      footerLinks: action.payload,
    }
  }

  if (isType(action, socialAccountsLoaded)) {
    return {
      ...state,
      socialAccounts: action.payload,
    }
  }

  if (isType(action, bannersLoaded)) {
    return {
      ...state,
      banners: action.payload,
    }
  }

  if (isType(action, nonEventConfigLoaded)) {
    return {
      ...state,
      nonEventConfig: action.payload,
    }
  }

  if (isType(action, pageLoaded)) {
    return {
      ...state,
      pages: {
        ...state.pages,
        [action.payload.slug]: action.payload,
      },
    }
  }

  if (isType(action, sponsoredResultsLoaded)) {
    return {
      ...state,
      sponsoredResults: [...action.payload],
    }
  }

  if (isType(action, cfDealsLoaded)) {
    return {
      ...state,
      cfDeals: action.payload,
    }
  }

  return state
}
