/* eslint-disable react/no-unknown-property */
import * as React from 'react'
import './Finalist.scss'
import { Layout } from '../../layout'
import axios from 'axios'
import { Grid } from '../../../components/grid'
import { DictionaryValue } from '../../../components/dictionary-value'
import bind from 'bind-decorator'
import shuffle from 'lodash.shuffle'

type Candidate = {
  name: string
  portrait: string
  entryvideo: string
  finalvideo: string
  state: string
  occupation: string
  aboutme: string
  haul: string
  shipping: string
  whybest: string
  votes: number
}

type Config = {
  winnershow: boolean
  haulshow: boolean
  votingshow: boolean
  votinglink: string
}

export interface State {
  top3: Candidate[]
  candidates: Candidate[]
  config: Config
  showmore: boolean[]
}

// eslint-disable-next-line @typescript-eslint/ban-types
class Finalist extends React.Component<{}, State> {
  DATA_API_URL = process.env.REACT_APP_DATA_API_URL

  // eslint-disable-next-line @typescript-eslint/ban-types
  constructor(props: Readonly<{}>) {
    super(props)
    this.state = {
      top3: [],
      candidates: [],
      config: Object(),
      showmore: Array.apply(false, Array<boolean>(21)) as boolean[],
    }
  }

  async getStandardData<T>(filename: string): Promise<T> {
    const response = await axios.get<T>(`${this.DATA_API_URL}/${filename}`)
    return response.data
  }

  @bind
  onClick(e: React.MouseEvent, index: number) {
    e.preventDefault()
    const showmore = [...this.state.showmore]
    showmore[index] = !showmore[index]
    this.setState({ showmore })
  }

  @bind
  handlePageChange(e: React.MouseEvent, name: string) {
    e.preventDefault()
    const param1 = '&CANDIDATES='
    const param2 = '&SUBSCRIBE=Yes'
    window.location.href = this.state.config.votinglink + param1 + encodeURIComponent(name) + param2
    return null
  }

  async componentDidMount() {
    await this.getStandardData<Config>('custom-page/ironshopper/config.json').then(configresponse => {
      this.setState({
        config: configresponse,
      })
    })

    this.getStandardData<Candidate[]>('custom-page/ironshopper/candidates.json').then(response => {
      response.sort((a, b) => {
        return b['votes'] - a['votes']
      })

      if (response[0] !== undefined && response[0].votes !== 0) {
        this.setState({
          top3: response.slice(0, 3),
        })

        if (this.state.config.winnershow) {
          this.setState({
            candidates: shuffle(response.slice(1, response.length)),
          })
        } else {
          this.setState({
            candidates: shuffle(response.slice(3, response.length)),
          })
        }
      } else {
        this.setState({
          candidates: shuffle(response),
        })
      }
    })
  }
  render() {
    const { top3, candidates, config, showmore } = this.state

    let j = 0

    if (top3[0] !== undefined && top3[0].votes !== 0) {
      j = 4
    } else {
      j = 1
    }

    return (
      <Layout>
        <div className='FinalistPage'>
          <header className='FinalistPage__head'>
            <div className='FinalistPage__logo'>
              <img
                data-blink-uuid='b1406a85-4bb6-4e2f-b57c-f048b30ac721'
                alt=''
              />
            </div>
            <div className='FinalistPage__title'>
              <h1>
                Click Frenzy <strong>Ironshopper finalist</strong> Page
              </h1>
            </div>
          </header>
        </div>
        <div className='MainPage'>
          <div className='container grid'>
            <div className='MainPage__wrapper'>
              {top3[0] !== undefined && top3[0].votes !== 0 ? (
                config.winnershow ? (
                  <div className='MainPage__finalist'>
                    <Grid>
                      <div className='row FinalistList FinalistList--winner'>
                        <div className='col-xs-12'>
                          <h3>
                            <DictionaryValue token={'finalist-page.description'} />
                          </h3>
                        </div>
                        <div
                          className={`col-xs-12 FinalistList__finalist finalist
                                            ${!showmore[0] ? 'col-md-6 col-md-offset-3' : ''}`}
                        >
                          <div className='finalist__wrapper'>
                            <div className='row'>
                              <div className='col-md-4 col-xs-12'>
                                <div className='finalist__img'>
                                  <img
                                    src='data:image/png;base64,
                                                                iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAIAAACQd1Pe
                                                                AAAABnRSTlMA/wD/AP83WBt9AAAADElEQVQI12P4//8/
                                                                AAX+Av7czFnnAAAAAElFTkSuQmCC'
                                    data-blink-src={top3[0].portrait}
                                    alt=''
                                  />
                                </div>
                              </div>
                              <div className='col-md-8 col-xs-12'>
                                <div className='finalist__info'>
                                  <h4>{top3[0].name}</h4>
                                  <div className='row'>
                                    <div className='col-xs-12'>
                                      <div className='finalist__meta'>
                                        <span>State</span>
                                        <strong>{top3[0].state}</strong>
                                      </div>
                                    </div>
                                    <div className='col-xs-12'>
                                      <div className='finalist__meta'>
                                        <span>Occupation</span>
                                        <strong>{top3[0].occupation}</strong>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div className='finalist__btnArea row'>
                              <div className='finalist__moreInfo col-xs-12'>
                                <a
                                  className='button button--primary'
                                  onClick={e => this.onClick(e, 0)}
                                >
                                  <DictionaryValue token={'finalist-page.moreinfo'} />
                                </a>
                              </div>
                            </div>
                            {showmore[0] ? (
                              <div className='finalist__hidden row'>
                                <div className='col-xs-12'>
                                  <p className='finalist__greyTitle'>
                                    <DictionaryValue token={'finalist-page.aboutme'} />
                                  </p>
                                  <div
                                    dangerouslySetInnerHTML={{
                                      __html: top3[0].aboutme,
                                    }}
                                  />
                                </div>
                                <div className='col-md-6 col-xs-12'>
                                  <div className='finalist__videoResponsive'>
                                    <iframe
                                      width='100%'
                                      height='auto'
                                      src={top3[0].finalvideo}
                                      frameBorder='0'
                                      allowFullScreen={true}
                                    />
                                  </div>
                                </div>
                                <div className='col-md-6 col-xs-12'>
                                  <img
                                    data-blink-uuid='38a1a797-70f4-4ae9-8415-11bcb276ff84'
                                    width='100%'
                                    height='auto'
                                    alt=''
                                  />
                                  <div className='finalist__notepad'>
                                    <p className='finalist__greyTitle'>
                                      <DictionaryValue token={'finalist-page.myhaul'} />
                                    </p>
                                    <div
                                      dangerouslySetInnerHTML={{
                                        __html: top3[0].haul,
                                      }}
                                    />
                                    <p className='finalist__greyTitle'>
                                      <DictionaryValue token={'finalist-page.shipping'} />
                                    </p>
                                    <p
                                      dangerouslySetInnerHTML={{
                                        __html: top3[0].shipping,
                                      }}
                                    />
                                    <p className='finalist__greyTitle'>
                                      <DictionaryValue token={'finalist-page.whybest'} />
                                    </p>
                                    <p
                                      dangerouslySetInnerHTML={{
                                        __html: top3[0].whybest,
                                      }}
                                    />
                                  </div>
                                  <img
                                    data-blink-uuid='9f8ac6e8-fd1f-47a6-82ac-8f8f65cc2bac'
                                    width='100%'
                                    height='auto'
                                    alt=''
                                  />
                                </div>
                                <div className='col-xs-12'>
                                  <a
                                    className='finalist__less'
                                    onClick={e => this.onClick(e, 0)}
                                  >
                                    <DictionaryValue token={'finalist-page.showless'} />
                                  </a>
                                </div>
                              </div>
                            ) : (
                              ''
                            )}
                          </div>
                        </div>
                      </div>
                    </Grid>
                  </div>
                ) : (
                  <Grid>
                    <div className='row FinalistList FinalistList--toplist'>
                      <div className='col-xs-12'>
                        <h3>
                          <DictionaryValue token={'finalist-page.top3title'} />
                        </h3>
                      </div>
                      {top3.map((top, n) => (
                        <>
                          <div
                            className={`col-xs-12 FinalistList__finalist finalist finalist--top${n + 1}
                                            ${!showmore[n + 1] ? 'col-md-6' : ''}
                                            ${n === 0 && !showmore[n + 1] ? 'col-md-offset-3' : ''}`}
                            key={n}
                          >
                            <div className='finalist__topImage'>
                              <img
                                data-blink-uuid={`${
                                  n === 0
                                    ? '7d9a6d27-81f9-4984-8999-20e6bf053e73'
                                    : n === 1
                                    ? '32820637-6af6-41ba-946a-fa927db99068'
                                    : 'ca6df83a-5324-4d15-ad02-b2bd4f61640a'
                                }
                                                    `}
                                width='100%'
                                height='auto'
                                alt=''
                              />
                            </div>
                            <div className='finalist__wrapper'>
                              <div className='row'>
                                <div className='col-md-4 col-xs-12'>
                                  <div className='finalist__img'>
                                    <img
                                      src='data:image/png;base64,
                                                                iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAIAAACQd1Pe
                                                                AAAABnRSTlMA/wD/AP83WBt9AAAADElEQVQI12P4//8/
                                                                AAX+Av7czFnnAAAAAElFTkSuQmCC'
                                      data-blink-src={top.portrait}
                                      alt=''
                                    />
                                  </div>
                                </div>
                                <div className='col-md-8 col-xs-12'>
                                  <div className='finalist__info'>
                                    <h4>{top.name}</h4>
                                    <div className='row'>
                                      <div className='col-xs-12'>
                                        <div className='finalist__meta'>
                                          <span>State</span>
                                          <strong>{top.state}</strong>
                                        </div>
                                      </div>
                                      <div className='col-xs-12'>
                                        <div className='finalist__meta'>
                                          <span>Occupation</span>
                                          <strong>{top.occupation}</strong>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <div className='finalist__btnArea row'>
                                {config.votingshow && !config.winnershow ? (
                                  <div
                                    className={`'finalist__vote col-md-6 col-xs-12' ${
                                      showmore[n + 1] ? 'finalist__btnArea--padding' : ''
                                    }`}
                                  >
                                    <a
                                      className='button button--primary'
                                      onClick={e => this.handlePageChange(e, top.name)}
                                    >
                                      <DictionaryValue token={'finalist-page.vote'} />
                                    </a>
                                  </div>
                                ) : (
                                  ''
                                )}
                                <div
                                  className={
                                    'finalist__moreInfo col-xs-12 ' +
                                    (config.votingshow && !config.winnershow ? 'col-md-6' : '')
                                  }
                                >
                                  <a
                                    className='button button--primary'
                                    onClick={e => this.onClick(e, n + 1)}
                                  >
                                    <DictionaryValue token={'finalist-page.moreinfo'} />
                                  </a>
                                </div>
                              </div>
                              {showmore[n + 1] ? (
                                <div
                                  className='finalist__hidden row'
                                  id={`show-${n + 1}`}
                                >
                                  <div className='col-xs-12'>
                                    <p className='finalist__greyTitle'>
                                      <DictionaryValue token={'finalist-page.aboutme'} />
                                    </p>
                                    <div
                                      dangerouslySetInnerHTML={{
                                        __html: top.aboutme,
                                      }}
                                    />
                                  </div>
                                  {!config.haulshow ? (
                                    <div className='col-md-6 col-xs-12'>
                                      <div className='finalist__videoResponsive'>
                                        <iframe
                                          width='100%'
                                          height='auto'
                                          src={top.entryvideo}
                                          frameBorder='0'
                                          allowFullScreen={true}
                                        />
                                      </div>
                                    </div>
                                  ) : (
                                    ''
                                  )}
                                  <div className='col-md-6 col-xs-12'>
                                    <div className='finalist__videoResponsive'>
                                      <iframe
                                        width='100%'
                                        height='auto'
                                        src={top.finalvideo}
                                        frameBorder='0'
                                        allowFullScreen={true}
                                      />
                                    </div>
                                  </div>
                                  {config.haulshow ? (
                                    <div className='col-md-6 col-xs-12'>
                                      <img
                                        data-blink-uuid='38a1a797-70f4-4ae9-8415-11bcb276ff84'
                                        width='100%'
                                        height='auto'
                                        alt=''
                                      />
                                      <div className='finalist__notepad'>
                                        <p className='finalist__greyTitle'>
                                          <DictionaryValue token={'finalist-page.myhaul'} />
                                        </p>
                                        <div
                                          dangerouslySetInnerHTML={{
                                            __html: top.haul,
                                          }}
                                        />
                                        <p className='finalist__greyTitle'>
                                          <DictionaryValue token={'finalist-page.shipping'} />
                                        </p>
                                        <p
                                          dangerouslySetInnerHTML={{
                                            __html: top.shipping,
                                          }}
                                        />
                                        <p className='finalist__greyTitle'>
                                          <DictionaryValue token={'finalist-page.whybest'} />
                                        </p>
                                        <p
                                          dangerouslySetInnerHTML={{
                                            __html: top.whybest,
                                          }}
                                        />
                                      </div>
                                      <img
                                        data-blink-uuid='9f8ac6e8-fd1f-47a6-82ac-8f8f65cc2bac'
                                        width='100%'
                                        height='auto'
                                        alt=''
                                      />
                                    </div>
                                  ) : (
                                    ''
                                  )}
                                  <div className='col-xs-12'>
                                    <a
                                      className='finalist__less'
                                      onClick={e => this.onClick(e, n + 1)}
                                    >
                                      <DictionaryValue token={'finalist-page.showless'} />
                                    </a>
                                  </div>
                                </div>
                              ) : (
                                ''
                              )}
                            </div>
                          </div>
                        </>
                      ))}
                    </div>
                  </Grid>
                )
              ) : (
                ''
              )}
              <Grid>
                <div className='row FinalistList FinalistList--candidates'>
                  {candidates.map((object, i) => (
                    <>
                      <div
                        className={`FinalistList__finalist finalist col-xs-12
                                            ${!showmore[i + j] ? 'col-md-6' : ''}`}
                        key={i}
                      >
                        <div className='finalist__wrapper'>
                          <div className='row'>
                            <div className='col-md-4 col-xs-12'>
                              <div className='finalist__img'>
                                <img
                                  src='data:image/png;base64,
                                                                iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAIAAACQd1Pe
                                                                AAAABnRSTlMA/wD/AP83WBt9AAAADElEQVQI12P4//8/
                                                                AAX+Av7czFnnAAAAAElFTkSuQmCC'
                                  data-blink-src={object.portrait}
                                  alt=''
                                />
                              </div>
                            </div>
                            <div className='col-md-8 col-xs-12'>
                              <div className='finalist__info'>
                                <h4>{object.name}</h4>
                                <div className='row'>
                                  <div className='col-xs-12'>
                                    <div className='finalist__meta'>
                                      <span>State</span>
                                      <strong>{object.state}</strong>
                                    </div>
                                  </div>
                                  <div className='col-xs-12'>
                                    <div className='finalist__meta'>
                                      <span>Occupation</span>
                                      <strong>{object.occupation}</strong>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className='finalist__btnArea row'>
                            {config.votingshow && !config.winnershow ? (
                              <div
                                className={`'finalist__vote col-md-6 col-xs-12' ${
                                  showmore[i + j] ? 'finalist__btnArea--padding' : ''
                                }`}
                              >
                                <a
                                  className='button button--primary'
                                  onClick={e => this.handlePageChange(e, object.name)}
                                >
                                  <DictionaryValue token={'finalist-page.vote'} />
                                </a>
                              </div>
                            ) : (
                              ''
                            )}
                            <div
                              className={
                                'finalist__moreInfo col-xs-12' +
                                (config.votingshow && !config.winnershow ? ' col-md-6' : '')
                              }
                            >
                              <a
                                className='button button--primary'
                                onClick={e => this.onClick(e, i + j)}
                              >
                                <DictionaryValue token={'finalist-page.moreinfo'} />
                              </a>
                            </div>
                          </div>
                          {showmore[i + j] ? (
                            <div
                              className='finalist__hidden row'
                              id={`show-${i + j}`}
                            >
                              <div className='col-xs-12'>
                                <p className='finalist__greyTitle'>
                                  <DictionaryValue token={'finalist-page.aboutme'} />
                                </p>
                                <div dangerouslySetInnerHTML={{ __html: object.aboutme }} />
                              </div>
                              {!config.haulshow ? (
                                <div className='col-md-6 col-xs-12'>
                                  <div className='finalist__videoResponsive'>
                                    <iframe
                                      width='100%'
                                      height='auto'
                                      src={object.entryvideo}
                                      frameBorder='0'
                                      allowFullScreen={true}
                                    />
                                  </div>
                                </div>
                              ) : (
                                ''
                              )}
                              <div className='col-md-6 col-xs-12'>
                                <div className='finalist__videoResponsive'>
                                  <iframe
                                    width='100%'
                                    height='auto'
                                    src={object.finalvideo}
                                    frameBorder='0'
                                    allowFullScreen={true}
                                  />
                                </div>
                              </div>
                              {config.haulshow ? (
                                <div className='col-md-6 col-xs-12'>
                                  <img
                                    data-blink-uuid='38a1a797-70f4-4ae9-8415-11bcb276ff84'
                                    width='100%'
                                    height='auto'
                                    alt=''
                                  />
                                  <div className='finalist__notepad'>
                                    <p className='finalist__greyTitle'>
                                      <DictionaryValue token={'finalist-page.myhaul'} />
                                    </p>
                                    <div
                                      dangerouslySetInnerHTML={{
                                        __html: object.haul,
                                      }}
                                    />
                                    <p className='finalist__greyTitle'>
                                      <DictionaryValue token={'finalist-page.shipping'} />
                                    </p>
                                    <p
                                      dangerouslySetInnerHTML={{
                                        __html: object.shipping,
                                      }}
                                    />
                                    <p className='finalist__greyTitle'>
                                      <DictionaryValue token={'finalist-page.whybest'} />
                                    </p>
                                    <p
                                      dangerouslySetInnerHTML={{
                                        __html: object.whybest,
                                      }}
                                    />
                                  </div>
                                  <img
                                    data-blink-uuid='9f8ac6e8-fd1f-47a6-82ac-8f8f65cc2bac'
                                    width='100%'
                                    height='auto'
                                    alt=''
                                  />
                                </div>
                              ) : (
                                ''
                              )}
                              <div className='col-xs-12'>
                                <a
                                  className='finalist__less'
                                  onClick={e => this.onClick(e, i + j)}
                                >
                                  <DictionaryValue token={'finalist-page.showless'} />
                                </a>
                              </div>
                            </div>
                          ) : (
                            ''
                          )}
                        </div>
                      </div>
                    </>
                  ))}
                </div>
              </Grid>
            </div>
          </div>
        </div>
      </Layout>
    )
  }
}

export { Finalist }
