import * as React from 'react'
import classnames from 'classnames'
import { IconType } from '../../icon'
import './style.scss'

type Props = {
  className?: string
  redirectUrl?: string
  onClick?: (e: React.MouseEvent) => void
  buttonType?: 'blue' | 'pink' | 'cfprimary' | 'cfwhiteoutline' | 'cfwhiteblue'
  buttonStyle?: 'rounded' | 'square'
  display?: 'inline' | 'block'
  emphasise?: boolean
  size?: 'default' | 'large' | 'small'
  disableBoxShadow?: boolean
  type?: 'button' | 'submit' | 'reset' | undefined // TODO: find a better way to include all anchor and button options
  disabled?: boolean
  id?: string
  icon?: IconType
  dataAttributes?: Record<string, string>
  title?: string
}

class CfButton extends React.Component<React.PropsWithChildren<Props>> {
  static defaultProps = {
    className: '',
    buttonType: 'default',
    buttonStyle: 'rounded',
    display: 'inline',
    emphasise: false,
    size: 'default',
    disableBoxShadow: false,
    type: 'button',
    disabled: false,
  }

  render() {
    const {
      children,
      buttonType,
      buttonStyle,
      className,
      redirectUrl,
      onClick,
      display,
      emphasise,
      size,
      disableBoxShadow,
      type,
      disabled,
      id,
      icon,
      dataAttributes,
      title,
    } = this.props

    const Element = redirectUrl ? 'a' : 'button'
    const actionType = redirectUrl ? 'redirect' : 'callback'
    const outputType = redirectUrl ? undefined : type
    const classes = classnames(
      'button',
      `button--type-${buttonType}`,
      `button--action-${actionType}`,
      `button--style-${buttonStyle}`,
      `button--display-${display}`,
      `button--size-${size}`,
      className,
      {
        'button--emphasise': emphasise,
        'button--no-box-shadow': disableBoxShadow || disabled,
        'button--disabled': disabled,
        'button--has-icon': icon,
      }
    )

    return (
      <Element
        {...dataAttributes}
        onClick={onClick}
        href={redirectUrl}
        className={classes}
        type={outputType}
        disabled={disabled}
        id={id}
        title={title}
      >
        {children}
      </Element>
    )
  }
}

export { CfButton }
