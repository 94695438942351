import { API } from 'aws-amplify'

const updateBrazeUser = async (
  email: string,
  firstName: string,
  lastName: string,
  mobile: string,
  gender: string,
  postCode: number,
  country: string,
  marketingOptIn: boolean
) => {
  console.log('Opt in: ', marketingOptIn)
  await API.post('clickfrenzyFeEmarsys', '/contact', {
    body: {
      email,
      gender,
      postCode,
      country,
      given_name: firstName,
      family_name: lastName,
      phone_number: mobile,
      'custom:marketing_opt_in': marketingOptIn,
    },
  })
}

export { updateBrazeUser }
