import * as React from 'react'
import Footer, { FieldProps, FooterVariation } from './Footer'
import { ApplicationState } from '../../../reducers'
import { connect } from 'react-redux'
import { loadFooterData } from './actions'
import moment from 'moment'
import { currentEventSelector } from '../../../data/selectors'
import FooterLink from '../../../models/footerLink'

type LoadMethodProps = {
    loadFooterData: () => void
}

type OwnProps = {
    variation?: FooterVariation;
    showPartners?: boolean;
    customLinks?: FooterLink[];
}

type LoadProps = FieldProps & LoadMethodProps & OwnProps

class LoadFooter extends React.Component<LoadProps> {
    componentDidMount() {
        this.props.loadFooterData()
    }

    render() {
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        const { loadFooterData, ...props } = this.props

        return <Footer {...props} />
    }
}

const mapStateToProps = (state: ApplicationState, ownProps: OwnProps): FieldProps => {
    const event = currentEventSelector(state)
    const isEventRunning = state.status.isEventRunning
    const year = moment().format('Y')
    const links = ownProps.customLinks || state.data.footerLinks.sort((a, b) => a.sortOrder - b.sortOrder)
    const socialAccounts = state.data.socialAccounts.sort((a, b) => a.sortOrder - b.sortOrder)
    const topBanner = isEventRunning ?
                    (event && event.topBanner ? event.topBanner : null) :
                    (state.data.nonEventConfig && state.data.nonEventConfig.topBanner ?
                        state.data.nonEventConfig.topBanner : null)
    const eventLogo = event === undefined || event.logo === undefined ? null : event.logo

    return {
        topBanner,
        links,
        socialAccounts,
        year,
        isEventRunning,
        eventLogo,
        variation: ownProps.variation,
        eventType: event && event.type ? event.type : undefined,
        loading: state.loading.count !== 0,
    }
}

const actions: LoadMethodProps = {
    loadFooterData,
}

const FooterWithData = connect(mapStateToProps, actions)(LoadFooter)
export default FooterWithData
