import * as React from 'react'
import './ModalHeader.scss'
import { ModalPadding } from './types'

type Props = {
    className?: string
    modalPadding?: ModalPadding
}

class ModalHeader extends React.Component<React.PropsWithChildren<Props>> {
    render() {
        const { className = '', modalPadding, children } = this.props

        return (
            <div className={`modal-header ${className} modal-header--padding-${modalPadding}`}>
                {children}
            </div>
        )
    }
}

export default ModalHeader
