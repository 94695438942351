import * as React from 'react'
import { connect } from 'react-redux'
import Card from '../../deal/Card'
import { SearchHit } from '../types'
import { getBrandUrl } from '../../brand/utilities'
import EventType from '../../../models/eventType'
import Event from '../../../models/event'
import { ApplicationState } from '../../../reducers'
import { currentEventSelector } from '../../../data/selectors'
import { isLoggedInSelector } from '../../account/reducer'
import moment from 'moment'

export type Props = {
  hit: SearchHit
}

type FieldProps = {
  eventType?: EventType
  isLoggedIn?: boolean
  event?: Event
  networkTime?: number
  isAdmin?: boolean
}

type AllProps = FieldProps & Props

class LoadSearchResult extends React.Component<AllProps> {
  render() {
    const { hit, eventType, isLoggedIn, event, isAdmin } = this.props

    const brandUrl = hit.brandSlug === undefined ? undefined : getBrandUrl(hit.brandSlug)

    const dealStartTime = hit.dealStartTime
      ? hit.dealStartTime
      : event
      ? parseInt(moment(moment.unix(event.start).format('YYYY-MM-DD')).format('X'))
      : undefined

    const dealEndTime = hit.dealEndTime ? hit.dealEndTime : event ? parseInt(event.end.toString()) : undefined
    const isExclusive = hit.isExclusive ? hit.isExclusive : false

    if (
      (dealStartTime &&
        dealStartTime <= Math.floor(Date.now() / 1000) &&
        dealEndTime &&
        dealEndTime > Math.floor(Date.now() / 1000)) ||
      isAdmin ||
      isExclusive
    ) {
      return (
        <div className='col-xs-6 col-sm-6 col-md-4 col-lg-3 list-deal'>
          <Card
            id={hit.objectID}
            title={hit.title}
            offer={hit.offer}
            optional1={hit.details}
            optional2={hit.redemptionInfo}
            imageUrl={hit.image}
            brandName={hit.brand}
            brandUrl={brandUrl}
            brandImageUrl={hit.brandLogo}
            slug={hit.slug}
            type={hit.type}
            eventType={eventType}
            hit={hit}
            isExclusive={isExclusive}
            isGreyedOut={hit.isGreyedOut ? hit.isGreyedOut : false}
            dealStartTime={dealStartTime}
            dealEndTime={dealEndTime}
            isLoggedIn={isLoggedIn}
            dealURL={hit.dealUrl}
          />
        </div>
      )
    } else {
      return null
    }
  }
}

const mapStateToProps = (state: ApplicationState, ownProps: Props): FieldProps => {
  if (ownProps.hit === undefined) {
    return {}
  }
  const event = currentEventSelector(state)
  const isLoggedIn = isLoggedInSelector(state)

  return {
    isLoggedIn,
    eventType: event && event.type ? event.type : undefined,
    event: event,
    networkTime: state.status.networkTime,
    isAdmin: state.account.isAdmin,
  }
}

const SearchResult = connect(mapStateToProps)(LoadSearchResult)
export { SearchResult }
