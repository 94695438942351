import { ApplicationState } from '../../reducers'
import { connect } from 'react-redux'
import BrandDeals, { Props } from './BrandDeals'
import { brandDetailedSelector } from '../../data/selectors'

type OwnProps = {
    slug: string
}

const mapStateToProps = (state: ApplicationState, ownProps: OwnProps): Props => {
    const brand = brandDetailedSelector(state, ownProps.slug)

    return {
        brand,
    }
}

export default connect(mapStateToProps)(BrandDeals)
