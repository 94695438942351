import * as React from 'react'
import { BannerType } from '../../models/banner'
import './BannerAd.scss'
import { Grid } from '../grid'
import BannerWithData from './BannerWithData'

export type FieldProps = {
    type: BannerType
}

type Props = FieldProps

class BannerAd extends React.Component<Props> {
    render() {
        const { type } = this.props

        return (
            <div className='banner-ad'>
                <Grid>
                    <div className='row'>
                        <div className='banner-ad__banner-wrapper col-xs-12 col-md-12'>
                            <BannerWithData type={type} />
                        </div>
                    </div>
                </Grid>
            </div>
        )
    }
}

export default BannerAd
