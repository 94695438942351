// SVG ICON : ClockIco
import React from 'react'

type Props = {
  color?: string
}

class ClockIco extends React.Component<Props> {
  render() {
    const { color } = this.props

    return (
      <svg
        width='33'
        height='32'
        viewBox='0 0 33 32'
        fill='none'
        xmlns='http://www.w3.org/2000/svg'
      >
        <path
          d='M21.0772 29.5517L29.9726 20.6563C30.2202 20.4087 30.4166 20.1147 30.5507 19.7912C30.6847 19.4677 30.7537 19.1209 30.7537 18.7707C30.7537 18.4205 30.6847 18.0737 30.5507 17.7502C30.4166 17.4266 30.2202 17.1326 29.9726 16.885L15.4206 2.33301H2.75391V14.9997L17.3059 29.5517C17.5535 29.7993 17.8475 29.9957 18.1711 30.1298C18.4946 30.2638 18.8414 30.3328 19.1916 30.3328C19.5418 30.3328 19.8885 30.2638 20.2121 30.1298C20.5356 29.9957 20.8296 29.7993 21.0772 29.5517Z'
          stroke='#E9028D'
          strokeMiterlimit='10'
          strokeLinecap='square'
        />
        <path
          d='M10.0885 12.3333C11.5613 12.3333 12.7552 11.1394 12.7552 9.66667C12.7552 8.19391 11.5613 7 10.0885 7C8.61578 7 7.42188 8.19391 7.42188 9.66667C7.42188 11.1394 8.61578 12.3333 10.0885 12.3333Z'
          stroke='#E9028D'
          strokeMiterlimit='10'
          strokeLinecap='square'
        />
        <path
          d='M17.4219 13L22.7552 18.3333'
          stroke={color || '#E9028D'}
          strokeMiterlimit='10'
          strokeLinecap='square'
        />
        <path
          d='M13.4219 17L18.7552 22.3333'
          stroke={color || '#E9028D'}
          strokeMiterlimit='10'
          strokeLinecap='square'
        />
      </svg>
    )
  }
}

export default ClockIco
