import * as React from 'react'
import bind from 'bind-decorator'
import { CheckboxItem } from './types'
import { Checkbox } from './Checkbox'

type Props = {
    item: CheckboxItem
    onChange: (item: CheckboxItem) => void
}

class PoweredCheckbox extends React.Component<React.PropsWithChildren<Props>> {
    @bind
    handleChange() {
        const { onChange, item } = this.props

        onChange(item)
    }

    render() {
        const { children, item } = this.props

        const name = (item.name as string).length ? item.name as string : undefined

        return (
            <Checkbox name={name} id={item.id} checked={item.checked} onChange={this.handleChange}>
                {item.description || children}
            </Checkbox>
        )
    }
}

export { PoweredCheckbox }
