import * as React from 'react'
import { CheckboxList, DictionaryValue, Logo } from '../../components'
import { CheckboxItem } from '../../components/form'
import bind from 'bind-decorator'
import Brand from '../../models/brand'
import './FilterByBrand.scss'
import { history } from '../../history'
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore TODO: improve this
import { Location } from 'history'
import { filterToPagePath } from './utilities'
import { getResizedImageFromCDN, brandLogoSize } from '../../utilities'

export type FieldProps = {
    brands: Brand[]
    filterBrands: string[]
    location: Location
    toggleDropdown: () => void
    dropdownStatus: boolean
}

export type MethodProps = {
    resetPage: () => void
}

type Props = FieldProps & MethodProps

type BrandItem = {
    brandName: string           // used to sort brands by name in checkbox list
    checkboxItem: CheckboxItem
}

type State = {
    items: BrandItem[]
}

class FilterByBrand extends React.Component<Props, State> {
    componentDidUpdate(prevProps: Readonly<Props>): void {
        if (this.props === prevProps) {
            return
        }
        const brands = this.props.brands
        const selectedBrands = this.props.filterBrands
        const brandsToCheckboxItems = brands.map(brand => (
        {
            brandName: brand.name,
            checkboxItem: {
                name: (
                    <span className='filter-by-brand__checkbox-name' data-brand-slug={brand.slug}>
                        <Logo
                            className='filter-by-brand__checkbox-name-image'
                            src={getResizedImageFromCDN(brand.logo, brand.logoIsBitmap, brandLogoSize.filter)}
                            alt={brand.name}
                            size='small'
                        />
                        <span className='filter-by-brand__checkbox-name-text'>{brand.name}</span>
                    </span>
                ),
                checked: Boolean(selectedBrands.includes(brand.slug)),
                id: brand.slug,
            },
        }))

        const allBrandsCheckboxItem = {
            brandName: 'SHOW_ALL',
            checkboxItem: {
                name: (
                    <span className='filter-by-brand__checkbox-name filter-by-brand__checkbox-name--no-logo'>
                    <DictionaryValue token='category.filter.all' />
                </span>
                ),
                checked: Boolean(selectedBrands.length <= 0),
                id: 'SHOW_ALL',
            },
        }

        this.setState({
            items: [
                allBrandsCheckboxItem,
                ...this.sortBrandsByName(brandsToCheckboxItems),
            ],
        })
    }

    applyFilterBySelected(selectedBrands: string[]) {
        const pathnameWithFilter = filterToPagePath(this.props.location.pathname, selectedBrands)
        history.push(pathnameWithFilter)
        this.props.resetPage()
    }

    sortBrandsByName(brandItems: BrandItem[]): BrandItem[] {
        return brandItems.sort((a, b) => {
            if (a.checkboxItem.id === 'SHOW_ALL') {
                return -1
            }
            if (b.checkboxItem.id === 'SHOW_ALL') {
                return 1
            }
            if (a.checkboxItem.checked && !b.checkboxItem.checked) {
                return -1
            }
            if (b.checkboxItem.checked && !a.checkboxItem.checked) {
                return 1
            }
            return a.brandName.localeCompare(b.brandName)
        })
    }

    @bind
    handleApplyFilter() {
        this.props.toggleDropdown()
    }

    @bind
    handleItemClick(changedItem: CheckboxItem) {
        let defaultApplied = true
        let items

        if (changedItem.id === 'SHOW_ALL') {
            items = this.state.items.map((item) => {
                item.checkboxItem.checked = (item.checkboxItem.id === 'SHOW_ALL')
                return item
            })
        } else {
            items = this.state.items.map((item) => {
                item.checkboxItem.checked = item.checkboxItem.id === changedItem.id ?
                    !item.checkboxItem.checked : item.checkboxItem.checked
                if (item.checkboxItem.checked && item.checkboxItem.id !== 'SHOW_ALL') {
                    defaultApplied = false
                }
                return item
            })
            const defaultItem = items.find(item => item.checkboxItem.id === 'SHOW_ALL')
            if (defaultItem) {
                defaultItem.checkboxItem.checked = defaultApplied
            }
        }
        items = this.sortBrandsByName(items)
        const newState = {
            items,
        }
        this.setState(newState)

        const selectedBrands = items.filter(item => item.checkboxItem.checked)
            .sort((a, b) => a.brandName.localeCompare(b.brandName))
            .map(item => item.checkboxItem.id)
        this.applyFilterBySelected(selectedBrands)
    }

    render() {
        if (this.state === null) {
            return null
        }

        return (
            <CheckboxList
                className='filter-by-brand'
                handleAction={this.handleApplyFilter}
                onItemClick={this.handleItemClick}
                buttonText={<DictionaryValue token='category.filter.modal.done' />}
                items={this.state.items.map(item => item.checkboxItem)}
            />
        )
    }
}

export { FilterByBrand }
