import { connect } from 'react-redux'
import ComingEvent, { Props } from '.'
import { ApplicationState } from '../../../../../reducers'
import { currentAndFutureEventsSelector } from '../../../../../data/selectors'

const mapStateToProps = (state: ApplicationState): Props => {
  return {
    events: currentAndFutureEventsSelector(state),
  }
}

export default connect(mapStateToProps)(ComingEvent)
