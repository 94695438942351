import { useState, useEffect } from 'react'
import { Link } from 'react-scroll'

export type FieldProps = {
  letter: string
  isMissing: boolean
  className?: string
}

export type MethodProps = {
  navigateTo: (letter: string) => void
}

export type Props = FieldProps & MethodProps

const NavigationLetter = (props: Props) => {
  const { letter, isMissing, className = '', navigateTo } = props
  const brandClass = 'brand-navigation__item'
  const [navBarHeight, setNavBarHeight] = useState(0)

  useEffect(() => {
    if (window.localStorage.getItem('nav-bar-height'))
      setNavBarHeight(Number(window.localStorage.getItem('nav-bar-height')) + 30)
  }, [])

  const navigate = (e: React.MouseEvent) => {
    if (!isMissing) {
      e.preventDefault()
      navigateTo(letter)
    }
  }

  return (
    <li
      className={`${brandClass} ${isMissing ? `${brandClass}--is-missing` : ''} ${className}`}
      onClick={navigate}
    >
      <Link
        activeClass='active'
        to={`brand-group--${letter}`}
        smooth={true}
        offset={-navBarHeight}
      >
        {letter}
      </Link>
    </li>
  )
}

export default NavigationLetter
