import { connect } from 'react-redux'
import SecondNav, { FieldProps } from './SecondNav'
import { ApplicationState } from '../../../reducers'
import { currentEventSelector } from '../../../data/selectors'

const mapStateToProps = (
  state: ApplicationState,
  currentProps: Pick<FieldProps, 'categoryMenuHandler' | 'eventType'>
): FieldProps => {
  const currentEvent = currentEventSelector(state)
  const categories = currentEvent !== undefined && currentEvent.categories !== undefined ? currentEvent.categories : []
  const totalCategories = categories.length

  return {
    totalCategories,
    items: [
      {
        path: '',
        name: 'Shop by Category',
      },
      {
        path: '/brands',
        name: 'Shop by Brand',
      },
      // {
      //   path: '/cf-exclusives',
      //   name: 'Exclusive Deals',
      // },
      // {
      //   path: '/best-of-frenzy',
      //   name: 'Best of Frenzy',
      // },
      {
        path: 'https://faq.clickfrenzy.com.au/support/home',
        name: 'FAQs',
      },
    ],
    isCategoryMenuOpen: state.nav.isCategoryMenuOpen,
    isEventRunning: state.status.isEventRunning,
    ...currentProps,
  }
}

const SecondNavWithData = connect(mapStateToProps)(SecondNav)
export default SecondNavWithData
