import * as React from 'react'
import { Link } from '../../../../../components'
import { DealBadge } from '../../../../../components/cf-package'
import { CfDealItem } from '../../../../../models/cfdeal'
import { checkDateStatus, getImgUrl, isExpired } from '../../../../../utilities/deal.helper'
import './DealCardItem.scss'

type Props = {
  className?: string
  id?: string
  data: CfDealItem
  openModal: (data: unknown) => void
}

class DealCardItem extends React.Component<Props> {
  render() {
    const { className = '', id, data, openModal } = this.props
    const { brandLogoId, dealImageId, description, title, trackingUrl, expiryDate } = data

    return (
      <div
        id={id}
        className={`cf-deal-item ${className} ${isExpired(expiryDate) ? 'expired' : ''}`}
      >
        {expiryDate && (
          <DealBadge
            date={expiryDate}
            text={checkDateStatus(expiryDate)}
            variant={checkDateStatus(expiryDate)}
          />
        )}
        <figure className='thumb'>
          <Link
            to={trackingUrl}
            target='_blank'
          >
            {dealImageId && (
              <img
                src={getImgUrl(dealImageId, '600x300')}
                alt='deal item'
                className='thumb-img'
              />
            )}
            {brandLogoId && (
              <div className='deal-logo'>
                <img
                  src={getImgUrl(brandLogoId)}
                  alt='deal logo'
                />
              </div>
            )}
          </Link>
        </figure>
        <div className='content'>
          {title && (
            <div className='ttl font-barlow-bold'>
              <Link
                to={trackingUrl}
                target='_blank'
              >
                {title}
              </Link>
            </div>
          )}
          {description && <div className='desc'>{description}</div>}
          <div className='link-blk'>
            <span onClick={() => openModal('temp')}>Terms apply</span>
          </div>
        </div>
      </div>
    )
  }
}

export default DealCardItem
