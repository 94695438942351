import * as React from 'react'
import { CFCountdown, DateButton } from '../../../../../components/cf-package'
import Event from '../../../../../models/event'
import {
  AttendArrow,
  AttendArrowSm,
  CalendarIco,
  HitTheButton,
  HitTheButtonSm,
} from '../../../../../components/cf-package/Svg'
import './style/MainEvent.scss'
import moment from 'moment'

type Props = {
  className?: string
  id?: string
  data: Event
}

class MainEvent extends React.Component<Props> {
  render() {
    const { className = '', id, data } = this.props
    const { slug, name, logo, start } = data

    const to = moment.unix(start)

    return (
      <section
        className={`${className} cf-main-event`}
        id={id}
      >
        <div className='inner cf-flex flex-row align-center justify-between'>
          <div className='img'>
            <img
              src='data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAIAAACQd1PeAAAABnRSTlMA/
                            wD/AP83WBt9AAAADElEQVQI12P4//8/AAX+Av7czFnnAAAAAElFTkSuQmCC'
              data-blink-src={logo}
              alt={name}
            />
          </div>
          <div className='ct'>
            {name && <h4 className='ttl head-md'>{name}</h4>}
            <time
              className='meta-date'
              dateTime={to.format('Y-m-d')}
            >
              {to.format('h:mmA ddd, MMM DD YYYY')}
            </time>
            <CFCountdown to={start} />
            <div className='cta-blk cf-flex flex-col align-center'>
              <DateButton
                id={`countdownCard-${slug}`}
                utmCampaign='savethedate'
              >
                <span>Save the date</span>
                <CalendarIco color='#F7F410' />
              </DateButton>

              <div className='guide-box-sm'>
                <AttendArrowSm className='pos-arrow-sm' />
                <HitTheButtonSm className='pos-text-sm' />
              </div>
              <AttendArrow className='pos-arrow' />
              <HitTheButton className='pos-text' />
            </div>
          </div>
        </div>
      </section>
    )
  }
}

export default MainEvent
