import { connect } from 'react-redux'
import { currentEventSelector } from '../../../data/selectors'
import Category from '../../../models/category'
import FeaturedBrand from '../../../models/featuredBrand'
import { ApplicationState } from '../../../reducers'
import { isLoggedInSelector } from '../../account/reducer'
import { closeMobileNavMenu, openMobileNavMenu } from './actions'
import MobileNav, { FieldProps, MethodProps } from './MobileNav'

const mapStateToProps = (state: ApplicationState, ownProps: Pick<FieldProps, 'resetPageHeight'>): FieldProps => {
  const currentEvent = currentEventSelector(state)
  let categories: Category[] = []
  let categoryFeaturedBrands: FeaturedBrand | undefined = undefined

  if (currentEvent !== undefined && currentEvent.categories !== undefined) {
    categories = currentEvent.categories.sort((a, b) => Number(a.sortOrder) - Number(b.sortOrder))

    if (currentEvent.categoryFeaturedBrands !== undefined && currentEvent.categoryFeaturedBrands.brands.length) {
      categoryFeaturedBrands = currentEvent.categoryFeaturedBrands
    }
  }

  return {
    categories,
    categoryFeaturedBrands,
    isMobileNavMenuOpen: state.nav.isMobileNavMenuOpen,
    isEventRunning: state.status.isEventRunning,
    eventType: currentEvent === undefined || currentEvent.type === undefined ? null : currentEvent.type,
    isLoggedIn: isLoggedInSelector(state),
    ...ownProps,
  }
}

const actions: MethodProps = {
  openMobileNavMenu,
  closeMobileNavMenu,
}

const MobileNavWithData = connect(mapStateToProps, actions)(MobileNav)
export default MobileNavWithData
