import React from 'react'
import classnames from 'classnames'
import { SearchResult } from './SearchResult'
import { connectInfiniteHits } from 'react-instantsearch-dom'
import { HitsProvided } from 'react-instantsearch-core'
import { SearchHit } from '../types'
import './SearchResults.scss'
import { SearchFooter } from './SearchFooter'

type OwnProps = {
    className?: string
    isVisible: boolean
} & HitsProvided<SearchHit>

type MethodProps = {
    refineNext: () => void
    hasMore: boolean
}

type Props = OwnProps & MethodProps

class SearchResultsView extends React.Component<Props> {
    render() {
        const { className, isVisible } = this.props
        const classes = classnames(
            'search-results',
            className,
            { 'search-results--visible': isVisible }
        )
        const footerClasses = classnames(
            'search-results__footer',
            { 'search-results__footer--visible': isVisible }
        )

        return (
            <>
                <div className={classes}>
                    <div className='row'>
                        {this.props.hits.map(hit =>
                            <SearchResult key={hit.objectID} hit={hit} />
                        )}
                    </div>
                </div>
                <SearchFooter className={footerClasses}/>
            </>
        )
    }
}

const SearchResults = connectInfiniteHits<Props, SearchHit>(SearchResultsView)

export { SearchResults, SearchResultsView }
