import * as React from 'react'
import { DictionaryValue, DropdownWithViewport } from '../../components'
import Brand from '../../models/brand'
import { FilterByBrandWithData } from './FilterByBrandWithData'
import './Refinement.scss'
import { ApplicationState } from '../../reducers'
import { brandsForDealsSelector, categorySelector, dealsForCategorySelector } from '../../data/selectors'
import { connect } from 'react-redux'
import { isSubcategory, brandsQueryToArray } from './utilities'
import Deal from '../../models/deal'

type Props = {
    brands: Brand[]
    selectedBrands: string[]
}

class RefinementComponent extends React.Component<Props> {
    render() {
        const { brands, selectedBrands } = this.props

        const categoryFilterTitle = <DictionaryValue token='category.filter.title' />

        const filterToken = selectedBrands.length > 0 ? 'category.filter.selected' : 'category.filter.all'
        const categoryFilterAll = <DictionaryValue token={filterToken} />

        return (
            <div className='category-refinement'>
                <DropdownWithViewport
                    className='category-refinement__dropdown'
                    label={
                        (
                            <>
                                {categoryFilterTitle}: {categoryFilterAll}
                            </>
                        )
                    }
                    labelMobile={<>{categoryFilterTitle}</>}
                    dropdownMenuTitle={<DictionaryValue token='category.filter.modal.title' />}
                    hideCloseButtonForDropdownMenu={true}
                >
                    {(toggleDropdown, dropdownStatus) => (
                        <FilterByBrandWithData
                            brands={brands}
                            toggleDropdown={toggleDropdown}
                            dropdownStatus={dropdownStatus}
                        />
                    )}
                </DropdownWithViewport>
            </div>
        )
    }
}

type OwnProps = {
    slug: string
    subCatSlugs?: string[]
}

const mapStateToProps = (state: ApplicationState, ownProps: OwnProps) => {
    if (ownProps.subCatSlugs && ownProps.subCatSlugs.length > 0) {
        const filterDealsBy = 'all'
        let categoryDeals: Deal[] = []
        ownProps.subCatSlugs.map((slug) => {
            const category = categorySelector(state, slug)
            if (category) {
                categoryDeals = categoryDeals.concat(dealsForCategorySelector(state, category, filterDealsBy))
            }
        })

        const categoryBrands = brandsForDealsSelector(state, categoryDeals)
        const selectedBrands = brandsQueryToArray(
            state.router.location.search,
            categoryBrands.map(brand => brand.slug)
        )

        return {
            selectedBrands,
            brands: categoryBrands,
        }
    }

    const category = categorySelector(state, ownProps.slug)
    const filterDealsBy = isSubcategory(state, ownProps.slug) ? 'all' : 'gold'
    const categoryDeals = category ? dealsForCategorySelector(state, category, filterDealsBy) : []
    const categoryBrands = brandsForDealsSelector(state, categoryDeals)
    const selectedBrands = brandsQueryToArray(
        state.router.location.search,
        categoryBrands.map(brand => brand.slug)
    )

    return {
        selectedBrands,
        brands: categoryBrands,
    }
}

const Refinement = connect(mapStateToProps)(RefinementComponent)

export { Refinement }
