// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore TODO: improve this
import { Location, Action } from 'history'
import { applyMiddleware, compose, createStore } from 'redux'
import { routerMiddleware } from 'connected-react-router'
import { Config, createStateSyncMiddleware, withReduxStateSync } from 'redux-state-sync'
import thunk from 'redux-thunk'
import { isProduction, enableScroll } from './utilities'
import { createRootReducer } from './reducers'
import { itemLoading, itemLoaded } from './features/global/loading/actions'
import { recentSearchTermsUpdated } from './features/search/store/actions'
import { loadDictionary } from './services/Api'
import { dictionaryLoaded } from './data/actions'
import { gtmMiddleware } from './services/gtm'
import { configureAccountListener } from './features/account/thunks'
import { history } from './history'

// If we go to a new page, everything will be closed,
// we should be allowed to scroll, and we should
// scroll to the top of the page
let previousLocation: Location | undefined
// eslint-disable-next-line @typescript-eslint/no-unused-vars
history.listen((location: Location, action: Action) => {
  if (
    // We are navigating from a non-search page on load to another non-search page
    // eg. Brands -> Home
    (!previousLocation && !location.pathname.endsWith('search')) ||
    // We came from somewhere other than search
    // eg. Brands -> Home -> Search
    (previousLocation && !previousLocation.pathname.endsWith('search')) ||
    // We came from somewhere other than search, directly after a page load
    // eg. Brands -> Search
    (!previousLocation && location.pathname.endsWith('search') && !location.search) ||
    // We are navigating away from the search page
    (previousLocation && previousLocation.pathname.endsWith('search') && !location.pathname.endsWith('search'))
  ) {
    window.scrollTo({ top: 0 })
  }

  enableScroll()
  previousLocation = location
})

const stateSyncConfig: Config = {
  channel: 'cf_redux_state_sync',
  whitelist: [recentSearchTermsUpdated.type],
}

export default function configureStore(preloadedState: any) {
  const composeEnhancers = (window as any).__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose
  const rootReducer = withReduxStateSync(createRootReducer(history))

  const store = createStore(
    rootReducer, // root reducer with router state
    preloadedState,
    composeEnhancers(
      applyMiddleware(
        routerMiddleware(history), // for dispatching history actions
        thunk,
        gtmMiddleware,
        createStateSyncMiddleware(stateSyncConfig) // for syncing state across tabs
      )
    )
  )

  // load the dictionary
  store.dispatch(itemLoading())
  loadDictionary().then(dictionary => {
    store.dispatch(dictionaryLoaded(dictionary))
    store.dispatch(itemLoaded())
  })

  // hook up AWS Auth listeners
  store.dispatch(configureAccountListener() as any)

  if (!isProduction) {
    if (module.hot) {
      module.hot.accept('./reducers', () => {
        store.replaceReducer(rootReducer)
      })
    }
  }

  return store
}

export const store = configureStore({})
