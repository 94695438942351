import * as React from 'react'
import NamedItem from '../../../models/namedItem'
import Brand from '../../../models/brand'
import Group from '../group'
import './List.scss'
import { Layout } from '../../layout'
import Navigation from '../navigation'
import { Grid } from '../../../components/grid'
import { DictionaryValue } from '../../../components/dictionary-value'
import { BackToTop } from '../../../components/back-to-top'

type FieldProps = {
    groups: NamedItem<Brand[]>[]
}

type MethodProps = {
    navigateTo: (letter: string) => void
}

type Props = FieldProps & MethodProps

class List extends React.Component<Props> {
    render() {
        const { groups, navigateTo } = this.props

        return (
            <Layout>
                <div className='brand-page-navigation'>
                    <Navigation groups={groups} navigateTo={navigateTo} />
                </div>
                <Grid className='brand-page-heading'>
                    <div className='row'>
                        <div className='col-xs-12'>
                            <h1 className='brand-page-heading__title'><DictionaryValue token='brand.list.title' /></h1>
                        </div>
                    </div>
                </Grid>
                <div className='brand-page-listing'>
                    {groups.map(group => <Group key={group.name} name={group.name} brands={group.item} />)}
                </div>
                <BackToTop />
            </Layout>
        )
    }
}

export default List
