import { connect } from 'react-redux'
import { currentEventSelector } from '../../../data/selectors'
import { ApplicationState } from '../../../reducers'
import { isLoggedInSelector } from '../../account/reducer'
import {
  closeCategoryMenu,
  closeMobileNavMenu,
  closeSubmenu,
  openCategoryMenu,
  openMobileNavMenu,
  openSubmenu,
} from './actions'
import NavBar, { FieldProps, MethodProps } from './NavBar'

const mapStateToProps = (
  state: ApplicationState,
  currentProps: { resetPageHeight: () => void } & Pick<FieldProps, 'customColors' | 'customMenuItems'>
): FieldProps => {
  const event = currentEventSelector(state)

  const topBanner = state.status.isEventRunning
    ? event === undefined || event.topBanner === undefined
      ? null
      : event.topBanner
    : state.data.nonEventConfig === undefined || state.data.nonEventConfig.topBanner === undefined
    ? null
    : state.data.nonEventConfig.topBanner

  return {
    ...currentProps,
    topBanner,
    isEventRunning: state.status.isEventRunning,
    isSubmenuOpen: state.nav.isSubmenuOpen,
    isCategoryMenuOpen: state.nav.isCategoryMenuOpen,
    isMobileNavMenuOpen: state.nav.isMobileNavMenuOpen,
    isLoggedIn: isLoggedInSelector(state),
    eventType: event === undefined || event.type === undefined ? null : event.type,
    eventLogo: event === undefined || event.logo === undefined ? null : event.logo,
    eventStartDate: event === undefined || event.start === undefined ? null : event.start,
    eventName: event === undefined || event.name === undefined ? null : event.name,
  }
}

const actions: MethodProps = {
  openSubmenu,
  closeSubmenu,
  openCategoryMenu,
  closeCategoryMenu,
  openMobileNavMenu,
  closeMobileNavMenu,
}

const NavBarWithData = connect(mapStateToProps, actions)(NavBar)
export default NavBarWithData
