import * as React from 'react'
import { DictionaryValue } from '../../../components/dictionary-value'
import bind from 'bind-decorator'
import { SearchTerm } from '../types'
import Event from '../../../models/event'
import { SearchSuggestion } from './SearchSuggestion'
import './RecentSearches.scss'
import { Button } from '../../../components/button'

export type FieldProps = {
    recentSearchTerms: SearchTerm[]
    currentEvent?: Event
}

export type MethodProps = {
    clearRecentSearchTerms: () => void
}

type Props = FieldProps & MethodProps

type State = {
    showMoreRecentSearches: boolean
}

class RecentSearches extends React.Component<Props, State> {
    static searchesPerPage = 10

    state = {
        showMoreRecentSearches: false,
    }

    @bind
    handleClearRecentSearchesClick() {
        this.setState({ showMoreRecentSearches: false })
        this.props.clearRecentSearchTerms()
    }

    @bind
    toggleMoreRecentSearches() {
        this.setState(currentState => ({
            showMoreRecentSearches: !currentState.showMoreRecentSearches,
        }))
    }

    @bind
    renderRecentSearches() {
        const { recentSearchTerms } = this.props
        const { showMoreRecentSearches } = this.state

        if (recentSearchTerms.length === 0) {
            return (
                <p className='recent-searches__message'>
                    <DictionaryValue token='search.recent.empty' />
                </p>
            )
        }

        return recentSearchTerms.map((term, index) => {
            if (!showMoreRecentSearches && index >= RecentSearches.searchesPerPage) {
                return null
            }

            return <SearchSuggestion key={`${term.term}-${index}`} text={term.term} />
        })
    }

    render() {
        const { recentSearchTerms } = this.props
        const { showMoreRecentSearches } = this.state
        const showMoreLessButton = recentSearchTerms.length > RecentSearches.searchesPerPage
        const showClearSearchesButton = recentSearchTerms.length > 0

        return (
            <div className='recent-searches'>
                {this.renderRecentSearches()}
                {showMoreLessButton && (
                    <Button
                        buttonType='primary'
                        buttonStyle='square'
                        display='block'
                        size='large'
                        callback={this.toggleMoreRecentSearches}
                        emphasise={true}
                        disableBoxShadow={true}
                        className='recent-searches__action'
                    >
                        <DictionaryValue token={showMoreRecentSearches ? 'search.recent.less' : 'search.recent.more'} />
                    </Button>
                )}
                {showClearSearchesButton && (
                    <Button
                        buttonType='tertiary'
                        display='block'
                        size='large'
                        callback={this.handleClearRecentSearchesClick}
                        className='recent-searches__action'
                    >
                        <DictionaryValue token={'search.recent.clear'} />
                    </Button>
                )}
            </div>
        )
    }
}

export { RecentSearches }
