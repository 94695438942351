import { connect } from 'react-redux'
import { currentEventSelector } from '../../../data/selectors'
import Category from '../../../models/category'
import FeaturedBrand from '../../../models/featuredBrand'
import { ApplicationState } from '../../../reducers'
import CategoryNav, { FieldProps } from './CategoryNav'

const mapStateToProps = (
  state: ApplicationState,
  currentProps: Pick<FieldProps, 'isActive' | 'mainCategoryIndex' | 'mainCategoryHandler'>
): FieldProps => {
  const currentEvent = currentEventSelector(state)
  let categories: Category[] = []
  let categoryFeaturedBrands: FeaturedBrand | undefined = undefined

  if (currentEvent !== undefined && currentEvent.categories !== undefined) {
    categories = currentEvent.categories.sort((a, b) => Number(a.sortOrder) - Number(b.sortOrder))

    if (currentEvent.categoryFeaturedBrands !== undefined && currentEvent.categoryFeaturedBrands.brands.length) {
      categoryFeaturedBrands = currentEvent.categoryFeaturedBrands
    }
  }

  return {
    categories,
    categoryFeaturedBrands,
    ...currentProps,
  }
}

const CategoryNavWithData = connect(mapStateToProps)(CategoryNav)
export default CategoryNavWithData
