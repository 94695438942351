import React from 'react'
import classnames from 'classnames'
import { ColumnHeader } from '../../../components/typography'
import { DictionaryValue } from '../../../components/dictionary-value'
import { CategoryListWithData } from './SearchCategories'
import './SearchDefaultContent.scss'
import { FeaturedBrandsWithData } from '../../brand/featured-brands'
import CardWithData from '../../deal/CardWithData'
import { Carousel } from '../../../components/carousel'
import { Row, Col } from 'antd'
import EventType from '../../../models/eventType'

export interface CustomFieldProps {
  className?: string
  isVisible: boolean
  isSearch?: boolean
  featuredDealSlug: string[] | undefined
  eventType?: EventType | null
}

type Props = CustomFieldProps
class SearchDefaultContent extends React.Component<Props> {
  render() {
    const { className, isVisible, featuredDealSlug, eventType } = this.props
    let total: number | undefined
    let rand: number
    if (featuredDealSlug && featuredDealSlug.length === 0) {
      return null
    }
    // eslint-disable-next-line prefer-const
    total = featuredDealSlug && featuredDealSlug.length
    // eslint-disable-next-line prefer-const
    rand = total ? Math.floor(Math.random() * total) : 0
    const classes = classnames('search-default-content', className, {
      'search-default-content--visible': isVisible,
    })

    return (
      <div className={classes}>
        <Row
          gutter={12}
          justify='space-between'
        >
          <Col
            span={24}
            md={{ span: 6, offset: 2, order: 2 }}
          >
            <div className='search-page__featured-deal-wrapper'>
              <ColumnHeader>
                <DictionaryValue token='search.featured.deal.title' />
              </ColumnHeader>
              <div className='search-page__featured-deal deal-card--hover-effect'>
                {featuredDealSlug && featuredDealSlug.length > 0 && (
                  <Carousel
                    rand={rand}
                    isSearch={true}
                    eventType={eventType}
                  >
                    {featuredDealSlug.map(slug => (
                      <CardWithData
                        key={slug}
                        slug={slug}
                        showHover={false}
                      />
                    ))}
                  </Carousel>
                )}
              </div>
            </div>
          </Col>
          <Col
            span={24}
            md={{ span: 6, order: 1 }}
            className='popup-card__image-col'
          >
            <div className='search-page__featured-brands-wrapper'>
              <ColumnHeader>
                <DictionaryValue token='search.featured.brands.title' />
              </ColumnHeader>
              <FeaturedBrandsWithData location='search' />
            </div>
          </Col>
          <Col
            span={24}
            md={{ span: 6, offset: 2, order: 3 }}
          >
            <div className='search-page__categories'>
              <ColumnHeader>
                <DictionaryValue token='search.categories.title' />
              </ColumnHeader>
              <CategoryListWithData />
            </div>
          </Col>
        </Row>
      </div>
    )
  }
}

export { SearchDefaultContent }
