import * as React from 'react'
import bind from 'bind-decorator'
import Container from '../Container'
import { MenuButtonSm } from '../MobileMenu'
import { Link } from '../../link'
import CFMenu from './CFMenu'
import CFMenuSm from '../MobileMenu/CFMenuSm'
import { toggleMobileMenu } from '../../../utilities/mobileMenu'
import CFSignInWithData from './CFSignInWithData'
import FooterLink from '../../../models/footerLink'
import './style.scss'

type Props = {
  className?: string
  id?: string
  links?: FooterLink[]
}

type State = {
  isOpenMobileNav: boolean
}

class CFHeader extends React.Component<Props> {
  readonly state: State = {
    isOpenMobileNav: false,
  }

  @bind
  toggleMobileNav() {
    toggleMobileMenu(this.state.isOpenMobileNav)
    // Set State
    this.setState({
      isOpenMobileNav: !this.state.isOpenMobileNav,
    })
  }

  render() {
    const { className = '', id, links } = this.props
    const { isOpenMobileNav } = this.state

    return (
      <div>
        <header className={`bg-primary cf-header ${isOpenMobileNav ? 'is-open-menu' : ''}`}>
          <Container className='max-w-1320'>
            <section
              className={`${className} inner`}
              id={id}
            >
              <div className='cf-flex flex-row align-center justify-between'>
                <div className='cf-left cf-flex flex-row align-center'>
                  <Link
                    to='./'
                    className='logo'
                  >
                    <img
                      src='./static/images/cf_logo_always_on.png'
                      alt='white logo'
                    />
                  </Link>
                  {/* <Link
                    to='https://l.clickfrenzy.au/paypal'
                    className='partner-logo'
                  >
                    <img
                      src='./static/images/paypal-logo.png'
                      alt='partner logo'
                    />
                  </Link> */}
                  <CFMenu links={links} />
                </div>
                <div className='cf-right'>
                  <CFSignInWithData />
                  <MenuButtonSm
                    isOpenMenu={isOpenMobileNav}
                    toggleMenu={this.toggleMobileNav}
                  />
                </div>
              </div>
            </section>
          </Container>
        </header>
        <CFMenuSm
          id='mobile-nav'
          toggleMenu={this.toggleMobileNav}
        />
      </div>
    )
  }
}

export default CFHeader
