import DealPlacement from '../../models/dealPlacement'

export const convertSlugToDealPlacement = (
    slug: string,
    type: 'gold' | 'silver' | undefined = 'gold',
    position = 1
): DealPlacement => ({
    slug,
    type,
    position,
})

export const convertSlugsToDealPlacements = (slugs: string[]): DealPlacement[] =>
    slugs.map(slug => convertSlugToDealPlacement(slug))
