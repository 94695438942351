/**
 * Helper for Deal
 * */
import { CfDealItem } from '../models/cfdeal'

export const isDraft = (draftList: CfDealItem[], currentId: string) => {
  return draftList.some(draftItem => draftItem.id === currentId)
}

export const getDraft = (draftList: CfDealItem[], currentId: string) => {
  const draftItem = draftList.find(draftItem => draftItem.id === currentId)

  return draftItem ? draftItem : null
}

export const isExpired = (expiryDate: string): boolean => {
  const now: Date = new Date()
  const today: Date = new Date(now.getFullYear(), now.getMonth(), now.getDate(), 0, 0, 0) // 12:00AM

  const expiration: Date = new Date(expiryDate + 'T00:00:00')

  const isExpired: boolean = expiration < today

  return isExpired
}

export const filterExpired = (list: CfDealItem[]) => {
  if (list.length <= 0) return undefined
  return list.filter(item => !isExpired(item.expiryDate))
}

export const mergeDealList = (publicList: CfDealItem[], draftsList: CfDealItem[]): CfDealItem[] => {
  const mergedList: CfDealItem[] = [...draftsList] // Create a copy of publicList

  for (const item of publicList) {
    // Check if the item exists in publicList by comparing IDs
    if (!mergedList.some(existingItem => existingItem.id === item.id)) {
      mergedList.push(item) // Add the item from draftsList if it doesn't exist in publicList
    }
  }

  return mergedList
}

export const isEmpty = (value: any): value is boolean => {
  if (value === null || value === undefined) return true
  if (typeof value === 'number') return false
  if (Array.isArray(value)) return !value.length
  if (Object.prototype.toString.call(value) === '[object Date]') return false
  if (typeof value === 'object') return Object.keys(value).length === 0
  if (Object.prototype.toString.call(value) === '[object String]') return value === ''

  return false
}

/** GET THE IMG LINK */
//  https://ucarecdn.com/93f8a5f8-6c87-432d-b8d6-b479fbdad85a/-/preview/400x300/
export const getImgUrl = (imgId: string, cropSize?: string) => {
  let _url = `https://ucarecdn.com/${imgId}/`
  if (cropSize) {
    _url += `-/scale_crop/${cropSize}/center/`
  } else {
    _url += '-/preview/'
  }
  return _url
}

export const shuffleArray = <T>(array: T[]): T[] => {
  const shuffledArray = [...array] // Random

  for (let i = shuffledArray.length - 1; i > 0; i--) {
    const j = Math.floor(Math.random() * (i + 1))
    ;[shuffledArray[i], shuffledArray[j]] = [shuffledArray[j], shuffledArray[i]]
  }

  return shuffledArray
}
// Date : YYYY-MM-DD
export const checkDateStatus = (date: string): string => {
  if (!date) return 'expired'
  const now: Date = new Date()
  const currentDate = new Date(now.getFullYear(), now.getMonth(), now.getDate(), 0, 0, 0) // 12:00AM
  const inputDate = new Date(date + 'T00:00:00')
  const timeDiff = inputDate.getTime() - currentDate.getTime()
  const dayDiff = Math.ceil(timeDiff / (1000 * 3600 * 24))

  if (dayDiff > 5) {
    return 'no-expired'
  } else if (dayDiff <= 5 && dayDiff >= 0) {
    return 'ending'
  } else {
    return 'expired'
  }
}

// Date : YYYY-MM-DD => DD MMM => 02 Oct
export const formatShortDate = (date: string) => {
  const options = { day: '2-digit', month: 'short' }
  const formattedDate = new Date(date).toLocaleDateString('en-US', options as Intl.DateTimeFormatOptions)
  const [formattedMonth, formattedDay] = formattedDate.split(' ')
  return `${formattedDay} ${formattedMonth}`
}

// SORT FUNCTIONS
export const softDealsBy = (arr: CfDealItem[], type: 'trending' | 'new' | 'ending'): CfDealItem[] => {
  if (type === 'new') return sortByCreateDate(arr)
  else if (type === 'ending') return sortByExpiryDate(arr)
  else return sortByPosition(arr)
}

export const sortByPosition = (arr: CfDealItem[]): CfDealItem[] => {
  const sortedArr = [...arr]
  // sortedArr.sort((a, b) => a.position - b.position) // DESC
  sortedArr.sort((a, b) => {
    if (a.position === 0 && b.position === 0) return 0
    if (a.position === 0) return 1
    if (b.position === 0) return -1
    return a.position - b.position
  })

  return sortedArr
}

export const sortByCreateDate = (arr: CfDealItem[]): CfDealItem[] => {
  const sortedArr = [...arr]

  // Latest
  sortedArr.sort((a, b) => new Date(b.createDate).getTime() - new Date(a.createDate).getTime())

  return sortedArr
}

export const sortByExpiryDate = (arr: CfDealItem[]): CfDealItem[] => {
  const sortedArr = [...arr]
  const now = new Date()
  const today = new Date(now.getFullYear(), now.getMonth(), now.getDate(), 0, 0, 0) // 12:00AM

  sortedArr.sort((a, b) => {
    const expiryDateA = new Date(a.expiryDate + 'T00:00:00')
    const expiryDateB = new Date(b.expiryDate + 'T00:00:00')

    if (expiryDateA < today && expiryDateB < today) {
      return 0 // Put B expiryDate < today to END
    } else if (expiryDateA < today) {
      return 1 // Put A expiryDate < today to END
    } else if (expiryDateB < today) {
      return -1 // Put B expiryDate < today to END
    } else {
      return expiryDateA.getTime() - expiryDateB.getTime() // expiryDate ASC
    }
  })

  return sortedArr
}

// PAGINATION DEALS
export const paginateArr = <T>(arr: T[], page: number, itemsPerPage: number): T[] => {
  const startIndex = (page - 1) * itemsPerPage
  const endIndex = startIndex + itemsPerPage
  return arr.slice(startIndex, endIndex)
}
