import * as React from 'react'
import { Route, Switch } from 'react-router'
import { ConnectedRouter } from 'connected-react-router'
import { history } from '../../../history'
import { isProduction } from '../../../utilities'
import HomePage from '../../home/event-site'
import Styleguide from '../../styleguide/Styleguide'
import { ListWithData } from '../../brand/list'
import BrandDetailIndex from '../../brand/detail'
import { CategoryPageWithData } from '../../category'
import { SearchPage } from '../../search'
import { InterstitialPageWithData } from '../../interstitial'
import { AccountArea, UploadArea } from '../../account'
import { RouterWithData } from '../router'
import PartnerDetailPageWithData from '../../partners/partnerDetailPageWithData'
import { Finalist } from '../../custom-page/finalist/Finalist'
import { Subscribe } from '../../custom-page/subscribe/Subscribe'
import { FavouritePageWithData } from '../../favourite'
import { PopupWithData } from '../popup'
import CfReceiptWithData from '../../cf-receipt/CfReceiptWithData'
import NotFound from '../../NotFound'

type Props = {
  isEventRunning: boolean
}

class EventSite extends React.Component<Props> {
  render() {
    const { isEventRunning } = this.props
    return (
      <ConnectedRouter history={history}>
        <Switch>
          <Route
            exact={true}
            path='/'
            component={HomePage}
          />
          <Route
            exact={true}
            path='/brands/:slug'
            component={BrandDetailIndex}
          />
          <Route
            exact={true}
            path='/brands'
            component={ListWithData}
          />
          <Route
            exact={true}
            path='/partners/:slug'
            component={PartnerDetailPageWithData}
          />
          <Route
            exact={true}
            path='/search'
            component={SearchPage}
          />
          <Route
            exact={true}
            path='/favourite'
            component={FavouritePageWithData}
          />
          <Route
            exact={true}
            path='/account'
            render={() => <AccountArea isEventRunning={isEventRunning} />}
          />
          <Route
            exact={true}
            path='/account/upload'
            component={UploadArea}
          />
          <Route
            path='/outgoing/:type/:slug'
            component={InterstitialPageWithData}
          />
          <Route
            exact={true}
            path='/ironshopper'
            component={Finalist}
          />
          <Route
            exact={true}
            path='/subscribe'
            component={Subscribe}
          />
          <Route
            exact={true}
            path='/clickfrenzyreceipt'
            component={
              process.env.REACT_APP_REGION && process.env.REACT_APP_REGION === 'AU' ? CfReceiptWithData : NotFound
            }
          />
          {!isProduction ? (
            <Route
              exact={true}
              path='/style-guide'
              component={Styleguide}
            />
          ) : null}
          <Route
            exact={true}
            path='/:parentSlug/:slug'
            component={CategoryPageWithData}
          />
          <Route
            path='/:slug'
            component={RouterWithData}
          />
        </Switch>
        <PopupWithData />
      </ConnectedRouter>
    )
  }
}

export default EventSite
