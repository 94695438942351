import * as React from 'react'
import './Logo.scss'

export type LogoProps = {
    className?: string
    src: string
    alt: string
    size?: 'small' | 'default' | 'block'
    href?: string
}

type WrapperElement = {
    className: string
    href?: string
} & React.HTMLProps<HTMLAnchorElement | HTMLDivElement>

class Logo extends React.Component<LogoProps> {
    static defaultProps = {
        className: '',
        size: 'default',
    }

    render() {
        const { className, src, size, href, alt, ...rest } = this.props
        const WrapperElement = (href ? 'a' : 'div') as string

        const props: WrapperElement = {
            className: `logo logo--size-${size} ${className}`,
        }

        if (href) {
            props.href = href
        }

        return (
            <WrapperElement {...rest} {...props}>
                <span className='logo__image-wrapper'>
                    <img
                        className='logo__image'
                        src='data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAIAAACQd1PeAAAABnRSTlMA/
                        wD/AP83WBt9AAAADElEQVQI12P4//8/AAX+Av7czFnnAAAAAElFTkSuQmCC'
                        data-blink-src={src}
                        alt={alt}
                    />
                </span>
            </WrapperElement>
        )
    }
}

export { Logo }
