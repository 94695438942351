import * as React from 'react'
import bind from 'bind-decorator'
import './SearchSuggestion.scss'
import { arrowTopLeft, Icon } from '../../../components/icon'

type Props = {
    text: string
    onTextClick?: () => void
    onActionClick?: (text: string) => void
}

class SearchSuggestion extends React.Component<Props> {

    @bind
    handleActionClick() {
        const { onActionClick, text } = this.props

        if (!onActionClick) {
            return
        }

        onActionClick(text)
    }

    render() {
        const { text, onTextClick } = this.props

        return (
            <div className='search-suggestion'>
                <a
                    className='search-suggestion__text'
                    href={`/search?q=${encodeURIComponent(text)}`}
                    onClick={onTextClick}
                    target='_blank'
                    rel='noreferrer'
                >
                    {text}
                </a>
                <button
                    className='search-suggestion__action'
                    onClick={this.handleActionClick}
                >
                    <Icon icon={arrowTopLeft} wrappingElement='span' />
                </button>
            </div>
        )
    }
}

export { SearchSuggestion }
