import Category from '../../models/category'
import { ApplicationState } from '../../reducers'
import { currentEventSelector } from '../../data/selectors'
import qs from 'qs'

function getCategoryUrl(slug: string): string {
    return `/${slug}`
}

function getSubcategoryUrl(categorySlug: string, subcategorySlug: string) {
    return `/${categorySlug}/${subcategorySlug}`
}

export type MatchedCategory = {
    parentCategory?: Category
    category: Category
}

function findCategoryBySlug(categories: Category[], slug: string): MatchedCategory | undefined {
    let matchedCategory
    let matchedSubCategory

    const matchedMainCategory = categories.find((mainCategory) => {
        // return current main category without matched subCategory
        if (mainCategory.slug.toLowerCase() === slug.toLowerCase()) {
            return true
        }

        // try to find matched subCategory if no matched main category found
        if (mainCategory.subCategories !== undefined) {
            // find matched subCategory
            const foundSubCategory = mainCategory.subCategories.find((subCategory) => {
                return subCategory.slug.toLowerCase() === slug.toLowerCase()
            })
            // return current main category (which is parent of the matched subCategory)
            if (foundSubCategory !== undefined) {
                matchedSubCategory = foundSubCategory
                return true
            }
        }
        // return nothing if there's no matched main category or subCategory
        return false
    })

    if (matchedMainCategory !== undefined) {
        if (matchedSubCategory !== undefined) {
            matchedCategory = {
                parentCategory: matchedMainCategory,
                category: matchedSubCategory,
            }
        } else {
            matchedCategory = {
                category: matchedMainCategory,
            }
        }
    }
    return matchedCategory
}

function isSubcategory(state: ApplicationState, slug: string): boolean {
    const currentEvent = currentEventSelector(state)

    if (currentEvent !== undefined && currentEvent.categories !== undefined) {
        const matchedCategory = findCategoryBySlug(currentEvent.categories, slug)

        return !!(matchedCategory && matchedCategory.parentCategory)
    }

    return false
}

function getCategoryUrlFromCategories(categories: Category[], slug: string): string {
    const matchedCategory = findCategoryBySlug(categories, slug)

    let url = ''
    if (matchedCategory !== undefined) {
        if (matchedCategory.parentCategory !== undefined) {
            url = getSubcategoryUrl(matchedCategory.parentCategory.slug, matchedCategory.category.slug)
        } else {
            url = getCategoryUrl(matchedCategory.category.slug)
        }
    }
    return url
}

function filterToPagePath(pagePath: string, filterBrandSlugs: string[]) {
    if (filterBrandSlugs.length <= 0 || filterBrandSlugs.includes('SHOW_ALL')) {
        return pagePath
    }
    const baseUrl = new URL('http://dummy.com')
    const url = new URL(pagePath, baseUrl)
    const params = new URLSearchParams()
    const query = filterBrandSlugs.toString()
    params.append('brands', query)
    url.search = params.toString()
    return `${url.pathname}${url.search.toString()}`
}

function brandsQueryToArray(queryStr: string, brandSlugs: string[] | undefined = undefined) {
    let queryBrands
    let updatedQuery = queryStr
    if (updatedQuery.charAt(0) === '?') {
        updatedQuery = updatedQuery.substr(1)
    }
    const query = qs.parse(updatedQuery)
    if (query && query.brands) {
        queryBrands = query.brands.toString().split(',')
        if (brandSlugs !== undefined) {
            queryBrands = queryBrands.filter((slug: string) => brandSlugs.includes(slug))
        }
        return queryBrands
    }
    return []
}

function getCategoryDealsPageSize() {
    return process.env.REACT_APP_CATEGORY_DEALS_PER_PAGE !== undefined
        ? parseInt(process.env.REACT_APP_CATEGORY_DEALS_PER_PAGE, 10)
        : 60
}

export {
    getCategoryUrl,
    getSubcategoryUrl,
    getCategoryUrlFromCategories,
    findCategoryBySlug,
    isSubcategory,
    filterToPagePath,
    brandsQueryToArray,
    getCategoryDealsPageSize,
}
