import { connect } from 'react-redux'
import { ApplicationState } from '../../reducers'
import CategoryList, { FieldProps, MethodProps } from './CategoryList'
import { selectModalCategory, toggleCategoryMenu } from './actions'
import { currentEventSelector } from '../../data/selectors'
import Category from '../../models/category'
import { ModalProps, withModal } from '../../components/modal'

const mapStateToProps = (state: ApplicationState, ownProps: ModalProps): FieldProps & ModalProps => {
    const currentEvent = currentEventSelector(state)
    let categories: Category[] = []

    if (currentEvent !== undefined && currentEvent.categories !== undefined) {
        categories = currentEvent.categories.sort((a, b) => Number(a.sortOrder) - Number(b.sortOrder))
    }

    return {
        ...ownProps,
        categories,
        eventType: currentEvent ? currentEvent.type : undefined,
        menuIsOpen: state.category.categoryMenuOpen,
    }
}

const actions: MethodProps = {
    selectModalCategory,
    toggleCategoryMenu,
}

const CategoryListWithData = withModal(connect(mapStateToProps, actions)(CategoryList))
export default CategoryListWithData
