import * as React from 'react'
import classnames from 'classnames'
import { Icon, unchecked, checked as checkedIcon, uncheckedSquare, checkedSquare } from '..'
import './Checkbox.scss'
import { DictionaryValue } from '../dictionary-value'

type CheckboxPresentation = 'round' | 'square'
type CheckboxAlignment = 'left' | 'center'
type CheckboxType = 'default' | 'field'

type ComponentProps = {
    presentation?: CheckboxPresentation
    alignment?: CheckboxAlignment
    errorMessage?: string
    checkboxType?: CheckboxType
}

type InputProps = React.InputHTMLAttributes<HTMLInputElement>

type Props = ComponentProps & InputProps

class Checkbox extends React.Component<Props> {
    render() {
        const {
            children,
            // eslint-disable-next-line react/prop-types
            className,
            presentation = 'round',
            alignment = 'left',
            checkboxType = 'default',
            errorMessage,
            ...rest
        } = this.props
        const icon = presentation === 'round'
            ? rest.checked ? checkedIcon : unchecked
            : rest.checked ? checkedSquare : uncheckedSquare
        const classes = classnames(
            className,
            'checkbox',
            { 'checkbox--checked': rest.checked },
            `checkbox--presentation-${presentation}`,
            `checkbox--alignment-${alignment}`,
            { 'checkbox--error': errorMessage },
            `checkbox--type-${checkboxType}`
        )

        return (
            <>
                <label className={classes}>
                    <Icon className='checkbox__icon' icon={icon} />
                    <span className='checkbox__label'>
                        {children || rest.name}
                        { errorMessage && (
                            <span className='checkbox__error-message'>
                                <DictionaryValue token={errorMessage} />
                            </span>
                        )}
                    </span>
                    <input
                        className='checkbox__input'
                        type='checkbox'
                        {...rest}
                    />
                </label>
            </>
        )
    }
}

export { Checkbox }
