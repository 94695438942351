import { GraphQLResult } from '@aws-amplify/api'
import { Modal } from 'antd'
import { API, graphqlOperation } from 'aws-amplify'
import bind from 'bind-decorator'
import moment from 'moment'
import * as React from 'react'
import Observable from 'zen-observable'
import { awsToPhone, DictionaryValue, phoneToAws, PopupCard } from '../../../components'
import { createUserPopupAnswers } from '../../../graphql/mutations'
import * as subscriptions from '../../../graphql/subscriptions'
import { history } from '../../../history'
import EventType from '../../../models/eventType'
import { handleError } from '../../../utilities'
import { getCookie, setCookie } from '../../../utilities/cookies'
import { renderAllData } from '../../../utilities/graphql'
import { AddressFormat } from '../../account/types'
import './Popup.scss'

type PopupDetail = {
  id: string
  eventId: string
  title: string
  start: number
  end: number
  finish: number
  product: string
  price?: string
  rrp?: string
  img: string
  question: string
  usergroups: string[]
}

export type FieldProps = {
  loading: boolean
  isLoggedIn: boolean
  group?: string
  username?: string
  mobile?: string
  address?: AddressFormat
  eventType?: EventType
}

type Props = FieldProps

type State = {
  show: boolean
  answerSubmitted: boolean
  popup?: PopupDetail
  noAddress?: boolean
  noAddressModalShow?: boolean
}

class Popup extends React.Component<Props, State> {
  readonly state: State = {
    show: false,
    answerSubmitted: false,
    popup: undefined,
    noAddress: undefined,
    noAddressModalShow: undefined,
  }

  // eslint-disable-next-line @typescript-eslint/ban-types
  sub = API.graphql({ query: subscriptions.onCreatePopup, authMode: 'AWS_IAM' }) as unknown as Observable<object>

  subscription: ZenObservable.Subscription | null = null

  async componentDidMount() {
    await this.prepareSubscription()
    this.checkPopup()

    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore TODO: improve this
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    history.listen(async (location, action) => {
      if (
        location.pathname.toLowerCase() === '/terms-and-conditions' &&
        location.search.toLowerCase() === '?popup=true'
      ) {
        this.setState({ show: false })
      } else {
        await this.prepareSubscription()
        this.checkPopup()
      }
    })
  }

  async componentDidUpdate(prevProps: Props) {
    if (prevProps.address !== this.props.address) {
      this.setState({ noAddress: !this.props.address })
    }

    if (prevProps.isLoggedIn !== this.props.isLoggedIn && this.props.isLoggedIn) {
      await this.prepareSubscription()
      this.checkPopup()
    }
  }

  componentWillUnmount() {
    if (this.subscription !== null) {
      this.subscription.unsubscribe()
    }
  }

  @bind
  async prepareSubscription() {
    this.subscription = await this.sub.subscribe({
      next: (popupData: any) => {
        const popup: PopupDetail = popupData.value.data.onCreatePopup
        this.openPopup(popup)
      },
      error: (error: any) => {
        console.log(error)
        // handleError('Connecting error, please refresh the page to retry.')
      },
    })
  }

  @bind
  async checkPopup() {
    const livePopup = await renderAllData([], undefined, 'listPopups', null, null, !this.props.isLoggedIn)

    if (livePopup.length !== 0) {
      this.openPopup(livePopup[0])
    }
  }

  @bind
  async openPopup(popup: PopupDetail) {
    const { noAddress } = this.state

    if (getCookie('popupClosed')) {
      return
    }

    if (noAddress) {
      if (!getCookie('noAddressReminderClosed')) {
        this.setState({ noAddressModalShow: true })
      }
      return
    }

    const now = Math.floor(Date.now() / 1000)
    const diff = popup.start - now

    setTimeout(() => this.setState({ popup, show: true }), diff * 1000)

    const finishTimeout = Math.min(popup.finish - now, popup.finish - popup.start)
    if (finishTimeout > 0) {
      setTimeout(() => {
        this.closePopup()
      }, finishTimeout * 1000)
    } else {
      this.closePopup()
    }
  }

  @bind
  closePopup() {
    const { popup } = this.state
    this.setState({
      show: false,
      popup: undefined,
    })

    const now = Math.floor(Date.now() / 1000)
    if (popup) {
      const expireTime = (popup.end - now) * 1000
      setCookie('popupClosed', true, expireTime)
    }
  }

  @bind
  onCancel(e: React.MouseEvent) {
    e.preventDefault()
    this.closePopup()
  }

  @bind
  async handleSubmit(answer: string, mobile: string, email: string) {
    const { username, isLoggedIn } = this.props
    const { popup } = this.state

    const response: any = !isLoggedIn
      ? ((await API.graphql({
          query: createUserPopupAnswers,
          authMode: 'AWS_IAM',
          variables: {
            input: {
              answer,
              username: '',
              mobile: process.env.REACT_APP_REGION === 'NZ' ? mobile : phoneToAws(mobile),
              email,
              popupId: popup && popup.id,
              product: popup && popup.product,
              question: popup && popup.question,
              answeredAt: moment(),
            },
          },
        })) as any)
      : ((await API.graphql(
          graphqlOperation(createUserPopupAnswers, {
            input: {
              answer,
              username,
              mobile: process.env.REACT_APP_REGION === 'NZ' ? mobile : phoneToAws(mobile),
              email,
              popupId: popup && popup.id,
              product: popup && popup.product,
              question: popup && popup.question,
              answeredAt: moment(),
            },
          })
        )) as GraphQLResult)

    if (response.data && response.data !== null) {
      this.setState({ answerSubmitted: true })
      setTimeout(() => {
        this.closePopup()
        this.setState({ answerSubmitted: false })
      }, 15000)
    } else {
      handleError(response.errors)
    }
  }

  @bind
  handleNoAddressOk(e: React.MouseEvent) {
    e.preventDefault()
    const { popup } = this.state
    const now = Math.floor(Date.now() / 1000)
    let expireTime = 86400 * 1000
    if (popup) {
      expireTime = (popup.end - now) * 1000
    }
    setCookie('noAddressReminderClosed', true, expireTime)
    this.setState({
      noAddressModalShow: false,
    })
    history.replace('/account')
  }

  @bind
  handleNoAddressCancel(e: React.MouseEvent) {
    e.preventDefault()
    this.setState({
      noAddressModalShow: false,
    })
    const { popup } = this.state
    const now = Math.floor(Date.now() / 1000)
    if (popup) {
      const expireTime = (popup.end - now) * 1000
      setCookie('noAddressReminderClosed', true, expireTime)
    } else {
      setCookie('noAddressReminderClosed', true, 86400 * 1000)
    }
  }

  @bind
  decryptCall(data: any, time?: number) {
    return API.post('clickfrenzyFeDecrypt', '/key', {
      body: {
        data,
        time,
      },
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
    }).catch(err => {
      setTimeout(() => {
        this.decryptCall(data)
      }, 1000)
    })
  }

  render() {
    const { loading, mobile, eventType } = this.props
    const { show, answerSubmitted, popup, noAddress, noAddressModalShow } = this.state

    return (
      <div className='cfa-popup'>
        {popup && eventType && !noAddress && (
          <Modal
            centered={true}
            open={show && !loading}
            onCancel={this.onCancel}
            className={`cfa-popup__modal cfa-popup__modal--${eventType}`}
            width={800}
            footer={[]}
          >
            <PopupCard
              type={eventType}
              popupTitle={popup.title}
              productName={popup.product}
              productImage={popup.img}
              productPrice={popup.price}
              productRRP={popup.rrp}
              popupQuestion={popup.question}
              popupTime={popup.finish}
              handleSubmit={this.handleSubmit}
              answerSubmitted={answerSubmitted}
              mobile={awsToPhone(mobile)}
            />
          </Modal>
        )}
        {noAddress && noAddressModalShow && (
          <Modal
            centered={true}
            open={noAddress && noAddressModalShow && !loading}
            className={`cfa-popup__modal cfa-popup__modal--${eventType} cfa-popup__modal--noAddress`}
            okText='Edit Profile Now'
            onOk={this.handleNoAddressOk}
            onCancel={this.handleNoAddressCancel}
            okButtonProps={{ size: 'large' }}
            cancelButtonProps={{ size: 'large' }}
          >
            <h1>
              <DictionaryValue token={'popup.noaddress.title'} />
            </h1>
            <p>
              <DictionaryValue token={'popup.noaddress.content'} />
            </p>
          </Modal>
        )}
      </div>
    )
  }
}

export default Popup
