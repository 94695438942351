import * as React from 'react'
import DealPlacement from '../../models/dealPlacement'
import { Grid, DictionaryValue, Button } from '../../components'
import CardWithData from '../deal/CardWithData'

type Props = {
  deals: DealPlacement[]
}

class FavouritePage extends React.Component<Props> {
  render() {
    const { deals } = this.props

    return (
      <Grid>
        <div className='row'>
          <div className='col-xs-12'>
            <h1 className='favourite-page__title'>
              <DictionaryValue token='favourite.title' />
            </h1>
          </div>
        </div>
        <div className='row'>
          {deals.length > 0 ? (
            <>
              {deals.map(({ slug, type }) => (
                <div
                  className='col-xs-12 col-sm-6 col-md-3 list-deal'
                  key={slug}
                >
                  <CardWithData
                    slug={slug}
                    type={type}
                  />
                </div>
              ))}
            </>
          ) : (
            <>
              <div className='favourite-page__text col-xs-12'>
                <DictionaryValue token='favourite.nodeal' />
              </div>
              <div className='col-xs-12 col-sm-6 col-sm-offset-3 col-lg-3 col-lg-offset-4'>
                
                <Button
                  buttonType='primary'
                  buttonStyle='square'
                  size='large'
                  type='button'
                  redirectUrl='/'
                  display='block'
                  className='favourite-page__button'
                >
                  <DictionaryValue token='favourite.buttontext' />
                </Button>
              </div>
            </>
          )}
        </div>
      </Grid>
    )
  }
}

export { FavouritePage }
