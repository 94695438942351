import actionCreatorFactory from 'typescript-fsa'
import { ApplicationState } from '../../../reducers'
import { itemLoading, itemLoaded } from '../loading/actions'
import {
  loadEvents as getEvents,
  loadEventExtraData as getEventExtraData,
  loadNonEventConfig,
} from '../../../services/Api'
import { eventsLoaded, eventDetailsLoaded, nonEventConfigLoaded } from '../../../data/actions'
import Event from '../../../models/event'
import { currentEventFromEventsSelector, isEventRunning } from '../../../data/selectors'
import { getFavDealsForUser } from '../../account/utilities'
import { updateAccount } from '../../account/actions'

function loadEvents(isByTimer = false, isLoggedIn = false) {
  return async (dispatch: any, getState: () => ApplicationState) => {
    if (!isByTimer) {
      dispatch(itemLoading())
    }
    try {
      let events = await getEvents()
      const state = getState()

      // Change the start times according to user group
      if (isLoggedIn) {
        const group = state.account.group
        events = events.map(event => {
          if (event.ultraEarlyStart && group && event.ultraEarlyGroups.includes(group)) {
            event.start = event.ultraEarlyStart
          } else if (
            event.earlyBirdStart &&
            (event.earlyBirdGroups.length === 0 || (group && event.earlyBirdGroups.includes(group)))
          ) {
            event.start = event.earlyBirdStart
          }
          return event
        })
      }

      if (state.account.username) {
        const favDeals = await getFavDealsForUser(state.account.username)
        dispatch(
          updateAccount({
            favDeals,
          })
        )
      }

      dispatch(eventsLoaded(events))
      // const event = currentEventFromEventsSelector(events, state.status.networkTime)
      // const running = await isEventRunning(event, state.status.networkTime)
      const event = currentEventFromEventsSelector(events)
      const running = await isEventRunning(event)
      if (event !== undefined && running) {
        dispatch(eventStarted(event))
      } else {
        dispatch(noEventRunning())

        // load nonEventRunning states
        if (!state.status.nonEventConfigLoaded) {
          try {
            const homepageData = await loadNonEventConfig()
            dispatch(nonEventConfigLoaded(homepageData))
          } catch (e) {
            // don't error when we fail to load the data
          }
        }
      }
    } catch (e) {
      if (!isByTimer) {
        dispatch(itemLoaded())
      }
      return
    }
    if (!isByTimer) {
      dispatch(itemLoaded())
    }
  }
}

function eventStarted(currentEvent: Event) {
  return async (dispatch: any, getState: () => ApplicationState) => {
    dispatch(eventRunning())
    dispatch(itemLoading())
    try {
      // load event categories & collections
      const updatedCurrentEvent = await getEventExtraData(currentEvent.slug).then(async eventExtra => {
        if (eventExtra.categories !== undefined) {
          currentEvent.categories = eventExtra.categories.map(eventCategory => {
            return {
              slug: eventCategory.slug,
              name: eventCategory.name,
              sortOrder: eventCategory.sortOrder,
              featuredBrands: eventCategory.featuredBrands,
              featuredDealSlug: eventCategory.featuredDealSlug,
              brands: eventCategory.brands,
              subCategories:
                eventCategory.subCategories === undefined
                  ? []
                  : eventCategory.subCategories.map(subCategory => {
                      return {
                        slug: subCategory.slug,
                        name: subCategory.name,
                        sortOrder: subCategory.sortOrder,
                      }
                    }),
            }
          })
        }

        currentEvent.interstitialPageBanner = eventExtra.interstitialPageBanner
        currentEvent.topBanner = eventExtra.topBanner
        currentEvent.partners = eventExtra.partners
        currentEvent.interstitialPagePartners = eventExtra.interstitialPagePartners
        currentEvent.homepageFeaturedBrands = eventExtra.homepageFeaturedBrands
        currentEvent.homepageTopRetailers = eventExtra.homepageTopRetailers
        currentEvent.categoryFeaturedBrands = eventExtra.categoryFeaturedBrands
        currentEvent.menu = eventExtra.menu
        currentEvent.searchFeaturedDeal = eventExtra.searchFeaturedDeal
        currentEvent.searchFeaturedBrands = eventExtra.searchFeaturedBrands
        currentEvent.membershipBenefits = eventExtra.membershipBenefits
        currentEvent.collections = eventExtra.collections
        return currentEvent
      })

      const updatedEvents = getState().data.events.map(event => {
        if (updatedCurrentEvent.slug === event.slug) {
          return updatedCurrentEvent
        }
        return event
      })
      // dispatch data is loaded
      dispatch(eventDetailsLoaded(updatedEvents))
    } catch (e) {
      dispatch(itemLoaded())
    }
    dispatch(itemLoaded())
  }
}

function updateNetworkTime(time: number | undefined) {
  return (dispatch: any, getState: () => ApplicationState) => {
    if (time) {
      dispatch(networkTimeUpdated(time))
      return
    }
    dispatch(networkTimeUpdated(getState().status.networkTime))
  }
}

const actionCreator = actionCreatorFactory('STATUS')
const eventRunning = actionCreator('EVENT_RUNNING')
const noEventRunning = actionCreator('NO_EVENT_RUNNING')
const networkTimeUpdated = actionCreator<number>('NETWORK_TIME_UPDATED')

export { loadEvents, eventStarted, eventRunning, noEventRunning, networkTimeUpdated, updateNetworkTime }
