import * as React from 'react'
import Category from '../../models/category'
import { ModalProps, Modal } from '../../components/modal'
import { DictionaryValue } from '../../components/dictionary-value'
import './CategoryModal.scss'
import { getCategoryUrl, getSubcategoryUrl } from './utilities'
import CategoryModalCategoryItem from './CategoryModalCategoryItem'
import classNames from 'classnames'
import { FeaturedBrandsWithData } from '../brand/featured-brands'
import CardWithData from '../deal/CardWithData'
import bind from 'bind-decorator'

export type FieldProps = {
  category?: Category
}

type Props = FieldProps & ModalProps

class CategoryModal extends React.Component<Props> {
  @bind
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  handleLinkClick(e: React.MouseEvent) {
    this.props.toggleModal()
  }

  render() {
    const { category, isModalOpen, toggleModal } = this.props

    if (!category) {
      return null
    }

    const categoryClasses = classNames(
      'modal__column-header',
      'category-modal__column-header',
      'category-modal__column-header--column-category'
    )

    const brandsDealClasses = classNames(
      'modal__column-header',
      'category-modal__column-header',
      'category-modal__column-header--column-brands-deal'
    )

    return (
      <Modal
        isOpen={isModalOpen}
        onRequestClose={toggleModal}
        size='responsive'
        showCloseButton={true}
      >
        <div className='category-modal'>
          <div className='category-modal__column'>
            <div className='category-modal__column-category-name'>
              <h2 className={categoryClasses}>{category.name}</h2>
            </div>
            <ul className='category-modal__categories'>
              <CategoryModalCategoryItem
                url={getCategoryUrl(category.slug)}
                handleLinkClick={this.handleLinkClick}
              >
                All
              </CategoryModalCategoryItem>
              {category.subCategories &&
                category.subCategories.map(subcategory => (
                  <CategoryModalCategoryItem
                    key={subcategory.slug}
                    url={getSubcategoryUrl(category.slug, subcategory.slug)}
                    handleLinkClick={this.handleLinkClick}
                  >
                    {subcategory.name}
                  </CategoryModalCategoryItem>
                ))}
            </ul>
          </div>
          <div className='category-modal__column'>
            <h2 className={brandsDealClasses}>
              <DictionaryValue token='modal.column.featured-brands' />
            </h2>
            <FeaturedBrandsWithData location='category' />
          </div>
          <div className='category-modal__column'>
            <h2 className={brandsDealClasses}>
              <DictionaryValue token='modal.column.featured-deal' />
              {category.featuredDealSlug && category.featuredDealSlug.length > 0 && (
                <div className='category-modal__featured-deal'>
                  <CardWithData
                    slug={category.featuredDealSlug.sort((a, b) => Number(a.position) - Number(b.position))[0].slug}
                    showHover={false}
                    className='category-modal__featured-deal-card'
                  />
                </div>
              )}
            </h2>
          </div>
        </div>
      </Modal>
    )
  }
}

export default CategoryModal
