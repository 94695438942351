import React from 'react'
import { connect } from 'react-redux'
import { ApplicationState } from '../../../reducers'
import Category from '../../../models/category'
import { categoriesForCurrentEventSelector } from '../../../data/selectors'
import { getCategoryUrl } from '../../category'
import { Link } from '../../../components/link'
import './SearchCategories.scss'

type Props = {
    categories: Category[]
}

class SearchCategories extends React.Component<Props> {
    render() {
        return (
            <ul className='search-categories'>
                {this.props.categories.map(category => (
                    <li key={category.slug} className='search-categories__link-wrapper'>
                        <Link
                            className='search-categories__link'
                            to={getCategoryUrl(category.slug)}
                        >
                            {category.name}
                        </Link>
                    </li>
                ))}
            </ul>
        )
    }
}

const mapStateToProps = (state: ApplicationState) => {
    const categories = categoriesForCurrentEventSelector(state)

    return {
        categories,
    }
}

const CategoryListWithData = connect(mapStateToProps)(SearchCategories)

export { SearchCategories, CategoryListWithData }
