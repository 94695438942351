import * as React from 'react'
import { DictionaryValue, EmailField, PasswordField } from '../../components'
import bind from 'bind-decorator'
import { AccountForm } from './AccountForm'
import { FederatedLogin } from './FederatedLogin'

type Props = {
    error?: Error
    handleSubmit: (event: React.FormEvent<HTMLFormElement>) => void
    showSignUp: () => void
    showForgotPassword: () => void
}

class Login extends React.Component<Props> {
    @bind
    showSignUp(e: React.MouseEvent) {
        e.preventDefault()
        this.props.showSignUp()
        window.scrollTo(0, 0)
    }

    @bind
    showForgotPassword(e: React.MouseEvent) {
        e.preventDefault()
        this.props.showForgotPassword()
        window.scrollTo(0, 0)
    }

    render() {
        const { handleSubmit, error } = this.props

        return (
            <AccountForm
                handleSubmit={handleSubmit}
                error={error}
                heading='login.heading'
                intro={(
                    <>
                        <p>
                            <DictionaryValue token='login.no-account' />{' '}
                            <a
                                onClick={this.showSignUp}
                            >
                                <DictionaryValue token='login.sign-up' />
                            </a>
                        </p>
                        {process.env.REACT_APP_REGION === 'AU' && <FederatedLogin />}
                    </>
                )}
                ctaLabel='login.submit'
                footer={(
                    <a onClick={this.showForgotPassword}>
                        <DictionaryValue token='login.forgot-password' />
                    </a>
                )}
                fieldList={[
                    <EmailField name='email' placeholder={'login.field.email'} key='email' validateFormat={false} />,
                    (
                        <PasswordField
                            name='password'
                            placeholder={'login.field.password'}
                            key='password'
                            validateFormat={false}
                        />
                    ),
                ]}
            />
        )
    }
}

export { Login }
