import * as React from 'react'
import { Route, Switch } from 'react-router'
import { ConnectedRouter } from 'connected-react-router'
import { history } from '../../../history'
import { isProduction } from '../../../utilities'
import { NonEventHomeWithData } from '../../home/standard-site'
import Styleguide from '../../styleguide/Styleguide'
import { PageWithData } from '../../page'
import { Finalist } from '../../custom-page/finalist/Finalist'
import { AccountArea, UploadArea } from '../../account'
import { Subscribe } from '../../custom-page/subscribe/Subscribe'
import { LayoutBlock } from '../../page/builder-components/Layout'
import CfReceiptWithData from '../../cf-receipt/CfReceiptWithData'
import NotFound from '../../NotFound'
import FooterLink from '../../../models/footerLink'

type Props = {
  isEventRunning: boolean
  links?: FooterLink[]
}

class StandardSite extends React.Component<Props> {
  render() {
    const { isEventRunning, links } = this.props
    return (
      <ConnectedRouter history={history}>
        <Switch>
          <Route
            exact={true}
            path='/'
            component={NonEventHomeWithData}
          />
          {!isProduction ? (
            <Route
              exact={true}
              path='/style-guide'
              component={Styleguide}
            />
          ) : null}
          <Route
            exact={true}
            path='/account'
            render={() => (
              <AccountArea
                isEventRunning={isEventRunning}
                links={links}
              />
            )}
          />
          <Route
            exact={true}
            path='/account/upload'
            component={UploadArea}
          />
          <Route
            exact={true}
            path='/ironshopper'
            component={Finalist}
          />
          <Route
            exact={true}
            path='/subscribe'
            component={Subscribe}
          />
          <Route
            exact={true}
            path='/clickfrenzyreceipt'
            component={
              process.env.REACT_APP_REGION && process.env.REACT_APP_REGION === 'AU' ? CfReceiptWithData : NotFound
            }
          />
          <Route
            path='/c/layout'
            exact={false}
            component={LayoutBlock}
          />
          <Route
            path='/:slug'
            component={PageWithData}
          />
        </Switch>
      </ConnectedRouter>
    )
  }
}

export default StandardSite
