import * as React from 'react'
import { SignUp } from './SignUp'
import './SignUpWithBenefits.scss'
import MembershipBenefitsWithData from './MembershipBenefitsWithData'

type Props = {
    error?: Error
    handleSubmit: (event: React.FormEvent<HTMLFormElement>) => void
}

class SignUpWithBenefits extends React.Component<Props> {
    render() {
        const { handleSubmit, error } = this.props
        return (
        <div className='row signUpWithBenifitsGrid'>
            <div className='signUpGrid col-md-6'>
                <SignUp handleSubmit={handleSubmit} error={error} />
            </div>
            <div className='benifitsGrid col-md-6'>
                <MembershipBenefitsWithData />
            </div>
        </div>
        )
    }

}

export { SignUpWithBenefits }
