import * as React from 'react'
import { Field } from 'react-final-form'
import { InputField } from './InputField'
import { required, composeValidators, email } from '../validations'

type Props = {
  name: string
  placeholder: string
  validateFormat?: boolean
  disabled?: boolean
}

class EmailField extends React.Component<Props> {
  render() {
    const { name, placeholder, validateFormat = true, disabled } = this.props

    let validations = required(name)

    if (validateFormat) {
      validations = composeValidators(required(name), email(name))
    }

    return (
      <Field<string>
        name={name}
        component={InputField}
        placeholder={placeholder}
        type='email'
        validate={validations}
        readOnly={disabled}
      />
    )
  }
}

export { EmailField }
