import { FilterByBrand, FieldProps, MethodProps } from './FilterByBrand'
import { connect } from 'react-redux'
import { ApplicationState } from '../../reducers'
import Brand from '../../models/brand'
import { brandsQueryToArray } from './utilities'
import { resetCategoryPage } from './actions'

type OwnProps = {
    brands: Brand[]
    toggleDropdown: () => void
    dropdownStatus: boolean
}

const mapStateToProps = (state: ApplicationState, ownProps: OwnProps): FieldProps => {
    const brands = ownProps.brands
    const selectedBrands = brandsQueryToArray(state.router.location.search, brands.map(brand => brand.slug))

    return {
        brands,
        filterBrands: selectedBrands,
        location: state.router.location,
        toggleDropdown: ownProps.toggleDropdown,
        dropdownStatus: ownProps.dropdownStatus,
    }
}

const actions: MethodProps = {
    resetPage: resetCategoryPage,
}

const FilterByBrandWithData = connect(mapStateToProps, actions)(FilterByBrand)
export { FilterByBrandWithData }
