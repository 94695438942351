// SVG ICON : ClockIco
import React from 'react'

type Props = {
  color?: string
}

class ClockIco extends React.Component<Props> {
  render() {
    const { color } = this.props

    return (
      <svg
        width='33'
        height='32'
        viewBox='0 0 33 32'
        fill='none'
        xmlns='http://www.w3.org/2000/svg'
      >
        <path
          d='M16.7565 31.0003C24.8567 31.0003 31.4232 24.4338 31.4232 16.3337C31.4232 8.23348 24.8567 1.66699 16.7565 1.66699C8.65633 1.66699 2.08984 8.23348 2.08984 16.3337C2.08984 24.4338 8.65633 31.0003 16.7565 31.0003Z'
          stroke={color || '#E9028D'}
          strokeMiterlimit='10'
          strokeLinecap='square'
        />
        <path
          d='M16.7578 7V16.3333H26.0911'
          stroke={color || '#E9028D'}
          strokeMiterlimit='10'
          strokeLinecap='square'
        />
      </svg>
    )
  }
}

export default ClockIco
