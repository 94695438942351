import * as React from 'react'
import classnames from 'classnames'
import { connect, DispatchProp } from 'react-redux'
import { ApplicationState } from '../../reducers'
import { dictionaryValueSelector } from '../../data/selectors'
import './ClearInput.scss'

type Props = React.InputHTMLAttributes<HTMLInputElement> & {
    inputRef?: (element: HTMLInputElement) => void
}

type PropsWithDispatch = Props & DispatchProp

class InputComponent extends React.Component<Props> {
    render() {
        // eslint-disable-next-line
        const { dispatch, inputRef, className, ...rest } = this.props as PropsWithDispatch
        const classes = classnames(
            'clear-input',
            className
        )

        return <input className={classes} ref={inputRef} {...rest} />
    }
}

const mapStateToProps = (state: ApplicationState, ownProps: Props) => ({
    placeholder: dictionaryValueSelector(ownProps.placeholder || '', state),
})

const ClearInput = connect(mapStateToProps)(InputComponent)

export { ClearInput }
