import { SearchTerm } from '../features/search/types'

const RECENT_SEARCH_TERMS_KEY = 'CF-RECENT-SEARCH-TERMS'

function generateLocalStorage<T>(key: string, defaultContent: T): { load: () => T; save: (content: T) => void } {
  return {
    load: () => {
      try {
        const content = localStorage.getItem(key) || ''

        return JSON.parse(content)
      } catch (e) {
        return defaultContent
      }
    },
    save: (content: T) => {
      try {
        const termsAsString = JSON.stringify(content)

        localStorage.setItem(key, termsAsString)
        // eslint-disable-next-line no-empty
      } catch (e) {}
    },
  }
}

const { load: getRecentSearchTerms, save: setRecentSearchTerms } = generateLocalStorage<SearchTerm[]>(
  RECENT_SEARCH_TERMS_KEY,
  []
)

export { getRecentSearchTerms, setRecentSearchTerms }
