import { Action } from 'redux'
import { isType } from 'typescript-fsa'
import { itemLoading, itemLoaded } from './actions'
import { ApplicationState } from '../../../reducers'

export type State = {
    count: number
    isInitialLoading: boolean
}

const initialState: State = {
    count: 0,
    isInitialLoading: true,
}

export function loadingReducer(state: State = initialState, action: Action): State {
    if (isType(action, itemLoading)) {
        return {
            ...state,
            count: state.count + 1,
            isInitialLoading: false,
        }
    }

    if (isType(action, itemLoaded)) {
        return {
            ...state,
            count: state.count - 1,
            isInitialLoading: false,
        }
    }

    return state
}

export function isLoading(state: ApplicationState): boolean {
    return state.loading.isInitialLoading || state.loading.count > 0
}
