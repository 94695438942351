import moment from 'moment'

const getCurrentTime = async () => {
  try {
    const response = await fetch(`${process.env.REACT_APP_AUTH_CALLBACK_URL}`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        'Access-Control-Allow-Origin': '*',
      },
    })
    const dateString = response.headers.get('Date')
    return moment(dateString).local()
  } catch (error) {
    console.log('error', error)
    return moment()
  }
}

export default getCurrentTime
