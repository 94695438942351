import * as React from 'react'
import './BrandDetailAdditionalSummary.scss'
import { convertStringToReactComponents, transformOpenAllAnchorsInNewWindow } from '../../utilities/html'
import { ModalProps } from '../../components/modal/types'
import { HTMLBlock } from '../../components/html-block'
import { ImageAndTitle } from '../../components/image-and-title'
import { Modal, withModal } from '../../components/modal'
import EventType from '../../models/eventType'

type Props = {
    title: JSX.Element | string
    brandName: string
    brandLogo: string
    className?: string
    content?: string
    eventType?: EventType | null
} & ModalProps

class BrandDetailAdditionalSummary extends React.Component<Props> {
    render() {
        const { className, title, content = '', brandName, brandLogo, toggleModal, isModalOpen, eventType } = this.props

        const formattedContent = (
            <HTMLBlock fontSize={'sm'}>
                {convertStringToReactComponents(content, transformOpenAllAnchorsInNewWindow)}
            </HTMLBlock>
        )

        const modalHeader = (
            <>
                <ImageAndTitle
                    className='brand-detail-additional-summary__modal-logo-and-name'
                    imageURL={brandLogo}
                    title={brandName}
                    padTitleOnMobile={true}
                />
                <h3 className='brand-detail-additional-summary__modal-title'>{title}:</h3>
            </>
        )

        return (
            <>
                <div className={`brand-detail-additional-summary ${className}`}>
                    <h2
                        className={`brand-detail-additional-summary__title brand-detail-additional-summary__${eventType}`}
                        onClick={toggleModal}
                    >
                        {title}
                    </h2>

                </div>
                <Modal
                    isOpen={isModalOpen}
                    onRequestClose={toggleModal}
                    padding='large'
                    header={modalHeader}
                    body={formattedContent}
                />
            </>
        )
    }
}

export default withModal(BrandDetailAdditionalSummary)
