import * as React from 'react'
import bind from 'bind-decorator'
import { getCurrentTargetScrollBarWidth } from 'scroll-lock'
import './GradientContainer.scss'

type Props = {
    className?: string
    visible?: boolean
}

type State = {
    isVisible: boolean
}

const GRADIENT_MULTIPLIER = 4

class GradientContainer extends React.Component<React.PropsWithChildren<Props>, State> {
    public static defaultProps = {
        className: '',
        visible: true,
    }

    public state = {
        isVisible: false,
    }

    private bodyRef = React.createRef<HTMLDivElement>()
    private contentRef = React.createRef<HTMLDivElement>()
    private topGradientRef = React.createRef<HTMLDivElement>()
    private bottomGradientRef = React.createRef<HTMLDivElement>()

    private observer: IntersectionObserver | undefined

    @bind
    setOpacityForGradients() {
        setTimeout(() => {
            const topGradientEl = this.topGradientRef.current
            const bottomGradientEl = this.bottomGradientRef.current
            const contentEl = this.contentRef.current

            if (!topGradientEl || !bottomGradientEl || !contentEl) {
                return
            }

            const scrollPosition = contentEl.scrollTop
            const topGradientOpacity = (scrollPosition / 100) * GRADIENT_MULTIPLIER
            const { clientHeight, scrollHeight } = contentEl
            const bottomGradientOpacity = ((scrollHeight - clientHeight - scrollPosition) / 100) * GRADIENT_MULTIPLIER

            topGradientEl.style.opacity = `${topGradientOpacity > 1 ? 1 : topGradientOpacity}`
            bottomGradientEl.style.opacity = `${bottomGradientOpacity > 1 ? 1 : bottomGradientOpacity}`
        })
    }

    @bind
    handleContentScroll() {
        this.setOpacityForGradients()
    }

    @bind
    resizeContent() {
        const bodyEl = this.bodyRef.current
        const contentEl = this.contentRef.current
        const topGradientEl = this.topGradientRef.current
        const bottomGradientEl = this.bottomGradientRef.current

        if (bodyEl && contentEl) {
            contentEl.style.height = 'auto'
            contentEl.style.height = `${bodyEl.clientHeight}px`
        }

        if (contentEl && topGradientEl && bottomGradientEl) {
            const scrollBarWith = getCurrentTargetScrollBarWidth(contentEl)
            const gradientWidth = `calc(100% - ${scrollBarWith}px`

            topGradientEl.style.width = gradientWidth
            bottomGradientEl.style.width = gradientWidth
        }
    }

    @bind
    handleVisibilityChange(entries: IntersectionObserverEntry[]) {
        const { isVisible } = this.state
        const [bodyEntry] = entries

        if (!bodyEntry) {
            return
        }

        if (bodyEntry.isIntersecting !== isVisible) {
            this.resizeContent()
            this.setOpacityForGradients()
        }

        this.setState({
            isVisible: bodyEntry.isIntersecting,
        })
    }

    @bind
    observeVisibility() {
        const bodyEl = this.bodyRef.current

        if (!bodyEl) {
            return
        }

        this.observer = new IntersectionObserver(this.handleVisibilityChange)
        this.observer.observe(bodyEl)
    }

    componentDidMount() {
        this.resizeContent()
        this.setOpacityForGradients()
        this.observeVisibility()

        const contentEl = this.contentRef.current

        if (contentEl) {
            contentEl.addEventListener('scroll', this.handleContentScroll)
        }

        window.addEventListener('resize', this.resizeContent)
    }

    componentWillUnmount() {
        const contentEl = this.contentRef.current

        if (contentEl) {
            contentEl.removeEventListener('scroll', this.handleContentScroll)
        }

        if (this.observer) {
            this.observer.disconnect()
        }

        window.removeEventListener('resize', this.resizeContent)
    }

    render() {
        const { className, children } = this.props

        return (
            <div className={`gradient-container ${className}`} ref={this.bodyRef}>
                <div className='gradient-container__content' ref={this.contentRef} data-scroll-lock-scrollable={true}>
                    {children}
                </div>
                <div
                    className='gradient-container__gradient gradient-container__gradient--top'
                    ref={this.topGradientRef}
                />
                <div
                    className='gradient-container__gradient gradient-container__gradient--bottom'
                    ref={this.bottomGradientRef}
                />
            </div>
        )
    }
}

export { GradientContainer }
