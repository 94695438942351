import React, { Component } from 'react'
import { Grid } from '../../components'
import EventsCountdownCard from './EventsCountdownCard'
import Event from '../../models/event'
import './EventsCountdown.scss'

export type Props = {
    events: Event[]
}

class EventsCountdown extends Component<Props> {
    render() {
        const { events } = this.props

        if (events.length === 0) {
            return null
        }

        return (
            <Grid className='cf-events-countdown'>
                <div className='row'>
                    {events.map((event: Event) => (
                        <EventsCountdownCard key={event.slug} event={event} />
                    ))}
                </div>
            </Grid>
        )
    }
}

export default EventsCountdown
