import React from 'react'
import makeCountdown from '../../properties/makeCountdown'

type Props = {
  hours: number
  minutes: number
  seconds: number
}

class ExclusiveCountdownComponent extends React.Component<Props> {
  render() {
    const { hours, minutes, seconds } = this.props
    const format = new Intl.NumberFormat('en-AU', { style: 'decimal', minimumIntegerDigits: 2 }).format

    return (
      <div>
        <span>{format(hours)}</span>:<span>{format(minutes)}</span>:<span>{format(seconds)}</span>
      </div>
    )
  }
}

const ExclusiveCountdown = makeCountdown(ExclusiveCountdownComponent)
export default ExclusiveCountdown
