import * as React from 'react'
import './style.scss'
import makeCountdown from '../../../properties/makeCountdown'

type Props = {
  days: number
  hours: number
  minutes: number
  seconds: number
  variant?: string
}

class CFCountdown extends React.Component<Props> {
  render() {
    const { days, hours, minutes, seconds, variant } = this.props
    const format = new Intl.NumberFormat('en-AU', { style: 'decimal', minimumIntegerDigits: 2 }).format

    return (
      <div className='count-down-blk cf-flex flex-row'>
        <div className='c-cell'>
          <div className='c-number'>{format(days)}</div>
          <div className='c-txt'>Days</div>
        </div>
        <div className='c-cell'>
          <div className='c-number'>{format(hours % 24)}</div>
          <div className='c-txt'>Hours</div>
        </div>
        {variant !== 'tiny' && (
          <div className='c-cell'>
            <div className='c-number'>{format(minutes)}</div>
            <div className='c-txt'>Mins</div>
          </div>
        )}
        {variant !== 'tiny' && (
          <div className='c-cell'>
            <div className='c-number'>{format(seconds)}</div>
            <div className='c-txt'>Secs</div>
          </div>
        )}
      </div>
    )
  }
}

export default makeCountdown(CFCountdown)
