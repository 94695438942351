import { ApplicationState } from '../../reducers'
import { connect } from 'react-redux'
import ThreeColumnImageText, { Props } from './ThreeColumnImageText'
import { membershipBenefitsForCurrentEventSelector } from '../../data/selectors'

const mapStateToProps = (state: ApplicationState, currentProps: Pick<Props, 'eventType' >): Props => ({
    ...currentProps,
    threeColumnData: membershipBenefitsForCurrentEventSelector(state),
})

const MembershipBenefitsForCurrentEventWithData = connect(mapStateToProps)(ThreeColumnImageText)

export { MembershipBenefitsForCurrentEventWithData }
