import React from 'react'
import { connectInfiniteHits, HitsProvided } from 'react-instantsearch-core'
import { SearchHit } from '../types'
import { DictionaryValue } from '../../../components/dictionary-value'
import classnames from 'classnames'
import { SearchResultsProgress } from './SearchResultsProgress'
import './SearchFooter.scss'
import { Button } from '../../../components/button'

type OwnProps = {
    className?: string
} & HitsProvided<SearchHit>

type MethodProps = {
    refineNext: () => void
    hasMore: boolean
}

type Props = OwnProps & MethodProps

class SearchFooterView extends React.Component<Props> {
    render() {
        const { className, refineNext, hasMore } = this.props
        const footerClasses = classnames(
            'search-footer',
            className
        )
        const loadMoreClasses = classnames(
            'search-footer__load-more',
            { 'search-footer__load-more--visible': hasMore }
        )

        return (
            <div className={footerClasses}>
                <SearchResultsProgress />
                <Button
                    buttonType='primary'
                    display='block'
                    size='large'
                    buttonStyle='square'
                    className={loadMoreClasses}
                    callback={refineNext}
                >
                    <DictionaryValue token='search.results.load-more' />
                </Button>
            </div>
        )
    }
}
const SearchFooter = connectInfiniteHits<Props, SearchHit>(SearchFooterView)

export { SearchFooter, SearchFooterView }
