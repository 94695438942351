import * as React from 'react'
import {
  Grid,
  DictionaryValue,
  Button,
  EmailField,
  NameField,
  MobileField,
  GenderFieldWithData,
  EmailsField,
  phoneToAws,
  PasswordField,
  CountdownText,
  CodeField,
  AddressFieldWithData,
} from '../../components'
import './ProfilePage.scss'
import bind from 'bind-decorator'
import { Form, AnyObject } from 'react-final-form'
import { Auth } from '@aws-amplify/auth'
import { FORM_ERROR } from 'final-form'
// import { updateEmarsysContact } from '../../utilities'
import { updateBrazeUser } from '../../utilities/braze'
import { AddressFormat, BlankAddress } from './types'
import { UserActionInput } from './actions'

export type FieldProps = {
  username: string
  firstName: string
  lastName: string
  gender: string
  email: string
  mobile: string
  address?: AddressFormat
  marketing: boolean
  handler: () => void
  onFinish: (payload: UserActionInput) => void
}

type Props = FieldProps

type State = {
  error?: Error
  passwordChange: boolean
  codeSent: boolean
  disableTimeout: boolean
  emailVerified: boolean
  updatedEmail: string
}

class ProfilePageEdit extends React.Component<Props, State> {
  readonly state: State = {
    passwordChange: false,
    codeSent: false,
    disableTimeout: false,
    emailVerified: false,
    updatedEmail: this.props.email,
  }

  @bind
  handlePasswordChange() {
    this.setState({ passwordChange: !this.state.passwordChange })
  }

  @bind
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  async sendVerificationCode(newEmail: string) {
    try {
      await Auth.verifyCurrentUserAttribute('email')
      this.setState({
        disableTimeout: true,
        codeSent: true,
      })

      setTimeout(() => {
        this.setState({ disableTimeout: false })
      }, 60000)
    } catch (e: any) {
      alert(e.message)
    }
  }

  @bind
  async handleCodeVerification(code: string, newEmail: string) {
    try {
      await Auth.verifyCurrentUserAttributeSubmit('email', code)
      this.setState({
        emailVerified: true,
        codeSent: false,
        updatedEmail: newEmail,
      })
    } catch (e: any) {
      alert(e.message)
    }
  }

  @bind
  async onSubmit(values: AnyObject) {
    const { username } = this.props
    const { passwordChange, emailVerified } = this.state

    if (!passwordChange && 'password' in values) {
      delete values['password']
    }

    const { email, oldpassword, password, emails, firstName, gender, lastName, mobile, postcode } = values

    const currentUser = await Auth.currentAuthenticatedUser()

    if (password) {
      try {
        await Auth.changePassword(currentUser, oldpassword, password)
      } catch (e: any) {
        return { [FORM_ERROR]: e.message }
      }
    }

    const address = {
      // street_address: escape(addressone),
      // address_2: `${addresstwo ? escape(addresstwo) : ''}`,
      // locality: escape(suburb),
      // region: state,
      postal_code: postcode,
      country: process.env.REACT_APP_REGION === 'NZ' ? 'New Zealand' : 'Australia',
    }

    const updateInfo = {
      email,
      gender,
      address: JSON.stringify(address),
      'custom:postcode': postcode,
      'custom:marketing_opt_in': emails ? 'true' : 'false',
      given_name: firstName,
      family_name: lastName,
      phone_number: phoneToAws(mobile),
    }

    if (!emailVerified && 'email' in updateInfo) {
      delete updateInfo['email']
    }

    Auth.updateUserAttributes(currentUser, updateInfo)
      .then(async response => {
        if (response === 'SUCCESS') {
          console.log('Opt-in', emails)
          updateBrazeUser(
            email,
            firstName,
            lastName,
            phoneToAws(mobile),
            gender,
            Number(address.postal_code),
            address.country,
            emails
          )
          // updateEmarsysContact(
          //     email,
          //     firstName,
          //     lastName,
          //     phoneToAws(mobile),
          //     gender,
          //     JSON.stringify(address),
          //     emails
          // )
        }
        this.setState({ passwordChange: false })
        this.props.onFinish({
          username,
          email,
          address,
          firstName,
          lastName,
          gender,
          mobile: phoneToAws(mobile),
          marketing: emails,
        } as UserActionInput)
      })
      .catch(err => {
        return { [FORM_ERROR]: err.message }
      })
  }

  render() {
    const { firstName, lastName, gender, mobile, address, marketing } = this.props

    const { error, passwordChange, codeSent, disableTimeout, emailVerified, updatedEmail } = this.state

    const fullAddress = address ? address : BlankAddress
    const t = new Date()
    t.setMinutes(t.getMinutes() + 1)

    return (
      <Grid>
        <div className='row'>
          <div className='col-xs-12'>
            <h1 className='profile-page__title'>
              <DictionaryValue token='profile.title' />
            </h1>
          </div>
        </div>
        <Form
          onSubmit={this.onSubmit}
          initialValues={{
            firstName,
            lastName,
            gender,
            mobile,
            addressone: unescape(fullAddress.street_address),
            addresstwo: fullAddress.address_2 ? unescape(fullAddress.address_2) : '',
            suburb: unescape(fullAddress.locality),
            state: fullAddress.region,
            postcode: fullAddress.postal_code,
            country: fullAddress.country,
            email: updatedEmail,
            emails: marketing,
            code: '',
          }}
          render={({ submitError, handleSubmit, dirtyFields, valid, dirty, values }) => (
            <form onSubmit={handleSubmit}>
              <div className='row'>
                {submitError && (
                  <div className='profile-page__alert col-xs-12'>
                    <strong>Error: </strong>
                    {submitError}
                  </div>
                )}
                <div className='col-xs-12 col-md-6'>
                  <div className='profile-page__section'>
                    <div className='profile-page__row'>
                      <div className='profile-page__label'>
                        <DictionaryValue token='profile.first-name' />
                      </div>
                      <div className='profile-page__value'>
                        <NameField
                          name='firstName'
                          placeholder='sign-up.field.firstName'
                          key='firstName'
                        />
                      </div>
                    </div>
                    <div className='profile-page__row'>
                      <div className='profile-page__label'>
                        <DictionaryValue token='profile.last-name' />
                      </div>
                      <div className='profile-page__value'>
                        <NameField
                          name='lastName'
                          placeholder='sign-up.field.lastName'
                          key='lastName'
                        />
                      </div>
                    </div>
                    <div className='profile-page__row'>
                      <div className='profile-page__label'>
                        <DictionaryValue token='profile.gender' />
                      </div>
                      <div className='profile-page__value'>
                        <GenderFieldWithData
                          name='gender'
                          placeholder='sign-up.field.gender'
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <div className='col-xs-12 col-md-6 profile-page__email'>
                  <div className='profile-page__section profile-page__section--secondary'>
                    <div className='profile-page__row'>
                      <div className='profile-page__label'>
                        <DictionaryValue token='profile.password' />
                      </div>
                      <div className='profile-page__value'>
                        {!passwordChange ? (
                          <Button
                            buttonType='tertiary'
                            buttonStyle='square'
                            type='button'
                            callback={this.handlePasswordChange}
                            className='profile-page__button profile-page__button--editpass'
                          >
                            Change Password
                          </Button>
                        ) : (
                          <>
                            <PasswordField
                              name='oldpassword'
                              placeholder={'update.field.oldpassword'}
                              key='oldpassword'
                              validateFormat={false}
                            />
                            <PasswordField
                              name='password'
                              placeholder={'update.field.newpassword'}
                              tooltip='sign-up.password.tooltip'
                              key='password'
                              validateFormat={true}
                            />
                            <Button
                              buttonType='tertiary'
                              buttonStyle='square'
                              type='button'
                              callback={this.handlePasswordChange}
                              className='
                                                            profile-page__button profile-page__button--cancelpass
                                                        '
                            >
                              Cancel
                            </Button>
                          </>
                        )}
                      </div>
                    </div>
                    <div className='profile-page__row'>
                      <div className='profile-page__label'>
                        <DictionaryValue token='profile.mobile' />
                      </div>
                      <div className='profile-page__value'>
                        <MobileField
                          name='mobile'
                          placeholder='sign-up.field.mobile'
                          key='mobile'
                        />
                      </div>
                    </div>
                    <div className='profile-page__row'>
                      <div className='profile-page__label'>
                        <DictionaryValue token='profile.email' />
                      </div>
                      <div className='profile-page__value'>
                        <EmailField
                          name='email'
                          placeholder={'sign-up.field.email'}
                          key='email'
                          disabled={true}
                        />
                      </div>
                      {dirtyFields.email && !emailVerified ? (
                        <div className='profile-page__value'>
                          <Button
                            buttonType='tertiary'
                            buttonStyle='square'
                            type='button'
                            disabled={disableTimeout}
                            callback={() => this.sendVerificationCode(values.email)}
                            className='profile-page__button profile-page__button--send'
                          >
                            {`${codeSent ? 'Resend' : 'Send'} Verification Code`}
                            {disableTimeout ? (
                              <>
                                <span> (</span>
                                <CountdownText to={Math.floor(t.getTime() / 1000)} />
                                <span>)</span>
                              </>
                            ) : (
                              ''
                            )}
                          </Button>
                        </div>
                      ) : (
                        <div className='profile-page__value'>
                          <Button
                            buttonType='tertiary'
                            buttonStyle='square'
                            type='button'
                            disabled={true}
                            className='profile-page__button profile-page__button--verified'
                          >
                            Verified
                          </Button>
                        </div>
                      )}
                    </div>
                    {codeSent ? (
                      <div className='profile-page__row'>
                        <div className='profile-page__label' />
                        <div className='profile-page__value profile-page__value--narrow'>
                          <CodeField
                            name='code'
                            placeholder='Verification Code'
                          />
                        </div>
                        <div className='profile-page__value profile-page__value--narrow'>
                          <Button
                            buttonType='tertiary'
                            buttonStyle='square'
                            type='button'
                            callback={() => this.handleCodeVerification(values.code, values.email)}
                            className='profile-page__button profile-page__button--verify'
                          >
                            Verify
                          </Button>
                        </div>
                      </div>
                    ) : (
                      ''
                    )}
                  </div>
                </div>
                <div className='col-xs-12 profile-page__address'>
                  <div className='profile-page__row'>
                    <div className='profile-page__label'>
                      <DictionaryValue token='profile.address' />
                    </div>
                    <div className='profile-page__value'>
                      <AddressFieldWithData
                        name='address'
                        placeholder='sign-up.field.'
                      />
                    </div>
                  </div>
                </div>
                <div className='col-xs-12 profile-page__marketing'>
                  <EmailsField
                    name='emails'
                    key='emails'
                  />
                </div>
              </div>
              <div className='row'>
                <div
                  className='col-xs-12 col-sm-8
                                    col-md-6 col-md-offset-1
                                    col-lg-4 col-lg-offset-2'
                >
                  <>
                    
                    <Button
                      buttonType='primary'
                      buttonStyle='square'
                      size='large'
                      type='submit'
                      className='profile-page__button profile-page__button--save'
                      disabled={valid && (emailVerified || (dirty && !dirtyFields.email)) ? false : true}
                    >
                      Save
                    </Button>
                    {error}
                  </>
                </div>
                <div className='col-xs-12 col-sm-8 col-md-6 col-lg-4'>
                  
                  <Button
                    buttonType='primary'
                    buttonStyle='square'
                    size='large'
                    type='button'
                    callback={this.props.handler}
                    className='profile-page__button profile-page__button--cancel'
                  >
                    Cancel
                  </Button>
                </div>
              </div>
            </form>
          )}
        />
        <>{error}</>
      </Grid>
    )
  }
}

export { ProfilePageEdit }
