import * as React from 'react'
import { Button, PoweredCheckbox, GradientContainer } from '../index'
import { CheckboxItem } from './types'
import './CheckboxList.scss'

type Props = {
    className?: string
    handleAction: () => void
    onItemClick: (item: CheckboxItem) => void
    buttonText: string | JSX.Element
    items: CheckboxItem[]
}

class CheckboxList extends React.Component<Props> {
    render() {
        const { className = '', handleAction, onItemClick, buttonText, items } = this.props

        return (
            <div className={`checkbox-list ${className}`}>
                <GradientContainer className='checkbox-list__body'>
                    {items.map(item => (
                        <PoweredCheckbox key={item.id} item={item} onChange={onItemClick}>
                            {item.name}
                        </PoweredCheckbox>
                    ))}
                </GradientContainer>
                <div className='checkbox-list__footer'>
                    <Button
                        buttonType='primary'
                        buttonStyle='square'
                        size='large'
                        display='block'
                        callback={handleAction}
                    >
                        {buttonText}
                    </Button>
                </div>
            </div>
        )
    }
}

export { CheckboxList }
