import * as React from 'react'
import { Highlight } from 'react-instantsearch-dom'
import { DictionaryValue } from '../../components/dictionary-value'
import { DealType } from '../../models/dealType'
import { SearchHit } from '../search/types'
import { getDealUrl } from '../interstitial'
import EventType from '../../models/eventType'
import DealCardWithData from '../../components/deal-card'

type Props = {
  id: string
  title: string
  slug: string
  offer: string
  imageUrl: string
  brandName?: string
  brandUrl?: string
  brandImageUrl?: string
  optional1?: string
  optional2?: string
  type?: DealType
  eventType?: EventType
  location?: 'home' | 'default'
  showHover?: boolean
  hit?: SearchHit
  className?: string
  isFavourite?: boolean
  favDealId?: string
  dealURL?: string
  isExclusive?: boolean
  isGreyedOut?: boolean
  isLoggedIn?: boolean
  dealStartTime?: number
  dealEndTime?: number
  isSponsored?: boolean
  toggleFavourite?: (e: React.MouseEvent) => void
}

class Card extends React.Component<Props> {
  static defaultProps = {
    type: 'gold',
  }

  highlightAttribute(attribute: string, fallback: string) {
    const { hit } = this.props

    if (hit !== undefined) {
      return (
        <Highlight
          attribute={attribute}
          hit={hit}
          tagName='mark'
        />
      )
    }

    return fallback
  }

  render() {
    const {
      title,
      slug,
      offer,
      optional1,
      optional2,
      isExclusive,
      isGreyedOut,
      dealStartTime,
      dealEndTime,
      isSponsored,
    } = this.props

    const cta = <DictionaryValue token='go-to-deal' />
    const couponAlt = <DictionaryValue token='exclusive.coupon.alternate' />
    const url =
      process.env.REACT_APP_INTERSTITIAL_PAGE === 'true'
        ? getDealUrl(slug)
        : this.props.dealURL !== undefined
        ? this.props.dealURL
        : getDealUrl(slug)

    return (
      <DealCardWithData
        {...this.props}
        cta={cta}
        brandImageURL={this.props.brandImageUrl}
        imageURL={this.props.imageUrl}
        formattedOffer={this.highlightAttribute('offer', offer)}
        formattedTitle={this.highlightAttribute('title', title)}
        optional1={optional1 ? this.highlightAttribute('details', optional1) : undefined}
        optional2={optional2 ? this.highlightAttribute('redemptionInfo', optional2) : undefined}
        url={url}
        brandURL={this.props.brandUrl}
        isExclusive={isExclusive}
        isGreyedOut={isGreyedOut}
        dealStartTime={dealStartTime}
        dealEndTime={dealEndTime}
        couponAlt={couponAlt}
        isSponsored={isSponsored}
        eventType={this.props.eventType}
      />
    )
  }
}

export default Card
