import Collection from '../models/collection'
import axios from 'axios'
import Deal from '../models/deal'
import Dictionary from '../models/dictionary'
import Category from '../models/category.js'
import Brand from '../models/brand'
import Event from '../models/event'
import Option from '../models/option'
import Menu from '../models/menu'
import FooterLink from '../models/footerLink'
import SocialAccount from '../models/socialAccount'
import Banner from '../models/banner'
import EventExtra from '../models/eventExtra'
import NonEventConfig from '../models/nonEventConfig'
import CmsPage from '../models/cmsPage'
import { isUserAdminInsecure } from '../utilities'
import { API, Auth } from 'aws-amplify'
import { CfDeals } from '../models/cfdeal'


const DATA_API_URL = process.env.REACT_APP_DATA_API_URL
const ALWAYS_ON_DATA_API_URL =  process.env.REACT_APP_ALWAYS_ON_DATA_API_URL

async function getEventData<T>(filename: string): Promise<T> {
    const isAdmin = isUserAdminInsecure()
    if (isAdmin) {
        const result = await API.get('clickfrenzyPreview', '/files', {
            queryStringParameters: {
                filename,
                jwtToken: (await Auth.currentSession()).getAccessToken().getJwtToken(),
            },
        })

        return result
    }

    const response = await axios.get<T>(`${DATA_API_URL}/event/${filename}`)
    return response.data
}

// eslint-disable-next-line @typescript-eslint/ban-types
export async function getStandardData<T>(filename: String): Promise<T> {
    const response = await axios.get<T>(`${DATA_API_URL}/${filename}`)
    return response.data
}

// AlwaysOnDeals : GET CF API
export async function getCfData<T>(filename: String): Promise<T> {
    const response = await axios.get<T>(`${ALWAYS_ON_DATA_API_URL}/${filename}`)
    return response.data
}

const loadDictionary = () => getStandardData<Dictionary[]>('dictionaries/clickfrenzy-dictionary.json')
const loadPayments = () => getEventData<Option[]>('payment.json')
const loadDeliveries = () => getEventData<Option[]>('delivery.json')
const loadRewardsCashbacks = () => getEventData<Option[]>('rewardscashback.json')
const loadDeal = (slug: string) => getEventData<Deal>(`deals/${slug}.json`)
const loadCollection = (slug: string) => getEventData<Collection>(`collections/${slug}.json`)
const loadCategory = (slug: string) => getEventData<Category>(`categories/${slug.toLowerCase()}.json`)
const loadBrands = () => getEventData<Brand[]>('brands.json')
const loadEvents = () => getStandardData<Event[]>('eventData.json')
const loadEventExtraData = (slug: string) => getEventData<EventExtra>(`events/${slug}.json`)
const loadBrandDetails = (slug: string) => getEventData<Brand>(`brands/${slug.toLowerCase()}.json`)
const loadFooterLinks = () => getStandardData<FooterLink[]>('footerlink.json')
const loadSocialAccounts = () => getStandardData<SocialAccount[]>('socialaccount.json')
const loadBanners = () => getEventData<Banner[]>('banner.json')
const loadNonEventConfig = () => getStandardData<NonEventConfig>('nonEventData.json')
const loadCmsPage = (slug: string) => getStandardData<CmsPage>(`pages/${slug.toLowerCase()}.json`)
const loadCfDealsFn = () => getCfData<CfDeals>('deals.json')


async function loadMenu(slug: string | undefined, isEvent: boolean): Promise<Menu> {
    if (isEvent) {
        return getEventData<Menu>(`menus/${slug}.json`)
    }

    return getStandardData<Menu>(`${slug}.json`)
}

export {
    loadBanners,
    loadBrands,
    loadBrandDetails,
    loadCategory,
    loadCollection,
    loadDeal,
    loadDeliveries,
    loadDictionary,
    loadEvents,
    loadEventExtraData,
    loadFooterLinks,
    loadMenu,
    loadPayments,
    loadRewardsCashbacks,
    loadSocialAccounts,
    loadNonEventConfig,
    loadCmsPage,
    loadCfDealsFn,
}
