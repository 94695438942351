import * as React from 'react'
import './ThreeColumnImageText.scss'
import { Grid } from '../grid'
import ThreeColumnItem from './ThreeColumnItem'
import { default as Data } from '../../models/threeColumnImageText'
import Slider, { Settings } from 'react-slick'
import NextArrow from '../carousel/NextArrow'
import PrevArrow from '../carousel/PrevArrow'
import { Button } from '../button'
import classNames from 'classnames'
import EventType from '../../models/eventType'

export type Props = {
  threeColumnData: Data
  eventType?: EventType | null
}

class ThreeColumnImageText extends React.Component<Props> {
  state = {
    width: 0,
    height: 0,
  }

  componentDidMount() {
    this.updateWindowDimensions()
    window.addEventListener('resize', this.updateWindowDimensions)
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.updateWindowDimensions)
  }

  updateWindowDimensions = () => {
    this.setState({ width: window.innerWidth, height: window.innerHeight })
  }

  render() {
    const { threeColumnData, eventType } = this.props

    const settings: Settings = {
      slidesToScroll: 1,
      arrows: true,
      autoplay: true,
      autoplaySpeed: 5000,
      infinite: true,
      speed: 1000,
      initialSlide: 0,
      className: 'carousel',
      nextArrow: <NextArrow />,
      prevArrow: <PrevArrow />,
      responsive: [
        {
          breakpoint: 999999,
          settings: {
            slidesToShow: 3,
          },
        },
        {
          breakpoint: 767,
          settings: {
            slidesToShow: 1,
          },
        },
      ],
    }

    return (
      <div className={`threeColumn__container-background threeColumn-${eventType}`}>
        <Grid>
          <div className={`threeColumn threeColumn-${eventType}`}>
            <h2 className='threeColumn__main-title'>{threeColumnData.title}</h2>
            {threeColumnData.description && <p className='threeColumn__description'>{threeColumnData.description}</p>}
            <div className='threeColumn__carousel'>
              <Slider {...settings}>
                {(threeColumnData.items || []).map((item, index) => (
                  <ThreeColumnItem
                    key={index}
                    title={item.title}
                    body={item.body}
                    imageUrl={item.image}
                    footer={item.footer}
                  />
                ))}
              </Slider>
            </div>
            {threeColumnData.button && (
              <div className='threeColumn__button-wrapper'>
                <Button
                  className={classNames('threeColumn__button', {
                    [`threeColumn__button--${eventType}`]: eventType,
                  })}
                  buttonType='primary'
                  buttonStyle='square'
                  size='large'
                  redirectUrl={threeColumnData.button.url}
                >
                  {threeColumnData.button.text}
                </Button>
              </div>
            )}
          </div>
        </Grid>
      </div>
    )
  }
}

export default ThreeColumnImageText
