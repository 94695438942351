import * as React from 'react'
import { CloseButton } from './Modal'
import { Grid } from '../grid'
import { Icon, cross } from '../icon/Icon'
import classNames from 'classnames'

type Props = {
    type?: CloseButton
    onClick: () => void
}

class ModalCloseButton extends React.Component<Props> {
    wrap(content: JSX.Element) {
        const { type } = this.props

        if (type !== 'header') {
            return content
        }

        return (
            <Grid>
                <div className='row'>
                    <div className='col-xs-12 modal__close-button-cell'>
                        {content}
                    </div>
                </div>
            </Grid>
        )
    }

    render() {
        const { onClick, type } = this.props
        const classNamePrefix = 'modal__close-button'
        const classes = classNames(
            classNamePrefix,
            `${classNamePrefix}--${type}`
        )

        return this.wrap((
            <button className={classes} onClick={onClick}>
                <Icon icon={cross} wrappingElement='span' />
            </button>
        ))
    }
}

export default ModalCloseButton
