import { ApplicationState } from '../../reducers'
import { loadCategory } from '../../services/Api'
import { categoryLoaded, loadDeals } from '../../data/actions'
import { itemLoading, itemLoaded } from '../global/loading/actions'
import Category from '../../models/category'
import actionCreatorFactory from 'typescript-fsa'
import { categorySelector } from '../../data/selectors'
import shuffle from 'lodash.shuffle'

const actionCreator = actionCreatorFactory('CATEGORY')

const toggleCategoryMenu = actionCreator('TOGGLE_CATEGORY_MENU')
const selectModalCategory = actionCreator<string>('SELECT_MODAL_CATEGORY')
const loadMoreCategoryDeals = actionCreator('LOAD_MORE_CATEGORY_DEALS')
const resetCategoryPage = actionCreator('RESET_CATEGORY_SILVER_DEALS_PAGE')

function loadCategoryData(categorySlug: string) {
    return async (dispatch: any, getState: () => ApplicationState) => {
        if (categorySelector(getState(), categorySlug) !== undefined) {
            return
        }

        dispatch(itemLoading())

        let category: Category

        try {
            category = await loadCategory(categorySlug)
        } catch (e) {
            dispatch(itemLoaded())
            return
        }

        if (category.silverDeals) {
            category.silverDeals = shuffle(category.silverDeals)
        }

        if (category.selfServeDeals) {
            category.selfServeDeals = shuffle(category.selfServeDeals)
        }

        dispatch(categoryLoaded(category))

        if (category.goldDeals !== undefined && category.goldDeals.length > 0) {
            await dispatch(loadDeals(category.goldDeals.map(dealPlacement => dealPlacement.slug)))
        }

        if (category.silverDeals !== undefined && category.silverDeals.length > 0) {
            await dispatch(loadDeals(category.silverDeals.map(dealPlacement => dealPlacement.slug)))
        }

        if (category.selfServeDeals !== undefined && category.selfServeDeals.length > 0) {
            await dispatch(loadDeals(category.selfServeDeals.map(dealPlacement => dealPlacement.slug)))
        }

        dispatch(itemLoaded())
    }
}

export {
    loadCategoryData,
    selectModalCategory,
    toggleCategoryMenu,
    loadMoreCategoryDeals,
    resetCategoryPage,
}
