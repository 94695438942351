import * as React from 'react'
import { PopupCardPresentation } from './PopupCardPresentation'
import EventType from '../../models/eventType'

type Props = {
  type: EventType
  popupTitle: string
  productName: string
  productImage: string
  productPrice?: string
  productRRP?: string
  popupQuestion: string
  handleSubmit: (answer: string, mobile: string, email: string) => void
  answerSubmitted: boolean
  popupTime?: number
  mobile?: string
}

class PopupCard extends React.Component<Props> {
  render() {
    const {
      type,
      popupTime,
      popupTitle,
      productName,
      productImage,
      productPrice,
      productRRP,
      popupQuestion,
      handleSubmit,
      answerSubmitted,
      mobile,
    } = this.props

    return (
      <PopupCardPresentation
        {...this.props}
        eventType={type}
        popupTitle={popupTitle}
        productName={productName}
        productImage={productImage}
        productPrice={productPrice}
        productRRP={productRRP}
        popupQuestion={popupQuestion}
        handleSubmit={handleSubmit}
        popupTime={popupTime}
        answerSubmitted={answerSubmitted}
        mobile={mobile}
      />
    )
  }
}

export default PopupCard
