import { ApplicationState } from '../../reducers'
import { itemLoading, itemLoaded } from '../global/loading/actions'
import { loadCmsPage } from '../../services/Api'
import { pageLoaded } from '../../data/actions'
import { pageSelector } from '../../data/selectors'
import { builder } from '@builder.io/react'
import { API, Auth } from 'aws-amplify'
import { CognitoUser } from 'amazon-cognito-identity-js'

const loadBuilderJson = async (slug: string) => {
    let currentUser: CognitoUser | undefined | null
    try {
        currentUser = await Auth.currentAuthenticatedUser({
            bypassCache: false,
        })
    // eslint-disable-next-line no-empty
    } catch (_) {}

    try {
        const [protectedJson, publicJson] = await Promise.all([
            currentUser
                ? API.get('clickfrenzyFeBuilderio', `/page/${slug}`, {}).then(result => result.builderJson || null)
                : Promise.resolve(null),
            builder.get('page', { url: `/${slug}` }).promise(),
        ])

        if (protectedJson) {
            return protectedJson
        }

        return publicJson
    } catch (error) {
        console.error(error)
        return null
    }
}

function loadPageData(slug: string) {
    return async (dispatch: any, getState: () => ApplicationState) => {
        const page = pageSelector(getState(), slug)

        if (page) {
            return
        }

        dispatch(itemLoading())

        try {
            const builderJson = await loadBuilderJson(slug)
            if (builderJson) {
                dispatch(
                    pageLoaded({
                        slug,
                        builderJson,
                        title: builderJson.data && builderJson.data.title ? builderJson.data.title : undefined,
                    })
                )
            } else {
                const page = await loadCmsPage(slug)
                dispatch(pageLoaded(page))
            }
        } catch (e) {
            console.error(e)
        }

        dispatch(itemLoaded())
    }
}

export { loadPageData }
