import * as React from 'react'
import { Link } from '../../link'
import { CfButton } from '../Button'
import bind from 'bind-decorator'
import { history } from '../../../../src/history'
import EventType from '../../../models/eventType'
import goWildAvatar from '../../../static/images/avatar.png'
import mayhemAvatar from '../../../static/images/mayhem-avatar.png'
import juloveTravelAvatar from '../../../static/images/julove-travel-avatar.png'
import './style.scss'

export type FieldProps = {
  className?: string
  id?: string
  isLoggedIn: boolean
  eventType?: EventType | null
}

let avatar: any = undefined
class CFSignIn extends React.Component<FieldProps> {
  @bind
  handleSignUpLink() {
    history.push('/account?join')
  }

  render() {
    const { className = '', id, isLoggedIn, eventType } = this.props

    // AVATAR - Handle by eventType
    avatar = goWildAvatar
    if (eventType !== undefined && eventType !== null) {
      if (eventType.toString() === 'mayhem') {
        avatar = mayhemAvatar
      }
      if (eventType.toString() === 'julove' || eventType.toString() === 'travel') {
        avatar = juloveTravelAvatar
      }
    }

    return (
      <div
        id={id}
        className={`cf-sign-in ${className}`}
      >
        {!isLoggedIn && (
          <div className='cf-flex flex-row align-center'>
            <Link to='/account?login'>Sign in</Link>
            <CfButton
              onClick={this.handleSignUpLink}
              buttonType='blue'
              size='large'
            >
              Become a member
            </CfButton>
          </div>
        )}
        {isLoggedIn && (
          <Link
            to='/account'
            className='cf-avatar'
          >
            <img
              data-blink-src={avatar}
              alt='Account'
            />
          </Link>
        )}
      </div>
    )
  }
}

export default CFSignIn
