import React, { PropsWithChildren } from 'react'
import classnames from 'classnames'
import './ModalSecondaryLayer.scss'

type Props = {
    isOpen: boolean
}

const ModalSecondaryLayer: React.FunctionComponent<PropsWithChildren<Props>> = ({ isOpen, children }) => {
    const classes = classnames(
        'modal-secondary-layer',
        { 'modal-secondary-layer--open': isOpen }
    )

    return (
        <div className={classes}>
            {children}
        </div>
    )
}

export { ModalSecondaryLayer }
