import { Auth } from '@aws-amplify/auth'
import { Hub, HubCallback } from '@aws-amplify/core'
import * as jose from 'jose'
import { awsToPhone } from '../../components'
import { ApplicationState } from '../../reducers'
import { getMembershipGroup, userHasAdminGroup } from '../../utilities/account'
import { loadEvents } from '../global/status'
import { signIn, signOut, UserActionInput } from './actions'

const getUser = (userData: any): UserActionInput => ({
  isAdmin: userHasAdminGroup(undefined, userData['cognito:groups']),
  group: getMembershipGroup(undefined, userData['cognito:groups']),
  email: userData.email,
  address: userData.address ? JSON.parse(userData.address.formatted) : undefined,
  username: userData.sub,
  mobile: userData['phone_number'] ? awsToPhone(userData['phone_number']) : undefined,
  firstName: userData['given_name'],
  lastName: userData['family_name'],
  gender: userData['gender'],
  marketing: userData['custom:marketing_opt_in'] === 'true',
})

const getLastAuthUser = () => {
  const lastAuth = Object.keys(localStorage).filter(key => key.includes('LastAuthUser'))
  const rawUserData: string[] = Object.keys(localStorage).filter(key => key.includes('idToken'))

  if (lastAuth.length > 0 && rawUserData.length > 0) {
    const userData: any = jose.decodeJwt(localStorage[rawUserData[0]])

    /// check is idToken expired
    if (userData.exp < new Date().getTime() / 1000) {
      return null
    }

    const user = getUser(userData)
    return user
  }

  return null
}

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const configureAccountListener = () => async (dispatch: any, getState: () => ApplicationState) => {
  const listener: HubCallback = async data => {
    switch (data.payload.event) {
      case 'signIn': {
        const session = await Auth.currentSession()
        if (session.isValid()) {
          const user = getUser(session.getIdToken().payload)
          dispatch(signIn(user))
        }
        break
      }
      case 'signOut':
        dispatch(signOut())
        break
      case 'cognitoHostedUI':
        dispatch(loadEvents())
        break
    }
  }

  Hub.listen('auth', listener)

  const user = getLastAuthUser()
  if (user) {
    dispatch(signIn(user))
  }
}

export { configureAccountListener }
