import * as React from 'react'
import { Field } from 'react-final-form'
import { InputField } from './InputField'
import { composeValidators, postcode } from '../validations'
import { Row, Col } from 'antd'

export type AddressProps = {
    name: string
    placeholder: string
    state: string
}

type Props = AddressProps

class AddressField extends React.Component<Props> {
    render() {
        const { placeholder } = this.props

        return (
            <Row gutter={8}>
                {/* <Col span={24}>
                    <Field<string>
                        name='addressone'
                        component={InputField}
                        type='text'
                        placeholder={`${placeholder}addressone`}
                        validate={composeValidators(
                            addressField('addressone')
                        )}
                    />
                </Col> */}
                {/* <Col span={24}>
                    <Field<string>
                        name='addresstwo'
                        component={InputField}
                        type='text'
                        placeholder={`${placeholder}addresstwo`}
                        validate={composeValidators(
                            addressField('addresstwo')
                        )}
                    />
                </Col> */}
                {/* <Col span={24}>
                    <Field<string>
                        name='suburb'
                        component={InputField}
                        type='text'
                        placeholder={`${placeholder}suburb`}
                        validate={composeValidators(
                            addressField('suburb')
                        )}
                    />
                </Col> */}
                {/* <Col span={12}>
                    <Field<string>
                        name='state'
                        component={SelectField}
                    >
                        <option value=''>{state}</option>
                        <option value='ACT'>ACT</option>
                        <option value='NSW'>NSW</option>
                        <option value='NT'>NT</option>
                        <option value='QLD'>QLD</option>
                        <option value='SA'>SA</option>
                        <option value='TAS'>TAS</option>
                        <option value='VIC'>VIC</option>
                        <option value='WA'>WA</option>
                    </Field>
                </Col> */}
                <Col span={12}>
                    <Field<string>
                        name='postcode'
                        component={InputField}
                        type='text'
                        placeholder={`${placeholder}postcode`}
                        validate={composeValidators(
                            postcode('postcode')
                        )}
                    />
                </Col>
                <Col span={12}>
                    <Field<string>
                        name='country'
                        component={InputField}
                        type='text'
                        placeholder='New Zealand'
                        readOnly={true}
                    />
                </Col>
            </Row>
        )
    }
}

export { AddressField }
