import * as React from 'react'
import { CustomArrowProps } from 'react-slick'
import Arrow from './Arrow'

type Props = CustomArrowProps & {
    icon?: string
    isSearch? : boolean
}

class NextArrow extends React.Component<Props> {
    render() {
        return (
            <Arrow direction='next' {...this.props} />
        )
    }
}

export default NextArrow
