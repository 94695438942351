import * as React from 'react'
import ThreeColumnItemSignUp from '../../components/threeColumnImageText/ThreeColumnItemSignUp'
import './MembershipBenefits.scss'
import NonEventConfig from '../../models/nonEventConfig'

export type Props = {
  nonEventConfigData?: NonEventConfig
}

let defaultMembershipBenefits = {
  title: `Membership Benefits`,
  description: `Signing up is free and unlocks huge event benefits!`,
  items: [
    {
      body: `Check out all the Click Frenzy deals 1 hour before the rest of Australia!`,
      footer: '',
      image: `https://ucarecdn.com/1ae09230-562f-4c42-8b02-44d1c7f24fcf/MembershipBenefitsNew1.png`,
      title: `Early Access`,
    },
    {
      body: `Have a chance at winning some of our amazing prizes during a Click Frenzy sale event.`,
      footer: '',
      image: `https://ucarecdn.com/17646c4d-e84b-49da-b939-9f541e3490f7/MembershipBenefitsNew2.png`,
      title: `Amazing Prizes`,
    },
    {
      body: `Join a community of over 1.8 million shoppers that love to shop and find a great deals online.`,
      footer: '',
      image: `https://ucarecdn.com/41655345-cd19-462f-b01e-e159907e8e6e/MembershipBenefitsNew3.png`,
      title: `Great Deals`,
    },
  ],
}

if (process.env.REACT_APP_REGION === 'NZ') {
  defaultMembershipBenefits = {
    title: `Membership Benefits`,
    description: `Signing up is free and unlocks huge event benefits!`,
    items: [
      {
        body: `Check out all the Click Frenzy deals 30 minutes before the rest of Australia!`,
        footer: '',
        image: `https://ucarecdn.com/15330a65-bb23-4c69-9786-1999730e9aa6/MembershipBenefitsIcons.jpg`,
        title: `Early Access`,
      },
      {
        body: `From $11 Samsung Galaxy phones to $18 65" TVs! Only members are eligible for Click Frenzy's famous 99% off deals!`,
        footer: '',
        image: `https://ucarecdn.com/7e26e988-a234-4821-9034-d2aac484832e/MembershipBenefitsIcons2.jpg`,
        title: `99% Off Deals`,
      },
    ],
  }
}

class MembershipBenefits extends React.Component<Props> {
  constructor(props: Props) {
    super(props)
    this.state = { listThreeColumnImageTexts: [] }
  }
  render() {
    const { nonEventConfigData } = this.props
    const membershipBenefits =
      nonEventConfigData && nonEventConfigData.membershipBenefits
        ? nonEventConfigData.membershipBenefits
        : defaultMembershipBenefits

    return (
      <div>
        <div className='membershipBenefits row'>
          <div
            className='
                        col-xs-12 col-sm-8 col-sm-offset-2 col-md-8 col-md-offset-2 col-lg-10 col-lg-offset-1
                        '
          >
            <h1 className='membershipBenefits__main-title'>{membershipBenefits.title}</h1>
          </div>
        </div>
        {(membershipBenefits.items || []).map((item: any, index: any) => (
          <div
            className='row'
            key={index}
          >
            <div
              className={`
                                col-xs-12 col-sm-8 col-sm-offset-2 col-md-8 col-md-offset-2 col-lg-10 col-lg-offset-1
                            `}
            >
              <ThreeColumnItemSignUp
                title={item.title}
                body={item.body}
                imageUrl={item.image}
                footer={item.footer}
              />
            </div>
          </div>
        ))}
      </div>
    )
  }
}

export default MembershipBenefits
