import * as React from 'react'
import { Builder } from '@builder.io/react'
import { FooterWithData } from '../../global/footer'
import { FooterVariation } from '../../global/footer/Footer'

type Props = {
    show: boolean
    variation?: FooterVariation
    showFooterPartners?: boolean
}

const FooterBuilderComponent = (props: Props) => {
    return (
        <>
            {props.show && (
                <div className='layout__footer'>
                    <FooterWithData variation={props.variation} showPartners={props.showFooterPartners} />
                </div>
            )}
        </>
    )
}

Builder.registerComponent(FooterBuilderComponent, {
    name: 'Footer Component',
    inputs: [
        {
            name: 'show',
            type: 'boolean',
            defaultValue: true,
        },
    ],
})

const CustomFooterBuilderComponent = ({ show, variation, showFooterPartners, ...restProps }: Props) => {
    return (
        <>
            {show && (
                <div className='layout__footer'>
                    <FooterWithData variation={variation} showPartners={showFooterPartners} {...restProps} />
                </div>
            )}
        </>
    )
}

Builder.registerComponent(CustomFooterBuilderComponent, {
    name: 'Custom Footer Component',
    inputs: [
        {
            name: 'show',
            type: 'boolean',
            defaultValue: true,
        },
        {
            name: 'customLinks',
            type: 'list',
            friendlyName: 'Links',
            required: true,
            defaultValue: [
                {
                    name: 'Account',
                    path: '/account',
                },
            ],
            subFields: [
                {
                    name: 'path',
                    type: 'string',
                    required: true,
                },
                {
                    name: 'name',
                    type: 'string',
                    required: true,
                },
            ],
        },
        {
            name: 'customColors',
            type: 'object',
            friendlyName: 'Colors',
            required: true,
            defaultValue: {
                backgroundColor: '#5C2D91',
                textColor: '#FFFFFF',
                copyrightBackgroundColor: '#2c1946',
                copyrightTextColor: '#FFFFFF',
                socialIconColor: '#FFFFFF',
                socialIconBackgroundColor: '#932091',
                socialIconHoverBackgroundColor: '#a320a0',
            },
            subFields: [
                {
                    name: 'backgroundColor',
                    type: 'color',
                    required: true,
                },
                {
                    name: 'textColor',
                    type: 'color',
                    required: true,
                },
                {
                    name: 'socialIconColor',
                    type: 'color',
                    required: true,
                },
                {
                    name: 'socialIconBackgroundColor',
                    type: 'color',
                    required: true,
                },
                {
                    name: 'socialIconHoverBackgroundColor',
                    type: 'color',
                    required: true,
                },
                {
                    name: 'copyrightTextColor',
                    type: 'color',
                    required: true,
                },
                {
                    name: 'copyrightBackgroundColor',
                    type: 'color',
                    required: true,
                },
            ],
        },
    ],
})
