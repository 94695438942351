enum EventType {
  goWild,
  travel,
  mayhem,
  julove,
  sports,
  boxingDay,
  fashion,
}

export default EventType
