import * as React from 'react'
import './ModalBody.scss'
import { ModalPadding } from './types'
import { GradientContainer } from '..'

type Props = {
    className?: string
    modalPadding?: ModalPadding
}

class ModalBody extends React.Component<React.PropsWithChildren<Props>> {

    render() {
        const { className = '', modalPadding = '', children } = this.props

        return (
            <GradientContainer className={`modal-body ${className} modal-body--padding-${modalPadding}`}>
                <div className='modal-body__content'>
                    {children}
                </div>
            </GradientContainer>
        )
    }
}

export { ModalBody }
