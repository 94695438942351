import { ApplicationState } from '../../reducers'
import { itemLoading, itemLoaded } from '../global/loading/actions'
import {
    brandDetailedLoading,
    brandDetailsLoaded,
    brandsLoaded,
    brandsLoading,
    deliveriesLoaded,
    loadDeals,
    paymentsLoaded,
    rewardsCashbacksLoaded,
} from '../../data/actions'
import {
    loadBrandDetails,
    loadBrands as getBrands,
    loadDeliveries,
    loadPayments,
    loadRewardsCashbacks,
} from '../../services/Api'
import { brandSelector } from '../../data/selectors'

function loadBrands() {
    return async (dispatch: any, getState: () => ApplicationState) => {
        const state = getState()

        if (state.data.brands !== null || state.status.brandsLoading) {
            return
        }

        dispatch(itemLoading())
        dispatch(brandsLoading())

        try {
            const brands = await getBrands()

            dispatch(brandsLoaded(brands))
        } finally {
            dispatch(itemLoaded())
        }
    }
}

function loadBrand(slug: string) {
    return async (dispatch: any, getState: () => ApplicationState) => {
        const state = getState()

        if (
            state.status.brandsDetailedLoading.includes(slug)
            || state.data.brandsDetailed.find(brand => brand.slug === slug)
        ) {
            return
        }

        dispatch(itemLoading())
        dispatch(brandDetailedLoading(slug))

        try {
            let brand = brandSelector(state, slug)
            if (brand === undefined || brand.description === undefined || brand.deals === undefined) {
                brand = await loadBrandDetails(slug)
                dispatch(brandDetailsLoaded(brand))
            }

            if (brand.deals !== undefined) {
                dispatch(loadDeals(brand.deals))
            }

            loadPayments().then((item) => {
                dispatch(paymentsLoaded(item))
            })
            loadDeliveries().then((item) => {
                dispatch(deliveriesLoaded(item))
            })
            loadRewardsCashbacks().then((item) => {
                dispatch(rewardsCashbacksLoaded(item))
            })
        } catch (e) {
            dispatch(itemLoaded())
            return
        }

        dispatch(itemLoaded())
    }
}

function navigateTo(letter: string) {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    return (dispatch: any, getState: () => ApplicationState) => {
        const element = document.getElementById(`brand-group--${letter}`)

        if (element !== null) {
            element.scrollIntoView({ behavior: 'smooth', block: 'start' })
        }
    }
}

export {
    loadBrands,
    navigateTo,
    loadBrand,
}
