import { CognitoUserAttribute } from 'amazon-cognito-identity-js'
import { API, graphqlOperation } from 'aws-amplify'
import { GraphQLResult } from '@aws-amplify/api'
import { FavouriteDeal } from './types'
import { UserFavouriteDealsByUser } from '../../graphql/queries'
import { SIGN_UP_SLUG } from '../../constants'

export function getAttributeByName(name: string, attributes: CognitoUserAttribute[]) {
  const attribute = attributes.find(attribute => attribute.getName() === name)

  return attribute ? attribute.getValue() : ''
}

export async function getFavDealsForUser(username: string) {
  const params = {
    userId: username,
  }

  const result = (await API.graphql(graphqlOperation(UserFavouriteDealsByUser, params))) as GraphQLResult

  if (result.data === undefined) {
    throw new Error(`Result loading exception: ${UserFavouriteDealsByUser}`)
  }

  const favDeals: FavouriteDeal[] = (result.data as any).UserFavouriteDealsByUser.items

  return favDeals
}

export const isUserOnSignupPage = () => {
  const currentUrl = window.location.href
  if (currentUrl && currentUrl.toLowerCase().includes(SIGN_UP_SLUG)) {
    return true
  }
  return false
}
