import * as React from 'react'
import { CfDealItem } from '../../../models/cfdeal'
import './style.scss'
import { getImgUrl } from '../../../utilities/deal.helper'

type Props = {
  className?: string
  id?: string
  data: CfDealItem
}

class DealTerm extends React.Component<Props> {
  render() {
    const { className = '', id, data } = this.props
    const { title, description, terms, brandLogoId } = data

    return (
      <div
        className={`${className} cf-deal-term`}
        id={id}
      >
        {brandLogoId && (
          <div className='deal-logo'>
            <img
              src={getImgUrl(brandLogoId)}
              alt={title}
            />
          </div>
        )}
        {title && <div className='ttl'>{title}</div>}
        {description && <div className='sub-ttl'>{description}</div>}
        {terms && (
          <div className='ct'>
            <div className='ct-ttl'>
              <strong>Terms</strong>
            </div>
            <div dangerouslySetInnerHTML={{ __html: terms }}></div>
          </div>
        )}
      </div>
    )
  }
}

export default DealTerm
