// SVG ICON : CalendarIco
import React from 'react'

type Props = {
  color?: string
}

class CalendarIco extends React.Component<Props> {
  render() {
    const { color } = this.props

    return (
      <svg
        width='16'
        height='14'
        viewBox='0 0 16 14'
        fill='none'
        xmlns='http://www.w3.org/2000/svg'
      >
        <path
          d='M2.85742 9.32129H13.0717'
          stroke={color || '#ffffff'}
          strokeMiterlimit='10'
          strokeLinecap='square'
        />
        <path
          d='M6.10742 7V11.6429'
          stroke={color || '#ffffff'}
          strokeMiterlimit='10'
          strokeLinecap='square'
        />
        <path
          d='M9.82227 7V11.6429'
          stroke={color || '#ffffff'}
          strokeMiterlimit='10'
          strokeLinecap='square'
        />
        <path
          d='M4.71484 0.5V2.35714'
          stroke={color || '#ffffff'}
          strokeMiterlimit='10'
          strokeLinecap='square'
        />
        <path
          d='M11.2148 0.5V2.35714'
          stroke={color || '#ffffff'}
          strokeMiterlimit='10'
          strokeLinecap='square'
        />
        <path
          d='M14.9286 5.14307H1'
          stroke={color || '#ffffff'}
          strokeMiterlimit='10'
        />
        <path
          d='M13.5357 2.35693H2.39286C1.6236 2.35693 1 2.98054 1 3.74979V12.1069C1 12.8762 1.6236 13.4998 2.39286 13.4998H13.5357C14.305 13.4998 14.9286 12.8762 14.9286 12.1069V3.74979C14.9286 2.98054 14.305 2.35693 13.5357 2.35693Z'
          stroke={color || '#ffffff'}
          strokeMiterlimit='10'
          strokeLinecap='square'
        />
      </svg>
    )
  }
}

export default CalendarIco
