import React, { PropsWithChildren } from 'react'
import './RefinementGroup.scss'

type Props = {
    className?: string
}

const RefinementGroup: React.FunctionComponent<PropsWithChildren<Props>> = ({ children, className = '' }) => (
    <div className={`refinement-group ${className}`}>
        {children}
    </div>
)

export { RefinementGroup }
