import * as React from 'react'
import { Field, FieldRenderProps } from 'react-final-form'
import { InputField } from './InputField'
import { required, composeValidators, code } from '../validations'

type Props = {
    name: string
    placeholder: string
}

class CodeField extends React.Component<Props> {
    renderField(props: FieldRenderProps<string, HTMLElement>) {
        return (
            <InputField
                {...props}
                autoComplete='off'
            />
        )
    }

    render() {
        const { name, placeholder } = this.props

        return (
            <Field<string>
                name={name}
                type='text'
                validate={composeValidators(
                    required(name),
                    code(name)
                )}
                placeholder={placeholder}
                render={this.renderField}
            />
        )
    }
}

export { CodeField }
