import React from 'react'
import './SearchRefineModal.scss'
import { ModalSecondaryLayer, DictionaryValue, DropdownWithViewport } from '../../../components'
import { SearchSortWithData } from './SearchSort'
import bind from 'bind-decorator'
import { SearchRefineByWithData } from './SearchRefineByWithData'
import { connectCurrentRefinements, CurrentRefinementsProvided } from 'react-instantsearch-core'

type Props = CurrentRefinementsProvided

type State = {
    filterLayerVisible: boolean
}

class SearchRefineModal extends React.Component<Props, State> {
    state = {
        filterLayerVisible: false,
    }

    @bind
    toggleFilterLayer() {
        this.setState(currentState => ({
            filterLayerVisible: !currentState.filterLayerVisible,
        }))
    }

    render() {
        const { items } = this.props
        const { filterLayerVisible } = this.state

        return (
            <div className='search-refine-modal'>
                <DropdownWithViewport
                    className='category-refinement__dropdown'
                    label={<DictionaryValue token='search.refine' />}
                    labelMobile={<DictionaryValue token='search.refine' />}
                    dropdownMenuTitle={<DictionaryValue token='search.refine' />}
                >
                    <ul className='search-refine-modal__list'>
                        <li className='search-refine-modal__list-item'>
                            <SearchSortWithData className='search-sort--context-modal' />
                        </li>
                        <li className='search-refine-modal__list-item'>
                            <button className='search-refine-modal__item' onClick={this.toggleFilterLayer}>
                                <span className='search-refine-modal__item-label'>
                                    <DictionaryValue token='search.filter.label' />
                                </span>
                                <span className='search-refine-modal__item-content'>
                                    <DictionaryValue
                                        token={items.length === 0 ? 'search.filter.all' : 'search.filter.selected'}
                                    />
                                </span>
                            </button>
                            <ModalSecondaryLayer isOpen={filterLayerVisible}>
                                <div className='modal-header'>
                                    <h1 className='modal-header__title'>
                                        <DictionaryValue token='search.filter.modal.title' />
                                    </h1>
                                </div>
                                <SearchRefineByWithData
                                    className='search-refine-by--context-modal'
                                    attribute='brand'
                                    requestClose={this.toggleFilterLayer}
                                />
                            </ModalSecondaryLayer>
                        </li>
                    </ul>
                </DropdownWithViewport>
            </div>
        )
    }
}

const SearchRefineModalWithData = connectCurrentRefinements(SearchRefineModal)

export { SearchRefineModal, SearchRefineModalWithData }
