import * as React from 'react'
import './Theme.scss'
import { Grid } from '../../components/grid'

const colours = [
    '--primary',
    '--secondary',
    '--secondary-dark',
    '--secondary-heavy',
    '--secondary-black',
    '--secondary-light',
    '--secondary-alternate',
    '--secondary-alternate-bright',
    '--error',
    '--white',
    '--white-shadow',
    '--ivory',
    '--bright-grey',
    '--light-grey',
    '--clear-grey',
    '--body',
    '--black',
    '--shadow',
    '--dark-shadow',
    '--hover-shadow',
]

class Theme extends React.Component {
    render() {
        const colourSwatchClassName = 'styleguide-colours__swatch styleguide-colours__swatch'

        return (
            <>
                <Grid>
                    <div className='row'>
                        <div className='col-xs-12'>
                            <h2 className='styleguide-colours__heading'>Theme Colours</h2>
                        </div>
                    </div>
                </Grid>
                <Grid>
                    <ul className='styleguide-colours row'>
                            { colours.map(colour => (
                                <li className='col-xs-6 col-sm-4 col-md-3' key={colour}>
                                    <div className='styleguide-colours__item '>
                                        <div className={`${colourSwatchClassName}${colour}`} />
                                        <h4 className='styleguide-colours__name'>{colour}</h4>
                                    </div>
                                </li>
                            ))}
                        </ul>
                </Grid>
            </>
        )
    }
}

export default Theme
