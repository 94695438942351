import * as React from 'react'
import DealPlacement from '../../models/dealPlacement'
import List from './List'
import { ApplicationState } from '../../reducers'
import { brandsQueryToArray, getCategoryDealsPageSize } from '../category/utilities'
import { categoryDealsSelector, filterCategoryDealsSelector } from '../../data/selectors'
import { connect } from 'react-redux'

type OwnProps = {
    slugs: string[]
    showGold?: boolean
    showSandSS?: boolean
}

type FieldProps = {
    goldDeals: DealPlacement[]
    silverDeals?: DealPlacement[]
    selfServeDeals?: DealPlacement[]
    currentPage: number
}

class MainCategoryDealList extends React.Component<FieldProps & OwnProps> {
    render() {
        const { goldDeals, silverDeals, selfServeDeals, currentPage, showGold, showSandSS } = this.props
        const pageSize = getCategoryDealsPageSize()

        let deals: DealPlacement[] = []

        if (showGold) {
            deals = goldDeals.slice(0, currentPage * pageSize)
        } else if (showSandSS && !showGold) {
            const concatDeals = () => {
                return [...silverDeals ? silverDeals : []]
                    .concat([
                        ...selfServeDeals ? selfServeDeals : [],
                    ])
            }
            deals = concatDeals().slice(0, currentPage * pageSize)
        } else {
            const concatDeals = () => {
                return [...goldDeals]
                    .concat([
                        ...silverDeals ? silverDeals : [],
                        ...selfServeDeals ? selfServeDeals : [],
                    ])
            }
            deals = concatDeals().slice(0, currentPage * pageSize)
        }

        return (
            <List deals={deals} />
        )
    }
}

const mapStateToProps = (state: ApplicationState, ownProps: OwnProps): FieldProps => {
    const selectedBrands = brandsQueryToArray(state.router.location.search)
    let goldDeals: DealPlacement[] = []
    let silverDeals: DealPlacement[] = []
    let selfServeDeals: DealPlacement[] = []

    ownProps.slugs.map((slug) => {
        goldDeals = goldDeals.concat(
            ...categoryDealsSelector(state, { slug }).goldDeals
        )
        silverDeals = silverDeals.concat(
            ...categoryDealsSelector(state, { slug }).silverDeals
        )
        selfServeDeals = selfServeDeals.concat(
            ...categoryDealsSelector(state, { slug }).selfServeDeals
        )
    })

    const filteredGoldDeals = filterCategoryDealsSelector(state, goldDeals, '', selectedBrands, 'gold', true)
    const filteredSilverDeals = filterCategoryDealsSelector(state, silverDeals, '', selectedBrands, 'silver', true)
    const filteredSelfServeDeals = filterCategoryDealsSelector(
        state, selfServeDeals, '', selectedBrands, 'selfServe', true)

    return {
        goldDeals: filteredGoldDeals,
        silverDeals: filteredSilverDeals,
        selfServeDeals: filteredSelfServeDeals,
        currentPage: state.category.silverDealsCurrentPage,
    }
}

export default connect(mapStateToProps)(MainCategoryDealList)
