import * as React from 'react'
import { DictionaryValue, Button } from '../../components'
import classNames from 'classnames'
import './AccountFormSignUp.scss'

type Props = {
    heading: string
    intro: string | JSX.Element
    fieldList: JSX.Element[]
    checkboxList?: JSX.Element[]
    ctaLabel: string
    footer: string | JSX.Element
    error?: Error
    handleSubmit: (event: React.FormEvent<HTMLFormElement>) => void
}

class AccountFormSignUp extends React.Component<Props> {
    dictionaryOrJsx(input: string | JSX.Element): JSX.Element {
        return typeof input === 'string' ? <DictionaryValue token={input as string} /> : (input as JSX.Element)
    }

    getKey(field: JSX.Element): string | number | undefined {
        return field.key ? field.key : undefined
    }

    render() {
        const { heading, intro, fieldList, checkboxList, ctaLabel, footer, handleSubmit, error } = this.props

        const formClasses = classNames('account-form-signup', { 'account-form-signup--has-error': error })

        const responsiveClasses = `
            col-xs-12 col-sm-10 col-sm-offset-1 col-md-8 col-md-offset-3 col-lg-8 col-lg-offset-3`

        return (
            <form className={formClasses} onSubmit={handleSubmit} noValidate={true}>
                <React.Fragment>
                    <div className='row'>
                        <div className={responsiveClasses}>
                            <h1 className='account-form-signup__page-heading'>
                                <DictionaryValue token={heading} />
                            </h1>
                            <div className='account-form-signup__page-intro'>{this.dictionaryOrJsx(intro)}</div>
                        </div>
                    </div>
                    {fieldList.map((field) => (
                        <div className='row account-form-signup__field-container' key={this.getKey(field)}>
                            <div className={responsiveClasses}>{field}</div>
                        </div>
                    ))}
                    {checkboxList &&
                        checkboxList.map((checkbox) => (
                            <div className='row account-form-signup__field-container' key={this.getKey(checkbox)}>
                                <div className={responsiveClasses}>{checkbox}</div>
                            </div>
                        ))}
                    {error && (
                        <div className='row account-form-signup__error'>
                            <div className={responsiveClasses}>
                                <p className='account-form-signup__error-message'>{error.message}</p>
                            </div>
                        </div>
                    )}
                    <div className='row'>
                        <div className={responsiveClasses}>
                            <Button
                                buttonType='primary'
                                buttonStyle='square'
                                size='large'
                                type='submit'
                                className='account-form-signup__submit'
                            >
                                <DictionaryValue token={ctaLabel} />
                            </Button>
                        </div>
                    </div>
                    <div className='row'>
                        <div className={responsiveClasses}>
                            <p className='account-form-signup__footer'>{this.dictionaryOrJsx(footer)}</p>
                        </div>
                    </div>
                </React.Fragment>
            </form>
        )
    }
}

export { AccountFormSignUp }
