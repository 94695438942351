/* eslint-disable react/no-children-prop */
import * as React from 'react'
import { FieldRenderProps } from 'react-final-form'
import { Checkbox } from '../Checkbox'
import { DictionaryValue } from '../../dictionary-value'
import { getErrorMessage } from '../utilities'

type Props = FieldRenderProps<string, HTMLElement>

class CheckboxField extends React.Component<Props> {
    showTextWithHtml(text: string) {
        return <span dangerouslySetInnerHTML={{ __html: text }} />
    }

    render() {
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        const { input: { type, ...input }, meta, ...rest } = this.props
        const errorMessage = getErrorMessage(meta)

        return (
            <Checkbox
                {...input}
                {...rest}
                checked={!!input.checked}
                errorMessage={errorMessage}
                presentation='square'
                alignment='center'
                checkboxType='field'
            >
                <DictionaryValue token={`form.checkbox.${input.name}`} children={this.showTextWithHtml} />
            </Checkbox>
        )
    }
}

export { CheckboxField }
