import * as React from 'react'
import { ApplicationState } from '../../reducers'
import ListRows from './ListRows'
import { connect } from 'react-redux'
import DealPlacement from '../../models/dealPlacement'

type OwnProps = {
    goldDeals: DealPlacement[]
    silverDeals?: DealPlacement[]
    selfServeDeals?: DealPlacement[]
    className?: string
}

class List extends React.Component<OwnProps> {
    render() {
        const { goldDeals, silverDeals, selfServeDeals, className } = this.props

        const silverSelfServeDeals: DealPlacement[] = [
            ...silverDeals ? silverDeals : [],
            ...selfServeDeals ? selfServeDeals : [],
        ]

        return (
            <div className={className}>
                <ListRows deals={goldDeals} />
                <ListRows deals={silverSelfServeDeals} />
            </div>
        )
    }
}

export type Props = {
    deals: DealPlacement[]
    together?: boolean
}

const mapStateToProps = (state: ApplicationState, ownProps: Props): OwnProps => {
    const goldDeals = ownProps.deals.filter(deal => deal.type === 'gold')
    const silverDeals = ownProps.deals.filter(deal => deal.type === 'silver')
    const selfServeDeals = ownProps.deals.filter(deal => deal.type === 'selfServe')

    if (ownProps.together) {
        return {
            goldDeals: [
                ...goldDeals,
                ...silverDeals,
                ...selfServeDeals,
            ],
        }
    }

    return {
        goldDeals,
        silverDeals,
        selfServeDeals,
    }
}

export default connect(mapStateToProps)(List)
