import * as React from 'react'
import Brand from '../../../models/brand'
import BrandDealsData from '../BrandDealsData'
import BrandDetailBasicData from '../BrandDetailBasicData'
import BrandDetailAdditionalData from '../BrandDetailAdditionalData'
import { Layout } from '../../layout'
import { Grid } from '../../../components/grid'
import './detailPage.scss'
import { Breadcrumb } from '../../../components/breadcrumb/Breadcrumb'
import EventType from '../../../models/eventType'

type Props = {
    brand: Brand
    eventType?: EventType | null
}

class BrandDetailPage extends React.Component<Props> {
    render() {
        const { brand, eventType } = this.props

        return (
            <Layout>
                <Grid className='brand-detail-page'>
                    <div className='brand-detail-head'>
                        <div className='row brand-detail-page-info-cont'>
                            <div className={`full-width-backdrop brand-detail-${eventType}`}/>
                            <div className='col-xs-12 col-md-12 breadcrumb-container'>
                                <Breadcrumb
                                    className='brand-detail-page-breadcrumb'
                                    eventType={eventType}
                                    items={[
                                        {
                                            title: 'All brands',
                                            to: '/brands',
                                        },
                                        {
                                            title: brand.name,
                                        },
                                    ]}
                                />
                            </div>
                            <div className='col-xs-12 col-md-8 brand-detail-page-info-cont__brand-info'>
                                <BrandDetailBasicData slug={brand.slug} />
                            </div>
                            <div className='col-xs-12 col-md-4'>
                                <BrandDetailAdditionalData slug={brand.slug} />
                            </div>
                        </div>
                    </div>
                </Grid>
                <BrandDealsData slug={brand.slug} />
            </Layout>
        )
    }
}

export default BrandDetailPage
