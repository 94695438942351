import { ApplicationState } from '../../../reducers'
import { nonEventConfigLoaded } from '../../../data/actions'
import {
    loadNonEventConfig,
} from '../../../services/Api'

function loadHomepageData() {
    return async (dispatch: any, getState: () => ApplicationState) => {
        const state = getState()

        if (state.status.nonEventConfigLoaded) {
            return
        }

        try {
            const homepageData = await loadNonEventConfig()
            dispatch(nonEventConfigLoaded(homepageData))
        } catch (e) {
            // don't error when we fail to load the data
        }
    }
}

export {
    loadHomepageData,
}
