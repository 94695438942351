import * as React from 'react'
import './style.scss'
import { formatShortDate } from '../../../utilities/deal.helper'

type Props = {
  className?: string
  variant?: string
  text: string
  date: string
}

const displayText = (str: string, date: string): string => {
  if (str === 'ending') return 'Ending soon'
  else if (str === 'no-expired') return `Ends ${formatShortDate(date)}`
  else return 'Expired'
}

class DealBadge extends React.Component<Props> {
  render() {
    const { className = '', variant = '', text, date } = this.props

    return <div className={`cf-deal-badge ${className} state-${variant}`}>{displayText(text, date)}</div>
  }
}

export default DealBadge
