import * as React from 'react'
import bind from 'bind-decorator'
import { history } from '../../../../../../src/history'
import { CfButton, Container } from '../../../../../components/cf-package'
import './style.scss'

type Props = {
  className?: string
  id?: string
}

class CFBoard extends React.Component<Props> {
  @bind
  goTo(path: string) {
    history.push(path)
  }

  render() {
    const { className = '', id } = this.props

    return (
      <Container>
        <section
          className={`${className} cf-board-blk`}
          id={id}
        >
          <div className='cf-flex flex-col'>
            <div className='content'>
              <h3 className='ttl head-lg'>
                Sharp marketing for
                <br />
                Australian brands
              </h3>
              <div className='desc'>
                Million’s of Australian’s attend our Click Frenzy events. Get your brand invovled!
              </div>
              <div className='cta-blk'>
                <CfButton
                  onClick={() => this.goTo('/advertise')}
                  buttonType='cfwhiteblue'
                  size='large'
                >
                  Advertise with us
                </CfButton>
              </div>
            </div>
          </div>
          <img
            src='./static/images/ipad.png'
            alt='ipad'
            className='img'
          />
        </section>
      </Container>
    )
  }
}

export default CFBoard
