import * as React from 'react'
import { Grid } from '..'
import './SectionHeader.scss'

type Props = {
    title: string
    rightSide?: JSX.Element
    className?: string
}

class SectionHeader extends React.Component<Props> {
    render() {
        const { title, rightSide, className = '' } = this.props
        const columnWidth = rightSide ? 9 : 12

        return (
            <Grid className={`section-header ${className}`}>
                <div className='row middle-xs'>
                    <div className={`col-xs-${columnWidth}`}>
                       <div className='section-header__title'>{title}</div>
                    </div>
                    {rightSide && (
                        <div className={`col-xs-${12 - columnWidth}`}>
                            {rightSide}
                        </div>
                    )}
                </div>
            </Grid>
        )
    }
}

export {
    SectionHeader
}
