import React from 'react'
import { Link, useHistory } from 'react-router-dom'
import { DictionaryValue } from '../../../components/dictionary-value'
import { Grid } from '../../../components/grid'
import Category from '../../../models/category'
import DealPlacement from '../../../models/dealPlacement'
import FeaturedBrand from '../../../models/featuredBrand'
import CardWithData from '../../deal/CardWithData'
import './CategoryNav.scss'

export type FieldProps = {
  isActive: boolean
  categories: Category[]
  categoryFeaturedBrands: FeaturedBrand | undefined
  mainCategoryIndex: number | null
  mainCategoryHandler: (index: number) => void
}

type Props = FieldProps

const CategoryNav = (props: Props) => {
  const { isActive, categories, categoryFeaturedBrands, mainCategoryIndex } = props
  const history = useHistory()

  const toggleDisplay = isActive ? 'block' : 'none'
  let subCategories: Category[] | undefined = []
  let featuredDealSlug: DealPlacement[] | undefined = []
  let featuredBrands: FeaturedBrand | undefined

  if (mainCategoryIndex !== null) {
    subCategories = categories[mainCategoryIndex].subCategories
    featuredDealSlug = categories[mainCategoryIndex].featuredDealSlug
    featuredBrands = categories[mainCategoryIndex].featuredBrands
  }

  const categoryHandler = (event: React.SyntheticEvent<HTMLLIElement>) => {
    if (event.currentTarget.dataset.index) {
      if (categories[parseInt(event.currentTarget.dataset.index, 10)].subCategories?.length === 0) {
        history.push(`/${props.categories[parseInt(event.currentTarget.dataset.index, 10)].slug}`)
      }
      props.mainCategoryHandler(parseInt(event.currentTarget.dataset.index, 10))
    }
  }

  return (
    <nav
      className='category-nav'
      style={{ display: toggleDisplay }}
      data-scroll-lock-scrollable={true}
    >
      <div className='category-nav__body'>
        <Grid>
          <div className='row category-nav__menu-deal-container'>
            <div className='col-xs-8 category-nav__menu-container'>
              <div className='row'>
                <div className='col-xs-12 category-nav__container-title'>Shop by Category</div>
                <div className='col-xs-6 category-nav__main-menu-container'>
                  <ul className='category-nav__main-menu-list'>
                    {categories.map((item, index) => {
                      return (
                        <li
                          key={item.slug}
                          data-index={index}
                          onClick={categoryHandler}
                          className={`category-nav__main-menu-item
                              ${index === mainCategoryIndex ? 'is-active' : ''}`}
                        >
                          {item.name}
                        </li>
                      )
                    })}
                  </ul>
                </div>
                {mainCategoryIndex !== null ? (
                  <div className='col-xs-6 category-nav__sub-menu-container'>
                    <ul className='category-nav__sub-menu-list'>
                      <Link to={`/${categories[mainCategoryIndex].slug}`}>
                        <li className='category-nav__sub-menu-item'>All</li>
                      </Link>
                      {subCategories &&
                        subCategories.map(item => {
                          return (
                            <Link
                              to={
                                mainCategoryIndex !== null ? `/${categories[mainCategoryIndex].slug}/${item.slug}` : ''
                              }
                              key={item.slug}
                            >
                              <li className='category-nav__sub-menu-item'>{item.name}</li>
                            </Link>
                          )
                        })}
                    </ul>
                  </div>
                ) : (
                  ''
                )}
              </div>
            </div>
            {featuredDealSlug && featuredDealSlug.length > 0 && (
              <div className='col-xs-4 category-nav__featured-deal'>
                <div className='row'>
                  <div className='col-xs-12 category-nav__container-title'>
                    <DictionaryValue token='modal.column.featured-deal' />
                  </div>
                  <div className='col-xs-12 category-nav__deal-container'>
                    <CardWithData
                      slug={featuredDealSlug.sort((a, b) => Number(a.position) - Number(b.position))[0].slug}
                      showHover={false}
                      className='category-modal__featured-deal-card'
                    />
                  </div>
                </div>
              </div>
            )}
          </div>
          {(props.mainCategoryIndex === null
            ? categoryFeaturedBrands && categoryFeaturedBrands.brands.length > 0
            : featuredBrands && featuredBrands.brands.length > 0) && (
            <div className='row category-nav__brands-section'>
              <div className='col-xs-12 category-nav__container-title'>
                <DictionaryValue token='modal.column.featured-brands' />
              </div>
              <div className='row category-nav__brands-container'>
                {(props.mainCategoryIndex === null ? categoryFeaturedBrands : featuredBrands)?.brands.map(item => {
                  return (
                    <div
                      className='col-xs-4 col-lg-2'
                      key={item.slug}
                    >
                      <Link
                        to={`/brands/${item.slug}`}
                        className='category-nav__brand-wrapper'
                      >
                        <img
                          data-blink-src={item.squarelogo ? item.squarelogo : item.logo}
                          alt={item.name}
                          className='category-nav__brand-logo'
                        />
                        <div className='category-nav__brand-name'>{item.name}</div>
                      </Link>
                    </div>
                  )
                })}
              </div>
            </div>
          )}
        </Grid>
      </div>
    </nav>
  )
}

export default CategoryNav
