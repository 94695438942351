import * as React from 'react'
import DealPlacement from '../../models/dealPlacement'
import List from './List'
import { ApplicationState } from '../../reducers'
import { brandsQueryToArray, getCategoryDealsPageSize } from '../category/utilities'
import { categoryDealsSelector, filterCategoryDealsSelector } from '../../data/selectors'
import { connect } from 'react-redux'

type OwnProps = {
    slug: string
    showGold?: boolean
    showSandSS?: boolean
}

type FieldProps = {
    goldDeals: DealPlacement[]
    silverDeals?: DealPlacement[]
    selfServeDeals?: DealPlacement[]
    currentPage: number
}

class CategoryDealList extends React.Component<FieldProps & OwnProps> {
    render() {
        const { goldDeals, silverDeals, selfServeDeals, currentPage, showGold, showSandSS } = this.props
        const pageSize = getCategoryDealsPageSize()

        let deals

        if (showGold) {
            deals = goldDeals.slice(0, currentPage * pageSize)
        } else if (showSandSS && !showGold) {
            const concatDeals = () => {
                return [...silverDeals ? silverDeals : []]
                    .concat([
                        ...selfServeDeals ? selfServeDeals : [],
                    ].slice(0, currentPage * pageSize))
            }
            deals = concatDeals()
        } else {
            const concatDeals = () => {
                return [...goldDeals]
                    .concat([
                        ...silverDeals ? silverDeals : [],
                        ...selfServeDeals ? selfServeDeals : [],
                    ].slice(0, currentPage * pageSize))
            }
            deals = concatDeals()
        }

        return (
            <List deals={deals} />
        )
    }
}

const mapStateToProps = (state: ApplicationState, ownProps: OwnProps): FieldProps => {
    const selectedBrands = brandsQueryToArray(state.router.location.search)
    const { goldDeals, silverDeals, selfServeDeals } = categoryDealsSelector(state, ownProps)
    const filteredGoldDeals = filterCategoryDealsSelector(state, goldDeals, ownProps.slug, selectedBrands, 'gold')
    const filteredSilverDeals = filterCategoryDealsSelector(state, silverDeals, ownProps.slug, selectedBrands, 'silver')
    const filteredSelfServeDeals = filterCategoryDealsSelector(
      state, selfServeDeals, ownProps.slug, selectedBrands, 'selfServe')

    return {
        goldDeals: filteredGoldDeals,
        silverDeals: filteredSilverDeals,
        selfServeDeals: filteredSelfServeDeals,
        currentPage: state.category.silverDealsCurrentPage,
    }
}

export default connect(mapStateToProps)(CategoryDealList)
