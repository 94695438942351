import * as React from 'react'
import CmsPage from '../../models/cmsPage'
import { Grid, HTMLBlock } from '../../components'
import { Layout } from '../layout'
import Helmet from 'react-helmet'
import { convertStringToReactComponents, transformOpenAllAnchorsInNewWindow } from '../../utilities'
import { BuilderComponent } from '@builder.io/react'

export type Props = {
    page: CmsPage
}

class Page extends React.Component<Props> {
    render() {
        const { page } = this.props

        return (
            <>
                <Helmet>
                    <title>{`${page.title} - ClickFrenzy`}</title>
                </Helmet>
                {page.builderJson && (
                    <Layout hideHeader={true} hideFooter={true}>
                        <BuilderComponent model='page' content={page.builderJson} />
                    </Layout>
                )}
                {page.content && (
                    <Layout>
                        <Grid>
                            <div className='row'>
                                <div className='col-xs-12'>
                                    <HTMLBlock>
                                        {convertStringToReactComponents(
                                            page.content,
                                            transformOpenAllAnchorsInNewWindow
                                        )}
                                    </HTMLBlock>
                                </div>
                            </div>
                        </Grid>
                    </Layout>
                )}
            </>
        )
    }
}

export { Page }
