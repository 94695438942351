import * as React from 'react'
import { Button } from '../button'
import { calendar } from '../icon'
import EventType from '../../models/eventType'
import styles from './CalendarButton.module.scss'
import classnames from 'classnames'

type Props = {
    id?: string
    eventType?: EventType
    className?: string
    utmSource?: string
    utmMedium?: string
    utmCampaign?: string
}

class CalendarButton extends React.Component<React.PropsWithChildren<Props>> {
    // static defaultProps = {
    //     id: 'customCalendarButton',
    // }

    // componentDidMount() {
    //     const { id, eventId, companyId } = this.props

    //     const eventable = new (window as any).Eventable({
    //         eventId,
    //         key: companyId,
    //         target: id,
    //         hideBranding: false,
    //     })

    //     eventable.init()
    // }

    render() {
        const {
            // id,
            className,
            // eventId,
            // companyId,
            children,
            eventType,
            utmSource = 'website',
            utmMedium= 'homepage',
            utmCampaign = 'main',
        } = this.props

        // const link = `https://add.eventable.com/events/${companyId}/${eventId}/`

        const baseUrl = '//clickfrenzy.roktcalendar.com/click-frenzy/subscription/subscribe'
        const link = `${baseUrl}?utm_source=${utmSource}&utm_medium=${utmMedium}&utm_campaign=${utmCampaign}`
        const clickHandler = (e: React.MouseEvent) => e.preventDefault()

        return (
            <div className={classnames(className, 'cta-banner__cta', styles.calendarButton)}>
                <Button
                    className={classnames({
                        [`cta-banner__button--${eventType}`]: eventType,
                    })}
                    buttonType='primary'
                    // id={id}
                    buttonStyle='square'
                    size='large'
                    redirectUrl={link}
                    icon={calendar}
                    dataAttributes={{
                        'data-popup': 'lightbox',
                        'data-type': 'custombutton',
                        'data-calreply-binding': 'true',
                    }}
                    callback={clickHandler}
                >
                    {children}
                </Button>
                {/* <a
                    href={link}
                    data-event={eventId}
                    className='eventable-link'
                    target='_blank'
                    data-key={companyId}
                    data-target={id}
                    style={{ display: 'none' }}
                >
                    {children}
                </a> */}
            </div>
        )
    }
}

export { CalendarButton }
