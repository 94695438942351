import React from 'react'
import './Progress.scss'
import classnames from 'classnames'

type Props = {
    current: number
    total: number
    className?: string
}

class Progress extends React.Component<Props> {
    render() {
        const { current, total, className } = this.props

        const classes = classnames(
            className,
            'progress-bar'
        )

        let progress = 0
        let percent = '0'
        if (current !== undefined && total > 0) {
            progress = current / total * 100
            percent = `${progress}%`
        }

        return (
            <div className={classes}>
                <div className='progress-bar__background'>
                    <div className='progress-bar__progress' style={{ width: percent }} />
                </div>
            </div>
        )
    }
}

export default Progress
