import actionCreatorFactory from 'typescript-fsa'

const actionCreator = actionCreatorFactory('LOADING')

const itemLoading = actionCreator('ITEM_LOADING')
const itemLoaded = actionCreator('ITEM_LOADED')

export {
    itemLoaded,
    itemLoading,
}
