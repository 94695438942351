import * as React from 'react'
import { Link as RouterLink, LinkProps } from 'react-router-dom'

class Link extends React.Component<LinkProps> {
    render() {
        const { to, ...otherProps } = this.props
        return /^https?:\/\//.test(to as string)
            ? <a href={to as string} {...otherProps} />
            : <RouterLink {...this.props} />
    }
}

export { Link }
