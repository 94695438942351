import * as React from 'react'
import {
    DictionaryValue,
    EmailField,
} from '../../components'
import bind from 'bind-decorator'
import { AccountForm } from './AccountForm'

type Props = {
    error?: Error
    handleSubmit: (event: React.FormEvent<HTMLFormElement>) => void
    showLogIn: () => void
}

class ForgotPassword extends React.Component<Props> {
    @bind
    showLogIn(e: React.MouseEvent) {
        e.preventDefault()
        this.props.showLogIn()
        window.scrollTo(0, 0)
    }

    render() {
        const { handleSubmit, error } = this.props

        return (
            <AccountForm
                handleSubmit={handleSubmit}
                error={error}
                heading='forgot-password.heading'
                intro='forgot-password.intro'
                ctaLabel='forgot-password.submit'
                footer={(
                    <a onClick={this.showLogIn}>
                        <DictionaryValue token='forgot-password.login' />
                    </a>
                )}
                fieldList={[
                    (
                        <EmailField
                            name='email'
                            placeholder='forgot-password.field.email'
                            key='email'
                            validateFormat={false}
                        />
                    ),
                ]}
            />
        )
    }
}

export { ForgotPassword }
