/* eslint-disable */
import './polyfills'
import App from './App'
import { createRoot } from 'react-dom/client'

/* Set the scroll restoration to manual, as 'auto' 
value was causing the issues with the refresh of 
the page with always-on deals data and snaping to 
the middle of the page */
if (history.scrollRestoration) {
  history.scrollRestoration = 'manual'
} else {
  window.onbeforeunload = function () {
    window.scrollTo(0, 0)
  }
}

const container = document.getElementById('root')
const root = createRoot(container!)
root.render(<App />)

if (module.hot) {
  module.hot.accept('./App', () => {
    const NextApp = require('./App').default
    root.render(NextApp)
  })
}
