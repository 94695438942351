import { ApplicationState } from '../../../reducers'
import { GenderField, Props } from './GenderField'
import { dictionaryValueSelector } from '../../../data/selectors'
import { connect } from 'react-redux'

type OwnProps = {
    name: string
    placeholder: string
}

const mapStateToProps = (state: ApplicationState, ownProps: OwnProps): Props => {
    const placeholder = dictionaryValueSelector(ownProps.placeholder, state)
    const male = dictionaryValueSelector('form.gender.male', state)
    const female = dictionaryValueSelector('form.gender.female', state)
    const unspecified = dictionaryValueSelector('form.gender.unspecified', state)

    return {
        ...ownProps,
        placeholder,
        male,
        female,
        unspecified,
    }
}

const GenderFieldWithData = connect(mapStateToProps)(GenderField)
export { GenderFieldWithData }
