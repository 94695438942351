import * as React from 'react'
import { MenuItem } from '../../../models/menu'
import { Link, Icon, chevron } from '../../../components'

type Props = {
    item: MenuItem
}

class SubNavItem extends React.Component<Props> {
    render() {
        const { item } = this.props

        return (
            <li className='sub-nav__item col-xs-12 col-md-4'>
                    <Link
                        to={item.path}
                        className='sub-nav__link'
                    >
                        <span className='sub-nav__text'>
                            {item.name}
                        </span>
                        <Icon icon={chevron} className='sub-nav__icon' />
                    </Link>
            </li>
        )
    }
}

export default SubNavItem
