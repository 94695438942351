import React from 'react'
import { Grid, LogoProps, Logo } from '..'
import './LogoWall.scss'

type Props = {
    logos: LogoProps[]
    className?: string
}

class LogoWall extends React.Component<Props> {
    static defaultProps = {
        className: '',
    }

    render() {
        const { logos, className } = this.props

        return (
            <Grid className={`logo-wall ${className}`}>
                <div className='row logo-wall__row'>
                    {logos.map(brand => (
                        <div key={brand.alt} className='col-xs-3 col-md-2 logo-wall__col'>
                            <Logo {...brand} size='block' />
                        </div>
                    ))}
                </div>
            </Grid>
        )
    }
}

export { LogoWall }
