import * as React from 'react'
import { ApplicationState } from '../../../reducers'
import { connect } from 'react-redux'
import FooterLink from '../../../models/footerLink'
import CFFooter, { FieldProps } from '.'
import { loadFooterData } from '../../../features/global/footer/actions'

type LoadMethodProps = {
  loadFooterData: () => void
}

type OwnProps = {
  showPartners?: boolean
  customLinks?: FooterLink[]
}

type LoadProps = FieldProps & LoadMethodProps & OwnProps

class CFLoadFooter extends React.Component<LoadProps> {
  componentDidMount() {
    this.props.loadFooterData()
  }

  render() {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const { loadFooterData, ...props } = this.props

    return <CFFooter {...props} />
  }
}

const mapStateToProps = (state: ApplicationState, ownProps: OwnProps): FieldProps => {
  const links = ownProps.customLinks || state.data.footerLinks.sort((a, b) => a.sortOrder - b.sortOrder)
  const socialAccounts = state.data.socialAccounts.sort((a, b) => a.sortOrder - b.sortOrder)

  return {
    links,
    socialAccounts,
  }
}

const actions: LoadMethodProps = {
  loadFooterData,
}

const CFFooterWithData = connect(mapStateToProps, actions)(CFLoadFooter)
export default CFFooterWithData
