import * as React from 'react'
import makeCountdown from '../../properties/makeCountdown'

type Props = {
  hours: number
  minutes: number
  seconds: number
}

class CountdownText extends React.Component<Props> {
  render() {
    const { hours, minutes, seconds } = this.props
    const format = new Intl.NumberFormat('en-AU', { style: 'decimal', minimumIntegerDigits: 2 }).format

    return (
      <>
        {hours !== 0 ? `{format(hours % 24)} : ` : ''}
        {minutes !== 0 ? `{format(minutes)} : ` : ''}
        {format(seconds)}
      </>
    )
  }
}

export default makeCountdown(CountdownText)
