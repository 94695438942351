import { LOCATION_CHANGE } from 'connected-react-router'
import { createMiddleware, EventsMap } from 'redux-beacon'
import GoogleTagManager from '@redux-beacon/google-tag-manager'
import { ApplicationState } from '../reducers'

const wd = (window as any)
const dl = wd.dataLayer || []
const dataLayerPushEvent = (event: string, category?: string, label?: string, value?: string) => {
    dl.push({
        event,
        category,
        label,
        value,
    })
}

const dataLayerPushPageType = (page: string) => {
    dl.push({ pageType: page })
}

const eventsMap: EventsMap = {
    [LOCATION_CHANGE]: (action, prevState: ApplicationState) => {
        // don't fire this event on the first LOCATION_CHANGE event, before
        // we've loaded events, as we will be firing another one with the
        // full data after the event is loaded
        if (prevState.data.events.length === 0) { return }

        return {
            hitType: 'pageview',
            page: action.payload,
        }
    },
}

const gtmMiddleware = createMiddleware(eventsMap, GoogleTagManager())

export {
    dataLayerPushEvent,
    dataLayerPushPageType,
    gtmMiddleware,
}
