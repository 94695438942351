import React from 'react'
import { Grid } from '../../../components'
import { BackToTop } from '../../../components/back-to-top'
import { HeaderWithData } from '../../home/event-site/header'
import { Layout } from '../../layout'
import { SearchBarWithData } from './SearchBar'
import { SearchHeaderWithData } from './SearchHeader'
import './SearchPage.scss'
import { SearchPageBodyWithData } from './SearchPageBody'
import { SearchRootWithData } from './SearchRoot'
import SponsoredResults from './SponsoredResults'

class SearchPage extends React.Component {
  render() {
    const headerBannerType = 'SEARCH_LEADERBOARD'
    return (
      <SearchRootWithData>
        <Layout>
          <HeaderWithData type={headerBannerType} />
          <Grid className='search-page'>
            <div className='row search-page__header'>
              <div className='col-xs-12'>
                <SearchBarWithData />
              </div>
            </div>
            <SponsoredResults />
            <SearchHeaderWithData />
            <SearchPageBodyWithData />
            <BackToTop />
          </Grid>
        </Layout>
      </SearchRootWithData>
    )
  }
}

export { SearchPage }
