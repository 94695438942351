import * as React from 'react'
import classNames from 'classnames'
import './Select.scss'
import { DictionaryValue } from '../dictionary-value'

type ComponentProps = {
    errorMessage?: string
}

type InputProps = React.InputHTMLAttributes<HTMLSelectElement>

type Props = ComponentProps & InputProps

class Select extends React.Component<Props> {
    render() {
        // eslint-disable-next-line react/prop-types
        const { className, errorMessage, children, value, ...rest } = this.props

        const classes = classNames(
            'select',
            className,
            { 'select--error': errorMessage },
            { 'select--empty': !value }
        )

        return (
            <>
                <select className={classes} value={value} {...rest}>
                    {children}
                </select>
                { errorMessage && (
                    <p className='select__error-message'>
                        <DictionaryValue token={errorMessage} />
                    </p>
                )}
            </>
        )
    }
}

export { Select }
