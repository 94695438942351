import * as React from 'react'
import classnames from 'classnames'
import { MenuItem } from '../../../models/menu'
import SubNavItem from './SubNavItem'
import './SubNav.scss'
import { Grid } from '../../../components/grid'
import EventType from '../../../models/eventType'
import { NavBarColors } from './NavBar'

export type FieldProps = {
    isSubmenuOpen: boolean
    items: MenuItem[]
    eventType?: EventType | null
    customColors?: NavBarColors
}

export type Props = FieldProps

class SubNav extends React.Component<Props> {
    render() {
        const { isSubmenuOpen, items, eventType, customColors } = this.props

        return (
            <div
                className={classnames('sub-nav', {
                    'sub-nav--is-active': isSubmenuOpen,
                    [`sub-nav--${eventType}`]: eventType,
                })}
                style={customColors ? { background: customColors.backgroundColor } : {}}
                data-scroll-lock-scrollable={true}
            >
                <Grid>
                    <ul className='row sub-nav__items'>
                        {items.map(item => <SubNavItem item={item} key={`${item.name}|${item.path}`} />)}
                    </ul>
                </Grid>
            </div>
        )
    }
}

export default SubNav
