import * as React from 'react'
import Slider, { Settings } from 'react-slick'

interface IProps {
  icon?: string
  rand?: number
  arrows: boolean
  buttonPrev?: JSX.Element | undefined
  buttonNext?: JSX.Element | undefined
}
class CFCarousel extends React.Component<React.PropsWithChildren<IProps>> {
  shouldComponentUpdate() {
    return false
  }

  render() {
    const { children, arrows, buttonPrev, buttonNext } = this.props
    const settings: Settings = {
      autoplaySpeed: 5000,
      speed: 1000,
      autoplay: true,
      arrows: arrows,
      dots: false,
      infinite: children && Array.isArray(children) ? children.length > 1 : false,
      slidesToScroll: 1,
      slidesToShow: 1,
      initialSlide: this.props.rand ? this.props.rand : 0,
      className: 'cf-carousel',
      nextArrow: buttonNext ? buttonNext : <span>NEXT</span>,
      prevArrow: buttonPrev ? buttonPrev : <span>PREV</span>,
    }

    return <Slider {...settings}>{children}</Slider>
  }
}

export default CFCarousel
