import React from 'react'
import './Badge.scss'

type Props = React.HTMLAttributes<HTMLSpanElement>

// eslint-disable-next-line react/prop-types
const Badge: React.FunctionComponent<Props> = ({ className = '', children, ...rest }) => (
    <span className={`badge ${className}`} {...rest}>
        {children}
    </span>
)

export { Badge }
