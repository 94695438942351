import { RefinementListExposed, RefinementListProvided } from 'react-instantsearch-core'
import { connectRefinementList } from 'react-instantsearch-dom'
import './SearchRefineBy.scss'
import { connect } from 'react-redux'
import { ApplicationState } from '../../../reducers'
import {
    attachBrandLogoToRefineItem,
    generateResetItem,
    sortRefineItemsAlphabetically,
    sortRefineItemsCheckedToTop,
} from '../utilities'
import { SearchRefineBy } from './SearchRefineBy'
import { loadBrands } from '../../brand/actions'

type OwnProps = RefinementListProvided & RefinementListExposed & {
    requestClose: () => void
    className?: string
}

const mapStateToProps = (state: ApplicationState, ownProps: OwnProps) => {
    const { items, refine, currentRefinement, requestClose, className } = ownProps

    const resetItem = generateResetItem('search.filter.all', currentRefinement.length === 0)

    const formattedItems = items
        .map((item, index) => attachBrandLogoToRefineItem(state, item, index))
        .sort(sortRefineItemsAlphabetically)
        .sort(sortRefineItemsCheckedToTop)

    const itemsWithReset = [
        resetItem,
        ...formattedItems,
    ]

    return {
        className,
        refine,
        requestClose,
        items: itemsWithReset,
    }
}

const dispatch = {
    loadBrands,
}

const SearchRefineByWithRedux = connect(mapStateToProps, dispatch)(SearchRefineBy)
const SearchRefineByWithData = connectRefinementList(SearchRefineByWithRedux)

export { SearchRefineByWithData }
