import { Action } from 'redux'
import { isType } from 'typescript-fsa'
import { recentSearchTermsLoaded, recentSearchTermsUpdated } from './actions'
import { SearchTerm } from '../types'

export type State = {
    recentSearchTerms: SearchTerm[]
}

const initialState: State = {
    recentSearchTerms: [],
}

export function searchReducer(state: State = initialState, action: Action): State {

    if (isType(action, recentSearchTermsLoaded)) {
        return {
            ...state,
            recentSearchTerms: action.payload,
        }
    }

    if (isType(action, recentSearchTermsUpdated)) {
        return {
            ...state,
            recentSearchTerms: action.payload,
        }
    }

    return state
}
