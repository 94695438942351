import * as React from 'react'
import { ApplicationState } from '../../../reducers'
import { loadHomepageData } from './actions'
import { connect } from 'react-redux'
import Home from './home'
import { dataLayerPushPageType } from '../../../services/gtm'
import Collection from '../../../models/collection'
import { currentEventSelector } from '../../../data/selectors'
import EventType from '../../../models/eventType'

type FieldProps = {
    eventType: EventType | undefined
    eventCollections: Collection[] | undefined
}

type MethodProps = {
    loadHomepage: () => void
}

type Props = MethodProps & FieldProps

class HomePage extends React.Component<Props> {
    componentDidMount() {
        this.props.loadHomepage()
        dataLayerPushPageType('Home')
    }

    render() {
        const { eventCollections, eventType } = this.props
        return (
            <Home
                collections={eventCollections}
                eventType={eventType}
            />
        )
    }
}

const mapStateToProps = (state: ApplicationState): FieldProps => {
    const event = currentEventSelector(state)
    let collections
    let eventType
    if (event !== undefined) {
        collections = event.collections
        eventType = event.type
    }
    return {
        eventType,
        eventCollections: collections,
    }
}

const actions: MethodProps = {
    loadHomepage: loadHomepageData,
}

export default connect(
    mapStateToProps,
    actions
)(HomePage)
