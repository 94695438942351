import moment from 'moment'
import * as React from 'react'
import CountdownTimer from '../countdown/CountdownTimer'
import Event from '../../models/event'
import './EventsCountdownCard.scss'
import { CalendarButton } from '../calendar-button'
import { DictionaryValue } from '../dictionary-value'

export type Props = {
  event: Event
}

class EventCountdownCard extends React.Component<Props> {
  render() {
    const {
      event: { slug, type, name, description, logo, start },
    } = this.props

    const to = moment.unix(start)

    return (
      <div className='col-xs-12 col-md-4'>
        <div className={`cf-events-countdown__card cf-events-countdown__card--${type}`}>
          <span className='cf-events-countdown__logo'>
            <img
              src='data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAIAAACQd1PeAAAABnRSTlMA/
                            wD/AP83WBt9AAAADElEQVQI12P4//8/AAX+Av7czFnnAAAAAElFTkSuQmCC'
              data-blink-src={logo}
              alt='Card Logo'
            />
          </span>
          <h3 className='cf-events-countdown__heading'>{name}</h3>
          <time
            className='cf-events-countdown__date'
            dateTime={to.format('Y-m-d')}
          >
            {to.format('h:mmA dddd, MMM DD YYYY')}
          </time>
          <p className='cf-events-countdown__description'>{description}</p>
          <CountdownTimer to={start} />
          <CalendarButton
            id={`countdownCard-${slug}`}
            eventType={type}
            className='cf-events-countdown__calendar-button'
            utmCampaign='savethedate'
          >
            <DictionaryValue token='be-reminded.cta' />
          </CalendarButton>
        </div>
      </div>
    )
  }
}

export default EventCountdownCard
