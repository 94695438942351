import { CloseCircleOutlined } from '@ant-design/icons'
import { Button, Input, Modal, Select } from 'antd'
import { toPng } from 'html-to-image'
import React, { useCallback, useEffect, useRef, useState } from 'react'
import { connect } from 'react-redux'
import { DictionaryValue } from '../../components'
import Brand from '../../models/brand'
import { ApplicationState } from '../../reducers'
import { loadBrands, loadNonEventConfig } from '../../services/Api'
import barcode from '../../static/images/barcode.gif'
import cflogo from '../../static/images/cf_classic_logo_black.png'
import { Layout } from '../layout'
import './ClickfrenzyReceipt.scss'

const { Option } = Select
const RestrictedNames: string[] = ['fuck', 'shit', 'wanker', 'tit', 'dick', 'cock', 'pussy', 'asshole']

const nativeInputValueSetter = Object.getOwnPropertyDescriptor(window.HTMLInputElement.prototype, 'value')?.set

type FieldProps = {
  isEventRunning: boolean
}

const ClickfrenzyReceipt = (props: FieldProps) => {
  const [isLoading, setIsLoading] = useState(false)
  const [brands, setBrands] = useState<Brand[]>([])
  const [itemCount, setItemCount] = useState(2)
  const [maxItemCount, setMaxItemCount] = useState(10)
  const [modalOpen, setModalOpen] = useState(false)
  const [validationError, setValidationError] = useState(false)
  const [isAddMoreHidden, setIsAddMoreHidden] = useState(false)
  const [priceError, setPriceError] = useState(false)
  const [isNameEntered, setIsNameEntered] = useState(false)
  const [isImageError, setIsImageError] = useState(false)

  const receiptRef = useRef<HTMLDivElement>(null)
  const previewImgRef = useRef<HTMLImageElement>(null)

  useEffect(() => {
    const loadAllBrands = async () => {
      try {
        setIsLoading(true)
        let data: Brand[] = []
        if (props.isEventRunning) {
          data = await loadBrands()
        } else {
          const result = await loadNonEventConfig()
          if (result.topRetailers && result.topRetailers.brands) {
            data = result.topRetailers.brands
          }
        }
        setBrands(data)
        setIsLoading(false)
      } catch (error) {
        console.log(error)
      }
    }
    loadAllBrands().catch(e => console.log(e))
  }, [])

  const handleShareReceipt = useCallback(
    (brands: Brand[]) => {
      const inputFeilds = document.querySelectorAll('.name-input, .item-input') as NodeListOf<HTMLInputElement>
      const inputValues = Array.from(inputFeilds).map(inputElement => inputElement.attributes[3].value)
      const priceFeilds = Array.from(
        document.querySelectorAll('.price-input .ant-input') as NodeListOf<HTMLInputElement>
      )
      const isRestrictedText = inputValues.find(value => RestrictedNames.includes(value.toLowerCase()))
      const erroredPriceFeilds = priceFeilds.filter(field =>
        field.value === '' ? false : !field.value.match(/^\d{1,4}(\.\d{0,2})?$/)
      )
      erroredPriceFeilds.forEach(field => {
        field.style.color = 'red'
      })
      const isPriceError = erroredPriceFeilds.length > 0
      if (isRestrictedText) {
        setValidationError(true)
      } else {
        setValidationError(false)
      }
      if (isPriceError) {
        setPriceError(true)
      } else {
        setPriceError(false)
      }
      if (!isRestrictedText && !isPriceError) {
        Array.from(inputFeilds).forEach(feild => {
          if (feild.value === '') {
            nativeInputValueSetter?.call(
              feild,
              Array.from(feild.classList).includes('name-input') ? 'Check Out My Dream Receipt' : 'Click Frenzy Bargain'
            )
            const event = new Event('input', { bubbles: true })
            feild.dispatchEvent(event)
          }
        })
        priceFeilds.forEach(feild => {
          if (feild.value === '') {
            nativeInputValueSetter?.call(feild, Math.floor(Math.random() * 41 + 10).toString())
            const event = new Event('input', { bubbles: true })
            feild.dispatchEvent(event)
          }
        })
        const brandSelects = document.querySelectorAll('.ant-select') as NodeListOf<HTMLDivElement>
        Array.from(brandSelects).forEach(elem => {
          if (elem.children[0].children[1].className !== 'ant-select-selection-item') {
            elem.style.display = 'none'
            const newElem = document.createElement('span')
            const randomBrand = brands[Math.floor(Math.random() * brands.length)]
            newElem.setAttribute('title', randomBrand ? randomBrand.name : 'Click Frenzy Brand')
            newElem.innerText = randomBrand ? randomBrand.name : 'Click Frenzy Brand'
            newElem.classList.add('select-substitute')
            Object.assign(newElem.style, {
              fontSize: '16px',
              paddingLeft: '11px',
              paddingRight: '11px',
              lineHeight: '30px',
              textOverflow: 'ellipsis',
              fontWeight: 'normal',
              whiteSpace: 'nowrap',
              color: '#000000D9',
            })
            elem.parentNode?.appendChild(newElem)
          }
        })
        toggleItemDelete(true)
        setIsAddMoreHidden(true)
      } else {
        setIsAddMoreHidden(false)
      }
    },
    [receiptRef]
  )

  useEffect(() => {
    if (isAddMoreHidden) {
      if (receiptRef.current === null) {
        return
      }

      setModalOpen(true)

      toPng(receiptRef.current, {
        skipAutoScale: true,
      })
        .then(dataUrl => {
          if (previewImgRef.current) {
            previewImgRef.current.src = dataUrl
            const link = document.createElement('a')
            link.download = 'cf-receipt.png'
            link.href = dataUrl
            link.click()
          }
        })
        .catch(err => {
          console.log(err)
          setIsImageError(true)
        })
    }
  }, [isAddMoreHidden])

  const handleTextChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const text = event.target.value.toLowerCase()
    if (RestrictedNames.find(restricted => text.includes(restricted))) {
      event.target.style.color = 'red'
    } else {
      event.target.style.color = 'black'
    }
  }

  const handlePriceChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    event.target.style.color = 'black'
  }

  const handleNameBlur = (event: React.FocusEvent<HTMLInputElement>) => {
    if (!isNameEntered && event.target.value !== '') {
      nativeInputValueSetter?.call(event.target, event.target.value + `'s Dream Receipt`)
      const changeEvent = new Event('input', { bubbles: true })
      event.target.dispatchEvent(changeEvent)
      setIsNameEntered(true)
    }
  }

  const handelModalCancel = () => {
    setModalOpen(false)
    setIsAddMoreHidden(false)
    toggleItemDelete(false)
    setIsImageError(false)
    const brandSelects = document.querySelectorAll('.ant-select') as NodeListOf<HTMLDivElement>
    Array.from(brandSelects).forEach(elem => {
      elem.style.display = elem.style.display === 'none' ? 'block' : elem.style.display
    })
    const selectSubsitutes = document.querySelectorAll('.select-substitute') as NodeListOf<HTMLSpanElement>
    Array.from(selectSubsitutes).forEach(elem => {
      elem.style.display = 'none'
    })
    const inputFeilds = document.querySelectorAll('.name-input, .item-input') as NodeListOf<HTMLInputElement>
    Array.from(inputFeilds).forEach(feild => {
      if (feild.value === 'Check Out My Dream Receipt' || feild.value === 'Click Frenzy Bargain') {
        nativeInputValueSetter?.call(feild, '')
        const event = new Event('input', { bubbles: true })
        feild.dispatchEvent(event)
      }
    })

    const priceFeilds = Array.from(document.querySelectorAll('.price-input .ant-input') as NodeListOf<HTMLInputElement>)
    priceFeilds.forEach(feild => {
      if (feild.value === '10') {
        nativeInputValueSetter?.call(feild, '')
        const event = new Event('input', { bubbles: true })
        feild.dispatchEvent(event)
      }
    })
  }

  const toggleItemDelete = (isHidden: boolean) => {
    const itemDeleteIcons = document.querySelectorAll('.close-icon') as NodeListOf<HTMLSpanElement>
    Array.from(itemDeleteIcons).forEach(elem => {
      elem.style.display = isHidden ? 'none' : 'block'
    })
  }

  const handleDelete = (index: number) => {
    const receipt = document.querySelector('.cfreceipt-receipt__page-content')
    const items = receipt?.childNodes as NodeListOf<HTMLDivElement>
    Array.from(items).forEach(item => {
      if (item.dataset.index === index.toString() && receipt) {
        receipt.removeChild(item)
      }
    })
    setMaxItemCount(prevState => prevState + 1)
  }

  if (isLoading) {
    return (
      <div className='loading-overlay'>
        <h1 className='loading-overlay__text'>Loading...</h1>
      </div>
    )
  }
  return (
    <>
      <Layout>
        <div className='container-new grid cfreceipt'>
          <div
            className='row header'
            style={{ marginBottom: '20px' }}
          >
            <div className='col-xs-12 cfreceipt-title__container'>
              <h2>
                <DictionaryValue token='cfreceipt.title' />
              </h2>
            </div>
            <div className='col-xs-12 cfreceipt-description__container'>
              <div
                style={{
                  fontSize: '16px',
                  margin: '10px 25px 0',
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'center',
                }}
              >
                <div className='description-title'>
                  Create your Click Frenzy Dream Receipt and post it to your Facebook or Instagram page for your chance
                  to win a Luxe Kitchen prize pack, valued at $3,347!
                </div>
                <div style={{ textAlign: 'left', marginTop: '15px' }}>
                  <div style={{ paddingLeft: '45px', marginBottom: '5px' }}>The winner will take home:</div>
                  <ul className='winner-prizes'>
                    <li>Bed Threads: Lavender, Sage & Olive Table Bundle</li>
                    <li>Smeg 50&apos;s Style Toaster</li>
                    <li>Le Creuset Cast Iron Casserole</li>
                    <li>KitchenAid Stand Mixer</li>
                    <li>Breville Barista Pro Coffee Machine</li>
                  </ul>
                </div>
                <div style={{ textAlign: 'left', marginTop: '10px' }}>
                  <ol>
                    <li>Post your receipt to Instagram or Facebook</li>
                    <li>Tag @click_Frenzy (Instagram) or @clickfrenzy (facebook)</li>
                    <li>Use #clickfrenzydreamreceipt</li>
                    <li>Wait for the winner to be announced!</li>
                  </ol>
                </div>
                <div>
                  <a
                    href='https://clickfrenzy.s3.ap-southeast-2.amazonaws.com/CF+Dream+Receipt+Terms+and+Conditions.pdf'
                    target='_blank'
                    rel='noreferrer'
                  >
                    *T&C’s apply
                  </a>
                </div>
              </div>
              {/* <p>
                <DictionaryValue token='cfreceipt.description' />
              </p> */}
            </div>
          </div>
          <div className='row content'>
            <div
              className='cfreceipt-receipt__container'
              ref={receiptRef}
            >
              <div className='col-xs-12 cfreceipt-receipt__page'>
                <div className='col-xs-12 cfreceipt-receipt__page-header'>
                  <div className='name-container'>
                    <Input
                      placeholder='Add your name'
                      defaultValue=''
                      bordered={false}
                      className='name-input'
                      onChange={handleTextChange}
                      onBlur={handleNameBlur}
                    />
                  </div>
                  <div className='logo-container'>
                    <img
                      src={cflogo}
                      alt='clickfrenzy_logo'
                      className='clickfrenzy-logo'
                    />
                  </div>
                </div>
                <div className='col-xs-12 cfreceipt-receipt__page-content'>
                  {[...Array(itemCount)].map((item, index) => {
                    return (
                      <div
                        className='col-xs-11 row-container'
                        key={index}
                        data-index={index.toString()}
                      >
                        <div className='item-container'>
                          <div className='brand-container'>
                            <Select
                              className='brand-select'
                              placeholder='Select Brand'
                              showSearch
                              bordered={false}
                              showArrow={false}
                              optionFilterProp='children'
                              filterOption={(input, option) =>
                                (option?.children as unknown as string).toLowerCase().includes(input.toLowerCase())
                              }
                            >
                              {brands.map((brand, index) => {
                                return (
                                  <Option
                                    key={index}
                                    value={brand.slug}
                                  >
                                    {brand?.name}
                                  </Option>
                                )
                              })}
                            </Select>
                            <CloseCircleOutlined
                              className='close-icon'
                              onClick={() => {
                                handleDelete(index)
                              }}
                            />
                          </div>
                          <div className='item-price-container'>
                            <div>
                              <Input
                                placeholder='Add Item'
                                bordered={false}
                                className='item-input'
                                onChange={handleTextChange}
                              />
                            </div>
                            <div>
                              <Input
                                placeholder='Price'
                                bordered={false}
                                prefix={<span>$</span>}
                                className='price-input'
                                type='number'
                                onFocus={handlePriceChange}
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                    )
                  })}
                </div>
                {!isAddMoreHidden && (
                  <Button
                    disabled={itemCount >= maxItemCount}
                    onClick={() =>
                      setItemCount(prevItemCount => (prevItemCount < maxItemCount ? prevItemCount + 1 : prevItemCount))
                    }
                  >
                    Add more items
                  </Button>
                )}
                <div className='col-xs-12 cfreceipt-receipt__page-footer'>
                  <div className='row'>
                    <div className='col-xs-11 divider'></div>
                  </div>
                  <div className='footer1'>
                    <DictionaryValue token='cfreceipt.receipt.footer1' />
                    <div>
                      <a href='https://clickfrenzy.com.au'>CLICKFRENZY.COM.AU</a>
                    </div>
                  </div>
                  <div className='barcode'>
                    <img
                      src={barcode}
                      alt='clickfrenzy_main_event_barcode'
                    />
                  </div>
                  <div className='footer2'>
                    <DictionaryValue token='cfreceipt.receipt.footer2' />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className='row cta'>
            <div className='col-xs-12'>
              <Button
                size='large'
                type='primary'
                onClick={() => handleShareReceipt(brands)}
              >
                Share Your Receipt
              </Button>
              <div className='error-message'>
                {validationError && <DictionaryValue token='cfreceipt.validation.text' />}
              </div>
              <div className='error-message'>
                {priceError && <DictionaryValue token='cfreceipt.validation.price' />}
              </div>
              <Modal
                title='Share Your Receipt'
                open={modalOpen}
                footer={null}
                onCancel={handelModalCancel}
              >
                <div className='cfreceipt-modal__content-container'>
                  <div className='cfreceipt-modal__preview-container'>
                    <img
                      src='https://via.placeholder.com/300x400.png?text=Clickfrenzy+Receipt+Preview'
                      alt='Preview'
                      ref={previewImgRef}
                    />
                  </div>
                  {isImageError ? (
                    <div
                      className='cfreceipt-modal__error-message'
                      style={{ fontSize: '16px', color: 'red', textAlign: 'center', marginTop: '10px' }}
                    >
                      Image preview failed, try again
                    </div>
                  ) : (
                    <div style={{ fontSize: '16px', textAlign: 'center', margin: '10px 25px 0' }}>
                      <div>
                        Post your dream receipt to your Instagram or Facebook to go into the running* to win our Luxe
                        Kitchen Prize Pack, valued at $3,347! Make sure you do the following:
                      </div>
                      <div style={{ textAlign: 'left', marginTop: '10px' }}>
                        <ol>
                          <li>Post your receipt to Instagram or Facebook</li>
                          <li>Tag @click_Frenzy (Instagram) or @clickfrenzy (facebook)</li>
                          <li>Use #clickfrenzydreamreceipt</li>
                          <li>Wait for the winner to be announced!</li>
                        </ol>
                      </div>
                      <div>
                        <a
                          href='https://clickfrenzy.s3.ap-southeast-2.amazonaws.com/CF+Dream+Receipt+Terms+and+Conditions.pdf'
                          target='_blank'
                          rel='noreferrer'
                        >
                          *T&C’s apply
                        </a>
                      </div>
                    </div>
                  )}
                </div>
              </Modal>
            </div>
          </div>
        </div>
      </Layout>
    </>
  )
}

const mapStateToProps = (state: ApplicationState): FieldProps => {
  return {
    isEventRunning: state.status.isEventRunning,
  }
}

const CfReceiptWithData = connect(mapStateToProps)(ClickfrenzyReceipt)

export default CfReceiptWithData
