import { Builder, BuilderElement, withChildren } from '@builder.io/react'
import React, { PropsWithChildren } from 'react'
import { Grid } from '../../../components/grid'

type Props = {
    children: BuilderElement[]
}

export const GridComponent = (props: PropsWithChildren<Props>) => (
    <Grid>
        <div className='row'>
            <div className='col-xs-12'>
                {props.children}
            </div>
        </div>
    </Grid>
)

const GridWithBuilderChildren = withChildren(GridComponent)

Builder.registerComponent(GridWithBuilderChildren, {
    name: 'Grid Component',
    // Adding defaults is important for easy usability
    defaultChildren: [
        {
            '@type': '@builder.io/sdk:Element',
            component: { name: 'Text', options: { text: 'I am child text block!' } },
        },
    ],
})
