export const toggleMobileMenu = (isOpenMobileNav: boolean) => {
  const mobileNav = document.getElementById('mobile-nav')
  const navContent = document.getElementById('menu-warp')

  if (isOpenMobileNav) {
    document.body.setAttribute('style', 'overflow: inherit;')
    mobileNav?.setAttribute('style', 'opacity:0; z-index: 9; pointer-events: none;')
    navContent?.setAttribute('style', 'opacity:0;')
    mobileNav?.setAttribute('style', 'z-index: -100;')
    // setTimeout(() => {
    //   mobileNav?.setAttribute('style', 'z-index: -100;')
    // }, 400)
  } else {
    document.body.setAttribute('style', 'overflow: hidden;')
    mobileNav?.setAttribute('style', 'opacity:1; z-index: 9; pointer-events: auto;')
    navContent?.setAttribute('style', 'opacity:1;')
  }
}
