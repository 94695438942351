import { Action } from 'redux'
import { isType } from 'typescript-fsa'
import { signIn, signOut, updateAccount } from './actions'
import { ApplicationState } from '../../reducers'
import { AddressFormat, FavouriteDeal } from './types'
import { setIsUserAdminInsecure, removeIsUserAdminInsecure } from '../../utilities'

export type State = {
    loggedIn: boolean
    username: string | undefined
    email: string | undefined
    favDeals: FavouriteDeal[]
    mobile?: string
    address?: AddressFormat
    isAdmin?: boolean
    group?: string
    firstName?: string
    lastName?: string
    gender?: string
    marketing?: boolean
}

const initialState: State = {
    loggedIn: false,
    isAdmin: false,
    group: undefined,
    username: undefined,
    email: undefined,
    favDeals: [],
    address: undefined,
    firstName: undefined,
    lastName: undefined,
    gender: undefined,
    marketing: undefined,
}

export function accountReducer(state: State = initialState, action: Action): State {
    if (isType(action, signIn)) {
        if (action.payload.isAdmin) {
            setIsUserAdminInsecure()
        } else {
            removeIsUserAdminInsecure()
        }

        return {
            ...state,
            loggedIn: true,
            ...action.payload,
        }
    }

    if (isType(action, updateAccount)) {
        return {
            ...state,
            ...action.payload,
        }
    }

    if (isType(action, signOut)) {
        removeIsUserAdminInsecure()
        return {
            ...state,
            loggedIn: false,
            username: undefined,
            email: undefined,
            isAdmin: undefined,
            group: undefined,
            address: undefined,
            mobile: undefined,
            firstName: undefined,
            lastName: undefined,
            gender: undefined,
            marketing: undefined,
        }
    }

    return state
}

export const isLoggedInSelector = (state: ApplicationState): boolean => state.account.loggedIn
