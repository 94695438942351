import * as React from 'react'
import { FieldRenderProps } from 'react-final-form'
import { Select } from '../Select'
import { getErrorMessage } from '../utilities'

type Props = FieldRenderProps<string, HTMLElement>

class SelectField extends React.Component<Props> {
    render() {
        const { input, meta, children, ...rest } = this.props
        const errorMessage = getErrorMessage(meta)

        return (
            <Select
                {...input}
                {...rest}
                errorMessage={errorMessage}
            >
                {children}
            </Select>
        )
    }
}

export { SelectField }
