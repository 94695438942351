import { FileInfo, Widget } from '@uploadcare/react-widget'
import classNames from 'classnames'
import { Component } from 'react'
import { ProjectType } from '../../constants'
import Block from '../block/Block'
import './ImageUpload.scss'

type Props = {
  project?: ProjectType
  type?: string
  minWidth?: number
  minHeight?: number
  resizeWidth?: number
  resizeHeight?: number
  allowCrop?: boolean
  allowMultiple?: boolean
  value?: any
  description?: string
  allowFileTypes?: string[]
  allowFileSize?: { min?: number; max?: number }
  onChange?: (value: string) => void
  onFinish?: (value: FileInfo) => void
  valueField?: keyof FileInfo
}

type State = {
  hasUploaded: boolean
  value: string | undefined
}

class ImageUpload extends Component<Props, State> {
  static defaultProps = {
    allowCrop: false,
    allowMultiple: false,
  }

  readonly state = {
    hasUploaded: false,
    value: undefined,
  }

  static getDerivedStateFromProps(props: Props, state: State) {
    if (props.value !== state.value) {
      return {
        value: props.value,
      }
    }
    return null
  }

  handleFileSelect = () => {
    this.setState({ hasUploaded: true })
  }

  handleChange = (fileInfo: FileInfo) => {
    const { onChange, onFinish, valueField } = this.props

    let field: any = null
    if (!valueField) {
      field = fileInfo.cdnUrl
    } else {
      field = fileInfo[valueField]
    }

    if (onChange && field !== null) {
      if (field) {
        onChange(field.toString())
        if (onFinish) {
          onFinish(fileInfo)
        }
        this.setState({ value: field.toString() })
      }
      if (!this.state.hasUploaded) {
        this.forceUpdate()
      }
    }
  }

  handleValidator = (fileInfo: FileInfo) => {
    if (fileInfo.name === null || fileInfo.size === null) {
      return
    }

    const extension = fileInfo.name.split('.').pop()

    if (extension && this.props.allowFileTypes && !this.props.allowFileTypes.includes(extension)) {
      alert('Wrong file type! Please click "Remove" and reselect the file.')
      throw new Error('fileType')
    }

    if (this.props.allowFileSize) {
      const min = this.props.allowFileSize.min ? this.props.allowFileSize.min : 0
      const max = this.props.allowFileSize.max ? this.props.allowFileSize.max : 100 * 1024 * 1024

      if (min && fileInfo.size < min * 1024 * 1024) {
        alert('File is too small. Please click "Remove" and reselect the file.')
        throw new Error('fileMinimalSize')
      }

      if (max && fileInfo.size > max * 1024 * 1024) {
        alert('File is too big. Please click "Remove" and reselect the file.')
        throw new Error('fileMaximumSize')
      }
    }
  }

  render() {
    const { project, type, minWidth, minHeight, resizeWidth, resizeHeight, allowCrop, allowMultiple, description } =
      this.props
    const classes = classNames('cfa-image-upload')
    const imageUrl = this.state.value
    let crop: string | undefined = `${minWidth}x${minHeight} minimum`
    let imageShrink: string | null = `${resizeWidth}x${resizeHeight}`

    if (!allowCrop) {
      crop = undefined
    }

    if (type && type === 'square') {
      crop = '1:1'
    }

    if (!resizeHeight || resizeWidth) {
      imageShrink = null
    }

    let publicKey: any = null

    switch (project) {
      case ProjectType.frenzWithBenefits:
        publicKey = process.env.REACT_APP_UPLOADCARE_PUBLIC_KEY_FWB || null
        break
      case ProjectType.frenzyBucks:
        publicKey = process.env.REACT_APP_UPLOADCARE_PUBLIC_KEY_USER || null
        break
      default:
        publicKey = process.env.REACT_APP_UPLOADCARE_PUBLIC_KEY_USER || null
        break
    }

    return (
      <div className={classes}>
        <Block
          left={true}
          right={true}
          className='cfa-image-upload__content'
        >
          <Widget
            value={imageUrl}
            multiple={allowMultiple}
            crop={crop}
            image-shrink={imageShrink}
            publicKey={publicKey}
            onChange={this.handleChange}
            onFileSelect={this.handleFileSelect}
            validators={[this.handleValidator]}
            clearable={true}
          />
          <p className='cfa-image-upload__description'>
            {description ? description : 'Accepted formats: SVG, JPG, JPEG, PNG'}
            <br />
            {minWidth && minHeight && `Image dimensions must be at least ${minWidth}px width by ${minHeight}px height`}
          </p>
        </Block>
      </div>
    )
  }
}

export default ImageUpload
