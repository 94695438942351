import * as React from 'react'
import { withRouter, RouteComponentProps } from 'react-router'
import { ApplicationState } from '../../reducers'
import { connect } from 'react-redux'
import PartnerDetailPage from './partnerDetailPage'
import { partnersForCurrentEventSelector, brandsForCurrentPartnerSelector } from '../../data/selectors'
import { loadBrands } from '../brand/actions'
import Partner from '../../models/partner'
import NotFound from '../NotFound'
import { LogoProps } from '../../components'
import { removeDuplicateBrands, sortBrandsAlphabetically, mapBrandToLogoProps } from '../brand/utilities'

type PathProps = {
    slug: string
}

type RouterProps = RouteComponentProps<PathProps>

type FieldProps = {
    brandLogos: LogoProps[] | null
    partner: Partner | undefined
}

type MethodProps = {
    loadBrands: () => void
}

type Props = FieldProps & MethodProps

class PartnerDetailPageWithData extends React.Component<Props> {
    componentDidMount() {
        const { loadBrands } = this.props

        loadBrands()
    }

    render() {
        const { partner, brandLogos } = this.props

        if (partner === undefined) {
            return <NotFound/>
        }

        return (
            <PartnerDetailPage
                brandLogos={brandLogos}
                partner={partner}
            />
        )
    }
}

const mapStateToProps = (state: ApplicationState, routerProps: RouterProps): FieldProps => {
    const slug = routerProps.match.params.slug.toLowerCase()

    let brandLogos: any[] | null = []
    const currentPartner = partnersForCurrentEventSelector(state).find(partner => partner.slug === slug)
    if (currentPartner) {
        brandLogos = brandsForCurrentPartnerSelector(state, currentPartner.brandsWithServiceType)
    }

    if (brandLogos) {
        brandLogos = brandLogos
        .reduce(removeDuplicateBrands, [])
        .sort(sortBrandsAlphabetically)
        .map(mapBrandToLogoProps)
    }

    return {
        brandLogos,
        partner: currentPartner,
    }
}

const actions: MethodProps = {
    loadBrands,
}

export default withRouter(
    connect(
        mapStateToProps,
        actions
    )(PartnerDetailPageWithData)
)
