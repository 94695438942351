import { connect } from 'react-redux'
import { ApplicationState } from '../../reducers'
import CategoryModal, { FieldProps } from './CategoryModal'
import { ModalProps } from '../../components/modal'
import { modalCategorySelector } from './reducer'

const mapStateToProps = (state: ApplicationState, ownProps: ModalProps): FieldProps & ModalProps => {
    const currentCategory = modalCategorySelector(state)
    if (currentCategory !== undefined && currentCategory.subCategories !== undefined) {
        currentCategory.subCategories = currentCategory.subCategories.sort(
            (a, b) => Number(a.sortOrder) - Number(b.sortOrder))
    }
    return {
        ...ownProps,
        category: currentCategory,
    }
}

const CategoryModalWithData = connect(mapStateToProps)(CategoryModal)
export default CategoryModalWithData
