import { Amplify } from 'aws-amplify'
import * as React from 'react'
import { Provider } from 'react-redux'
import { RoktContextProvider } from '../src/context/roktContext'
import amplifyConfig from './amplifyconfiguration.json'
import './App.scss'
import Loading from './features/global/loading'
import { Status } from './features/global/status'
import { store } from './store'
import { isProduction } from './utilities'

declare global {
  interface Window {
    Blinkloader: any
  }
}

window.Blinkloader = window.Blinkloader || {}

Amplify.configure({
  ...amplifyConfig,
  authenticationFlowType: 'USER_PASSWORD_AUTH',
})

class App extends React.Component {
  componentDidMount() {
    ;(function (src, cb) {
      const s = document.createElement('script')
      s.setAttribute('src', src)
      s.onload = cb
      document.body.appendChild(s)
    })('https://ucarecdn.com/libs/blinkloader/3.x/blinkloader.min.js', () => {
      window.Blinkloader.optimize({
        pubkey: process.env.REACT_APP_UPLOADCARE_PUBLIC_KEY,
        fadeIn: true,
        lazyload: true,
        batchInterval: 250, // 250ms by default
        batchSize: 10,
      })
    })
  }

  render() {
    return (
      <RoktContextProvider
        tagId={process.env.REACT_APP_ROKT_TAG_ID as string}
        sandbox={!isProduction ? true : false}
      >
        <Provider store={store}>
          <Loading />
          <Status />
        </Provider>
      </RoktContextProvider>
    )
  }
}

export default App
