import { connect } from 'react-redux'
import { ApplicationState } from '../../../reducers'
import { currentEventSelector } from '../../../data/selectors'
import { SearchDefaultContent, CustomFieldProps } from './SearchDefaultContent'

type DataProps = {
    featuredDealSlug: string[] | undefined
}
const mapStateToProps = (state: ApplicationState): DataProps & Pick<CustomFieldProps, 'eventType'> => {
    const currentEvent = currentEventSelector(state)
    const featuredDealSlug: string[] | undefined = []
    if (currentEvent !== undefined
        && currentEvent.searchFeaturedDeal !== undefined
        && currentEvent.searchFeaturedDeal.length > 0
    ) {
        currentEvent.searchFeaturedDeal.forEach((i) => {
            if (featuredDealSlug) {
                featuredDealSlug.push(i.slug)
            }
        })
    }
    return {
        featuredDealSlug,
        eventType: currentEvent === undefined || currentEvent.type === undefined ? null : currentEvent.type,
    }
}

const SearchDefaultContentWithData = connect(mapStateToProps)(SearchDefaultContent)

export { SearchDefaultContentWithData }
