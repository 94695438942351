import { Auth } from '@aws-amplify/auth'
import { AuthPieceProps, ForgotPassword as AWSForgotPassword } from 'aws-amplify-react'
import bind from 'bind-decorator'
import { AnyObject, Form, FormRenderProps } from 'react-final-form'
import { ForgotPassword } from './ForgotPassword'
import { ForgotPasswordChange } from './ForgotPasswordChange'

type State = {
  email: string
  forgotError?: Error
  changeError?: Error
  details?: any
}

class ForgotPasswordWithData extends AWSForgotPassword<AuthPieceProps, State> {
  readonly state: State = { email: '' }

  @bind
  async submitEmail(values: AnyObject) {
    try {
      this.setState({ forgotError: undefined, changeError: undefined })
      const data = await Auth.forgotPassword(values.email)
      this.setState({ details: data.CodeDeliveryDetails, email: values.email, forgotError: undefined })
    } catch (error: any) {
      this.setState({ forgotError: error })
    }
  }

  @bind
  async submitNewPassword(values: AnyObject) {
    try {
      this.setState({ changeError: undefined, forgotError: undefined })
      const username = this.state.email
      await Auth.forgotPasswordSubmit(username, values.code, values.password)
      this.changeState('signIn')
      this.setState({ details: undefined })
    } catch (error: any) {
      this.setState({ changeError: error })
    }
  }

  @bind
  async resendCode() {
    try {
      this.setState({ forgotError: undefined, changeError: undefined })
      const username = this.state.email
      const data = await Auth.forgotPassword(username)
      this.setState({ details: data.CodeDeliveryDetails, forgotError: undefined, changeError: undefined })
    } catch (error: any) {
      this.setState({ changeError: error })
    }
  }

  @bind
  showSignIn() {
    this.changeState('signIn')
  }

  @bind
  renderForgotForm({ handleSubmit }: FormRenderProps) {
    const { forgotError } = this.state

    return (
      <ForgotPassword
        handleSubmit={handleSubmit}
        showLogIn={this.showSignIn}
        error={forgotError}
      />
    )
  }

  @bind
  renderChangeForm({ handleSubmit }: FormRenderProps) {
    const { changeError } = this.state

    return (
      <ForgotPasswordChange
        handleSubmit={handleSubmit}
        resendCode={this.resendCode}
        error={changeError}
      />
    )
  }

  showComponent() {
    const { details } = this.state

    if (details) {
      return (
        <Form
          onSubmit={this.submitNewPassword}
          render={this.renderChangeForm}
        />
      )
    }

    return (
      <Form
        onSubmit={this.submitEmail}
        render={this.renderForgotForm}
      />
    )
  }
}

export { ForgotPasswordWithData }
