import * as React from 'react'
import bind from 'bind-decorator'
import { history } from '../../../../../../src/history'
import { CfButton, Container } from '../../../../../components/cf-package'
import { BigArrow, BigArrow2 } from '../../../../../components/cf-package/Svg'
import './style.scss'

type Props = {
  className?: string
  id?: string
}

class Numbers extends React.Component<Props> {
  @bind
  handleSignUpLink() {
    history.push('/account?join')
  }

  render() {
    const { className = '', id } = this.props

    return (
      <Container>
        <section
          className={`${className} cf-numbers-blk`}
          id={id}
        >
          <div className='cf-flex flex-row align-center justify-between'>
            <div className='content'>
              <h3 className='ttl head-lg'>The numbers don’t lie</h3>
              <div className='desc'>
                Our netbuster Click Frenzy events have delivered millions of exceptional bargains
              </div>
              <div className='cta-blk'>
                <CfButton
                  onClick={this.handleSignUpLink}
                  buttonType='blue'
                  size='large'
                >
                  <span className='display-desktop'>What are you waiting for?&nbsp;</span>Join today!
                </CfButton>
              </div>
            </div>
            <div className='display-numbers'>
              <div className='cf-flex flex-row justify-end'>
                <div className='numbers-item first-item'>
                  <div className='big-nb font-sg-bold'>10m</div>
                  <div className='txt'>Aussies have shopped via Click Frenzy</div>
                  <BigArrow />
                </div>
                <div className='numbers-item second-item'>
                  <div className='big-nb font-sg-bold'>$1bn+</div>
                  <div className='txt'>Online sales from Click Frenzy since 2012</div>
                  <BigArrow2 />
                </div>
              </div>
            </div>
          </div>
        </section>
      </Container>
    )
  }
}

export default Numbers
