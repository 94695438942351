import * as React from 'react'
import { connect } from 'react-redux'
import Banner from './Banner'
import { default as BannerModel, BannerType } from '../../models/banner'
import { ApplicationState } from '../../reducers'
import { loadBanners } from '../../data/actions'

type OwnProps = {
  type: BannerType
  category?: string
}

type LoadFieldProps = {
  banner?: BannerModel
  userId?: string | undefined
}

type LoadMethodProps = {
  loadBanners: () => void
}

type Props = LoadFieldProps & LoadMethodProps

class BannerLoad extends React.Component<Props> {
  componentWillMount() {
    this.props.loadBanners()
  }

  render() {
    const { banner, userId } = this.props

    if (banner === undefined) {
      return null
    }

    return (
      <Banner
        banner={banner}
        userId={userId}
      />
    )
  }
}

const mapStateToProps = (state: ApplicationState, ownProps: OwnProps): LoadFieldProps => {
  const banners = state.data.banners

  if (banners.length === 0) {
    return {}
  }

  let filtered = banners.filter(banner => banner.type === ownProps.type)

  if (ownProps.category !== undefined) {
    filtered = filtered.filter(banner => banner.category === ownProps.category)
  }
  // add filter for which category, etc, here, when we build those banners

  return {
    banner: filtered[0],
    userId: state.account.loggedIn ? state.account.username : undefined,
  }
}

const actions: LoadMethodProps = {
  loadBanners,
}

const BannerWithData = connect(mapStateToProps, actions)(BannerLoad)
export default BannerWithData
