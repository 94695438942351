import * as React from 'react'
import { Subtract } from 'utility-types'

export type ViewportProps = {
    isMobile?: boolean
    isDesktop?: boolean
    width?: number
    height?: number
}

const DESKTOP_BREAKPOINT = 768

const withViewport = <P extends ViewportProps>(Component: React.ComponentType<P>) => {
    type ParentProps = Subtract<P, ViewportProps>
    type State = {
        isMobile: boolean
        isDesktop: boolean
        width: number
        height: number
    }

    return class WithViewport extends React.Component<ParentProps, State> {
        constructor(props: ParentProps) {
            super(props)
            this.calculateViewportDimensions = this.calculateViewportDimensions.bind(this)
        }

        calculateViewportDimensions() {
            const width = document.body.clientWidth
            const height = document.body.clientHeight
            const isMobile = width < DESKTOP_BREAKPOINT
            const isDesktop = !isMobile

            this.setState({
                width,
                height,
                isMobile,
                isDesktop,
            })
        }

        componentDidMount() {
            this.calculateViewportDimensions()
            window.addEventListener('resize', this.calculateViewportDimensions)
        }

        componentWillUnmount() {
            window.removeEventListener('resize', this.calculateViewportDimensions)
        }

        render() {
            return (
                <Component
                    {...this.props as P}
                    {...this.state}
                />
            )
        }
    }
}

export { withViewport }
