import Brand from '../../models/brand'
import { LogoProps } from '../../components/logo'

function getBrandUrl(slug: string): string {
    return `/brands/${slug}`
}

// To be used with Array.sort()
function sortBrandsAlphabetically(a: Brand, b: Brand) {
    const aSlug = a.slug.toLowerCase()
    const bSlug = b.slug.toLowerCase()

    if (aSlug < bSlug) {
        return -1
    }

    if (aSlug > bSlug) {
        return 1
    }

    return 0
}

// To be used with Array.reduce()
function removeDuplicateBrands(brands: Brand[], currentBrand: Brand) {
    if (brands.find(brand => currentBrand.slug === brand.slug)) {
        return brands
    }

    brands.push(currentBrand)

    return brands
}

function mapBrandToLogoProps(brand: Brand): LogoProps {
    return {
        src: brand.logo,
        alt: brand.name,
        href: getBrandUrl(brand.slug),
    }
}

export { getBrandUrl, sortBrandsAlphabetically, removeDuplicateBrands, mapBrandToLogoProps }
