/* eslint-disable @typescript-eslint/no-non-null-assertion */
import { SearchOutlined } from '@ant-design/icons'
import { GraphQLResult } from '@aws-amplify/api'
import { Button, Col, Divider, Input, Modal, Row, Select, Table, Tag } from 'antd'
import type { FormInstance } from 'antd/es/form'
import Form from 'antd/lib/form'
import type { ColumnsType } from 'antd/lib/table'
import { API, Auth, graphqlOperation } from 'aws-amplify'
import bind from 'bind-decorator'
import filter from 'lodash/filter'
import pullAll from 'lodash/pullAll'
import moment from 'moment'
import * as React from 'react'
import { awsToPhone, Grid } from '../../../components'
import { DATE_FORMAT_TABLE, PAGE_SIZE } from '../../../constants'
import { deleteUserUploadedFiles } from '../../../graphql/mutations'
import { handleError } from '../../../utilities'
import { renderAllData } from '../../../utilities/graphql'
import { AddressFormat } from '../types'
import './index.scss'
import { File } from './UploadPageWithData'

const { Option } = Select

type FilesTable = {
  id: string
  userId: string
  brand: string
  spend: number
  paymentMethod: string
  save: number
  fileName: string
  url: string
  uploadedAt: string
}

// eslint-disable-next-line @typescript-eslint/ban-types
type Props = {}

type State = {
  error?: Error
  data: FilesTable[]
  filteredData: FilesTable[]
  loading: boolean
  modalShow: boolean
  modalContent: any
  filtered: boolean
}

class UploadFilesAdmin extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props)
    this.state = {
      data: [],
      filteredData: [],
      loading: true,
      modalShow: false,
      modalContent: undefined,
      filtered: false,
    }
  }

  formRef = React.createRef<FormInstance>()

  handleView = (id: string) => async (e: React.MouseEvent) => {
    e.preventDefault()
    this.setState({ loading: true })

    const { data } = this.state
    const result = data.find(item => item.id === id)
    if (result) {
      API.post('clickfrenzyFeUserUpload', '/read', {
        body: {
          jwtToken: (await Auth.currentSession()).getAccessToken().getJwtToken(),
          url: result.url,
        },
      })
        .then(response => {
          // Open File
          const element = document.createElement('a')
          element.setAttribute('href', response.signedUrl)
          element.setAttribute('target', '_blank')
          element.style.display = 'none'
          document.body.appendChild(element)
          element.click()
          document.body.removeChild(element)

          this.setState({ loading: false })
        })
        .catch(err => {
          console.log(err)
          this.setState({ loading: false })
        })
    } else {
      this.setState({ loading: false })
    }
  }

  handleDelete = (id: string) => (e: React.MouseEvent) => {
    e.preventDefault()
    this.setState({ loading: true })

    const response = API.graphql(
      graphqlOperation(deleteUserUploadedFiles, {
        input: { id },
      })
    ) as GraphQLResult

    if (response.data && response.data !== null) {
      const data = this.state.data.filter(file => {
        return file.id !== id
      })
      this.setState({ data, loading: false })
    } else {
      handleError(response.errors)
    }
  }

  @bind
  handleColumnChange(value: string) {
    this.formRef.current!.setFieldsValue({ column: value })
  }

  @bind
  handleTextChange(e: any) {
    this.formRef.current!.setFieldsValue({ text: e.target.value })
  }

  @bind
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  onSubmit(e: React.FormEvent<HTMLFormElement>) {
    const { data } = this.state
    this.setState({ filtered: true })

    const column = this.formRef.current!.getFieldValue('column')
    const text = this.formRef.current!.getFieldValue('text')

    const filteredData = filter(data, file => {
      if (column === 'all') {
        const isFound = pullAll(Object.keys(file), ['uploadedAt']).reduce((acc, key) => {
          const value = (file as any)[key]
          if (!value) {
            return false
          }

          if (value.toString().toLowerCase().indexOf(text.toLowerCase()) !== -1) {
            return true
          }
          return acc
        }, false)
        return isFound
      }

      if ((file as any)[column]) {
        return (file as any)[column].toLowerCase().indexOf(text.toLowerCase()) !== -1
      }

      return false
    })

    this.setState({ filteredData })
  }

  @bind
  onReset() {
    this.formRef.current!.resetFields()
    this.setState({ filtered: false })
  }

  @bind
  async handleUserInfo(userId: string) {
    this.setState({ modalShow: true })
    try {
      const response = await API.get('clickfrenzyFeUsers', `/users/${userId}`, {
        queryStringParameters: {
          jwtToken: (await Auth.currentSession()).getAccessToken().getJwtToken(),
        },
      })

      const fullAddress = response.address
      let address: AddressFormat | undefined = undefined
      if (fullAddress) {
        address = JSON.parse(fullAddress)
      }
      response.address = address

      if (response) {
        this.setState({ modalContent: response })
      }
    } catch (e) {
      this.setState({ modalShow: false })
      handleError(`Unable to get User details: ${e}`)
    }
  }

  @bind
  handleCancel() {
    this.setState({ modalShow: false })
    setTimeout(() => {
      this.setState({ modalContent: undefined })
    }, 300)
  }

  async componentDidMount() {
    this.setState({ loading: true })
    const data: FilesTable[] = []

    const allFiles: File[] = await renderAllData([], undefined, 'listUserUploadedFiless')

    if (allFiles.length > 0) {
      allFiles.forEach(file => {
        data.push({
          id: file.id,
          userId: file.userId,
          brand: file.brand,
          spend: file.spend,
          save: file.save,
          paymentMethod: file.paymentMethod,
          fileName: file.name,
          url: file.url,
          uploadedAt: moment(file.uploadedAt).format(DATE_FORMAT_TABLE),
        })
      })
    }

    this.setState({ data, loading: false })
  }

  render() {
    const { data, loading, filtered, filteredData, modalShow, modalContent } = this.state

    const dataSource = filtered ? filteredData : data

    const brands: any = [
      {
        text: '-',
        value: '-',
      },
    ]
    dataSource.forEach(item => {
      if (item.brand && item.brand.startsWith('[') && item.brand.endsWith(']')) {
        item.brand
          // eslint-disable-next-line no-useless-escape
          .replace(/[\[\]']+/g, '')
          .split(',')
          .map(String)
          .map(brand => {
            brands.push({
              text: brand.trim(),
              value: brand.trim(),
            })
          })
      }
    })

    brands.sort((a: any, b: any) => ((a.text as string).toUpperCase() > (b.text as string).toUpperCase() ? 1 : -1))

    const formatter = new Intl.NumberFormat('en-AU', {
      style: 'currency',
      currency: 'AUD',
    })

    const columns: ColumnsType<FilesTable> = [
      {
        title: 'UserId',
        key: 'userId',
        dataIndex: 'userId',
        sorter: (a: FilesTable, b: FilesTable) => +(a.userId > b.userId) - +(a.userId < b.userId),
        render: (record: FilesTable) => (
          <a
            onClick={async () => {
              await this.handleUserInfo(record.userId)
            }}
          >
            {record.userId}
          </a>
        ),
      },
      {
        title: 'Brand',
        key: 'brand',
        dataIndex: 'brand',
        filters: brands,
        filterMultiple: true,
        onFilter: (value: string | number | boolean | bigint, record: FilesTable) =>
          record.brand && typeof value === 'string' ? record.brand.includes(value) : value === '-',
        render: brand => (
          <span>
            {brand
              ? brand.startsWith('[') && brand.endsWith(']')
                ? brand
                    // eslint-disable-next-line no-useless-escape
                    .replace(/[\[\]']+/g, '')
                    .split(',')
                    .map(String)
                    .map((brand: any) => {
                      return (
                        <Tag
                          color='purple'
                          key={brand.trim()}
                        >
                          {brand.trim()}
                        </Tag>
                      )
                    })
                : brand
              : ''}
          </span>
        ),
      },
      {
        title: 'Spend',
        key: 'spend',
        dataIndex: 'spend',
        sorter: (a: FilesTable, b: FilesTable) => +(a.spend > b.spend) - +(a.spend < b.spend),
        render: spend => spend && formatter.format(spend),
      },
      {
        title: 'Payment Method',
        dataIndex: 'paymentMethod',
        key: 'paymentMethod',
        sorter: (a: FilesTable, b: FilesTable) =>
          +(a.paymentMethod > b.paymentMethod) - +(a.paymentMethod < b.paymentMethod),
      },
      {
        title: 'Save',
        key: 'save',
        dataIndex: 'save',
        sorter: (a: FilesTable, b: FilesTable) => +(a.save > b.save) - +(a.save < b.save),
        render: save => save && formatter.format(save),
      },
      {
        title: 'File Name',
        dataIndex: 'fileName',
        key: 'fileName',
        sorter: (a: FilesTable, b: FilesTable) => +(a.fileName > b.fileName) - +(a.fileName < b.fileName),
      },
      {
        title: 'Uploaded Time',
        dataIndex: 'uploadedAt',
        key: 'uploadedAt',
        sorter: (a: FilesTable, b: FilesTable) => +(a.uploadedAt > b.uploadedAt) - +(a.uploadedAt < b.uploadedAt),
      },
      {
        title: '',
        key: 'action',
        render: (record: FilesTable) => (
          <span>
            <a onClick={this.handleView(record.id)}>View</a>
            <Divider type='vertical' />
            <a onClick={this.handleDelete(record.id)}>Delete</a>
          </span>
        ),
      },
    ]

    return (
      <Grid className='cf-file-upload'>
        <h1>All Files</h1>
        <div className='cf-file-upload__search'>
          <Form
            layout='inline'
            onFinish={this.onSubmit}
            ref={this.formRef}
          >
            <Form.Item
              label='Search: '
              name='column'
              initialValue='all'
            >
              <Select
                allowClear={true}
                size='large'
                style={{ minWidth: '160px' }}
                onChange={this.handleColumnChange}
                id='column'
              >
                <Option value='all'>All</Option>
                <Option value='brand'>Brand</Option>
                {columns
                  .filter((column: any) => column.dataIndex && !['uploadedAt'].includes(column.dataIndex))
                  .map((column: any, index) => {
                    return (
                      <Option
                        key={index}
                        value={column.dataIndex}
                      >
                        {column.title}
                      </Option>
                    )
                  })}
              </Select>
            </Form.Item>
            <Form.Item name='text'>
              <Input
                type='text'
                size='large'
                placeholder='Search text'
                onChange={this.handleTextChange}
              />
            </Form.Item>
            <Form.Item>
              <Button
                type='primary'
                htmlType='submit'
              >
                <SearchOutlined />
                Search
              </Button>
            </Form.Item>
            <Form.Item>
              <Button onClick={this.onReset}>Clear</Button>
            </Form.Item>
          </Form>
        </div>
        <div className='cf-file-upload__fileslist'>
          <Table
            columns={columns}
            rowKey='id'
            dataSource={filtered ? filteredData : data}
            loading={loading}
            pagination={
              filtered
                ? filteredData && filteredData.length < PAGE_SIZE
                  ? false
                  : {
                      pageSize: PAGE_SIZE,
                    }
                : data && data.length < PAGE_SIZE
                  ? false
                  : {
                      pageSize: PAGE_SIZE,
                    }
            }
          />
        </div>
        <div className='cf-file-upload__userInfo'>
          <Modal
            title='User Info'
            visible={modalShow}
            onCancel={this.handleCancel}
            footer={[
              <Button
                key='close'
                type='primary'
                loading={loading}
                onClick={this.handleCancel}
              >
                Close
              </Button>,
            ]}
          >
            {modalContent ? (
              <>
                <Row>
                  <Col span={8}>Name:</Col>
                  <Col span={16}>
                    {modalContent.firstName} {modalContent.lastName}
                  </Col>
                </Row>
                <Row>
                  <Col span={8}>Email:</Col>
                  <Col span={16}>{modalContent.email}</Col>
                </Row>
                <Row>
                  <Col span={8}>Gender:</Col>
                  <Col span={16}>{modalContent.gender}</Col>
                </Row>
                <Row>
                  <Col span={8}>Mobile:</Col>
                  <Col span={16}>{awsToPhone(modalContent.mobile)}</Col>
                </Row>
                <Row>
                  <Col span={8}>Address:</Col>
                  <Col span={16}>
                    {modalContent.address && (
                      <>
                        {unescape(modalContent.address.street_address)}
                        <br />
                        {modalContent.address_2 && (
                          <>
                            {unescape(modalContent.address.address_2)}
                            <br />
                          </>
                        )}
                        {unescape(modalContent.address.locality)},{modalContent.address.region}{' '}
                        {modalContent.address.postal_code}
                        <br />
                        {modalContent.address.country}
                      </>
                    )}
                  </Col>
                </Row>
              </>
            ) : (
              'Loading, please wait ...'
            )}
          </Modal>
        </div>
      </Grid>
    )
  }
}

export default UploadFilesAdmin
