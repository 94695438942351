import * as React from 'react'
import './ThreeColumnImageTextSignUp.scss'

type Props = {
    imageUrl: string
    title: string
    body: string
    footer?: string
}

class ThreeColumnItemSingUp extends React.Component<Props> {

    render() {
        const {
            imageUrl,
            title,
            body,
            footer,
        } = this.props

        return (
            <div className='threeColumnSignup__item-wrapper'>
                <div className='threeColumnSignup__image-wrapper'>
                    <img
                        className='threeColumnSignup__image'
                        src='data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAIAAACQd1PeAAAABnRSTlMA/
                        wD/AP83WBt9AAAADElEQVQI12P4//8/AAX+Av7czFnnAAAAAElFTkSuQmCC'
                        data-blink-src={imageUrl}
                        alt=''
                    />
                </div>
                <h3 className='threeColumnSignup__item-title'>{title}</h3>
                <div className='threeColumnSignup__item-body'>{body}</div>
                {footer &&
                    <div className='threeColumnSignup__item-footer'>{footer}</div>
                }
            </div>
        )
    }
}

export default ThreeColumnItemSingUp
