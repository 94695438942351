import { Action } from 'redux'
import { isType } from 'typescript-fsa'
import { eventRunning, noEventRunning, networkTimeUpdated } from './actions'
import {
  brandDetailedLoading,
  brandDetailsLoaded,
  brandsLoaded,
  brandsLoading,
  nonEventConfigLoaded,
} from '../../../data/actions'

export type State = {
  isEventRunning: boolean
  brandsLoading: boolean
  brandsDetailedLoading: string[]
  nonEventConfigLoaded: boolean
  networkTime: number
}

const initialState: State = {
  isEventRunning: false,
  brandsLoading: false,
  brandsDetailedLoading: [],
  nonEventConfigLoaded: false,
  networkTime: 0,
}

export function statusReducer(state: State = initialState, action: Action): State {
  if (isType(action, eventRunning)) {
    return {
      ...state,
      isEventRunning: true,
    }
  }

  if (isType(action, noEventRunning)) {
    return {
      ...state,
      isEventRunning: false,
    }
  }

  if (isType(action, brandsLoading)) {
    return {
      ...state,
      brandsLoading: true,
    }
  }

  if (isType(action, brandsLoaded)) {
    return {
      ...state,
      brandsLoading: false,
    }
  }

  if (isType(action, brandDetailedLoading)) {
    const brandsDetailedLoading = [...state.brandsDetailedLoading, action.payload]

    return {
      ...state,
      brandsDetailedLoading,
    }
  }

  if (isType(action, brandDetailsLoaded)) {
    const brandsDetailedLoading = state.brandsDetailedLoading.filter(brand => brand !== action.payload.slug)

    return {
      ...state,
      brandsDetailedLoading,
    }
  }

  if (isType(action, nonEventConfigLoaded)) {
    return {
      ...state,
      nonEventConfigLoaded: true,
    }
  }

  if (isType(action, networkTimeUpdated)) {
    return {
      ...state,
      networkTime: action.payload + 1,
    }
  }

  return state
}
