import * as React from 'react'
import bind from 'bind-decorator'
import './layout.scss'
import { Helmet } from 'react-helmet'
import { NavBarWithData } from '../global/nav'
import { FooterWithData } from '../global/footer'
import { FooterVariation } from '../global/footer/Footer'

type Props = {
  variation?: FooterVariation
  showFooterPartners?: boolean
  hideHeader?: boolean
  hideFooter?: boolean
  className?: string
}

type State = {
  navBarHeight: number
}

class Layout extends React.Component<React.PropsWithChildren<Props>, State> {
  private navBar = React.createRef<HTMLDivElement>()
  readonly state: State = {
    navBarHeight: 0,
  }

  componentDidMount() {
    this.calculateHeight()
    window.addEventListener('resize', this.calculateHeight)
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.calculateHeight)
  }

  @bind
  calculateHeight() {
    if (this.navBar.current === null) {
      return
    }

    const navBarHeight = this.navBar.current.offsetHeight

    if (navBarHeight !== this.state.navBarHeight) {
      this.setState({
        navBarHeight,
      })

      window.localStorage.setItem('nav-bar-height', navBarHeight.toString())
    }
  }

  render() {
    const { children, variation, showFooterPartners = true, hideHeader, hideFooter, className } = this.props
    const { navBarHeight } = this.state

    const isCfReceiptPath = window.location.pathname === '/clickfrenzyreceipt'

    return (
      <div className={`layout ${className}`}>
        <Helmet>
          <style>{`
                        :root {
                            --nav-bar-height: ${navBarHeight}px;
                            --header-height: ${navBarHeight}px;
                        }
                    `}</style>
        </Helmet>
        <div className='layout__content'>
          {!hideHeader && (
            <header className='layout__header'>
              {/* <div ref={this.topHeader}>
                                <TopHeaderWithData calculateHeight={this.calculateHeight}/>
                            </div> */}
              <div ref={this.navBar}>
                <NavBarWithData resetPageHeight={this.calculateHeight} />
              </div>
            </header>
          )}
          {children}
        </div>
        {!hideFooter && (
          <div className='layout__footer'>
            <FooterWithData
              variation={variation}
              showPartners={isCfReceiptPath ? false : showFooterPartners}
            />
          </div>
        )}
      </div>
    )
  }
}

export default Layout
