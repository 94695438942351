import * as React from 'react'
import { Field } from 'react-final-form'
import { CheckboxField } from './CheckboxField'
import { required } from '../validations'

type Props = {
    name: string
}

class TermsField extends React.Component<Props> {
    render() {
        const { name } = this.props

        return (
            <Field<string>
                name={name}
                component={CheckboxField}
                validate={required(name)}
                type='checkbox'
            />
        )
    }
}

export { TermsField }
