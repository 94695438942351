import * as React from 'react'
import { Field } from 'react-final-form'
import { InputField } from './InputField'
import { required } from '../validations'

type Props = {
    name: string
    placeholder: string
}

class NewsletterField extends React.Component<Props> {
    render() {
        const { name, placeholder } = this.props

        return (
            <Field<string>
                name={name}
                component={InputField}
                validate={required(name)}
                placeholder={placeholder}
                type='email'
            />
        )
    }
}

export { NewsletterField }
