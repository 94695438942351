import * as React from 'react'
import classnames from 'classnames'
import './Hamburger.scss'

type Props = {
    isActive?: boolean
    animationType?: HamburgerAnimationType
    className?: string
    onClick?: () => void
}

type HamburgerAnimationType = 'minus' | 'arrowalt'

class Hamburger extends React.Component<Props> {
    static defaultProps = {
        isActive: false,
        animationType: 'minus',
    }

    render() {
        const { isActive, animationType, className, onClick } = this.props
        const classes = classnames(`cf-hamburger hamburger hamburger--${animationType}`, className, {
            'is-active': isActive,
        })

        return (
            <button className={classes} type='button' onClick={onClick}>
                <span className='hamburger-box'>
                    <span className='hamburger-inner' />
                </span>
            </button>
        )
    }
}

export { Hamburger }
