import React from 'react'
import { connectStateResults, StateResultsProvided } from 'react-instantsearch-core'
import { SearchResults } from './SearchResults'
import { SearchHit } from '../types'
import { SearchDefaultContentWithData } from './SearchDefaultContentWithData'
import { dataLayerPushPageType } from '../../../services/gtm'

type Props = StateResultsProvided<SearchHit>

class SearchPageBody extends React.Component<Props> {
  componentDidMount() {
    dataLayerPushPageType('Search')
  }

  hideSearchResults() {
    const { searching, searchResults } = this.props

    const queryIsEmpty = !searchResults || !searchResults.query
    const queryIsEmptyAndSearching = !!(searching && queryIsEmpty)
    const queryWithNoResults = !!(searchResults && searchResults.query && !searchResults.nbHits)
    const queryWithResultsAndSearching = !!(searching && searchResults && searchResults.query && searchResults.nbHits)

    return (queryIsEmpty || queryIsEmptyAndSearching || queryWithNoResults) && !queryWithResultsAndSearching
  }

  showDefaultContent() {
    const { searchResults } = this.props

    return !searchResults || searchResults.nbHits === 0 || !searchResults.query
  }

  render() {
    const showDefaultContent = this.showDefaultContent()
    const hideSearchResults = this.hideSearchResults()

    return (
      <div className='search-page__body'>
        <SearchResults isVisible={!hideSearchResults} />
        <SearchDefaultContentWithData isVisible={showDefaultContent} />
      </div>
    )
  }
}

const SearchPageBodyWithData = connectStateResults<Props>(SearchPageBody)

export { SearchPageBody, SearchPageBodyWithData }
