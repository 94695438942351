import * as React from 'react'
import './HTMLBlock.scss'

type Props = {
    className?: string
    fontSize?: 'sm' | 'md'
}

class HTMLBlock extends React.Component<React.PropsWithChildren<Props>> {
    private static defaultProps = {
        className: '',
        fontSize: 'md',
    }

    render() {
        const { className, fontSize, children } = this.props

        return (
            <div className={`html-block html-block--font-size-${fontSize} ${className}`}>
                {children}
            </div>
        )
    }
}

export default HTMLBlock
