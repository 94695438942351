import * as React from 'react'
import { CustomArrowProps } from 'react-slick'
import Arrow from './Arrow'

type Props = CustomArrowProps & {
    icon?: string
    isSearch?: boolean
}

class PrevArrow extends React.Component<Props> {
    render() {
        return (
            <Arrow direction='prev' {...this.props} />
        )
    }
}

export default PrevArrow
