import { connect } from 'react-redux'
import { DictionaryValue } from '../../../components'
import { Grid } from '../../../components/grid'
import Deal from '../../../models/deal'
import { ApplicationState } from '../../../reducers'
import CardWithData from '../../deal/CardWithData'
import './SponsoredResults.scss'

type Props = {
  sponsoredResults?: Deal[]
}

const SearchResults = ({ sponsoredResults }: Props) => {
  if (sponsoredResults && !sponsoredResults?.length) return null

  let lastScrollX = 0
  const onScroll = (event: React.TouchEvent) => {
    if (event.currentTarget.scrollLeft !== undefined && event.currentTarget.scrollLeft !== lastScrollX) {
      lastScrollX = event.currentTarget.scrollLeft
    }
  }

  return (
    <Grid>
      <div className='row'>
        <div className='col-xs-9 col-md-6'>
          <span className='search-header__status'>
            <DictionaryValue token='sponsored-results-title' />
          </span>
        </div>
      </div>
      <div
        className='deal-slider sponsored-deals'
        onTouchEnd={onScroll}
      >
        {sponsoredResults?.map(({ slug }) => (
          <div
            className='sponsored-deal-card'
            key={slug}
          >
            <CardWithData
              slug={slug}
              isSponsored={true}
            />
          </div>
        ))}
      </div>
    </Grid>
  )
}

const mapStateToProps = (state: ApplicationState) => {
  return {
    sponsoredResults: state.data.sponsoredResults,
  }
}

const SponsoredResults = connect(mapStateToProps)(SearchResults)
export default SponsoredResults
