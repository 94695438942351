import * as React from 'react'
import { Container } from '../../../../../components/cf-package'
import './style.scss'

type Props = {
  className?: string
  id?: string
}

class CFHero extends React.Component<Props> {
  render() {
    const { className = '', id } = this.props

    return (
      <div className='bg-primary cf-hero-section'>
        <Container>
          <section
            className={`${className} cf-hero-blk cf-flex flex-row align-center`}
            id={id}
          >
            <div className='ct'>
              <h1 className='ttl head-xl'>
                The deals we&apos;re famous for from quality brands.
                {/* The deals we&apos;re famous
                <br />
                 */}
              </h1>
              <div className='highlight head-xl'>Now all day!</div>
            </div>
            <img
              src='./static/images/hero.png'
              className='hero-img img-desktop'
              alt='hero'
            />
            <img
              src='./static/images/hero_sm.png'
              className='hero-img img-mobile'
              alt='hero'
            />
          </section>
        </Container>
      </div>
    )
  }
}

export default CFHero
