import * as React from 'react'
import { ProfilePage } from './ProfilePage'
import { ApplicationState } from '../../reducers'
import { isLoggedInSelector } from './reducer'
import { connect } from 'react-redux'
import { updateAccount, UserActionInput } from './actions'
import { UserInfo } from './types'

type FieldProps = {
    isLoggedIn: boolean
    group?: string
    isAdmin?: boolean
    userInfo?: UserInfo
}

type MethodProps = {
    updateAccount: (payload: UserActionInput) => void
}

type Props = FieldProps & MethodProps

class ProfilePageLoad extends React.Component<Props> {
    render() {
        const { isLoggedIn, group, isAdmin, userInfo, updateAccount } = this.props

        if (!isLoggedIn || !userInfo) {
            return
        }

        return (
            <ProfilePage
                userInfo={userInfo}
                showFwB={((group !== undefined) || isAdmin) || false}
                refresh={updateAccount}
            />
        )
    }
}

const mapStateToProps = (state: ApplicationState): FieldProps => ({
    isLoggedIn: isLoggedInSelector(state),
    group: state.account.group,
    isAdmin: state.account.isAdmin,
    userInfo: isLoggedInSelector(state) ? {
        username: state.account.username,
        email: state.account.email,
        firstName: state.account.firstName || '',
        lastName: state.account.lastName || '',
        mobile: state.account.mobile,
        gender: state.account.gender || '',
        address: state.account.address,
        marketingOptIn: state.account.marketing || false,
    } as UserInfo : undefined,
})

const actions: MethodProps = {
    updateAccount,
}

const ProfilePageWithData = connect(mapStateToProps, actions)(ProfilePageLoad)

export { ProfilePageWithData }
