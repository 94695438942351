/* eslint-disable @typescript-eslint/no-inferrable-types */
export const DATE_FORMAT_TABLE: string = 'DD/MM/YYYY - h:mma'

export const PAGE_SIZE: number = 20

export enum ProjectType {
  frenzyBucks = 'frenzyBucks',
  frenzWithBenefits = 'frenzWithBenefits',
}

export const SIGN_UP_SLUG: string = '?join'
