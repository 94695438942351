import * as React from 'react'
import Brand from '../../models/brand'
import { Icon, chevron } from '../../components/icon/Icon'
import './item.scss'
import { Link } from 'react-router-dom'
import { getBrandUrl } from './utilities'
import { getResizedImageFromCDN, brandLogoSize } from '../../utilities'

type Props = {
    brand: Brand
}

class Item extends React.Component<Props> {
    render() {
        const { brand } = this.props

        return (
            <Link to={getBrandUrl(brand.slug)} className='brand-item'>
                <div className='brand-item__logo-wrapper'>
                    <img
                        className='brand-item__logo'
                        src='data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAIAAACQd1PeAAAABnRSTlMA/
                        wD/AP83WBt9AAAADElEQVQI12P4//8/AAX+Av7czFnnAAAAAElFTkSuQmCC'
                        data-blink-src={getResizedImageFromCDN(brand.logo, brand.logoIsBitmap, brandLogoSize.list)}
                        alt=''
                    />
                </div>
                <div className='brand-item__content'>
                    <span className='brand-item__name'>{brand.name}</span>
                    <Icon className='brand-item__icon' icon={chevron} />
                </div>
            </Link>
        )
    }
}

export default Item
