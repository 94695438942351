import * as React from 'react'
import Category from '../../models/category'
import { DictionaryValue } from '../../components/dictionary-value'
import classNames from 'classnames'
import bind from 'bind-decorator'
import { Icon } from '../../components/icon'
import { chevron } from '../../components/icon/Icon'
import { Grid } from '../../components/grid'
import './CategoryList.scss'
import { ModalProps } from '../../components/modal'
import CategoryModalWithData from './CategoryModalWithData'
import CategoryListCategory from './CategoryListCategory'
import EventType from '../../models/eventType'

export type FieldProps = {
    categories: Category[]
    menuIsOpen: boolean
    eventType?: EventType
}

export type MethodProps = {
    toggleCategoryMenu: () => void
    selectModalCategory: (slug: string) => void
}

type Props = FieldProps & MethodProps & ModalProps

class CategoryList extends React.Component<Props> {
    @bind
    toggleMenu(event: React.MouseEvent) {
        event.preventDefault()
        this.props.toggleCategoryMenu()
    }

    @bind
    showModal(slug: string) {
        this.props.selectModalCategory(slug)
        this.props.toggleModal()
    }

    render() {
        const { categories, menuIsOpen, isModalOpen, toggleModal, eventType } = this.props
        const classes = classNames(
            'category-list',
            { 'category-list--shown': menuIsOpen }
        )

        return (
            <>
                <Grid className={classes}>
                    <div className='category-list__row row'>
                        <div className='category-list__cell col-xs-14'>
                            <button className='category-list__toggle' onClick={this.toggleMenu}>
                                <span className='category-list__toggle-text'>
                                    <DictionaryValue token='home.category-list.title' />
                                </span>
                                <Icon icon={chevron} className='category-list__toggle-chevron' />
                            </button>
                            <ul className='category-list__categories'>
                                {categories.map(category => (
                                    <CategoryListCategory
                                        key={category.slug}
                                        category={category}
                                        selectItem={this.showModal}
                                        eventType={eventType}
                                    />
                                ))}
                            </ul>
                        </div>
                    </div>
                </Grid>
                <CategoryModalWithData isModalOpen={isModalOpen} toggleModal={toggleModal} />
            </>
        )
    }
}

export default CategoryList
