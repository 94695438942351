import * as React from 'react'
import { DictionaryValue } from '../dictionary-value'
import { Grid } from '../grid'
import { Button } from '../button'
import './CtaBanner.scss'
import classNames from 'classnames'
import { IconType } from '../icon'
import EventType from '../../models/eventType'

type Props = {
  title: string
  description: string
  ctaText?: string
  ctaUrl?: string
  className?: string
  icon?: IconType
  eventType?: EventType
  standardSite?: boolean
  video?: string
  isCustomBackground?: boolean
}

class CtaBanner extends React.Component<React.PropsWithChildren<Props>> {
  render() {
    const {
      title,
      description,
      ctaText,
      ctaUrl,
      className,
      children,
      icon,
      eventType,
      standardSite,
      video,
      isCustomBackground,
    } = this.props

    let classes
    if (title && description) {
      classes = classNames(
        className,
        isCustomBackground ? `cta-banner--${eventType}-custom` : '',
        { 'cta-banner--no-cta': !((ctaText && ctaUrl) || children) },
        eventType && `${standardSite ? `cta-banner--${eventType}-standard` : `cta-banner--${eventType}`}`
      )
    } else {
      classes = classNames(className)
    }
    const ctaClasses = classNames(
      'col-xs-12 col-sm-8 col-sm-offset-2 col-lg-4 col-lg-offset-4',
      'cta-banner__cta-wrapper'
    )
    const containerClass = classNames(classes, {
      'cta-banner': title || description,
    })
    return (
      <div className={containerClass}>
        <Grid>
          {title && (
            <div className='row'>
              <div className='col-xs-12 cta-banner__title-wrapper'>
                <h2 className={`cta-banner__title cta-banner__title--${eventType}`}>
                  <DictionaryValue token={title} />
                </h2>
              </div>
            </div>
          )}
          {description && (
            <div className='row'>
              <div className='col-xs-12 cta-banner__description-wrapper'>
                <p className={`cta-banner__description cta-banner__description--${eventType}`}>
                  <DictionaryValue token={description} />
                </p>
              </div>
            </div>
          )}
          {video && (
            <div className='row'>
              <div className='col-xs-12 col-md-10 col-md-offset-1 cta-banner__video-wrapper'>
                <iframe
                  width='100%'
                  height='auto'
                  src={video}
                  frameBorder='0'
                  title='What is Click Frenzy?'
                  // eslint-disable-next-line react/no-unknown-property
                  allowFullScreen={true}
                />
              </div>
            </div>
          )}
          {((ctaText && ctaUrl) || children) && (
            <div className='row'>
              <div className={ctaClasses}>
                {!(ctaText && ctaUrl) ? (
                  children
                ) : (
                  <Button
                    className={`cta-banner__cta${eventType && ` cta-banner__button--${eventType}`}`}
                    buttonType='primary'
                    buttonStyle='square'
                    size='large'
                    redirectUrl={ctaUrl}
                    icon={icon}
                  >
                    <DictionaryValue token={ctaText} />
                  </Button>
                )}
              </div>
            </div>
          )}
        </Grid>
      </div>
    )
  }
}

export { CtaBanner }
