import * as React from 'react'
import { Container } from '..'
import { Link } from '../../link'
import { LinkArrow } from '../Svg'
import SocialAccount, { SocialAccountType } from '../../../models/socialAccount'
import FooterLink from '../../../models/footerLink'
import './style.scss'
import './copyright.scss'

export type FieldProps = {
  socialAccounts: SocialAccount[]
  links: FooterLink[]
}

export type OwnProps = {
  className?: string
  id?: string
}

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const networks: Record<SocialAccountType, { url: string; text: string }> = {
  FACEBOOK: { url: 'https://www.facebook.com/', text: 'Facebook' },
  INSTAGRAM: { url: 'https://www.instagram.com/', text: 'Instagram' },
  TWITTER: { url: 'https://twitter.com/', text: 'Twitter' },
}

export type Props = OwnProps & FieldProps
class CFFooter extends React.Component<Props> {
  render() {
    const { className = '', id, links } = this.props
    let otherLinks: FooterLink[] = []
    let termLink: FooterLink | undefined
    let policyLink: FooterLink | undefined

    if (links.length > 0) {
      otherLinks = links.filter(itm => itm.path !== '/privacy-policy' && itm.path !== '/terms-and-conditions')
      const menuItemsWithSortOrder = otherLinks.filter(item => item.sortOrder !== undefined)
      const menuItemsWithoutSortOrder = otherLinks.filter(item => item.sortOrder === undefined)
      const menuItemsSortedWithOrder = menuItemsWithSortOrder.sort((a: any, b: any) => a.sortOrder - b.sortOrder)
      otherLinks = [...menuItemsSortedWithOrder, ...menuItemsWithoutSortOrder]
      termLink = links.find(itm => itm.path === '/terms-and-conditions')
      policyLink = links.find(itm => itm.path === '/privacy-policy')
    }

    return (
      <footer className='cf-footer-blk bg-primary'>
        <Container>
          <section
            id={id}
            className={className}
          >
            <div className='footer-menu cf-flex flex-row justify-between'>
              <div className='ttl head-lg'>
                The most frenzy
                <br />
                worthy deals in Australia.
              </div>
              {otherLinks.length > 0 && (
                <div className='ft-menu cf-flex flex-row flex-wrap'>
                  {otherLinks.map((item, index) => {
                    return (
                      <Link
                        key={index}
                        to={item.path}
                      >
                        {item.name}
                      </Link>
                    )
                  })}
                </div>
              )}

              <div className='social-links cf-flex flex-col'>
                <Link
                  to='https://www.facebook.com/clickfrenzy/'
                  target='_blank'
                >
                  <span>Facebook</span> <LinkArrow />
                </Link>
                <Link
                  to='https://www.instagram.com/click_frenzy/'
                  target='_blank'
                >
                  <span>Instagram</span> <LinkArrow />
                </Link>
              </div>
            </div>
            <div className='footer-copyright'>
              <div className='cf-flex flex-row justify-between align-end'>
                <div className='text-col cf-flex flex-col'>
                  <div className='term'>
                    {policyLink && (
                      <Link
                        to={policyLink.path}
                        className='privacy'
                      >
                        Privacy policy
                      </Link>
                    )}
                    {termLink && <Link to={termLink.path}>Terms</Link>}
                  </div>
                  <div className='txt'>
                    The words Click Frenzy and all versions of the Click Frenzy logo are fully registered trademarks
                    owned by Click Frenzy. Usage of these trademarks is prohibited at all times, except with the express
                    permission of Click Frenzy Pty Ltd.
                  </div>
                  <div className='copyright'>
                    <span>© {new Date().getFullYear()} Global Marketplace Pty Ltd</span>
                    {policyLink && (
                      <Link
                        to={policyLink.path}
                        className='privacy'
                      >
                        Privacy policy
                      </Link>
                    )}
                    {termLink && <Link to={termLink.path}>Terms</Link>}
                  </div>
                </div>
                <div className='w-logo'>
                  <img
                    src='./static/images/w_logo.png'
                    alt='white logo'
                  />
                </div>
              </div>
            </div>
          </section>
        </Container>
      </footer>
    )
  }
}

export default CFFooter
