import { Action } from 'redux'
import { isType } from 'typescript-fsa'
import {
  openSubmenu,
  closeSubmenu,
  openCategoryMenu,
  closeCategoryMenu,
  openMobileNavMenu,
  closeMobileNavMenu,
} from './actions'
import { LOCATION_CHANGE } from 'connected-react-router'

export type State = {
  isSubmenuOpen: boolean
  isCategoryMenuOpen: boolean
  isMobileNavMenuOpen: boolean
}

const initialState: State = {
  isSubmenuOpen: false,
  isCategoryMenuOpen: false,
  isMobileNavMenuOpen: false,
}

export function navReducer(state: State = initialState, action: Action): State {
  if (isType(action, openSubmenu)) {
    return {
      ...state,
      isSubmenuOpen: true,
    }
  }

  if (isType(action, closeSubmenu)) {
    return {
      ...state,
      isSubmenuOpen: false,
    }
  }

  if (action.type === LOCATION_CHANGE) {
    return {
      ...state,
      isSubmenuOpen: false,
      isCategoryMenuOpen: false,
    }
  }

  if (isType(action, openCategoryMenu)) {
    return {
      ...state,
      isCategoryMenuOpen: true,
    }
  }

  if (isType(action, closeCategoryMenu)) {
    return {
      ...state,
      isCategoryMenuOpen: false,
    }
  }

  if (isType(action, openMobileNavMenu)) {
    return {
      ...state,
      isMobileNavMenuOpen: true,
    }
  }

  if (isType(action, closeMobileNavMenu)) {
    return {
      ...state,
      isMobileNavMenuOpen: false,
    }
  }

  return state
}
