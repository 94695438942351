import React from 'react'
import { SortBy } from 'react-instantsearch-dom'
import bind from 'bind-decorator'
import { connect } from 'react-redux'
import './SearchSort.scss'
import { ApplicationState } from '../../../reducers'
import { dictionaryValueSelector } from '../../../data/selectors'
import { SEARCH_INDEX, SEARCH_INDEX_BY_BRAND_ASC, SEARCH_INDEX_BY_BRAND_DESC } from '../constants'

type OwnProps = {
    isVisible?: boolean
    className?: string
}

type FieldProps = {
    sortRelevanceDictionaryValue: string
    sortBrandsAscDictionaryValue: string
    sortBrandsDescDictionaryValue: string
    sortLabelDictionaryValue: string
}

type Props = OwnProps & FieldProps

class SearchSort extends React.Component<Props> {
    static defaultProps = {
        isVisible: true,
    }

    private wrapperRef = React.createRef<HTMLDivElement>()
    private labelRef = React.createRef<HTMLDivElement>()

    @bind
    resize() {
        const { current: wrapper } = this.wrapperRef
        const { current: label } = this.labelRef

        if (!wrapper || !label) {
            return
        }

        const select = wrapper.querySelector('select')

        if (select) {
            select.style.paddingLeft = `${label.clientWidth}px`
        }
    }

    componentDidMount() {
        this.resize()
        window.addEventListener('resize', this.resize)
    }

    componentWillUnmount() {
        window.removeEventListener('resize', this.resize)
    }

    componentDidUpdate(prevProps: Readonly<Props>) {
        const { isVisible } = this.props

        if (isVisible && !prevProps.isVisible) {
            this.resize()
        }
    }

    render() {
        const {
            sortRelevanceDictionaryValue,
            sortBrandsAscDictionaryValue,
            sortBrandsDescDictionaryValue,
            sortLabelDictionaryValue,
            className = '',
        } = this.props

        return (
            <div className={`search-sort ${className}`} ref={this.wrapperRef}>
                <div className='search-sort__label' ref={this.labelRef}>
                    {sortLabelDictionaryValue}
                    <span className='search-sort__label-separator'>:</span>
                </div>
                <SortBy
                    items={[
                        { value: SEARCH_INDEX, label: sortRelevanceDictionaryValue },
                        { value: SEARCH_INDEX_BY_BRAND_ASC, label: sortBrandsAscDictionaryValue },
                        { value: SEARCH_INDEX_BY_BRAND_DESC, label: sortBrandsDescDictionaryValue },
                    ]}
                    defaultRefinement={SEARCH_INDEX}
                />
            </div>
        )
    }
}

const mapStateToProps = (state: ApplicationState, ownProps: OwnProps) => {
    return {
        sortRelevanceDictionaryValue: dictionaryValueSelector('search.sort.relevance', state),
        sortBrandsAscDictionaryValue: dictionaryValueSelector('search.sort.brands.asc', state),
        sortBrandsDescDictionaryValue: dictionaryValueSelector('search.sort.brands.desc', state),
        sortLabelDictionaryValue: dictionaryValueSelector('search.sort.label', state),
        className: ownProps.className,
    }
}

const SearchSortWithData = connect(mapStateToProps)(SearchSort)

export { SearchSort, SearchSortWithData }
