import { connect } from 'react-redux'
import { ApplicationState } from '../../../../reducers'
import Header, { FieldProps } from './Header'
import { currentEventSelector } from '../../../../data/selectors'
import { BannerType } from '../../../../models/banner'

type OwnProps = {
    type: BannerType
    category?: string
}

const mapStateToProps = (state: ApplicationState, ownProps: OwnProps): FieldProps => {
    const event = currentEventSelector(state)

    if (event === undefined) {
        return {
            ...ownProps,
            logo: '',
            name: '',
            eventType: null,
            to: 0,
            countDownTimerLogo: '',
        }
    }
    const to = state.status.isEventRunning ? event.end : event.start
    return {
        to,
        ...ownProps,
        logo: event.logo === undefined ? '' : event.logo,
        name: event.name === undefined ? '' : event.name,
        eventType: event.type === undefined ? null : event.type,
        countDownTimerLogo: event.countdownTimerLogo === undefined ? '' : event.countdownTimerLogo,
    }
}

const HeaderWithData = connect(mapStateToProps)(Header)
export default HeaderWithData
