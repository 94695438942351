import * as React from 'react'
import { Grid, DictionaryValue, Button } from '../../components'
import classNames from 'classnames'
import './AccountForm.scss'

type Props = {
    heading: string
    intro: string | JSX.Element
    fieldList: JSX.Element[]
    checkboxList?: JSX.Element[]
    ctaLabel: string
    footer: string | JSX.Element
    error?: Error
    handleSubmit: (event: React.FormEvent<HTMLFormElement>) => void
}

class AccountForm extends React.Component<Props> {
    dictionaryOrJsx(input: string | JSX.Element): JSX.Element {
        return typeof input === 'string' ? <DictionaryValue token={input as string} /> : (input as JSX.Element)
    }

    getKey(field: JSX.Element): string | number | undefined {
        return field.key ? field.key : undefined
    }

    render() {
        const { heading, intro, fieldList, checkboxList, ctaLabel, footer, handleSubmit, error } = this.props

        const formClasses = classNames('account-form', { 'account-form--has-error': error })

        const fieldClasses = 'col-xs-12 col-sm-8 col-sm-offset-2 col-md-6 col-md-offset-3 col-lg-4 col-lg-offset-4'
        const checkboxClasses = 'col-xs-12 col-sm-8 col-sm-offset-2 col-md-12 col-md-offset-0'

        return (
            <form className={formClasses} onSubmit={handleSubmit} noValidate={true}>
                <Grid>
                    <div className='row'>
                        <div
                            className={`
                                col-xs-12 col-sm-8 col-sm-offset-2 col-md-6 col-md-offset-3 col-lg-4 col-lg-offset-4
                            `}
                        >
                            <h1 className='account-form__page-heading'>
                                <DictionaryValue token={heading} />
                            </h1>
                            <div className='account-form__page-intro'>{this.dictionaryOrJsx(intro)}</div>
                        </div>
                    </div>
                    {fieldList.map((field) => (
                        <div className='row' key={this.getKey(field)}>
                            <div className={fieldClasses}>{field}</div>
                        </div>
                    ))}
                    {checkboxList &&
                        checkboxList.map((checkbox, index) => (
                            <div
                                className={`row ${index === 0 ? 'account-form__first-checkbox' : ''}`}
                                key={this.getKey(checkbox)}
                            >
                                <div className={checkboxClasses}>{checkbox}</div>
                            </div>
                        ))}
                    {error && (
                        <div className='row account-form__error'>
                            <div className={checkboxClasses}>
                                <p className='account-form__error-message'>{error.message}</p>
                            </div>
                        </div>
                    )}
                    <div className='row'>
                        <div className={fieldClasses}>
                            <Button
                                buttonType='primary'
                                buttonStyle='square'
                                size='large'
                                type='submit'
                                className='account-form__submit'
                            >
                                <DictionaryValue token={ctaLabel} />
                            </Button>
                        </div>
                    </div>
                    <div className='row'>
                        <div className={fieldClasses}>
                            <p className='account-form__footer'>{this.dictionaryOrJsx(footer)}</p>
                        </div>
                    </div>
                </Grid>
            </form>
        )
    }
}

export { AccountForm }
