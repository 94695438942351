import * as React from 'react'
import { connect } from 'react-redux'
import BannerCarousel from './BannerCarousel'
import { default as BannerModel, BannerType } from '../../models/banner'
import { ApplicationState } from '../../reducers'
import { loadBanners } from '../../data/actions'
import EventType from '../../models/eventType'

type OwnProps = {
  type: BannerType
  category?: string
  eventType?: EventType | null
}

type LoadFieldProps = {
  banners?: BannerModel[]
  eventType?: EventType | null
  userId?: string | undefined
}

type LoadMethodProps = {
  loadBanners: () => void
}

type Props = LoadFieldProps & LoadMethodProps

class BannerCarouselLoad extends React.Component<Props> {
  componentWillMount() {
    this.props.loadBanners()
  }

  render() {
    const { banners, eventType, userId } = this.props

    if (banners === undefined) {
      return null
    }

    return (
      <BannerCarousel
        banners={banners}
        eventType={eventType}
        userId={userId}
      />
    )
  }
}

const mapStateToProps = (state: ApplicationState, ownProps: OwnProps): LoadFieldProps => {
  const banners = state.data.banners

  if (banners.length === 0) {
    return {}
  }

  let filtered = banners.filter(banner => banner.type === ownProps.type)

  if (ownProps.category !== undefined) {
    filtered = filtered.filter(banner => banner.category === ownProps.category)
  }

  // const withPositions = filtered.filter(banner => banner.position !== undefined)
  // const withoutPositions = filtered.filter(banner => banner.position === undefined)
  // const sortedWithPositions = withPositions.sort((a:any, b:any) => a.position - b.position)
  // const sorted = [...sortedWithPositions, ...withoutPositions]

  const shuffled = filtered
    .map(value => ({ value, sort: Math.random() }))
    .sort((a, b) => a.sort - b.sort)
    .map(({ value }) => value)

  return {
    banners: shuffled,
    eventType: ownProps.eventType,
    userId: state.account.loggedIn ? state.account.username : undefined,
  }
}

const actions: LoadMethodProps = {
  loadBanners,
}

const BannerCarouselWithData = connect(mapStateToProps, actions)(BannerCarouselLoad)
export default BannerCarouselWithData
