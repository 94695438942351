import moment from 'moment'
import { useEffect, useState } from 'react'
import { Subtract } from 'utility-types'
// import getCurrentTime from '../utilities/fetchCurrentTime'

type CounterProps = {
  to: number
}

type InjectedProps = {
  days?: number
  hours: number
  minutes: number
  seconds: number
}

const makeCountdown = <P extends InjectedProps>(Component: React.ComponentType<P>) => {
  type ParentProps = Subtract<P, InjectedProps> & CounterProps

  const MakeCountdown = (props: ParentProps) => {
    const [countDown, setCountDown] = useState({
      days: 0,
      hours: 0,
      minutes: 0,
      seconds: 0,
    })

    const [networkTimerInterval, setNetworkTimerInterval] = useState(0)

    useEffect(() => {
      // const getRightTime = async () => {
      // const time = await getCurrentTime()
      // if (time) {
      setNetworkTimerInterval(window.setInterval(tick, 1000))
      // }
      // }
      // getRightTime()
      return () => {
        window.clearInterval(networkTimerInterval)
      }
    }, [])

    const tick = () => {
      const now = moment()
      const target = moment.unix(to)
      const diff = moment.duration(target.diff(now))

      setCountDown({
        days: Math.floor(Math.max(diff.asDays(), 0)),
        hours: Math.max(Math.floor(diff.asHours()), 0),
        minutes: Math.max(diff.minutes(), 0),
        seconds: Math.max(diff.seconds(), 0),
      })
    }

    const { to } = props

    const { days, hours, minutes, seconds } = countDown

    return (
      <Component
        {...(props as unknown as P)}
        days={days}
        hours={hours}
        minutes={minutes}
        seconds={seconds}
      />
    )
  }
  return MakeCountdown
}

export default makeCountdown
