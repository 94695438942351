import { actionCreatorFactory } from 'typescript-fsa'
import Deal from '../models/deal'
import Collection from '../models/collection'
import { GroupOf } from '../models/groupof'
import Category from '../models/category'
import Brand from '../models/brand'
import Option from '../models/option'
import Dictionary from '../models/dictionary'
import { ApplicationState } from '../reducers'
import { loadDeal, loadBanners as getBanners, loadCfDealsFn } from '../services/Api'
import Event from '../models/event'
import { itemLoading, itemLoaded } from '../features/global/loading/actions'
import Menu from '../models/menu'
import FooterLink from '../models/footerLink'
import SocialAccount from '../models/socialAccount'
import Banner from '../models/banner'
import NonEventConfig from '../models/nonEventConfig'
import CmsPage from '../models/cmsPage'
import { API, graphqlOperation } from 'aws-amplify'
import { GraphQLResult } from '@aws-amplify/api'
import { updateUserFavouriteDeal, createUserFavouriteDeal } from '../graphql/mutations'
import { handleError } from '../utilities'
import { getFavDealsForUser } from '../features/account/utilities'
import { updateAccount } from '../features/account/actions'
import moment from 'moment'
import { CfDeals } from '../models/cfdeal'
import { isEmpty } from '../utilities/deal.helper'

const actionCreator = actionCreatorFactory('DATA')

const dealsLoaded = actionCreator<GroupOf<Deal>>('DEALS_LOADED')
const collectionsLoaded = actionCreator<GroupOf<Collection>>('COLLECTIONS_LOADED')
const categoryLoaded = actionCreator<Category>('CATEGORY_LOADED')
const brandsLoaded = actionCreator<Brand[]>('BRANDS_LOADED')
const brandsLoading = actionCreator('BRANDS_LOADING')
const brandDetailedLoading = actionCreator<string>('BRAND_DETAILED_LOADING')
const brandDetailsLoaded = actionCreator<Brand>('BRAND_DETAILS_LOADED')
const paymentsLoaded = actionCreator<Option[]>('PAYMENTS_LOADED')
const deliveriesLoaded = actionCreator<Option[]>('DELIVERY_LOADED')
const rewardsCashbacksLoaded = actionCreator<Option[]>('REWARDSCASHBACK_LOADED')
const dictionaryLoaded = actionCreator<Dictionary[]>('DICTIONARY_LOADED')
const eventsLoaded = actionCreator<Event[]>('EVENTS_LOADED')
const eventDetailsLoaded = actionCreator<Event[]>('EVENT_DETAILS_LOADED')
const menuLoaded = actionCreator<Menu>('MENU_LOADED')
const footerLinksLoaded = actionCreator<FooterLink[]>('FOOTER_LINKS_LOADED')
const socialAccountsLoaded = actionCreator<SocialAccount[]>('SOCIAL_ACCOUNTS_LOADED')
const bannersLoaded = actionCreator<Banner[]>('BANNERS_LOADED')
const nonEventConfigLoaded = actionCreator<NonEventConfig>('NONEVENTCONFIG_LOADED')
const pageLoaded = actionCreator<CmsPage>('PAGE_LOADED')
const sponsoredResultsLoaded = actionCreator<Deal[]>('SPONSORED_RESULTS_LOADED')
const cfDealsLoaded = actionCreator<CfDeals>('CF_DEALS_LOADED')

function loadDeals(dealSlugs: string[]) {
  return async (dispatch: any, getState: () => ApplicationState) => {
    const neededDeals: string[] = dealSlugs
    const loadedDeals: GroupOf<Deal> = getState().data.deals
    const missingDeals: string[] = neededDeals.filter(deal => loadedDeals[deal] === undefined)
    const deals: GroupOf<Deal> = {}
    await Promise.all(
      missingDeals.map(slug =>
        loadDeal(slug).then(
          deal => {
            deals[slug] = deal
          },
          // eslint-disable-next-line @typescript-eslint/no-empty-function
          () => {}
        )
      )
    )
    dispatch(dealsLoaded(deals))
  }
}

const loadSponsoredResults = (results: Deal[]) => async (dispatch: any) => dispatch(sponsoredResultsLoaded(results))

function handleFavourite(dealId: string, type: string, brandSlug: string, favDealId?: string, active?: boolean) {
  return async (dispatch: any, getState: () => ApplicationState) => {
    const state = getState()
    const username = state.account.username

    if (!username) {
      handleError('User not found!')
    }

    if (favDealId) {
      let input
      if (active) {
        input = {
          input: {
            id: favDealId,
            active: false,
            deactivatedAt: moment.unix(state.status.networkTime),
          },
        }
      } else {
        input = {
          input: {
            id: favDealId,
            active: true,
            addedAt: moment.unix(state.status.networkTime),
          },
        }
      }

      const response = (await API.graphql(graphqlOperation(updateUserFavouriteDeal, input))) as GraphQLResult

      if (response.data && response.data !== null && username) {
        const favDeals = await getFavDealsForUser(username)
        dispatch(updateAccount({ favDeals }))
      } else {
        handleError(response.errors)
      }
    } else {
      const response = (await API.graphql(
        graphqlOperation(createUserFavouriteDeal, {
          input: {
            dealId,
            type,
            brandSlug,
            userId: username,
            active: true,
            addedAt: moment.unix(state.status.networkTime),
          },
        })
      )) as GraphQLResult

      if (response.data && response.data !== null && username) {
        const favDeals = await getFavDealsForUser(username)
        dispatch(updateAccount({ favDeals }))
      } else {
        handleError(response.errors)
      }
    }
  }
}

function loadBanners() {
  return async (dispatch: any, getState: () => ApplicationState) => {
    const state = getState()

    if (state.data.banners.length > 0) {
      return
    }

    dispatch(itemLoading())

    try {
      const banners = await getBanners()
      dispatch(bannersLoaded(banners))
    } finally {
      dispatch(itemLoaded())
    }
  }
}

function loadCfDeals() {
  return async (dispatch: any, getState: () => ApplicationState) => {
    const state = getState()

    if (!isEmpty(state.data.cfDeals.public)) {
      return
    }

    dispatch(itemLoading())

    try {
      const data = await loadCfDealsFn()
      dispatch(cfDealsLoaded(data))
    } catch (e) {
      handleError('Unable to load always-on deals.')
    } finally {
      dispatch(itemLoaded())
    }
  }
}

export {
  brandsLoading,
  brandsLoaded,
  brandDetailsLoaded,
  brandDetailedLoading,
  categoryLoaded,
  collectionsLoaded,
  dealsLoaded,
  paymentsLoaded,
  deliveriesLoaded,
  rewardsCashbacksLoaded,
  dictionaryLoaded,
  loadDeals,
  handleFavourite,
  eventsLoaded,
  eventDetailsLoaded,
  menuLoaded,
  footerLinksLoaded,
  socialAccountsLoaded,
  bannersLoaded,
  loadBanners,
  nonEventConfigLoaded,
  pageLoaded,
  sponsoredResultsLoaded,
  loadSponsoredResults,
  cfDealsLoaded,
  loadCfDeals,
}
