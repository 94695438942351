import { connect } from 'react-redux'
import { ApplicationState } from '../../../reducers'
import Loading, { Props } from './loading'
import { isLoading } from './reducers'

const mapStateToProps = (state: ApplicationState): Props => {
    return {
        loading: isLoading(state),
    }
}

export default connect(mapStateToProps)(Loading)
