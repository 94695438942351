import * as React from 'react'
import { CSSTransition } from 'react-transition-group'
import './Overlay.scss'

type Props = {
    show: boolean
    onClick?: () => void
}

class Overlay extends React.Component<Props> {
    static defaultProps = {
        show: false,
    }

    render() {
        const { show, onClick } = this.props

        return (
            <CSSTransition
                in={show}
                timeout={300}
                classNames='overlay-'
                unmountOnExit={true}
            >
                <div className='overlay' onClick={onClick} />
            </CSSTransition>
        )
    }
}

export { Overlay }
