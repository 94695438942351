// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore TODO: improve this
import { Action, History, Location, LocationState } from 'history'
import { combineReducers } from 'redux'
import { connectRouter } from 'connected-react-router'
import { dataReducer, State as DataState } from './data/reducers'
import { loadingReducer, State as LoadingState } from './features/global/loading/reducers'
import { navReducer, State as NavState } from './features/global/nav/reducers'
import { statusReducer, State as StatusState } from './features/global/status/reducers'
import { CategoryReducer, State as CategoryState } from './features/category/reducer'
import { searchReducer, State as SearchState } from './features/search/store/reducers'
import { accountReducer, State as AccountState } from './features/account/reducer'

export const createRootReducer = (history: History) => {
  return combineReducers({
    router: connectRouter(history) as any,
    data: dataReducer,
    loading: loadingReducer,
    nav: navReducer,
    status: statusReducer,
    category: CategoryReducer,
    search: searchReducer,
    account: accountReducer,
  })
}

export type RouterActionType = Action
export interface RouterLocation<S> extends Location<S> {
  query: Record<string, string>
  pathname: string
  search: string
}

export interface RouterState<S = LocationState> {
  location: RouterLocation<S>
  action: RouterActionType
}

export type ApplicationState = {
  router: RouterState
  data: DataState
  loading: LoadingState
  nav: NavState
  status: StatusState
  category: CategoryState
  search: SearchState
  account: AccountState
}
