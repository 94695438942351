import * as React from 'react'
import { Container } from '../../../../../components/cf-package'
import './style.scss'

type Props = {
  className?: string
  id?: string
}

class FeatureOn extends React.Component<Props> {
  render() {
    const { className = '', id } = this.props

    return (
      <div className='bg-primary'>
        <Container>
          <section
            className={`${className} cf-feature-blk`}
            id={id}
          >
            <h3 className='ttl head-lg'>As featured on</h3>
            <div className='logos-list cf-flex flex-row'>
              <div className='logo-item'>
                <img
                  src='./static/images/logos/logo_1.png'
                  alt='logo'
                />
              </div>
              <div className='logo-item'>
                <img
                  src='./static/images/logos/logo_2.png'
                  alt='logo'
                />
              </div>
              <div className='logo-item'>
                <img
                  src='./static/images/logos/logo_3.png'
                  alt='logo'
                />
              </div>
              <div className='logo-item'>
                <img
                  src='./static/images/logos/logo_4.png'
                  alt='logo'
                />
              </div>
              <div className='logo-item'>
                <img
                  src='./static/images/logos/logo_5.png'
                  alt='logo'
                />
              </div>
              <div className='logo-item'>
                <img
                  src='./static/images/logos/logo_6.png'
                  alt='logo'
                />
              </div>
            </div>
          </section>
        </Container>
      </div>
    )
  }
}

export default FeatureOn
