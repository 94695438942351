import _ from 'lodash'
import { useEffect, useState } from 'react'
import { connectHits } from 'react-instantsearch-dom'
import { connect } from 'react-redux'
import { loadSponsoredResults } from '../../../data/actions'
import Deal from '../../../models/deal'
import { ApplicationState } from '../../../reducers'

type Props = {
  hits: any
  loadSponsoredResults: (deals: Deal[]) => void
  sponsoredResults: Deal[] | []
}

const RawStructuredResults = (props: Props) => {
  const { hits, loadSponsoredResults, sponsoredResults } = props
  const [searchResults, setSearchResults] = useState(sponsoredResults)

  useEffect(() => {
    loadSponsoredResults(searchResults)
  }, [searchResults])

  const exactResults = hits?.filter(
    (hit: any) =>
      hit && hit._rankingInfo.words - 1 === hit._rankingInfo.proximityDistance && hit._rankingInfo.nbExactWords >= 1
  )

  if (!_.isEqual(exactResults, searchResults)) {
    setSearchResults(exactResults)
  }

  return null
}

const mapStateToProps = (state: ApplicationState) => {
  return {
    sponsoredResults: state.data.sponsoredResults,
  }
}

export const StructuredResults = connect(mapStateToProps, { loadSponsoredResults })(connectHits(RawStructuredResults))
