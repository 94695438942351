import * as React from 'react'
import { connect } from 'react-redux'
import { ApplicationState } from '../../reducers'
import { dictionaryValueSelector } from '../../data/selectors'

type DataProps = {
    value: string
    children?: (value: string) => JSX.Element
}

type InputProps = {
    token: string
    children?: (value: string) => JSX.Element
}

class DictionaryValueOutput extends React.Component<DataProps> {
    render() {
        const { value, children } = this.props

        if (children) {
            return children(value)
        }

        return this.props.value
    }
}

const mapStateToProps = (state: ApplicationState, ownProps: InputProps): DataProps => ({
    value: dictionaryValueSelector(ownProps.token, state),
    children: ownProps.children,
})

const DictionaryValue = connect(mapStateToProps)(DictionaryValueOutput)

export {
    DictionaryValue
}
