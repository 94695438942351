import { ApplicationState } from '../../../reducers'
import Collection from '../../../models/collection'
import { GroupOf } from '../../../models/groupof'
import { collectionsLoaded, loadDeals } from '../../../data/actions'
import { itemLoading, itemLoaded } from '../../global/loading/actions'
import {
    loadEventExtraData as getEventExtraData,
    loadCollection,
} from '../../../services/Api'
import { currentEventSelector } from '../../../data/selectors'

function loadHomepageData() {
    return async (dispatch: any, getState: () => ApplicationState) => {
        dispatch(itemLoading())

        const currentEvent = currentEventSelector(getState())
        if (currentEvent !== undefined) {
            // eslint-disable-next-line @typescript-eslint/no-unused-vars
            const updatedCurrentEvent = await getEventExtraData(currentEvent.slug).then(
                async (eventExtra) => {
                    if (eventExtra.collections !== undefined) {

                        // load collections of current event
                        const collectionSlugs = eventExtra.collections.map(item => item.slug)
                        if (collectionSlugs !== undefined) {
                            const collections: GroupOf<Collection> = {}

                            await Promise.all(collectionSlugs.map(slug =>
                                loadCollection(slug).then(
                                    (collection) => {
                                        collections[slug] = collection
                                    },
                                    // eslint-disable-next-line @typescript-eslint/no-empty-function
                                    () => {
                                    }
                                )
                            ))
                            dispatch(collectionsLoaded(collections))
                        }

                        // load deals of current event collections
                        const currentCollections = getState().data.collections
                        const dealSlugs =
                            Array.prototype.concat.apply([], Object.keys(currentCollections)
                                .map((collectionSlug) => {
                                    const collection = currentCollections[collectionSlug]
                                    if (collection !== undefined && collection.deals !== undefined) {
                                        return collection.deals.map(dealPlacement => dealPlacement.slug)
                                    }
                                    return []
                                })
                            )
                        dispatch(loadDeals(dealSlugs))

                        // add collections (with structured data) back to current event
                        currentEvent.collections = eventExtra.collections
                    }
                    return currentEvent
                }
            )
        }
        dispatch(itemLoaded())
    }
}

export {
    loadHomepageData,
}
