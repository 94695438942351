import * as React from 'react'
import './Subscribe.scss'
import { Grid, NameField, EmailField, Button } from '../../../components'
import bind from 'bind-decorator'
import { Layout } from '../../layout'
import Helmet from 'react-helmet'
import { Form } from 'react-final-form'
import ReCAPTCHA from 'react-google-recaptcha'
import axios from 'axios'
import { AnyObject } from 'final-form'

type Props = {
    site_key: string
    secret_key: string
    url: string
    recaptchaRef: any
}

export interface State {
    success: boolean
    errorMessage?: string | null
    isLoading: boolean
    corsURL: string
}

class Subscribe extends React.Component<Props, State> {
    static defaultProps = {
        site_key: process.env.REACT_APP_RECAPTCHA_SITE_KEY,
        secret_key: process.env.REACT_APP_RECAPTCHA_SECRET_KEY,
        url: process.env.REACT_APP_RECAPTCHA_URL,
        recaptchaRef: React.createRef(),
    }

    constructor(props: Readonly<Props>) {
        super(props)
        this.state = {
            success: false,
            errorMessage: null,
            isLoading: false,
            corsURL: 'https://cors-anywhere.herokuapp.com/',
        }
    }

    @bind
    async handleChange(value: string | null) {
        await axios({
            method: 'post',
            url: this.state.corsURL + process.env.REACT_APP_RECAPTCHA_URL,
            headers: {
                'Access-Control-Allow-Origin': '*',
                'Content-Type': 'application/json',
            },
            params: {
                secret: this.props.secret_key,
                response: value,
            },
        }).then(
            (response) => {
                if (response.data.success) {
                    this.setState({ success: true })
                }
            },
            (error) => {
                this.setState({ errorMessage: error })
            }
        )
    }

    @bind
    async handleSubmit(formValues: AnyObject) {
        if (this.state.success) {
            this.setState({ isLoading: true })
            const POST_URL = 'https://subscribe.clickfrenzy.com.au/emarsys/register'
            const formParams = {
                ...formValues,
                CID: 785858163,
                SID: '',
                UID: '',
                f: 715,
                p: 2,
                a: 'r',
                el: '',
                endlink: '',
                llid: '',
                c: '',
                counted: '',
                RID: '',
                mailnow: '',
                optin: 'y',
                'g-recaptcha-response': this.state.success,
            }

            await axios({
                method: 'post',
                url: this.state.corsURL + POST_URL,
                headers: {
                    'Access-Control-Allow-Origin': '*',
                    'Content-Type': 'application/json',
                },
                params: formParams,
            }).then(
                (response) => {
                    if (response.status === 200) {
                        window.location.href = 'https://suite24-cdn.emarsys.net/preview_landing.php?f=715&CID=785858163'
                    }
                },
                (error) => {
                    this.setState({ isLoading: false })
                    alert(error)
                }
            )
        } else {
            alert('ReCaptcha is not successfully verified yet, please submit the form again.')
        }
    }

    render() {
        const { site_key, recaptchaRef } = this.props
        const { success, errorMessage, isLoading } = this.state
        const fieldClasses = 'col-xs-12 col-sm-8 col-sm-offset-2 col-md-6 col-md-offset-3 col-lg-4 col-lg-offset-4'

        return (
            <>
                <Helmet>
                    <title>Subscribe - ClickFrenzy</title>
                </Helmet>
                <Layout>
                    <Grid>
                        <div className='subscribe row'>
                            <div className='col-xs-12'>
                                <img data-blink-uuid='48f13916-be58-4882-9ee7-88c5aa3cb252' alt='logo' />
                                <h1>Not getting our emails?</h1>
                                <h3>
                                    Enter your details below to be re-added and get all the info on Click Frenzy
                                    including our 99% off deals.
                                </h3>
                                <Form onSubmit={this.handleSubmit}>
                                    {({ handleSubmit }) => (
                                        <form onSubmit={handleSubmit}>
                                            <div className={fieldClasses}>
                                                <NameField name='inp_1' placeholder='First Name*' />
                                                <EmailField name='inp_3' placeholder='Email*' />
                                                {
                                                /* <Field name='inp_5' component={SelectField} validate={required(name)}>
                                                    <option value=''>Gender*</option>
                                                    <option value='1'>Male</option>
                                                    <option value='2'>Female</option>
                                                </Field> */
                                                }
                                            </div>
                                            <div className='subscribe__recaptcha'>
                                                <ReCAPTCHA
                                                    ref={recaptchaRef}
                                                    sitekey={site_key}
                                                    onChange={this.handleChange}
                                                />
                                                {!success && errorMessage !== null ? (
                                                    <h3>
                                                        ReCaptcha Error: {errorMessage}, Please refresh the page and
                                                        resubscribe.
                                                    </h3>
                                                ) : (
                                                    ''
                                                )}
                                            </div>
                                            <div className={fieldClasses}>
                                                <Button
                                                    buttonType='primary'
                                                    buttonStyle='square'
                                                    size='large'
                                                    type='submit'
                                                    className='subscribe__submit'
                                                >
                                                    {isLoading ? 'Loading ...' : 'Subscribe'}
                                                </Button>
                                            </div>
                                        </form>
                                    )}
                                </Form>
                            </div>
                        </div>
                    </Grid>
                </Layout>
            </>
        )
    }
}

export { Subscribe }
