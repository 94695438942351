import * as React from 'react'
import classNames from 'classnames'
import { ClearInput } from './ClearInput'
import './Input.scss'
import { DictionaryValue } from '../dictionary-value'
import { Icon, info, cross } from '../icon'
import bind from 'bind-decorator'

type ComponentProps = {
    errorMessage?: string
    tooltip?: string
    maskField?: boolean
    handleMaskField?: (e: React.MouseEvent) => void
}

type InputProps = React.InputHTMLAttributes<HTMLInputElement>

type Props = ComponentProps & InputProps

type State = {
    tooltipShown: boolean
}

class Input extends React.Component<Props, State> {
    readonly state: State = { tooltipShown: false }

    @bind
    toggleTooltip() {
        this.setState(state => ({
            tooltipShown: !state.tooltipShown,
        }))
    }

    render() {
        // eslint-disable-next-line react/prop-types
        const { className, errorMessage, tooltip, maskField, handleMaskField, ...rest } = this.props
        const { tooltipShown } = this.state

        const classes = classNames(
            'input',
            className,
            { 'input--error': errorMessage },
            { 'input--has-tooltip': tooltip },
            { 'input--tooltip-shown': tooltipShown }
        )

        return (
            <>
                <div className={classes}>
                    <ClearInput className='input__field' {...rest} />
                    { tooltip && (
                        <>
                            <Icon
                                icon={info}
                                className='input__tooltip-trigger'
                                onClick={this.toggleTooltip}
                            />
                            <div className='input__tooltip'>
                                <Icon
                                    icon={cross}
                                    className='input__tooltip-close'
                                    onClick={this.toggleTooltip}
                                />
                                <p className='input__tooltip-content'>
                                    <DictionaryValue token={tooltip} />
                                </p>
                            </div>
                        </>
                    )}
                    { (maskField !== undefined && handleMaskField) && (
                        <>
                            {/* {maskField ?
                                <IconField
                                    type='eye-invisible'
                                    onClick={handleMaskField}
                                    className='input__eye-trigger'
                                />
                            :
                                <IconField
                                    type='eye'
                                    theme='twoTone'
                                    twoToneColor='#932091'
                                    onClick={handleMaskField}
                                    className='input__eye-trigger'
                                />
                            } */}
                        </>
                    )}
                </div>
                { errorMessage && (
                    <p className='input__error-message'>
                        <DictionaryValue token={errorMessage} />
                    </p>
                )}
            </>
        )
    }
}

export { Input }
