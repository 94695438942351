import * as React from 'react'
import {
    DictionaryValue,
    EmailField,
    NameField,
    MobileField,
    PasswordField,
    GenderFieldWithData,
    PostcodeField,
} from '../../components'
import './SignUp.scss'
import { AccountFormSignUp } from './AccountFormSignUp'
import { Link } from 'react-router-dom'
import { TermsNCondition } from '../../components/form/fields/TermsNCondition'

type Props = {
    error?: Error
    handleSubmit: (event: React.FormEvent<HTMLFormElement>) => void
}

class SignUp extends React.Component<Props> {
    render() {
        const { handleSubmit, error } = this.props

        return (
            <AccountFormSignUp
                heading='sign-up.heading'
                intro='sign-up.intro'
                ctaLabel='sign-up.submit'
                handleSubmit={handleSubmit}
                error={error}
                footer={(
                    <>
                        <DictionaryValue token='sign-up.have-account' />{' '}
                        <Link to='/account?login'>
                            <DictionaryValue token='sign-up.login' />
                        </Link>
                    </>
                )}
                fieldList={[
                    <EmailField name='email' placeholder={'sign-up.field.email'} key='email' />,
                    (
                    <PasswordField
                        name='password'
                        placeholder={'sign-up.field.password'}
                        tooltip='sign-up.password.tooltip'
                        key='password'
                        validateFormat={true}
                    />
                    ),
                    <NameField name='firstName' placeholder='sign-up.field.firstName' key='firstName' />,
                    <NameField name='lastName' placeholder='sign-up.field.lastName' key='lastName' />,
                    <GenderFieldWithData name='gender' placeholder='sign-up.field.gender' key='gender' />,
                    <PostcodeField name='postcode' placeholder='sign-up.field.postcode' key='postcode' />,
                    <MobileField name='mobile' placeholder='sign-up.field.mobile' key='mobile' />,
                ]}
                checkboxList={[<TermsNCondition name='termsncondition' key='termsncondition' />]}
            />
        )
    }
}

export { SignUp }
