import { bind } from 'bind-decorator'
import moment from 'moment'
import * as React from 'react'
import { history } from '../../../../../../src/history'
import { CfButton, CFCountdown } from '../../../../../components/cf-package'
import { AttendArrow, AttendArrowSm, HitTheButton, HitTheButtonSm } from '../../../../../components/cf-package/Svg'
import Event from '../../../../../models/event'
import './style/BrandwallSignup.scss'

type Props = {
  className?: string
  id?: string
  data: Event
}

class BrandwallSignup extends React.Component<Props> {
  @bind
  handleSignUpLink() {
    history.push('/account?join')
  }

  render() {
    const { className = '', id, data } = this.props
    const { name, logo, start } = data

    const to = moment.unix(start)

    return (
      <section
        className={`${className} cf-brandwall-signup`}
        id={id}
      >
        <div className='inner cf-flex flex-row align-center justify-between'>
          <div className='img'>
            <img
              src='data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAIAAACQd1PeAAAABnRSTlMA/
                            wD/AP83WBt9AAAADElEQVQI12P4//8/AAX+Av7czFnnAAAAAElFTkSuQmCC'
              data-blink-src={logo}
              alt={name}
            />
          </div>
          <div className='ct'>
            {name && <h4 className='ttl head-md'>{name}</h4>}
            <time
              className='meta-date'
              dateTime={to.format('Y-m-d')}
            >
              {to.format('h:mmA ddd, MMM DD YYYY')}
            </time>
            <CFCountdown to={start} />
            <div className='cta-blk cf-flex flex-col align-center'>
              <CfButton
                onClick={this.handleSignUpLink}
                buttonType='cfwhiteoutline'
                size='large'
              >
                <span style={{ padding: '0 25px' }}>Sign Up Now</span>
              </CfButton>
              <div className='guide-box-sm'>
                <AttendArrowSm className='pos-arrow-sm' />
                <HitTheButtonSm className='pos-text-sm' />
              </div>
              <AttendArrow className='pos-arrow' />
              <HitTheButton className='pos-text' />
            </div>
          </div>
        </div>
      </section>
    )
  }
}

export default BrandwallSignup
