import * as React from 'react'
import { Grid } from '../../../../components/normal-grid'
// import { BannerWithData } from '../../../../components/banner'
import { BannerType } from '../../../../models/banner'
import './Header.scss'
import EventType from '../../../../models/eventType'
import MainCountdown from '../../../../components/main-countdown'
// import CategoryCountdown from '../../../../components/category-countdown'

export type FieldProps = {
  logo: string
  name: string
  eventType: EventType | null
  type: BannerType
  category?: string
  to: number
  countDownTimerLogo: string
}

type Props = FieldProps

class Header extends React.Component<Props> {
  render() {
    // const { eventType, type, category, to, countDownTimerLogo } = this.props
    const { eventType, to, countDownTimerLogo } = this.props

    // const currentLocation = window.location.pathname

    return (
      <div className={`home-header home-header--${eventType}`}>
        <Grid className='home-header__timer-section'>
          <MainCountdown
            to={to}
            countDownTimerLogo={countDownTimerLogo}
            eventType={eventType}
          />
        </Grid>
        {/* {currentLocation === '/' ?
                (
                    <Grid className='home-header__timer-section' >
                        <MainCountdown to={to} countDownTimerLogo={countDownTimerLogo} eventType={eventType} />
                    </Grid>) :
                (
                    <Grid className='home-header__banner-section'>
                        <div className='row'>
                            {currentLocation === '/search' ? (
                                <div className='col-xs-12 col-md-4 home-header__category-timer isHidden'>
                                    <CategoryCountdown
                                        to={to}
                                        countDownTimerLogo={countDownTimerLogo}
                                        eventType={eventType}
                                    />
                                </div>
                            ) : (
                                <div className='col-xs-12 col-md-4 home-header__category-timer'>
                                    <CategoryCountdown
                                        to={to}
                                        countDownTimerLogo={countDownTimerLogo}
                                        eventType={eventType}
                                    />
                                </div>
                            )}
                            <div className='col-xs-12 col-md-8 home-header__banner'>
                                <BannerWithData type={type} category={category} />
                            </div>
                        </div>
                    </Grid>
                )} */}
        {/* <Grid className='home-header__banner-section'>
                    <div className='row'>
                        <div
                            className={`col-xs-12 col-md-2
                            home-header__logo-wrapper
                            home-header__logo-wrapper--${eventType}`}
                        >
                            <div className='home-header__logo-container'>
                                <img
                                    src='data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAIAAACQd1PeAAAABnRSTlMA/
                                    wD/AP83WBt9AAAADElEQVQI12P4//8/AAX+Av7czFnnAAAAAElFTkSuQmCC'
                                    data-blink-src={logo}
                                    alt={name}
                                    className='home-header__logo'
                                />
                            </div>
                        </div>
                        <div className='col-xs-12 col-md-10 home-header__banner'>
                            <BannerWithData type={type} category={category} />
                        </div>
                    </div>
                </Grid> */}
        {/* {type === 'HOME_HEADER' && (
                  <div className='home-header__categories'>
                      <CategoryListWithData />
                  </div>
                )} */}
      </div>
    )
  }
}

export default Header
