import { ApplicationState } from '../../../reducers'
import { itemLoading, itemLoaded } from '../loading/actions'
import { loadCategoryData } from '../../category/actions'
import { categorySelector } from '../../../data/selectors'
import { loadPageData } from '../../page/actions'

function route(slug: string) {
    return async (dispatch: any, getState: () => ApplicationState) => {
        dispatch(itemLoading())

        await dispatch(loadCategoryData(slug))
        const category = categorySelector(getState(), slug)

        if (category) {
            dispatch(itemLoaded())
            return
        }

        await dispatch(loadPageData(slug))
        dispatch(itemLoaded())
    }
}

export { route }
