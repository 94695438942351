import React from 'react'
import { Link } from 'react-router-dom'
import { Grid } from '../../components/grid'
import EventType from '../../models/eventType'
import FooterLink from '../../models/footerLink'
import SocialAccount, { SocialAccountType } from '../../models/socialAccount'
import { TopBanner } from '../../models/topBanner'
import clickFrenzyClassicWhiteLogo from '../../static/images/CF-Classic-Logo-White.png'
import { DictionaryValue } from '../dictionary-value'
import { IconType, SvgIcon, facebook, instagram, twitter } from '../icon'
import SponsorBubble from '../sponsor-bubble'
import styles from './index.module.scss'
// import tempFashionEventLogo from '../../static/images/PPxFF-HORIZ-WHITE.png'
// import tempFashionFooterPartnerLogo from '../../static/images/PayPal-logo-horizontal-one-color-flat-RGB-white.png'

type Props = {
  topBanner: TopBanner | null
  menuList: FooterLink[]
  socialAccounts: SocialAccount[]
  year: string
  eventLogo: string | null
  eventType?: EventType | null
}

const networks: Record<SocialAccountType, { url: string; icon: IconType }> = {
  FACEBOOK: { url: 'https://www.facebook.com/', icon: facebook },
  INSTAGRAM: { url: 'https://www.instagram.com/', icon: instagram },
  TWITTER: { url: 'https://twitter.com/', icon: twitter },
}

class NewFooter extends React.Component<Props> {
  render() {
    const {
      topBanner,
      menuList,
      socialAccounts,
      year,
      // eventLogo,
      eventType,
    } = this.props

    const menuItemsWithSortOrder = menuList.filter(item => item.sortOrder !== undefined)
    const menuItemsWithoutSortOrder = menuList.filter(item => item.sortOrder === undefined)
    const menuItemsSortedWithOrder = menuItemsWithSortOrder.sort((a: any, b: any) => a.sortOrder - b.sortOrder)
    const sortedMenuList = [...menuItemsSortedWithOrder, ...menuItemsWithoutSortOrder]

    return (
      <footer className={styles.footer}>
        <div className={`${styles.footerBody} ${styles[`footer-${eventType}`]}`}>
          <Grid>
            <div className={styles.footerTop}>
              <div className={styles.eventLogo}>
                <div className={styles.logoWrapper}>
                  <a href='/'>
                    {
                      //This is the original code which is commented out temporarily for Travel Frenzy
                      /* {eventLogo ? (
                      <img
                        data-blink-src={eventLogo}
                        data-blink-quality='better'
                        data-blink-resize='200x'
                        className={styles.logoImage}
                        alt='footer-logo'
                      />
                    ) : (
                      <Icon
                        icon={logo}
                        className={styles.logoImage}
                      />
                    )} */
                    }
                    {
                      //This is the temporary code for Travel Frenzy
                      <img
                        src={clickFrenzyClassicWhiteLogo}
                        className={styles.logoImage}
                        alt='footer-logo'
                      />
                    }
                  </a>
                </div>
                {topBanner && (
                  // This is the original code which is commented out temporarily for Fashion Frenzy
                  <SponsorBubble
                    topBanner={topBanner}
                    textColor={topBanner.bannerTextColor ? topBanner.bannerTextColor.toString() : '#fff'}
                    backgroundColor={topBanner.backgroundColor ? topBanner.backgroundColor.toString() : '#fff'}
                  />
                  // This is the temporary code for Fashion Frenzy
                  // <SponsorBubble
                  //   topBanner={topBanner}
                  //   textColor='#fff'
                  //   backgroundColor='#5c2d91'
                  //   partnerLogo={tempFashionFooterPartnerLogo}
                  // />
                )}
              </div>
              <div className={`row ${styles.menuList}`}>
                {sortedMenuList.map((listItem, index) => {
                  if (
                    listItem.path.includes('https://') ||
                    listItem.path.includes('http://') ||
                    listItem.path.includes('www') ||
                    listItem.path.includes('.com')
                  ) {
                    return (
                      <div
                        className={`col-xs-12 col-md-4 ${styles.menuItem}`}
                        key={index}
                      >
                        <a
                          href={listItem.path}
                          className={styles.itemLink}
                        >
                          {listItem.name}
                        </a>
                      </div>
                    )
                  }
                  return (
                    <div
                      className={`col-xs-12 col-md-4 ${styles.menuItem}`}
                      key={index}
                    >
                      <Link
                        to={listItem.path}
                        className={styles.itemLink}
                      >
                        {listItem.name}
                      </Link>
                    </div>
                  )
                })}
              </div>
              <div className={styles.socialActionsContainer}>
                <div className={styles.socialActions}>
                  {socialAccounts.map((account, index) => {
                    return (
                      <a
                        href={`${networks[account.type].url}${account.account}`}
                        key={index}
                        className={`${styles.socialIcons} ${styles[`footer-social-${eventType}`]}`}
                      >
                        <div>
                          <SvgIcon icon={networks[account.type].icon} />
                        </div>
                      </a>
                    )
                  })}
                </div>
                <div className={styles.socialActionsTrademark}>
                  <strong>License to Use Registered Click Frenzy Trademarks</strong>
                  <div>
                    The words Click Frenzy and all versions of the Click Frenzy logo are fully registered trademarks
                    owned by Click Frenzy. Usage of these trademarks is prohibited at all times, except with the express
                    permission of Click Frenzy Pty Ltd.
                  </div>
                </div>
              </div>
            </div>
            <div className={styles.footerBottom}>
              <div className={styles.copyrightText}>
                &copy; {year} <DictionaryValue token='footer.copyright' />
              </div>
            </div>
          </Grid>
        </div>
      </footer>
    )
  }
}

export default NewFooter
