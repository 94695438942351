import { createSelector } from 'reselect'
import { ApplicationState } from '../reducers'
import NamedItem from '../models/namedItem'
import Brand from '../models/brand'
import Event from '../models/event'
import Category from '../models/category'
import Deal from '../models/deal'
import Partner from '../models/partner'
import Banner from '../models/banner'
import { LandingPageDeals } from '../models/cfdeal'
import { isSubcategory } from '../features/category/utilities'
import ThreeColumnImageText from '../models/threeColumnImageText'
import { initialState } from './reducers'
import CmsPage from '../models/cmsPage'
import DealPlacement from '../models/dealPlacement'
import { DealType } from '../models/dealType'
import { isUserAdmin } from '../utilities'
import moment from 'moment'
import { filterExpired } from '../utilities/deal.helper'

const brandsForDealsSelector = (state: ApplicationState, deals: Deal[], detailed = false) => {
  const categoryBrandSlugs = deals.reduce<string[]>((brands, deal) => {
    if (deal.brandSlug && !brands.includes(deal.brandSlug)) {
      brands.push(deal.brandSlug)
    }

    return brands
  }, [])

  const brands = detailed ? state.data.brandsDetailed : state.data.brands

  if (brands === null) {
    return []
  }

  return brands
    .filter(brand => categoryBrandSlugs.includes(brand.slug))
    .sort((a, b) => {
      if (a.name > b.name) {
        return 1
      }

      if (a.name < b.name) {
        return -1
      }

      return 0
    })
}

const brandsSelector = (state: ApplicationState) => state.data.brands

const brandSelector = (state: ApplicationState, slug: string) => {
  return state.data.brands
    ? state.data.brands.find(item => {
        return item.slug === slug
      })
    : undefined
}

const brandsFromSlugsSelector = (state: ApplicationState, slugs: string[]) => {
  return state.data.brands ? state.data.brands.filter(item => slugs.includes(item.slug)) : []
}

const brandSelectorByName = (state: ApplicationState, name: string) => {
  return state.data.brands
    ? state.data.brands.find(item => {
        return item.name === name
      })
    : undefined
}

const brandDetailedSelector = (state: ApplicationState, slug: string) => {
  return state.data.brandsDetailed.find(item => {
    return item.slug.toLowerCase() === slug.toLowerCase()
  })
}

const categorySelector = (state: ApplicationState, slug: string): Category | undefined => {
  return state.data.categories[slug] || state.data.categories[slug.toLowerCase()] || undefined
}

const categoriesForCurrentEventSelector = (state: ApplicationState) => {
  const currentEvent = currentEventSelector(state)

  if (!currentEvent || !currentEvent.categories) {
    return []
  }

  return currentEvent.categories.sort((a, b) => Number(a.sortOrder) - Number(b.sortOrder))
}

const currentAndFutureEventsSelectorUsingNetworkTime = (state: ApplicationState) => {
  const events = eventsSelector(state)
  const now = state.status.networkTime

  return events.filter(event => event.end > now).sort((a, b) => a.end - b.end)
}

const currentAndFutureEventsSelector = (state: ApplicationState) => {
  const events = eventsSelector(state)
  const now = moment().unix()

  return events.filter(event => event.end > now).sort((a, b) => a.end - b.end)
}

const currentEventSelector = (state: ApplicationState): Event | undefined => {
  // return currentEventFromEventsSelector(eventsSelector(state), state.status.networkTime)
  return currentEventFromEventsSelector(eventsSelector(state))
}

const currentEventFromEventsSelectorUsingNetworkTime = (events: Event[], networkTime: number): Event | undefined => {
  const now = networkTime
  return events.filter(event => event.start > now || event.end > now).sort((a, b) => a.end - b.end)[0]
}

const currentEventFromEventsSelector = (events: Event[]): Event | undefined => {
  const now = Math.floor(Date.now() / 1000)
  return events.filter(event => event.start > now || event.end > now).sort((a, b) => a.end - b.end)[0]
}

const dealsSelector = (state: ApplicationState) => state.data.deals

const dealsForCategorySelector = (state: ApplicationState, category: Category, type: DealType | 'all' = 'all') => {
  let deals: DealPlacement[] | undefined = []

  switch (type) {
    case 'gold':
      deals = category.goldDeals
      break
    case 'silver':
      deals = category.silverDeals
      break
    case 'selfServe':
      deals = category.selfServeDeals
      break
    case 'all':
      deals = [...(category.goldDeals || []), ...(category.silverDeals || []), ...(category.selfServeDeals || [])]
      break
  }

  if (!deals || deals.length === 0) {
    return []
  }

  return deals.map(deal => state.data.deals[deal.slug]).filter(deal => deal !== undefined)
}

const dealsForBrandSelector = (state: ApplicationState, brand: Brand) => {
  if (!brand.deals) {
    return []
  }

  return brand.deals.map(deal => state.data.deals[deal])
}

const dictionaryValueSelector = (requestKey: string, state: ApplicationState): string => {
  const dictionary = state.data.dictionary
  const returnValue = dictionary.find(item => item.key === requestKey)

  return returnValue === undefined ? requestKey : returnValue.value
}

const eventsSelector = (state: ApplicationState) => state.data.events

const partnersForCurrentEventSelector = (state: ApplicationState): Partner[] => {
  const event = currentEventSelector(state)
  return (event && event.partners) || []
}

const interstitialPagePartnersForCurrentEventSelector = (state: ApplicationState): Partner[] => {
  const event = currentEventSelector(state)
  return (event && event.interstitialPagePartners) || []
}

const partnersForNonEventSelector = (state: ApplicationState): Partner[] => {
  return state.data.nonEventConfig.partners || []
}

const brandsForCurrentPartnerSelector = (state: ApplicationState, brandSlugs: string[]): Brand[] | null => {
  const brandsWithService = state.data.brands
    ? state.data.brands.filter(brand => brandSlugs.indexOf(brand.slug) !== -1)
    : null
  return brandsWithService
}

const membershipBenefitsForCurrentEventSelector = (state: ApplicationState): ThreeColumnImageText => {
  const event = currentEventSelector(state)
  let membershipBenefits = initialState.membershipBenefits

  if (event === undefined) {
    return membershipBenefits
  }

  if (event.membershipBenefits) {
    membershipBenefits = event.membershipBenefits
  }

  return membershipBenefits
}

const groupBrandsSelector = createSelector(brandsSelector, (brands): NamedItem<Brand[]>[] => {
  type BrandGroupType = { [k: string]: NamedItem<Brand[]> }
  const nameRegex = /[^A-Z]+/

  if (brands === null) {
    return []
  }

  const groups: BrandGroupType = brands.reduce((previous: BrandGroupType, brand) => {
    const firstCharacter = brand.name[0].toUpperCase()

    const name = nameRegex.test(firstCharacter) ? '#' : firstCharacter

    const item = previous[name] !== undefined ? previous[name] : { name, item: [] }

    item.item.push(brand)

    return {
      ...previous,
      [name]: item,
    }
  }, {})

  return Object.keys(groups)
    .sort()
    .map(name => ({
      ...groups[name],
      item: groups[name].item.sort((a, b) => {
        if (a.name > b.name) {
          return 1
        }

        if (a.name < b.name) {
          return -1
        }

        return 0
      }),
    }))
})

export type CategoryProps = {
  slug?: string
}

const currentCategorySelector = (state: ApplicationState, props: any) =>
  props.slug ? state.data.categories[props.slug] || state.data.categories[props.slug.toLowerCase()] : undefined

const categoryDealsSelector = createSelector(
  currentCategorySelector,
  dealsSelector,
  (
    category,
    deals
  ): {
    goldDeals: DealPlacement[]
    silverDeals: DealPlacement[]
    selfServeDeals: DealPlacement[]
  } => {
    let goldDeals: DealPlacement[] = []
    let silverDeals: DealPlacement[] = []
    let selfServeDeals: DealPlacement[] = []

    if (category === undefined) {
      return {
        goldDeals: [],
        silverDeals: [],
        selfServeDeals: [],
      }
    }

    if (category.goldDeals) {
      goldDeals = category.goldDeals
        .filter(dealPlacement => deals[dealPlacement.slug] !== undefined)
        .sort((a, b) => Number(a.position) - Number(b.position))
    }

    if (category.silverDeals) {
      silverDeals = category.silverDeals.filter(dealPlacement => deals[dealPlacement.slug] !== undefined)
    }

    if (category.selfServeDeals) {
      selfServeDeals = category.selfServeDeals.filter(dealPlacement => deals[dealPlacement.slug] !== undefined)
    }

    return {
      goldDeals,
      silverDeals,
      selfServeDeals,
    }
  }
)

const filterCategoryDealsSelector = (
  state: ApplicationState,
  deals: DealPlacement[],
  slug: string,
  brands: string[],
  type: DealType,
  ignoreCheck?: boolean
): DealPlacement[] => {
  const isSubCat = ignoreCheck ? ignoreCheck : isSubcategory(state, slug)

  return deals.reduce<DealPlacement[]>((deals, currentDeal) => {
    const dealFromState = state.data.deals[currentDeal.slug]

    if (!dealFromState) {
      return deals
    }

    if (brands.length <= 0 || (dealFromState.brandSlug && brands.includes(dealFromState.brandSlug))) {
      if (type === 'gold' && currentDeal.position !== -1) {
        deals.push(currentDeal)
      }

      // Add silver/self serve deals
      if (isSubCat && type !== 'gold') {
        deals.push(currentDeal)
      }
    }

    return deals
  }, [])
}

const isEventRunning = async (event: Event | undefined): Promise<boolean> => {
  const now = Math.floor(Date.now() / 1000)

  const running = event === undefined ? false : event.start <= now && event.end > now

  if (running) {
    return true
  }

  return isUserAdmin()
}

const isEventRunningUsingNetworkTime = async (event: Event | undefined, networkTime: number): Promise<boolean> => {
  const now = networkTime

  const running = event === undefined ? false : event.start <= now && event.end > now

  if (running) {
    return true
  }

  return isUserAdmin()
}

const getTimeoutToNextRunUsingNetworkTime = (event: Event | undefined, networkTime: number): number => {
  const defaultTimeout = 10
  if (!event) {
    return defaultTimeout
  }
  const now = networkTime
  const diffToStart = event.start - now
  const diffToEnd = event.end - now
  const refreshRange = 30 // in minutes
  const switchPoint = 1 // in minutes
  if (diffToStart <= 0) {
    return diffToEnd > 0 ? diffToEnd : 3600
  }
  if (diffToStart > 60 * switchPoint && diffToStart <= 60 * refreshRange) {
    return 60
  }
  if (diffToStart > 0 && diffToStart <= 60 * switchPoint) {
    return 10
  }
  if (diffToStart > 60 * refreshRange) {
    return diffToStart - 60 * refreshRange
  }
  return defaultTimeout
}

const getTimeoutToNextRun = (event: Event | undefined): number => {
  const defaultTimeout = 10
  if (!event) {
    return defaultTimeout
  }
  const now = Math.floor(Date.now() / 1000)
  const diffToStart = event.start - now
  const diffToEnd = event.end - now
  const refreshRange = 30 // in minutes
  const switchPoint = 1 // in minutes
  if (diffToStart <= 0) {
    return diffToEnd > 0 ? diffToEnd : 3600
  }
  if (diffToStart > 60 * switchPoint && diffToStart <= 60 * refreshRange) {
    return 60
  }
  if (diffToStart > 0 && diffToStart <= 60 * switchPoint) {
    return 10
  }
  if (diffToStart > 60 * refreshRange) {
    return diffToStart - 60 * refreshRange
  }
  return defaultTimeout
}

const bannerBySlugSelector = (state: ApplicationState, slug: string): Banner | undefined => {
  return state.data.banners.find(banner => banner.slug === slug)
}

const nonEventConfigSelector = (state: ApplicationState) => {
  return state.data
}

const pageSelector = (state: ApplicationState, slug: string): CmsPage | undefined =>
  state.data.pages[slug.toLowerCase()]

const cfDealsSelector = (state: ApplicationState): LandingPageDeals | undefined => {
  if (!state.data.cfDeals) return undefined

  const { public: publicList } = state.data.cfDeals

  const featureDeals = filterExpired(publicList.filter(deal => deal?.isFeaturedDeal))
  const nonFeatureDeals = publicList.filter(deal => !deal?.isFeaturedDeal)

  return {
    featureDeals: featureDeals ? featureDeals : undefined,
    deals: nonFeatureDeals ? nonFeatureDeals : undefined,
  }
}

export {
  brandSelector,
  brandSelectorByName,
  brandDetailedSelector,
  brandsForDealsSelector,
  brandsSelector,
  brandsFromSlugsSelector,
  categorySelector,
  categoriesForCurrentEventSelector,
  dealsForBrandSelector,
  dealsForCategorySelector,
  dealsSelector,
  currentEventFromEventsSelector,
  currentEventFromEventsSelectorUsingNetworkTime,
  currentEventSelector,
  currentAndFutureEventsSelector,
  currentAndFutureEventsSelectorUsingNetworkTime,
  dictionaryValueSelector,
  groupBrandsSelector,
  isEventRunning,
  isEventRunningUsingNetworkTime,
  getTimeoutToNextRun,
  getTimeoutToNextRunUsingNetworkTime,
  partnersForCurrentEventSelector,
  interstitialPagePartnersForCurrentEventSelector,
  bannerBySlugSelector,
  filterCategoryDealsSelector,
  categoryDealsSelector,
  nonEventConfigSelector,
  membershipBenefitsForCurrentEventSelector,
  pageSelector,
  brandsForCurrentPartnerSelector,
  partnersForNonEventSelector,
  cfDealsSelector,
}
