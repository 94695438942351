import { Auth, SignUpParams } from '@aws-amplify/auth'
import { Modal } from 'antd'
import { AuthPieceProps, SignUp as AwsSignUp } from 'aws-amplify-react'
import bind from 'bind-decorator'
import { AnyObject, Form, FormRenderProps } from 'react-final-form'
import { connect } from 'react-redux'
import { phoneToAws } from '../../components'
import { history } from '../../history'
import { ApplicationState } from '../../reducers'
import { dataLayerPushEvent, dataLayerPushPageType } from '../../services/gtm'
import { SignUpWithBenefits } from './SignUpWithBenefits'
import { SignUpFormSubmission } from './types'

const PRE_SIGNUP_LAMBDA_EXCEPTION = 'UserLambdaValidationException'
const PRE_SIGNUP_PREFIX_MSG = 'PreSignUp failed with error'

type OwnProps = {
  setPassword: (password: string) => void
}

type ComponentProps = OwnProps & {
  isJoin: boolean
}

type Props = AuthPieceProps & ComponentProps

type State = {
  error?: Error
}

class AwsSignUpWithData extends AwsSignUp<Props, State> {
  readonly state: State = {}

  componentDidUpdate() {
    if (this.props.isJoin) {
      this.changeState('signUp')
      dataLayerPushPageType('Signup')
    } else {
      dataLayerPushPageType('Login')
    }
  }

  @bind
  async onSubmit(values: AnyObject) {
    const { termsncondition } = values
    // eslint-disable-next-line @typescript-eslint/no-this-alias
    const self = this
    if (!termsncondition) {
      Modal.confirm({
        title: 'Are you sure?',
        content: `By opting out of marketing messages you will miss out on all clues for our 99% off deals,
                    exclusive member offers and the latest info on upcoming Click Frenzy events.`,
        okText: 'Opt in',
        cancelText: 'Opt out',
        onOk() {
          values.emails = true
          self.onModalConfirm(values)
        },
        onCancel() {
          self.onModalConfirm(values)
        },
      })
    } else {
      this.onModalConfirm(values)
    }
  }

  @bind
  async onModalConfirm(values: AnyObject) {
    const { email, password, termsncondition, firstName, gender, lastName, mobile, postcode } =
      values as SignUpFormSubmission

    this.props.setPassword(password)

    const address = {
      /*  street_address: escape(addressone),
            address_2: `${addresstwo ? escape(addresstwo) : ''}`,
            locality: escape(suburb),
            region: state, */
      postal_code: postcode,
      country: process.env.REACT_APP_REGION === 'NZ' ? 'New Zealand' : 'Australia',
    }

    const signupInfo: SignUpParams = {
      password,
      username: email,
      attributes: {
        email,
        gender,
        address: JSON.stringify(address),
        'custom:postcode': postcode || '',
        'custom:marketing_opt_in': termsncondition ? 'true' : 'false',
        given_name: escape(firstName),
        family_name: escape(lastName),
        phone_number: phoneToAws(mobile),
      },
    }

    try {
      dataLayerPushEvent('accountRequest', '', '')
      this.setState({ error: undefined })
      const result = await Auth.signUp(signupInfo)
      history.replace('/account')
      this.changeState('confirmSignUp', result.user.getUsername())
      window.scrollTo(0, 0)
    } catch (error: any) {
      if (error.code === PRE_SIGNUP_LAMBDA_EXCEPTION && error.message) {
        error.message = error.message.replace(PRE_SIGNUP_PREFIX_MSG, '')
      }
      this.setState({ error })
    }
  }

  @bind
  renderForm({ handleSubmit }: FormRenderProps) {
    const { error } = this.state
    return (
      <SignUpWithBenefits
        handleSubmit={handleSubmit}
        error={error}
      />
    )
  }

  showComponent() {
    return (
      <Form
        onSubmit={this.onSubmit}
        render={this.renderForm}
      />
    )
  }
}

const mapStateToProps = (state: ApplicationState, ownProps: OwnProps): ComponentProps => {
  const params = new URLSearchParams(state.router.location.search)

  return {
    ...ownProps,
    isJoin: params.get('join') !== null,
  }
}

const SignUpWithData = connect(mapStateToProps)(AwsSignUpWithData)

export { SignUpWithData }
