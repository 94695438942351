import * as React from 'react'
import BrandDealList from '../deal-list/BrandDealList'
import Brand from '../../models/brand'
import { Grid } from '../../components/grid'
import './BrandDeals.scss'
import { DictionaryValue } from '../../components/dictionary-value'

export type Props = {
    brand: Brand | undefined
}

class BrandDeals extends React.Component<Props> {
    render() {
        const { brand } = this.props

        if (brand === undefined) {
            return null
        }

        const brandDealsTitle = <DictionaryValue token='all.deals.by' />

        return (
            <div className='brand-deals'>
                <Grid className='brand-deals__title-grid'>
                    <div className='row'>
                        <div className='col-xs-12'>
                            <h3 className='brand-deals__title'>{brandDealsTitle} {brand.name}</h3>
                        </div>
                    </div>
                </Grid>
                <BrandDealList slug={brand.slug} />
            </div>
        )
    }
}

export default BrandDeals
