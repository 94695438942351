import ReactHtmlParser, { convertNodeToElement, Transform } from 'react-html-parser'

const transformOpenAllAnchorsInNewWindow: Transform = (node: any, index: number) => {
    if (node.type === 'tag' && node.name === 'a' && node.attribs) {
        if (node.attribs.class && node.attribs.class.includes('blankIgnore')) {
            return
        }
        node.attribs.target = '_blank'
        return convertNodeToElement(node, index, transformOpenAllAnchorsInNewWindow)
    }
}

const convertStringToReactComponents = (html: string, transform?: Transform) => {
    if (transform) {
        return ReactHtmlParser(html, { transform })
    }

    return ReactHtmlParser(html)
}

export {
    transformOpenAllAnchorsInNewWindow,
    convertStringToReactComponents
}
