import moment from 'moment'
import { useEffect, useState } from 'react'

type Props = {
  to: number
}

const PopupCountdownTimer = ({ to }: Props) => {
  const [countdown, setCountdown] = useState({ minutes: 0, seconds: 0 })
  const [timer, setTimer] = useState(0)

  useEffect(() => {
    setTimer(
      window.setInterval(() => {
        const now = moment()
        const target = moment.unix(to)
        const diff = moment.duration(target.diff(now))

        const minutes = Math.max(diff.minutes(), 0)
        const seconds = Math.max(diff.seconds(), 0)
        setCountdown({ minutes, seconds })
      }, 1000)
    )

    return () => window.clearInterval(timer)
  }, [])

  const format = new Intl.NumberFormat('en-AU', { style: 'decimal', minimumIntegerDigits: 2 }).format

  return (
    <table>
      <tbody>
        <tr className='popup-card__countdown__numbers'>
          <td>{format(countdown.minutes)}</td>
          <td>:</td>
          <td>{format(countdown.seconds)}</td>
        </tr>
        <tr className='popup-card__countdown__text'>
          <td>M</td>
          <td />
          <td>S</td>
        </tr>
      </tbody>
    </table>
  )
}

export default PopupCountdownTimer
