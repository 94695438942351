import * as React from 'react'
import { ConfirmSignUp as AwsConfirmSignUp, AuthPieceProps } from 'aws-amplify-react'
import { ConfirmSignUp } from './ConfirmSignUp'
import { dataLayerPushPageType } from '../../services/gtm'
import { ApplicationState } from '../../reducers'
import { connect } from 'react-redux'

type OwnProps = {
  getPassword: () => string
}

type ComponentProps = OwnProps & {
  isConfirm: boolean
  username: string | null
  confirmationCode: string | null
  email: string | null
}

type Props = AuthPieceProps & ComponentProps

type State = {
  error?: Error
}

class AwsConfirmSignUpWithData extends AwsConfirmSignUp<Props, State> {
  readonly state: State = {}

  componentDidUpdate() {
    if (this.props.isConfirm) {
      this.changeState('confirmSignUp')
      dataLayerPushPageType('ConfirmSignUp')
    } else {
      dataLayerPushPageType('Login')
    }
  }

  showComponent() {
    const { username, email, confirmationCode } = this.props
    const { error } = this.state

    return (
      <ConfirmSignUp
        username={username !== null ? username : this.usernameFromAuthData()}
        email={email}
        confirmationCode={confirmationCode}
        error={error}
      />
    )
  }
}

const mapStateToProps = (state: ApplicationState, ownProps: OwnProps): ComponentProps => {
  const params = new URLSearchParams(state.router.location.search)

  return {
    ...ownProps,
    isConfirm: params.get('confirm') !== null,
    username: params.get('username'),
    confirmationCode: params.get('code'),
    email: params.get('email'),
  }
}

const ConfirmSignUpWithData = connect(mapStateToProps)(AwsConfirmSignUpWithData)

export { ConfirmSignUpWithData }
