import actionCreatorFactory from 'typescript-fsa'
import { AddressFormat, FavouriteDeal } from './types'

const actionCreator = actionCreatorFactory('ACCOUNT')

export interface UserActionInput {
    username: string,
    email: string,
    favDeals?: FavouriteDeal[],
    isAdmin?: boolean,
    group?: string,
    mobile?: string
    address?: AddressFormat
    firstName?: string
    lastName?: string
    gender?: string
    marketing?: boolean
}

export const signIn = actionCreator<UserActionInput>('SIGN_IN')
export const updateAccount = actionCreator<Partial<UserActionInput>>('UPDATE_ACCOUNT')
export const signOut = actionCreator('SIGN_OUT')
