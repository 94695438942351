import { ApplicationState } from '../../../reducers'
import { itemLoading, itemLoaded } from '../loading/actions'
import { loadFooterLinks, loadSocialAccounts } from '../../../services/Api'
import { footerLinksLoaded, socialAccountsLoaded } from '../../../data/actions'
import FooterLink from '../../../models/footerLink'
import SocialAccount from '../../../models/socialAccount'

const loadFooterData = () => {
    return async (dispatch: any, getState: () => ApplicationState) => {
        const state = getState()
        const footerLinks = state.data.footerLinks
        const socialAccounts = state.data.socialAccounts

        dispatch(itemLoading())

        // eslint-disable-next-line no-async-promise-executor
        const footerLinksPromise = new Promise<void>(async (resolve, reject) => {
            if (footerLinks.length > 0) {
                resolve()
                return
            }

            let links: FooterLink[] = []

            try {
                links = await loadFooterLinks()
            } catch (e) {
                reject(e)
                return
            }

            dispatch(footerLinksLoaded(links))
            resolve()
        })

        // eslint-disable-next-line no-async-promise-executor
        const socialAccountsPromise = new Promise<void>(async (resolve, reject) => {
            if (socialAccounts.length > 0) {
                resolve()
                return
            }

            let accounts: SocialAccount[] = []

            try {
                accounts = await loadSocialAccounts()
            } catch (e) {
                reject(e)
                return
            }

            dispatch(socialAccountsLoaded(accounts))
            resolve()
        })

        await Promise.all([
            footerLinksPromise,
            socialAccountsPromise,
        ]).finally(() => {
            dispatch(itemLoaded())
        })
    }
}

export {
    loadFooterData,
}
