import * as React from 'react'
import { Grid } from '../../components/grid'
import './Spacing.scss'

interface ISpacing {
    name: string
    unit: string
}

const spacingData: ISpacing[] = [
    {
        name: 'none',
        unit: '0px',
    },
    {
        name: '2xs',
        unit: '2px',
    },
    {
        name: 'xs',
        unit: '4px',
    },
    {
        name: 'default',
        unit: '8px',
    },
    {
        name: 'md',
        unit: '12px',
    },
    {
        name: 'lg',
        unit: '16px',
    },
    {
        name: 'xl',
        unit: '20px',
    },
    {
        name: '2xl',
        unit: '24px',
    },
    {
        name: '3xl',
        unit: '28px',
    },
    {
        name: '4xl',
        unit: '32px',
    },
    {
        name: '5xl',
        unit: '36px',
    },
    {
        name: '6xl',
        unit: '40px',
    },
    {
        name: '7xl',
        unit: '60px',
    },
]

class Spacing extends React.Component {
    render() {
        const variationSwatchClassName = 'styleguide-spacing__element styleguide-spacing__element--index-'
        const spacingSwatchClassName = 'styleguide-spacing__spacer styleguide-spacing__spacer--unit-'

        return (
            <Grid>
                <div className='row'>
                    <div className='col-xs-12'>
                        <h2>Spacing</h2>
                        <ul className='styleguide-spacing wrapper'>
                            {spacingData.map((spacingUnit, index) => (
                                <li key={spacingUnit.name} className={`${variationSwatchClassName}${index}`}>
                                    <div className={`${spacingSwatchClassName}${spacingUnit.name}`} />
                                    <h4>{spacingUnit.name}</h4>
                                    <p>{spacingUnit.unit}</p>
                                </li>
                            ))}
                        </ul>
                    </div>
                </div>
            </Grid>
        )
    }
}

export default Spacing
